import { relativeRoute, ROUTES } from 'general/constants/routes'
import { DefaultGenerics, Navigate, Route } from '@tanstack/react-location'

export const couriersRoutes: Route<DefaultGenerics> = {
  path: relativeRoute(ROUTES.app.organization.couriers.root()),
  children: [
    {
      path: relativeRoute(ROUTES.app.organization.couriers.list()),
      element: () => import('./couriers-list').then((el) => <el.default />),
    },
    {
      path: relativeRoute(ROUTES.app.organization.couriers.details.root()),
      element: () => import('./courier-details').then((el) => <el.default />),
      children: [
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.account()
          ),
          element: () =>
            import('./courier-account').then((el) => <el.default />),
        },
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.orders()
          ),
          element: () =>
            import('./courier-orders').then((el) => <el.default />),
        },
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.payments()
          ),
          element: <div>Payments</div>,
        },
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.hardware()
          ),
          element: <div>Hardware</div>,
        },
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.blockings()
          ),
          element: <div>Blockings</div>,
        },
        {
          path: relativeRoute(
            ROUTES.app.organization.couriers.details.activityLog()
          ),
          element: <div>Activity log</div>,
        },
        {
          element: (
            <Navigate
              to={relativeRoute(
                ROUTES.app.organization.couriers.details.account()
              )}
            />
          ),
        },
      ],
    },
    {
      element: (
        <Navigate to={relativeRoute(ROUTES.app.organization.couriers.list())} />
      ),
    },
  ],
}
