import {
  Navigate,
  Outlet,
  ReactLocation,
  Router,
} from '@tanstack/react-location'
import { parseSearch, stringifySearch } from '@tanstack/react-location-jsurl'
import { fullRoute, relativeRoute, ROUTES } from 'general/constants/routes'
import AppGuard from 'modules/auth/guards/AppGuard'
import AuthGuard from 'modules/auth/guards/AuthGuard'
import RedirectAppGuard from 'modules/auth/guards/RedirectAppGuard'
import RedirectRootGuard from 'modules/auth/guards/RedirectRootGuard'
import RedirectSetupGuard from 'modules/auth/guards/RedirectSetupGuard'
import SetupGuard from 'modules/auth/guards/SetupGuard'
import { couriersRoutes } from 'modules/couriers/router'
import { liveMapRoutes } from 'modules/live-map/router'
import { ordersRoutes } from 'modules/orders/router'
import { pricingManagementRouter } from 'modules/pricing/router'
import { apiManagementRouter } from 'modules/settings/router'
import React from 'react'
import { selfServiceRoutes } from '../self-service/router'

const location = new ReactLocation({ parseSearch, stringifySearch })

export const EntryRouter: React.FC = () => {
  return (
    <Router
      location={location}
      routes={[
        {
          element: <AppGuard />,
          path: relativeRoute(ROUTES.app.root),
          children: [
            {
              path: relativeRoute(ROUTES.app.couriers.root()),
              element: () =>
                import('../courier-app').then((el) => <el.default />),
            },
            {
              path: relativeRoute(ROUTES.app.organization.root()),
              element: () =>
                import('../merchant-app-shell').then((el) => <el.default />),
              children: [
                {
                  path: relativeRoute(ROUTES.app.organization.dashboard()),
                  element: () =>
                    import('../dashboard').then((el) => <el.default />),
                },
                ordersRoutes,
                couriersRoutes,
                ...apiManagementRouter,
                liveMapRoutes,
                ...pricingManagementRouter,
                {
                  element: (
                    <Navigate
                      to={relativeRoute(ROUTES.app.organization.orders.root())}
                    />
                  ),
                },
              ],
            },
            {
              element: <RedirectAppGuard />,
            },
          ],
        },
        {
          element: <AuthGuard />,
          path: relativeRoute(ROUTES.auth.root),
          children: [
            {
              path: relativeRoute(ROUTES.auth.signIn()),
              element: () =>
                import('../auth/SignIn').then((el) => <el.default />),
            },
            {
              path: relativeRoute(ROUTES.auth.signUpCourier()),
              element: () =>
                import('../auth/SignUpCourier').then((el) => <el.default />),
            },
            {
              path: relativeRoute(ROUTES.auth.signUpMerchant()),
              element: () =>
                import('../auth/SignUpMerchant').then((el) => <el.default />),
            },
            {
              path: relativeRoute(ROUTES.auth.passwordReset()),
              element: () =>
                import('../auth/PasswordReset').then((el) => <el.default />),
            },
            {
              element: <Navigate to={fullRoute(ROUTES.auth.signIn())} />,
            },
          ],
        },
        {
          element: <SetupGuard />,
          path: relativeRoute(ROUTES.setup.root),
          children: [
            {
              path: relativeRoute(ROUTES.setup.profile()),
              element: () =>
                import('../auth/SetupProfile').then((el) => <el.default />),
            },
            {
              path: relativeRoute(ROUTES.setup.organization()),
              element: () =>
                import('../auth/SetupOrganization').then((el) => (
                  <el.default />
                )),
            },
            {
              path: relativeRoute(ROUTES.setup.courier()),
              element: () =>
                import('../auth/SetupCourier').then((el) => <el.default />),
            },
            {
              element: <RedirectSetupGuard />,
            },
          ],
        },
        selfServiceRoutes,
        {
          element: <RedirectRootGuard />,
        },
      ]}
    >
      <Outlet />
    </Router>
  )
}
