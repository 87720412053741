import { useLocaleContext } from '@barbora-delivery/merchant-ui'
import { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { getMessagesForLocale } from 'translations/locales'

export const IntlContextProvider: FC = ({ children }) => {
  const [locale] = useLocaleContext()
  return (
    <IntlProvider locale="en" messages={getMessagesForLocale(locale)}>
      {children}
    </IntlProvider>
  )
}
