import { LanguageMenu } from '../elements/LanguageMenu'
import { ReactComponent as BarboraExpressLogo } from 'assets/icons/barbora-express-logo.svg'

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 relative">
      <div className="absolute right-12 top-12">
        <LanguageMenu />
      </div>
      <div className="max-w-md w-full space-y-8 ">
        <div className="flex justify-center">
          <BarboraExpressLogo className="scale-75" />
        </div>
        {children}
      </div>
    </div>
  )
}
