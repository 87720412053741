import { Spinner } from '@barbora-delivery/merchant-ui'
import { FC } from 'react'

export const LoaderWindow: FC = () => {
  return (
    <div className="text-indigo-500 h-full">
      <Spinner size={'LARGE'} />
    </div>
  )
}
