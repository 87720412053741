import { useOrganizationContext } from '../contexts/OrganizationContextProvider'
import { useLinkWithOrganization } from './useLinkWithOrganization'
import { fullRoute, relativeRoute, ROUTES } from '../constants/routes'

export const useNavigationForPricing = () => {
  const [currentOrganization] = useOrganizationContext()
  const route = useLinkWithOrganization()

  let rootRoute, merchantsPricingRoute, couriersPricingRoute

  if (currentOrganization !== 'main') {
    throw new Error('Current organization is not allowed')
  }
  rootRoute = ROUTES.app.organization.pricing.root()
  merchantsPricingRoute = ROUTES.app.organization.pricing.merchants.root()
  couriersPricingRoute = ROUTES.app.organization.pricing.couriers.root()

  return {
    fullRoutes: {
      rootRoute: route(fullRoute(rootRoute)),
      merchantsPricingRoute: route(fullRoute(merchantsPricingRoute)),
      couriersPricingRoute: route(fullRoute(couriersPricingRoute)),
    },
    relativeRoutes: {
      rootRoute: relativeRoute(rootRoute),
      merchantsPricingRoute: relativeRoute(merchantsPricingRoute),
      couriersPricingRoute: relativeRoute(couriersPricingRoute),
    },
  }
}
