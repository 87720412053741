import poliline from '@mapbox/polyline'
import * as Turf from '@turf/turf'
import { Feature } from '@turf/turf'
import { Layer, Source } from 'react-map-gl'

export function RegionBoundaries(props: { regions: Feature<any> }) {
  return (
    <>
      <Source id="regions" type="geojson" data={props.regions}>
        <Layer
          id="region"
          paint={{
            'fill-color': 'rgb(5, 30, 52)',
            'fill-opacity': 0.1,
          }}
          type={'fill'}
        />
      </Source>
    </>
  )
}

export const GeoJSONRoute = (props: {
  route: string
  color: string
  id: string
}) => {
  const json = poliline.decode(props.route).map((q) => q.reverse())
  // const asd = poliline.toGeoJSON(props.route)
  // console.log(JSON.stringify(asd))
  const line = Turf.lineString(json)
  const collection = Turf.featureCollection([line])
  return (
    <>
      <Source key={'asdas'} type="geojson" data={collection}>
        <Layer
          id={'Zcxczx'}
          layout={{
            'line-join': 'round',
            'line-cap': 'round',
          }}
          paint={{
            'line-color': props.color,
            'line-width': 5,
          }}
          type={'line'}
        />
      </Source>
    </>
  )
}
