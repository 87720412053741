import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  organization: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  tokenHash: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Address = {
  __typename?: 'Address';
  _id: Scalars['ID'];
  address: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  coordinates: Array<Scalars['Float']>;
  id: Scalars['String'];
  info: AddressInfo;
  meta: Array<AddressMeta>;
  notes?: Maybe<Scalars['String']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  doorCode?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  staircase?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  address?: InputMaybe<Scalars['String']>;
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  coordinates?: InputMaybe<CoordinatesInput>;
  doorCode?: InputMaybe<Scalars['String']>;
  flat?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Array<AddressMetaInput>>;
  notes?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  staircase?: InputMaybe<Scalars['String']>;
};

export type AddressMeta = {
  __typename?: 'AddressMeta';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type AddressMetaInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Age limitation options */
export enum AgeLimitation {
  N18 = 'N18',
  N20 = 'N20',
  None = 'NONE'
}

export type BasePaginationQuery = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type BecomeCourierInput = {
  color?: InputMaybe<Scalars['String']>;
  licensePlate?: InputMaybe<Scalars['String']>;
  vehicleDescription: Scalars['String'];
  vehicleType: Scalars['String'];
};

export type BreakState = {
  __typename?: 'BreakState';
  action: Scalars['String'];
  canContinueAt: Scalars['DateTime'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export enum ChartScale {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type CollectPackagesState = {
  __typename?: 'CollectPackagesState';
  action: Scalars['String'];
  address: Address;
  batchId: Scalars['String'];
  id: Scalars['ID'];
  packages: Array<Package>;
  /** @deprecated Later on this field will be removed */
  taskId: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type CoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinatesOutput = {
  __typename?: 'CoordinatesOutput';
  _id: Scalars['ID'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  tuple: Array<Scalars['Float']>;
};

export type Courier = {
  __typename?: 'Courier';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  isOnline: Scalars['Boolean'];
  orders: PaginatedOrderObjectResponse;
  payoutByOrder: Array<CourierPayoutObject>;
  payoutByUnit: Array<CourierPayoutByUnitObject>;
  profile?: Maybe<CouriersProfileObject>;
  stats: Array<CourierStats>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  vehicles: Array<Vehicle>;
};


export type CourierOrdersArgs = {
  query?: InputMaybe<OrderQueryInput>;
};


export type CourierPayoutByUnitArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type CourierAppStateV2 = BreakState | CollectPackagesState | DeliverPackageState | GoToPickupLocationState | InactiveState | LoadingState | PickupOfferState | WaitingForOrdersState;

export type CourierDeviceInfo = {
  __typename?: 'CourierDeviceInfo';
  /** Coordinate tuple [lng, lat] */
  position?: Maybe<Array<Scalars['Float']>>;
  timestamp: Scalars['Float'];
};

export type CourierListQueryInput = {
  isOnline?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<CouriersSortBy>>;
};

export type CourierOrderReportObject = {
  __typename?: 'CourierOrderReportObject';
  createdAt: Scalars['DateTime'];
  order: Order;
  orderId: Scalars['String'];
  sum: Scalars['Float'];
};

export type CourierPayoutByUnitObject = {
  __typename?: 'CourierPayoutByUnitObject';
  orders: Array<CourierOrderReportObject>;
  sum: Scalars['Float'];
  unit: Scalars['String'];
};

export type CourierPayoutObject = {
  __typename?: 'CourierPayoutObject';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  order: Order;
  sum: Scalars['Float'];
};

export type CourierProfileInput = {
  activityCode: Scalars['String'];
  iban: Scalars['String'];
  identificationNumber: Scalars['String'];
};

export enum CourierSortFields {
  CourierFirstName = 'COURIER_FIRST_NAME',
  CourierLastName = 'COURIER_LAST_NAME',
  CreatedAt = 'CREATED_AT',
  LastLocation = 'LAST_LOCATION'
}

export enum CourierStateEnum {
  Break = 'BREAK',
  Loading = 'LOADING',
  TaskActive = 'TASK_ACTIVE',
  TaskStarted = 'TASK_STARTED',
  Waiting = 'WAITING'
}

export type CourierStateObject = {
  __typename?: 'CourierStateObject';
  courierUserId: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
  state: CourierStateEnum;
  title: Scalars['String'];
};

export type CourierStats = {
  __typename?: 'CourierStats';
  successRate: Scalars['Float'];
  totalOrders: Scalars['Int'];
};

export type CouriersProfileObject = {
  __typename?: 'CouriersProfileObject';
  _id: Scalars['ID'];
  activityCode: Scalars['String'];
  courierId: Scalars['Int'];
  iban: Scalars['String'];
  identificationNumber: Scalars['String'];
};

export type CouriersSortBy = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: CourierSortFields;
};

export type CouriersStates = {
  __typename?: 'CouriersStates';
  /** List of active couriers */
  states: Array<CourierStateObject>;
};

export type CouriersStats = {
  __typename?: 'CouriersStats';
  currentCapacity: Scalars['Int'];
  maxCapacity: Scalars['Int'];
};

export type CouriersWorkHoursReport = {
  __typename?: 'CouriersWorkHoursReport';
  items: Array<WorkHoursItem>;
  unit: GroupPeriodUnit;
};

export type CreateAccessTokenInput = {
  token: Scalars['String'];
};

export type CurrencyValue = {
  __typename?: 'CurrencyValue';
  displayValue: Scalars['String'];
  value: Scalars['Float'];
};


export type CurrencyValueDisplayValueArgs = {
  currency?: InputMaybe<Scalars['String']>;
};

export type DateRange = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type DeliverPackageState = {
  __typename?: 'DeliverPackageState';
  action: Scalars['String'];
  canComplete: Scalars['Boolean'];
  deliverUntil: Scalars['DateTime'];
  geometry: Scalars['String'];
  id: Scalars['ID'];
  package: FullPackage;
  /** @deprecated Later on this field will be removed */
  taskId: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export type DropOffInput = {
  address: AddressInput;
};

export type FullPackage = {
  __typename?: 'FullPackage';
  address: Address;
  barcode?: Maybe<Scalars['String']>;
  internalOrderId: Scalars['String'];
  merchantOrderId: Scalars['String'];
  n20?: Maybe<Scalars['Boolean']>;
  shortOrderId: Scalars['String'];
  status: OrderStatus;
};

export type GetQueryInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  courierUserId?: InputMaybe<Scalars['String']>;
};

export type GoToPickupLocationState = {
  __typename?: 'GoToPickupLocationState';
  action: Scalars['String'];
  address: Address;
  geometry: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export enum GroupPeriodUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type InactiveState = {
  __typename?: 'InactiveState';
  action: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type InviteMemberInput = {
  email: Scalars['String'];
  role: Scalars['String'];
};

export type JoinOrganizationInput = {
  invite: Scalars['String'];
};

export type ListAccessTokenQuery = {
  organizationId: Scalars['String'];
};

export type LoadingState = {
  __typename?: 'LoadingState';
  action: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type LocationPoint = {
  __typename?: 'LocationPoint';
  address: Scalars['String'];
  coordinates: CoordinatesOutput;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Finish break. Will throw if courier canContinueAt > now
   *
   */
  CourierActionConfirm: Scalars['Boolean'];
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Finish break. Will throw if courier canContinueAt > now
   *
   */
  CourierActionTerminate: Scalars['Boolean'];
  /** By calling this endpoint user will became courier */
  CourierCreate: Courier;
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Finish break. Will throw if courier canContinueAt > now
   *
   */
  CourierFinishBreak: Scalars['Boolean'];
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Register courier device
   *
   */
  CourierRegisterFMC: Scalars['Boolean'];
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Go online/offline
   *
   */
  CourierSetActivity: Scalars['Boolean'];
  CourierUpdateLocation: Scalars['Boolean'];
  /**
   * COURIER ONLY
   *     -----------------------------------
   *     Update courier profile
   *
   */
  CourierUpdateProfile: CouriersProfileObject;
  /**
   * ADMIN ONLY
   *     -----------------------------------
   *     Update courier information
   *
   */
  ManagementCourierUpdateProfile: CouriersProfileObject;
  /** Gracefully terminates order */
  OrderComplete: Order;
  /** Create order in organization */
  OrderCreate: Order;
  OrderListDownloadCSV: OrderDownloadUrl;
  /** Prioritize specific order */
  OrderPrioritize: Scalars['Boolean'];
  OrderTaskStatusUpdate: Scalars['Boolean'];
  /** Gracefully terminates order */
  OrderTerminate: Order;
  /** Update order in organization */
  OrderUpdate: Order;
  OrganizationAccessTokenCreate: Scalars['Boolean'];
  OrganizationAccessTokenDelete: Scalars['Boolean'];
  /** Create organization unit. This endpoint only accessible with JWT based authorization */
  OrganizationCreate: Organization;
  OrganizationInviteMember: Scalars['Boolean'];
  OrganizationJoin: OrganizationJoinOutput;
  /** Updates organization profile. */
  OrganizationUpdate: Organization;
  OrganizationWebhookCreate: Webhook;
  OrganizationWebhookDelete: Scalars['Boolean'];
  UserSignUpWithIdToken: Scalars['Boolean'];
  UserUpdateProfile: User;
};


export type MutationCourierActionConfirmArgs = {
  action: Scalars['String'];
};


export type MutationCourierActionTerminateArgs = {
  action: Scalars['String'];
  failure?: InputMaybe<OrderFailureReasonInput>;
};


export type MutationCourierCreateArgs = {
  data: BecomeCourierInput;
};


export type MutationCourierRegisterFmcArgs = {
  fmcToken: Scalars['String'];
};


export type MutationCourierSetActivityArgs = {
  isOnline: Scalars['Boolean'];
};


export type MutationCourierUpdateLocationArgs = {
  location: CoordinatesInput;
};


export type MutationCourierUpdateProfileArgs = {
  data: CourierProfileInput;
};


export type MutationManagementCourierUpdateProfileArgs = {
  courierId?: InputMaybe<Scalars['Int']>;
  profile: CourierProfileInput;
};


export type MutationOrderCompleteArgs = {
  courierUserId: Scalars['String'];
  orderId: Scalars['String'];
};


export type MutationOrderCreateArgs = {
  data: OrderCreateInput;
};


export type MutationOrderListDownloadCsvArgs = {
  query?: InputMaybe<OrderQueryInput>;
};


export type MutationOrderPrioritizeArgs = {
  courierUserId?: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
};


export type MutationOrderTaskStatusUpdateArgs = {
  data: UpdateTaskStatusInput;
};


export type MutationOrderTerminateArgs = {
  note: Scalars['String'];
  orderId: Scalars['String'];
  reason: OrderFailureReason;
};


export type MutationOrderUpdateArgs = {
  data: OrderUpdateInput;
};


export type MutationOrganizationAccessTokenCreateArgs = {
  data: CreateAccessTokenInput;
};


export type MutationOrganizationAccessTokenDeleteArgs = {
  accessTokenId: Scalars['String'];
};


export type MutationOrganizationCreateArgs = {
  data: OrganizationCreateInput;
};


export type MutationOrganizationInviteMemberArgs = {
  data: InviteMemberInput;
};


export type MutationOrganizationJoinArgs = {
  data: JoinOrganizationInput;
};


export type MutationOrganizationUpdateArgs = {
  data: OrganizationUpdateInput;
};


export type MutationOrganizationWebhookCreateArgs = {
  data: WebhookCreateInput;
};


export type MutationOrganizationWebhookDeleteArgs = {
  webhookId: Scalars['String'];
};


export type MutationUserSignUpWithIdTokenArgs = {
  data: SignUpInput;
};


export type MutationUserUpdateProfileArgs = {
  data: UpdateProfileInput;
};

export type Order_Active = {
  __typename?: 'ORDER_ACTIVE';
  accepted: Scalars['Boolean'];
  courierUserId: Scalars['String'];
  deadline?: Maybe<Scalars['DateTime']>;
  dropOff: Scalars['DateTime'];
  pickup: Scalars['DateTime'];
  startAfter?: Maybe<Scalars['DateTime']>;
};

export type Order_Completed = {
  __typename?: 'ORDER_COMPLETED';
  completedAt: Scalars['DateTime'];
  courierUserId: Scalars['String'];
};

export type Order_Courier_Missing = {
  __typename?: 'ORDER_COURIER_MISSING';
  courierUserId: Scalars['String'];
  dropOff: Scalars['DateTime'];
};

export type Order_Created = {
  __typename?: 'ORDER_CREATED';
  createdAt: Scalars['DateTime'];
};

export type Order_Failed = {
  __typename?: 'ORDER_FAILED';
  courierUserId: Scalars['String'];
  failedAt: Scalars['DateTime'];
};

export type Order_Need_Courier = {
  __typename?: 'ORDER_NEED_COURIER';
  dropOff: Scalars['DateTime'];
};

export type Order_Planned = {
  __typename?: 'ORDER_PLANNED';
  courierUserId: Scalars['String'];
  dropOff: Scalars['DateTime'];
  pickup: Scalars['DateTime'];
  startAfter?: Maybe<Scalars['DateTime']>;
};

export type Order_Started = {
  __typename?: 'ORDER_STARTED';
  courierUserId: Scalars['String'];
  dropOff: Scalars['DateTime'];
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ID'];
  completeBefore?: Maybe<Scalars['DateTime']>;
  courier?: Maybe<Courier>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  events: Array<OrderEvent>;
  id: Scalars['String'];
  metrics?: Maybe<OrderDeliveryStats>;
  orderId: Scalars['String'];
  organization: Organization;
  organizationId: Scalars['String'];
  payout?: Maybe<CourierPayoutObject>;
  shortOrderId?: Maybe<Scalars['String']>;
  startAfter?: Maybe<Scalars['DateTime']>;
  status: OrderStatus;
  summary?: Maybe<OrderSummary>;
  tasks: Array<Task>;
  trackingId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type OrderEventsArgs = {
  query?: InputMaybe<OrderEventsQuery>;
};

export type OrderCreateInput = {
  ageLimitation?: InputMaybe<AgeLimitation>;
  /** Unique barcode value */
  barcode?: InputMaybe<Scalars['String']>;
  completeBefore?: InputMaybe<Scalars['DateTime']>;
  dropOff: DropOffInput;
  orderId: Scalars['String'];
  pickup: PickupInput;
  /** Non unique Short ID (For display purposes) */
  shortId?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['DateTime']>;
};

export type OrderDeliveryStats = {
  __typename?: 'OrderDeliveryStats';
  /** Actual order delivery duration */
  actualDeliveryDuration?: Maybe<Scalars['Int']>;
  /** Latest courier delivery duration prediction */
  currentDeliveryDurationPrediction?: Maybe<Scalars['Int']>;
  /** Latest order lifetime prediction. { CurrentOrderCompletionPrediction - OrderCreated }  */
  currentLifeTimeDurationPrediction: Scalars['Int'];
  /** Initial courier delivery duration prediction. If difference between initial and current is more than 10 min we flag courier for bad performance */
  initialDeliveryDurationPrediction?: Maybe<Scalars['Int']>;
  /**
   * Initial order lifetime prediction. { InitialCompletionPrediction - OrderCreated }
   * If { initialLifeTimeDurationPrediction - currentLifeTimeDurationPrediction } < 0. This means that we late on our promise
   */
  initialLifeTimeDurationPrediction: Scalars['Int'];
  isLate: Scalars['Boolean'];
  isLateWithThreshold: Scalars['Boolean'];
  lifeTimePredictionDifference: Scalars['Int'];
  orderLifeTimeDuration?: Maybe<Scalars['Int']>;
};

export type OrderDownloadUrl = {
  __typename?: 'OrderDownloadUrl';
  url: Scalars['String'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  payload: Scalars['JSON'];
  type: Scalars['String'];
};

export type OrderEventsQuery = {
  types: Array<Scalars['String']>;
};

/** Failed task */
export enum OrderFailureReason {
  CourierCarAccident = 'COURIER_CAR_ACCIDENT',
  CourierVechicleFailure = 'COURIER_VECHICLE_FAILURE',
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  MerchantOrderNotFound = 'MERCHANT_ORDER_NOT_FOUND',
  Other = 'OTHER',
  Terminated = 'TERMINATED'
}

export type OrderFailureReasonInput = {
  note: Scalars['String'];
  reason: OrderFailureReason;
};

export type OrderQueryInput = {
  courierUserIds?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  /** Filter orders by organization ID. ADMIN only */
  organizationIds?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  /** Filter orders by date range */
  range?: InputMaybe<DateRange>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<OrdersSortBy>>;
  status?: InputMaybe<Array<OrderStatus>>;
};

/** Polyline encoded pickup and drop off geojson object */
export type OrderRoute = {
  __typename?: 'OrderRoute';
  dropOff: Scalars['String'];
  pickup: Scalars['String'];
};

export enum OrderSortFields {
  CourierFirstName = 'COURIER_FIRST_NAME',
  CourierLastName = 'COURIER_LAST_NAME',
  CreatedAt = 'CREATED_AT',
  OrganizationName = 'ORGANIZATION_NAME',
  Status = 'STATUS'
}

export type OrderStatsQueryInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  /** Filter orders by organization ID. ADMIN only */
  organizationIds?: InputMaybe<Array<Scalars['String']>>;
  /** Filter orders by date range */
  range?: InputMaybe<DateRange>;
  unit?: InputMaybe<ChartScale>;
};

/** The basic directions */
export enum OrderStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  CourierMissing = 'COURIER_MISSING',
  Created = 'CREATED',
  Failed = 'FAILED',
  NeedCourier = 'NEED_COURIER',
  Planned = 'PLANNED',
  Priority = 'PRIORITY',
  Started = 'STARTED'
}

export type OrderSummary = {
  __typename?: 'OrderSummary';
  _id: Scalars['ID'];
  /** Distance in meters */
  actualDropOffDistance: Scalars['Float'];
  /** Duration in milliseconds */
  actualDuration: Scalars['Int'];
  /** Distance in meters */
  actualPickupDistance: Scalars['Float'];
  actualRoute: OrderRoute;
  courierPayout: CurrencyValue;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Distance in meters */
  predictedDropOffDistance: Scalars['Float'];
  /** Duration in milliseconds */
  predictedDuration: Scalars['Int'];
  /** Distance in meters */
  predictedPickupDistance: Scalars['Float'];
  predictedRoute: OrderRoute;
};

export type OrderTracking = {
  __typename?: 'OrderTracking';
  _id: Scalars['ID'];
  arrival?: Maybe<Scalars['DateTime']>;
  courierLocation?: Maybe<CoordinatesOutput>;
  dropOff: LocationPoint;
  events: Array<PublicOrderStatusEvent>;
  merchantOrderId: Scalars['String'];
  orderId: Scalars['String'];
  pickup: LocationPoint;
  status: OrderStatus;
};

export type OrderUpdateInput = {
  ageLimitation?: InputMaybe<AgeLimitation>;
  /** Unique barcode value */
  barcode?: InputMaybe<Scalars['String']>;
  completeBefore?: InputMaybe<Scalars['DateTime']>;
  orderId: Scalars['String'];
  /** Non unique Short ID (For display purposes) */
  shortId?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['DateTime']>;
};

export type OrdersSortBy = {
  desc?: InputMaybe<Scalars['Boolean']>;
  field: OrderSortFields;
};

export type OrdersStats = {
  __typename?: 'OrdersStats';
  _id: Scalars['ID'];
  completedOrdersCountChart: Scalars['JSON'];
  failedOrdersCountChart: Scalars['JSON'];
  orderShippingDistanceChart: Scalars['JSON'];
  orderShippingDurationChart: Scalars['JSON'];
  statusCount: Array<StatusCount>;
};

export type Organization = {
  __typename?: 'Organization';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  members: Array<OrganizationMember>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrganizationCreateInput = {
  displayName: Scalars['String'];
  name: Scalars['String'];
};

export type OrganizationJoinOutput = {
  __typename?: 'OrganizationJoinOutput';
  email?: Maybe<Scalars['String']>;
  userExist: Scalars['Boolean'];
};

export type OrganizationListQueryInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type OrganizationMember = {
  __typename?: 'OrganizationMember';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  organization: Organization;
  organizationId: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type OrganizationMembersListQuery = {
  limit?: InputMaybe<Scalars['Int']>;
  /** List specific organization member */
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type OrganizationUpdateInput = {
  displayName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
};

export type Package = {
  __typename?: 'Package';
  barcode?: Maybe<Scalars['String']>;
  internalOrderId: Scalars['String'];
  merchantOrderId: Scalars['String'];
  shortOrderId: Scalars['String'];
  status: OrderStatus;
};

export type PaginatedCourierResponse = {
  __typename?: 'PaginatedCourierResponse';
  _id: Scalars['ID'];
  count: Scalars['Float'];
  items: Array<Courier>;
  limit: Scalars['Float'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedOrderObjectResponse = {
  __typename?: 'PaginatedOrderObjectResponse';
  _id: Scalars['ID'];
  count: Scalars['Float'];
  items: Array<Order>;
  limit: Scalars['Float'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedOrganizationResponse = {
  __typename?: 'PaginatedOrganizationResponse';
  _id: Scalars['ID'];
  count: Scalars['Float'];
  items: Array<Organization>;
  limit: Scalars['Float'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedRegionResponse = {
  __typename?: 'PaginatedRegionResponse';
  _id: Scalars['ID'];
  count: Scalars['Float'];
  items: Array<Region>;
  limit: Scalars['Float'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedUserResponse = {
  __typename?: 'PaginatedUserResponse';
  _id: Scalars['ID'];
  count: Scalars['Float'];
  items: Array<User>;
  limit: Scalars['Float'];
  page: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PickupInput = {
  address: AddressInput;
};

export type PickupOfferState = {
  __typename?: 'PickupOfferState';
  action: Scalars['String'];
  deadline: Scalars['DateTime'];
  geometry: Scalars['String'];
  id: Scalars['ID'];
  location: Array<Scalars['Float']>;
  /** @deprecated Later on this field will be removed */
  taskId: Scalars['Int'];
  timestamp: Scalars['DateTime'];
};

export enum PublicOrderStatus {
  /** Courier in transit to pickup targe location */
  OrderActive = 'ORDER_ACTIVE',
  /** Order successfully completed */
  OrderCompleted = 'ORDER_COMPLETED',
  /** Order created */
  OrderCreated = 'ORDER_CREATED',
  /** Order failed */
  OrderFailed = 'ORDER_FAILED',
  OrderPriority = 'ORDER_PRIORITY',
  OrderStarted = 'ORDER_STARTED'
}

export type PublicOrderStatusEvent = {
  __typename?: 'PublicOrderStatusEvent';
  createdAt: Scalars['DateTime'];
  /** @deprecated This field will be removed in favor of type field */
  event: Scalars['String'];
  type: Array<PublicOrderStatus>;
};

export type Query = {
  __typename?: 'Query';
  Courier: Courier;
  CourierList: PaginatedCourierResponse;
  CourierStats: CouriersStats;
  CourierWorkHoursStats: CouriersWorkHoursReport;
  HealthCheck: Scalars['JSON'];
  Me?: Maybe<User>;
  /** Get single order */
  Order: Order;
  OrderList: PaginatedOrderObjectResponse;
  /** Orders statistics */
  OrderStats: OrdersStats;
  /** Get organization data */
  Organization: Organization;
  OrganizationAccessTokensList: Array<AccessToken>;
  /** List accessible organizations */
  OrganizationList: PaginatedOrganizationResponse;
  OrganizationMember: User;
  OrganizationMemberList: PaginatedUserResponse;
  OrganizationWebhook?: Maybe<Webhook>;
  OrganizationWebhooksList: Array<Webhook>;
  Region: Region;
  RegionList: PaginatedRegionResponse;
  Statistics: StatisticsObject;
  User: User;
};


export type QueryCourierArgs = {
  courierId?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryCourierListArgs = {
  query?: InputMaybe<CourierListQueryInput>;
};


export type QueryCourierStatsArgs = {
  location?: InputMaybe<CoordinatesInput>;
};


export type QueryCourierWorkHoursStatsArgs = {
  courierId: Scalars['Float'];
  unit: Scalars['String'];
};


export type QueryOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryOrderListArgs = {
  query?: InputMaybe<OrderQueryInput>;
};


export type QueryOrderStatsArgs = {
  query?: InputMaybe<OrderStatsQueryInput>;
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationListArgs = {
  query?: InputMaybe<OrganizationListQueryInput>;
};


export type QueryOrganizationMemberArgs = {
  userId: Scalars['String'];
};


export type QueryOrganizationMemberListArgs = {
  query?: InputMaybe<OrganizationMembersListQuery>;
};


export type QueryOrganizationWebhookArgs = {
  webhookId: Scalars['String'];
};


export type QueryRegionArgs = {
  region: Scalars['String'];
};


export type QueryRegionListArgs = {
  query?: InputMaybe<BasePaginationQuery>;
};


export type QueryStatisticsArgs = {
  fetchSeries: Scalars['Boolean'];
  queryLabel: StatisticsQueryLabel;
  queryPeriod: StatisticsQueryPeriod;
};


export type QueryUserArgs = {
  userId: Scalars['String'];
};

export enum RefetchSubscriptionEnum {
  Courier = 'COURIER',
  Order = 'ORDER'
}

export type Region = {
  __typename?: 'Region';
  displayName: Scalars['String'];
  polygon: Array<Array<Scalars['Float']>>;
  region: Scalars['String'];
};

export type SeriesObject = {
  __typename?: 'SeriesObject';
  period: Scalars['String'];
  value: Scalars['Float'];
};

export type SignUpInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type StatisticsObject = {
  __typename?: 'StatisticsObject';
  change: Scalars['Float'];
  label: Scalars['String'];
  period: StatisticsQueryPeriod;
  series: Array<SeriesObject>;
  total: Scalars['Float'];
};

export enum StatisticsQueryLabel {
  AllOrders = 'AllOrders',
  AverageDeliveryTime = 'AverageDeliveryTime',
  AverageOrderDropOffTime = 'AverageOrderDropOffTime',
  AverageOrderPickUpTime = 'AverageOrderPickUpTime',
  DeliveredOrders = 'DeliveredOrders',
  ExpressDeliveryCost = 'ExpressDeliveryCost',
  FailedOrders = 'FailedOrders',
  PendingOrders = 'PendingOrders',
  TotalDeliveryTime = 'TotalDeliveryTime'
}

export enum StatisticsQueryPeriod {
  Day = 'day',
  Month = 'month',
  Week = 'week'
}

export type StatusCount = {
  __typename?: 'StatusCount';
  count: Scalars['Int'];
  status: OrderStatus;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * ADMIN ONLY
   *     -----------------------------------
   *     Observe courier location changes
   *
   */
  CourierLocation?: Maybe<CourierDeviceInfo>;
  /**
   * ADMIN OR COURIER
   *     -----------------------------------
   *     Courier application realtime state
   *
   */
  CouriersAppState?: Maybe<CourierAppStateV2>;
  /**
   * REQUIRES ADMIN PERMISSIONS
   *
   * Returns all active region couriers with corresponding activity status
   */
  CouriersStates?: Maybe<CouriersStates>;
  OrderTracking?: Maybe<OrderTracking>;
  Refetch?: Maybe<Scalars['Float']>;
  Time: Scalars['DateTime'];
};


export type SubscriptionCourierLocationArgs = {
  courierUserId: Scalars['String'];
};


export type SubscriptionCouriersAppStateArgs = {
  courierUserId: Scalars['String'];
};


export type SubscriptionCouriersStatesArgs = {
  region: Scalars['String'];
};


export type SubscriptionOrderTrackingArgs = {
  trackingId: Scalars['String'];
};


export type SubscriptionRefetchArgs = {
  namespace: RefetchSubscriptionEnum;
  organizationName: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  _id: Scalars['ID'];
  address: Address;
  addressId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  eta: Array<TaskEta>;
  id: Scalars['Int'];
  orderId: Scalars['String'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskStatus;
  taskType: TaskType;
  updatedAt: Scalars['DateTime'];
};

export type TaskEta = {
  __typename?: 'TaskETA';
  _id: Scalars['ID'];
  arrival: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  distance: Scalars['Int'];
  id: Scalars['Int'];
  type: TaskEtaType;
  updatedAt: Scalars['DateTime'];
};

export enum TaskEtaType {
  Current = 'CURRENT',
  Initial = 'INITIAL'
}

/** Task status */
export enum TaskStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Started = 'STARTED',
  Unassigned = 'UNASSIGNED'
}

/** Task type enum */
export enum TaskType {
  DropOff = 'DROP_OFF',
  Pickup = 'PICKUP'
}

export type UpdateProfileInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskStatusInput = {
  failureNotes?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<OrderFailureReason>;
  status: TaskStatus;
  taskId: Scalars['Int'];
};

/** User entity */
export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  courier?: Maybe<Courier>;
  createdAt: Scalars['DateTime'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  roles: Array<OrganizationMember>;
  updatedAt: Scalars['DateTime'];
};

export type Vehicle = {
  __typename?: 'Vehicle';
  _id: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  courierId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Mistake will be fixed */
  licencePlate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  vehicleDescription?: Maybe<Scalars['String']>;
  vehicleType: Scalars['String'];
};

export type WaitingForOrdersState = {
  __typename?: 'WaitingForOrdersState';
  action: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type Webhook = {
  __typename?: 'Webhook';
  _id: Scalars['ID'];
  id: Scalars['String'];
  types: Array<Scalars['String']>;
  url: Scalars['String'];
};

export type WebhookCreateInput = {
  types: Array<Scalars['String']>;
  url: Scalars['String'];
};

export type WorkHoursItem = {
  __typename?: 'WorkHoursItem';
  group: Scalars['String'];
  seconds: Scalars['Int'];
};

export type UserSignUpWithIdTokenMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type UserSignUpWithIdTokenMutation = { __typename?: 'Mutation', UserSignUpWithIdToken: boolean };

export type CourierCreateMutationVariables = Exact<{
  data: BecomeCourierInput;
}>;


export type CourierCreateMutation = { __typename?: 'Mutation', courier: { __typename?: 'Courier', _id: string, id: number } };

export type CourierCapacityQueryVariables = Exact<{
  location?: InputMaybe<CoordinatesInput>;
}>;


export type CourierCapacityQuery = { __typename?: 'Query', CourierStats: { __typename?: 'CouriersStats', currentCapacity: number, maxCapacity: number } };

export type CourierGetQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>;
}>;


export type CourierGetQuery = { __typename?: 'Query', courier: { __typename?: 'Courier', _id: string, id: number, isOnline: boolean, user: { __typename?: 'User', id: string, firstName: string, lastName: string, email: string, phoneNumber: string }, profile?: { __typename?: 'CouriersProfileObject', courierId: number, activityCode: string, iban: string, identificationNumber: string } | null, vehicles: Array<{ __typename?: 'Vehicle', vehicleType: string, vehicleDescription?: string | null, licencePlate?: string | null }> } };

export type CourierListQueryVariables = Exact<{
  query?: InputMaybe<CourierListQueryInput>;
}>;


export type CourierListQuery = { __typename?: 'Query', couriers: { __typename?: 'PaginatedCourierResponse', _id: string, count: number, limit: number, page: number, totalPages: number, items: Array<{ __typename?: 'Courier', id: number, userId: string, isOnline: boolean, updatedAt: any, user: { __typename?: 'User', firstName: string, lastName: string, phoneNumber: string, email: string }, vehicles: Array<{ __typename?: 'Vehicle', vehicleType: string, vehicleDescription?: string | null, licencePlate?: string | null }> }> } };

export type CourierStatesSubscriptionVariables = Exact<{
  region: Scalars['String'];
}>;


export type CourierStatesSubscription = { __typename?: 'Subscription', couriersStates?: { __typename?: 'CouriersStates', states: Array<{ __typename?: 'CourierStateObject', courierUserId: string, state: CourierStateEnum, title: string, payload?: any | null }> } | null };

export type CourierLocationSubscriptionVariables = Exact<{
  courierUserId: Scalars['String'];
}>;


export type CourierLocationSubscription = { __typename?: 'Subscription', courierLocation?: { __typename?: 'CourierDeviceInfo', position?: Array<number> | null, timestamp: number } | null };

export type OrderStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderStatsQuery = { __typename?: 'Query', stats: { __typename?: 'OrdersStats', orderShippingDistanceChart: any, failedOrdersCountChart: any, completedOrdersCountChart: any, orderShippingDurationChart: any, statusCount: Array<{ __typename?: 'StatusCount', status: OrderStatus, count: number }> } };

export type OrderCreateMutationVariables = Exact<{
  data: OrderCreateInput;
}>;


export type OrderCreateMutation = { __typename?: 'Mutation', OrderCreate: { __typename?: 'Order', orderId: string, shortOrderId?: string | null } };

export type OrderTaskStatusUpdateMutationVariables = Exact<{
  data: UpdateTaskStatusInput;
}>;


export type OrderTaskStatusUpdateMutation = { __typename?: 'Mutation', OrderTaskStatusUpdate: boolean };

export type OrderCompleteMutationVariables = Exact<{
  orderId: Scalars['String'];
  courierUserId: Scalars['String'];
}>;


export type OrderCompleteMutation = { __typename?: 'Mutation', OrderComplete: { __typename?: 'Order', _id: string, status: OrderStatus } };

export type OrderTerminateMutationVariables = Exact<{
  orderId: Scalars['String'];
  reason: OrderFailureReason;
  note: Scalars['String'];
}>;


export type OrderTerminateMutation = { __typename?: 'Mutation', OrderTerminate: { __typename?: 'Order', _id: string, status: OrderStatus } };

export type DetailedOrderFragment = { __typename?: 'Order', _id: string, id: string, orderId: string, shortOrderId?: string | null, trackingId?: string | null, status: OrderStatus, createdAt: any, startAfter?: any | null, organization: { __typename?: 'Organization', image: string, id: string, name: string, displayName: string }, metrics?: { __typename?: 'OrderDeliveryStats', actualDeliveryDuration?: number | null, isLate: boolean, initialLifeTimeDurationPrediction: number, currentLifeTimeDurationPrediction: number, initialDeliveryDurationPrediction?: number | null, currentDeliveryDurationPrediction?: number | null, lifeTimePredictionDifference: number, isLateWithThreshold: boolean, orderLifeTimeDuration?: number | null } | null, summary?: { __typename?: 'OrderSummary', actualDuration: number, actualDropOffDistance: number, actualPickupDistance: number, predictedDuration: number, predictedPickupDistance: number, predictedDropOffDistance: number, actualRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string }, predictedRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string } } | null, tasks: Array<{ __typename?: 'Task', taskType: TaskType, id: number, status: TaskStatus, orderId: string, completedAt?: any | null, updatedAt: any, startedAt?: any | null, createdAt: any, address: { __typename?: 'Address', address: string, contactName: string, contactPhone: string, coordinates: Array<number>, notes?: string | null }, eta: Array<{ __typename?: 'TaskETA', arrival: any, type: TaskEtaType }> }>, courier?: { __typename?: 'Courier', id: number, user: { __typename?: 'User', firstName: string, lastName: string, phoneNumber: string, id: string } } | null, events: Array<{ __typename?: 'OrderEvent', type: string, createdAt: any }> };

export type OrderGetQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type OrderGetQuery = { __typename?: 'Query', order: { __typename?: 'Order', _id: string, id: string, orderId: string, shortOrderId?: string | null, trackingId?: string | null, status: OrderStatus, createdAt: any, startAfter?: any | null, organization: { __typename?: 'Organization', image: string, id: string, name: string, displayName: string }, metrics?: { __typename?: 'OrderDeliveryStats', actualDeliveryDuration?: number | null, isLate: boolean, initialLifeTimeDurationPrediction: number, currentLifeTimeDurationPrediction: number, initialDeliveryDurationPrediction?: number | null, currentDeliveryDurationPrediction?: number | null, lifeTimePredictionDifference: number, isLateWithThreshold: boolean, orderLifeTimeDuration?: number | null } | null, summary?: { __typename?: 'OrderSummary', actualDuration: number, actualDropOffDistance: number, actualPickupDistance: number, predictedDuration: number, predictedPickupDistance: number, predictedDropOffDistance: number, actualRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string }, predictedRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string } } | null, tasks: Array<{ __typename?: 'Task', taskType: TaskType, id: number, status: TaskStatus, orderId: string, completedAt?: any | null, updatedAt: any, startedAt?: any | null, createdAt: any, address: { __typename?: 'Address', address: string, contactName: string, contactPhone: string, coordinates: Array<number>, notes?: string | null }, eta: Array<{ __typename?: 'TaskETA', arrival: any, type: TaskEtaType }> }>, courier?: { __typename?: 'Courier', id: number, user: { __typename?: 'User', firstName: string, lastName: string, phoneNumber: string, id: string } } | null, events: Array<{ __typename?: 'OrderEvent', type: string, createdAt: any }> } };

export type OrderListDownloadCsvMutationVariables = Exact<{
  query?: InputMaybe<OrderQueryInput>;
}>;


export type OrderListDownloadCsvMutation = { __typename?: 'Mutation', OrderListDownloadCSV: { __typename?: 'OrderDownloadUrl', url: string } };

export type OrderListQueryVariables = Exact<{
  query?: InputMaybe<OrderQueryInput>;
}>;


export type OrderListQuery = { __typename?: 'Query', orders: { __typename?: 'PaginatedOrderObjectResponse', _id: string, count: number, page: number, limit: number, totalPages: number, items: Array<{ __typename?: 'Order', _id: string, id: string, orderId: string, shortOrderId?: string | null, trackingId?: string | null, status: OrderStatus, createdAt: any, startAfter?: any | null, organization: { __typename?: 'Organization', image: string, id: string, name: string, displayName: string }, metrics?: { __typename?: 'OrderDeliveryStats', actualDeliveryDuration?: number | null, isLate: boolean, initialLifeTimeDurationPrediction: number, currentLifeTimeDurationPrediction: number, initialDeliveryDurationPrediction?: number | null, currentDeliveryDurationPrediction?: number | null, lifeTimePredictionDifference: number, isLateWithThreshold: boolean, orderLifeTimeDuration?: number | null } | null, summary?: { __typename?: 'OrderSummary', actualDuration: number, actualDropOffDistance: number, actualPickupDistance: number, predictedDuration: number, predictedPickupDistance: number, predictedDropOffDistance: number, actualRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string }, predictedRoute: { __typename?: 'OrderRoute', pickup: string, dropOff: string } } | null, tasks: Array<{ __typename?: 'Task', taskType: TaskType, id: number, status: TaskStatus, orderId: string, completedAt?: any | null, updatedAt: any, startedAt?: any | null, createdAt: any, address: { __typename?: 'Address', address: string, contactName: string, contactPhone: string, coordinates: Array<number>, notes?: string | null }, eta: Array<{ __typename?: 'TaskETA', arrival: any, type: TaskEtaType }> }>, courier?: { __typename?: 'Courier', id: number, user: { __typename?: 'User', firstName: string, lastName: string, phoneNumber: string, id: string } } | null, events: Array<{ __typename?: 'OrderEvent', type: string, createdAt: any }> }> } };

export type OrderTrackingSubscriptionVariables = Exact<{
  trackingId: Scalars['String'];
}>;


export type OrderTrackingSubscription = { __typename?: 'Subscription', OrderTracking?: { __typename?: 'OrderTracking', _id: string, arrival?: any | null, orderId: string, merchantOrderId: string, status: OrderStatus, events: Array<{ __typename?: 'PublicOrderStatusEvent', event: string, createdAt: any }>, dropOff: { __typename?: 'LocationPoint', address: string, coordinates: { __typename?: 'CoordinatesOutput', lng: number, lat: number } }, courierLocation?: { __typename?: 'CoordinatesOutput', lng: number, lat: number } | null, pickup: { __typename?: 'LocationPoint', address: string, coordinates: { __typename?: 'CoordinatesOutput', lng: number, lat: number } } } | null };

export type OrderPrioritizeMutationVariables = Exact<{
  orderId: Scalars['String'];
  courierUserId?: InputMaybe<Scalars['String']>;
}>;


export type OrderPrioritizeMutation = { __typename?: 'Mutation', OrderPrioritize: boolean };

export type OrganizationCreateMutationVariables = Exact<{
  data: OrganizationCreateInput;
}>;


export type OrganizationCreateMutation = { __typename?: 'Mutation', OrganizationCreate: { __typename?: 'Organization', _id: string, id: string } };

export type OrganizationListQueryVariables = Exact<{
  query?: InputMaybe<OrganizationListQueryInput>;
}>;


export type OrganizationListQuery = { __typename?: 'Query', organizations: { __typename?: 'PaginatedOrganizationResponse', _id: string, count: number, page: number, limit: number, totalPages: number, items: Array<{ __typename?: 'Organization', id: string, name: string, displayName: string, image: string }> } };

export type RefetchSubscriptionVariables = Exact<{
  namespace: RefetchSubscriptionEnum;
  organizationName: Scalars['String'];
}>;


export type RefetchSubscription = { __typename?: 'Subscription', Refetch?: number | null };

export type RegionQueryVariables = Exact<{
  region: Scalars['String'];
}>;


export type RegionQuery = { __typename?: 'Query', region: { __typename?: 'Region', region: string, displayName: string, polygon: Array<Array<number>> } };

export type RegionListQueryVariables = Exact<{ [key: string]: never; }>;


export type RegionListQuery = { __typename?: 'Query', regions: { __typename?: 'PaginatedRegionResponse', _id: string, count: number, items: Array<{ __typename?: 'Region', region: string, displayName: string, polygon: Array<Array<number>> }> } };

export type StatisticsQueryVariables = Exact<{
  queryLabel: StatisticsQueryLabel;
  queryPeriod: StatisticsQueryPeriod;
  fetchSeries: Scalars['Boolean'];
}>;


export type StatisticsQuery = { __typename?: 'Query', statistics: { __typename?: 'StatisticsObject', label: string, period: StatisticsQueryPeriod, total: number, change: number, series?: Array<{ __typename?: 'SeriesObject', period: string, value: number }> } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', _id: string, id: string, firstName: string, lastName: string, email: string, phoneNumber: string, roles: Array<{ __typename?: 'OrganizationMember', role: string, organizationId: string, organization: { __typename?: 'Organization', name: string, displayName: string, image: string } }>, courier?: { __typename?: 'Courier', id: number } | null } | null };

export type UserUpdateProfileMutationVariables = Exact<{
  data: UpdateProfileInput;
}>;


export type UserUpdateProfileMutation = { __typename?: 'Mutation', UserUpdateProfile: { __typename?: 'User', _id: string, firstName: string, lastName: string, phoneNumber: string } };

export type UserGetQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UserGetQuery = { __typename?: 'Query', user: { __typename?: 'User', _id: string, id: string, firstName: string, lastName: string, email: string, phoneNumber: string, roles: Array<{ __typename?: 'OrganizationMember', role: string }> } };

export const DetailedOrderFragmentDoc = gql`
    fragment DetailedOrder on Order {
  _id
  id
  orderId
  shortOrderId
  trackingId
  organization {
    image
    id
    name
    displayName
  }
  status
  createdAt
  startAfter
  metrics {
    actualDeliveryDuration
    isLate
    initialLifeTimeDurationPrediction
    currentLifeTimeDurationPrediction
    initialDeliveryDurationPrediction
    currentDeliveryDurationPrediction
    lifeTimePredictionDifference
    isLateWithThreshold
    orderLifeTimeDuration
  }
  summary {
    actualDuration
    actualDropOffDistance
    actualPickupDistance
    actualRoute {
      pickup
      dropOff
    }
    predictedRoute {
      pickup
      dropOff
    }
    predictedDuration
    predictedPickupDistance
    predictedDropOffDistance
  }
  tasks {
    taskType
    id
    address {
      address
      contactName
      contactPhone
      coordinates
      notes
    }
    status
    orderId
    eta {
      arrival
      type
    }
    completedAt
    updatedAt
    startedAt
    createdAt
  }
  courier {
    user {
      firstName
      lastName
      phoneNumber
      id
    }
    id
  }
  events {
    type
    createdAt
  }
}
    `;
export const UserSignUpWithIdTokenDocument = gql`
    mutation UserSignUpWithIdToken($data: SignUpInput!) {
  UserSignUpWithIdToken(data: $data)
}
    `;
export type UserSignUpWithIdTokenMutationFn = Apollo.MutationFunction<UserSignUpWithIdTokenMutation, UserSignUpWithIdTokenMutationVariables>;

/**
 * __useUserSignUpWithIdTokenMutation__
 *
 * To run a mutation, you first call `useUserSignUpWithIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignUpWithIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignUpWithIdTokenMutation, { data, loading, error }] = useUserSignUpWithIdTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserSignUpWithIdTokenMutation(baseOptions?: Apollo.MutationHookOptions<UserSignUpWithIdTokenMutation, UserSignUpWithIdTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSignUpWithIdTokenMutation, UserSignUpWithIdTokenMutationVariables>(UserSignUpWithIdTokenDocument, options);
      }
export type UserSignUpWithIdTokenMutationHookResult = ReturnType<typeof useUserSignUpWithIdTokenMutation>;
export type UserSignUpWithIdTokenMutationResult = Apollo.MutationResult<UserSignUpWithIdTokenMutation>;
export type UserSignUpWithIdTokenMutationOptions = Apollo.BaseMutationOptions<UserSignUpWithIdTokenMutation, UserSignUpWithIdTokenMutationVariables>;
export const CourierCreateDocument = gql`
    mutation CourierCreate($data: BecomeCourierInput!) {
  courier: CourierCreate(data: $data) {
    _id
    id
  }
}
    `;
export type CourierCreateMutationFn = Apollo.MutationFunction<CourierCreateMutation, CourierCreateMutationVariables>;

/**
 * __useCourierCreateMutation__
 *
 * To run a mutation, you first call `useCourierCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourierCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courierCreateMutation, { data, loading, error }] = useCourierCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCourierCreateMutation(baseOptions?: Apollo.MutationHookOptions<CourierCreateMutation, CourierCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CourierCreateMutation, CourierCreateMutationVariables>(CourierCreateDocument, options);
      }
export type CourierCreateMutationHookResult = ReturnType<typeof useCourierCreateMutation>;
export type CourierCreateMutationResult = Apollo.MutationResult<CourierCreateMutation>;
export type CourierCreateMutationOptions = Apollo.BaseMutationOptions<CourierCreateMutation, CourierCreateMutationVariables>;
export const CourierCapacityDocument = gql`
    query CourierCapacity($location: CoordinatesInput) {
  CourierStats(location: $location) {
    currentCapacity
    maxCapacity
  }
}
    `;

/**
 * __useCourierCapacityQuery__
 *
 * To run a query within a React component, call `useCourierCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierCapacityQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCourierCapacityQuery(baseOptions?: Apollo.QueryHookOptions<CourierCapacityQuery, CourierCapacityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourierCapacityQuery, CourierCapacityQueryVariables>(CourierCapacityDocument, options);
      }
export function useCourierCapacityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierCapacityQuery, CourierCapacityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourierCapacityQuery, CourierCapacityQueryVariables>(CourierCapacityDocument, options);
        }
export type CourierCapacityQueryHookResult = ReturnType<typeof useCourierCapacityQuery>;
export type CourierCapacityLazyQueryHookResult = ReturnType<typeof useCourierCapacityLazyQuery>;
export type CourierCapacityQueryResult = Apollo.QueryResult<CourierCapacityQuery, CourierCapacityQueryVariables>;
export const CourierGetDocument = gql`
    query CourierGet($userId: String) {
  courier: Courier(userId: $userId) {
    _id
    id
    user {
      id
      firstName
      lastName
      email
      phoneNumber
    }
    profile {
      courierId
      activityCode
      iban
      identificationNumber
    }
    isOnline
    vehicles {
      vehicleType
      vehicleDescription
      licencePlate
    }
  }
}
    `;

/**
 * __useCourierGetQuery__
 *
 * To run a query within a React component, call `useCourierGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCourierGetQuery(baseOptions?: Apollo.QueryHookOptions<CourierGetQuery, CourierGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourierGetQuery, CourierGetQueryVariables>(CourierGetDocument, options);
      }
export function useCourierGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierGetQuery, CourierGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourierGetQuery, CourierGetQueryVariables>(CourierGetDocument, options);
        }
export type CourierGetQueryHookResult = ReturnType<typeof useCourierGetQuery>;
export type CourierGetLazyQueryHookResult = ReturnType<typeof useCourierGetLazyQuery>;
export type CourierGetQueryResult = Apollo.QueryResult<CourierGetQuery, CourierGetQueryVariables>;
export const CourierListDocument = gql`
    query CourierList($query: CourierListQueryInput) {
  couriers: CourierList(query: $query) {
    _id
    items {
      user {
        firstName
        lastName
        phoneNumber
        email
      }
      id
      userId
      isOnline
      vehicles {
        vehicleType
        vehicleDescription
        licencePlate
      }
      updatedAt
    }
    count
    limit
    page
    totalPages
  }
}
    `;

/**
 * __useCourierListQuery__
 *
 * To run a query within a React component, call `useCourierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierListQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCourierListQuery(baseOptions?: Apollo.QueryHookOptions<CourierListQuery, CourierListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CourierListQuery, CourierListQueryVariables>(CourierListDocument, options);
      }
export function useCourierListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierListQuery, CourierListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CourierListQuery, CourierListQueryVariables>(CourierListDocument, options);
        }
export type CourierListQueryHookResult = ReturnType<typeof useCourierListQuery>;
export type CourierListLazyQueryHookResult = ReturnType<typeof useCourierListLazyQuery>;
export type CourierListQueryResult = Apollo.QueryResult<CourierListQuery, CourierListQueryVariables>;
export const CourierStatesDocument = gql`
    subscription CourierStates($region: String!) {
  couriersStates: CouriersStates(region: $region) {
    states {
      courierUserId
      state
      title
      payload
    }
  }
}
    `;

/**
 * __useCourierStatesSubscription__
 *
 * To run a query within a React component, call `useCourierStatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCourierStatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierStatesSubscription({
 *   variables: {
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCourierStatesSubscription(baseOptions: Apollo.SubscriptionHookOptions<CourierStatesSubscription, CourierStatesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CourierStatesSubscription, CourierStatesSubscriptionVariables>(CourierStatesDocument, options);
      }
export type CourierStatesSubscriptionHookResult = ReturnType<typeof useCourierStatesSubscription>;
export type CourierStatesSubscriptionResult = Apollo.SubscriptionResult<CourierStatesSubscription>;
export const CourierLocationDocument = gql`
    subscription CourierLocation($courierUserId: String!) {
  courierLocation: CourierLocation(courierUserId: $courierUserId) {
    position
    timestamp
  }
}
    `;

/**
 * __useCourierLocationSubscription__
 *
 * To run a query within a React component, call `useCourierLocationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCourierLocationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierLocationSubscription({
 *   variables: {
 *      courierUserId: // value for 'courierUserId'
 *   },
 * });
 */
export function useCourierLocationSubscription(baseOptions: Apollo.SubscriptionHookOptions<CourierLocationSubscription, CourierLocationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CourierLocationSubscription, CourierLocationSubscriptionVariables>(CourierLocationDocument, options);
      }
export type CourierLocationSubscriptionHookResult = ReturnType<typeof useCourierLocationSubscription>;
export type CourierLocationSubscriptionResult = Apollo.SubscriptionResult<CourierLocationSubscription>;
export const OrderStatsDocument = gql`
    query OrderStats {
  stats: OrderStats {
    statusCount {
      status
      count
    }
    orderShippingDistanceChart
    failedOrdersCountChart
    completedOrdersCountChart
    orderShippingDurationChart
  }
}
    `;

/**
 * __useOrderStatsQuery__
 *
 * To run a query within a React component, call `useOrderStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderStatsQuery(baseOptions?: Apollo.QueryHookOptions<OrderStatsQuery, OrderStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderStatsQuery, OrderStatsQueryVariables>(OrderStatsDocument, options);
      }
export function useOrderStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderStatsQuery, OrderStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderStatsQuery, OrderStatsQueryVariables>(OrderStatsDocument, options);
        }
export type OrderStatsQueryHookResult = ReturnType<typeof useOrderStatsQuery>;
export type OrderStatsLazyQueryHookResult = ReturnType<typeof useOrderStatsLazyQuery>;
export type OrderStatsQueryResult = Apollo.QueryResult<OrderStatsQuery, OrderStatsQueryVariables>;
export const OrderCreateDocument = gql`
    mutation OrderCreate($data: OrderCreateInput!) {
  OrderCreate(data: $data) {
    orderId
    shortOrderId
  }
}
    `;
export type OrderCreateMutationFn = Apollo.MutationFunction<OrderCreateMutation, OrderCreateMutationVariables>;

/**
 * __useOrderCreateMutation__
 *
 * To run a mutation, you first call `useOrderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCreateMutation, { data, loading, error }] = useOrderCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrderCreateMutation, OrderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCreateMutation, OrderCreateMutationVariables>(OrderCreateDocument, options);
      }
export type OrderCreateMutationHookResult = ReturnType<typeof useOrderCreateMutation>;
export type OrderCreateMutationResult = Apollo.MutationResult<OrderCreateMutation>;
export type OrderCreateMutationOptions = Apollo.BaseMutationOptions<OrderCreateMutation, OrderCreateMutationVariables>;
export const OrderTaskStatusUpdateDocument = gql`
    mutation OrderTaskStatusUpdate($data: UpdateTaskStatusInput!) {
  OrderTaskStatusUpdate(data: $data)
}
    `;
export type OrderTaskStatusUpdateMutationFn = Apollo.MutationFunction<OrderTaskStatusUpdateMutation, OrderTaskStatusUpdateMutationVariables>;

/**
 * __useOrderTaskStatusUpdateMutation__
 *
 * To run a mutation, you first call `useOrderTaskStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTaskStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTaskStatusUpdateMutation, { data, loading, error }] = useOrderTaskStatusUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderTaskStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrderTaskStatusUpdateMutation, OrderTaskStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderTaskStatusUpdateMutation, OrderTaskStatusUpdateMutationVariables>(OrderTaskStatusUpdateDocument, options);
      }
export type OrderTaskStatusUpdateMutationHookResult = ReturnType<typeof useOrderTaskStatusUpdateMutation>;
export type OrderTaskStatusUpdateMutationResult = Apollo.MutationResult<OrderTaskStatusUpdateMutation>;
export type OrderTaskStatusUpdateMutationOptions = Apollo.BaseMutationOptions<OrderTaskStatusUpdateMutation, OrderTaskStatusUpdateMutationVariables>;
export const OrderCompleteDocument = gql`
    mutation OrderComplete($orderId: String!, $courierUserId: String!) {
  OrderComplete(orderId: $orderId, courierUserId: $courierUserId) {
    _id
    status
  }
}
    `;
export type OrderCompleteMutationFn = Apollo.MutationFunction<OrderCompleteMutation, OrderCompleteMutationVariables>;

/**
 * __useOrderCompleteMutation__
 *
 * To run a mutation, you first call `useOrderCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCompleteMutation, { data, loading, error }] = useOrderCompleteMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      courierUserId: // value for 'courierUserId'
 *   },
 * });
 */
export function useOrderCompleteMutation(baseOptions?: Apollo.MutationHookOptions<OrderCompleteMutation, OrderCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderCompleteMutation, OrderCompleteMutationVariables>(OrderCompleteDocument, options);
      }
export type OrderCompleteMutationHookResult = ReturnType<typeof useOrderCompleteMutation>;
export type OrderCompleteMutationResult = Apollo.MutationResult<OrderCompleteMutation>;
export type OrderCompleteMutationOptions = Apollo.BaseMutationOptions<OrderCompleteMutation, OrderCompleteMutationVariables>;
export const OrderTerminateDocument = gql`
    mutation OrderTerminate($orderId: String!, $reason: OrderFailureReason!, $note: String!) {
  OrderTerminate(orderId: $orderId, reason: $reason, note: $note) {
    _id
    status
  }
}
    `;
export type OrderTerminateMutationFn = Apollo.MutationFunction<OrderTerminateMutation, OrderTerminateMutationVariables>;

/**
 * __useOrderTerminateMutation__
 *
 * To run a mutation, you first call `useOrderTerminateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTerminateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTerminateMutation, { data, loading, error }] = useOrderTerminateMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      reason: // value for 'reason'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useOrderTerminateMutation(baseOptions?: Apollo.MutationHookOptions<OrderTerminateMutation, OrderTerminateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderTerminateMutation, OrderTerminateMutationVariables>(OrderTerminateDocument, options);
      }
export type OrderTerminateMutationHookResult = ReturnType<typeof useOrderTerminateMutation>;
export type OrderTerminateMutationResult = Apollo.MutationResult<OrderTerminateMutation>;
export type OrderTerminateMutationOptions = Apollo.BaseMutationOptions<OrderTerminateMutation, OrderTerminateMutationVariables>;
export const OrderGetDocument = gql`
    query OrderGet($orderId: String!) {
  order: Order(orderId: $orderId) {
    ...DetailedOrder
  }
}
    ${DetailedOrderFragmentDoc}`;

/**
 * __useOrderGetQuery__
 *
 * To run a query within a React component, call `useOrderGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderGetQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderGetQuery(baseOptions: Apollo.QueryHookOptions<OrderGetQuery, OrderGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderGetQuery, OrderGetQueryVariables>(OrderGetDocument, options);
      }
export function useOrderGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderGetQuery, OrderGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderGetQuery, OrderGetQueryVariables>(OrderGetDocument, options);
        }
export type OrderGetQueryHookResult = ReturnType<typeof useOrderGetQuery>;
export type OrderGetLazyQueryHookResult = ReturnType<typeof useOrderGetLazyQuery>;
export type OrderGetQueryResult = Apollo.QueryResult<OrderGetQuery, OrderGetQueryVariables>;
export const OrderListDownloadCsvDocument = gql`
    mutation OrderListDownloadCSV($query: OrderQueryInput) {
  OrderListDownloadCSV(query: $query) {
    url
  }
}
    `;
export type OrderListDownloadCsvMutationFn = Apollo.MutationFunction<OrderListDownloadCsvMutation, OrderListDownloadCsvMutationVariables>;

/**
 * __useOrderListDownloadCsvMutation__
 *
 * To run a mutation, you first call `useOrderListDownloadCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderListDownloadCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderListDownloadCsvMutation, { data, loading, error }] = useOrderListDownloadCsvMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrderListDownloadCsvMutation(baseOptions?: Apollo.MutationHookOptions<OrderListDownloadCsvMutation, OrderListDownloadCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderListDownloadCsvMutation, OrderListDownloadCsvMutationVariables>(OrderListDownloadCsvDocument, options);
      }
export type OrderListDownloadCsvMutationHookResult = ReturnType<typeof useOrderListDownloadCsvMutation>;
export type OrderListDownloadCsvMutationResult = Apollo.MutationResult<OrderListDownloadCsvMutation>;
export type OrderListDownloadCsvMutationOptions = Apollo.BaseMutationOptions<OrderListDownloadCsvMutation, OrderListDownloadCsvMutationVariables>;
export const OrderListDocument = gql`
    query OrderList($query: OrderQueryInput) {
  orders: OrderList(query: $query) {
    _id
    items {
      ...DetailedOrder
    }
    count
    page
    limit
    totalPages
  }
}
    ${DetailedOrderFragmentDoc}`;

/**
 * __useOrderListQuery__
 *
 * To run a query within a React component, call `useOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrderListQuery(baseOptions?: Apollo.QueryHookOptions<OrderListQuery, OrderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderListQuery, OrderListQueryVariables>(OrderListDocument, options);
      }
export function useOrderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderListQuery, OrderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderListQuery, OrderListQueryVariables>(OrderListDocument, options);
        }
export type OrderListQueryHookResult = ReturnType<typeof useOrderListQuery>;
export type OrderListLazyQueryHookResult = ReturnType<typeof useOrderListLazyQuery>;
export type OrderListQueryResult = Apollo.QueryResult<OrderListQuery, OrderListQueryVariables>;
export const OrderTrackingDocument = gql`
    subscription OrderTracking($trackingId: String!) {
  OrderTracking(trackingId: $trackingId) {
    _id
    arrival
    orderId
    merchantOrderId
    status
    events {
      event
      createdAt
    }
    dropOff {
      coordinates {
        lng
        lat
      }
      address
    }
    courierLocation {
      lng
      lat
    }
    pickup {
      coordinates {
        lng
        lat
      }
      address
    }
  }
}
    `;

/**
 * __useOrderTrackingSubscription__
 *
 * To run a query within a React component, call `useOrderTrackingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderTrackingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTrackingSubscription({
 *   variables: {
 *      trackingId: // value for 'trackingId'
 *   },
 * });
 */
export function useOrderTrackingSubscription(baseOptions: Apollo.SubscriptionHookOptions<OrderTrackingSubscription, OrderTrackingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OrderTrackingSubscription, OrderTrackingSubscriptionVariables>(OrderTrackingDocument, options);
      }
export type OrderTrackingSubscriptionHookResult = ReturnType<typeof useOrderTrackingSubscription>;
export type OrderTrackingSubscriptionResult = Apollo.SubscriptionResult<OrderTrackingSubscription>;
export const OrderPrioritizeDocument = gql`
    mutation OrderPrioritize($orderId: String!, $courierUserId: String) {
  OrderPrioritize(orderId: $orderId, courierUserId: $courierUserId)
}
    `;
export type OrderPrioritizeMutationFn = Apollo.MutationFunction<OrderPrioritizeMutation, OrderPrioritizeMutationVariables>;

/**
 * __useOrderPrioritizeMutation__
 *
 * To run a mutation, you first call `useOrderPrioritizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPrioritizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPrioritizeMutation, { data, loading, error }] = useOrderPrioritizeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      courierUserId: // value for 'courierUserId'
 *   },
 * });
 */
export function useOrderPrioritizeMutation(baseOptions?: Apollo.MutationHookOptions<OrderPrioritizeMutation, OrderPrioritizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderPrioritizeMutation, OrderPrioritizeMutationVariables>(OrderPrioritizeDocument, options);
      }
export type OrderPrioritizeMutationHookResult = ReturnType<typeof useOrderPrioritizeMutation>;
export type OrderPrioritizeMutationResult = Apollo.MutationResult<OrderPrioritizeMutation>;
export type OrderPrioritizeMutationOptions = Apollo.BaseMutationOptions<OrderPrioritizeMutation, OrderPrioritizeMutationVariables>;
export const OrganizationCreateDocument = gql`
    mutation OrganizationCreate($data: OrganizationCreateInput!) {
  OrganizationCreate(data: $data) {
    _id
    id
  }
}
    `;
export type OrganizationCreateMutationFn = Apollo.MutationFunction<OrganizationCreateMutation, OrganizationCreateMutationVariables>;

/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationCreateMutation, OrganizationCreateMutationVariables>(OrganizationCreateDocument, options);
      }
export type OrganizationCreateMutationHookResult = ReturnType<typeof useOrganizationCreateMutation>;
export type OrganizationCreateMutationResult = Apollo.MutationResult<OrganizationCreateMutation>;
export type OrganizationCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationCreateMutation, OrganizationCreateMutationVariables>;
export const OrganizationListDocument = gql`
    query OrganizationList($query: OrganizationListQueryInput) {
  organizations: OrganizationList(query: $query) {
    _id
    items {
      id
      name
      displayName
      image
    }
    count
    page
    limit
    totalPages
  }
}
    `;

/**
 * __useOrganizationListQuery__
 *
 * To run a query within a React component, call `useOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationListQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrganizationListQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationListQuery, OrganizationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationListQuery, OrganizationListQueryVariables>(OrganizationListDocument, options);
      }
export function useOrganizationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationListQuery, OrganizationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationListQuery, OrganizationListQueryVariables>(OrganizationListDocument, options);
        }
export type OrganizationListQueryHookResult = ReturnType<typeof useOrganizationListQuery>;
export type OrganizationListLazyQueryHookResult = ReturnType<typeof useOrganizationListLazyQuery>;
export type OrganizationListQueryResult = Apollo.QueryResult<OrganizationListQuery, OrganizationListQueryVariables>;
export const RefetchDocument = gql`
    subscription Refetch($namespace: RefetchSubscriptionEnum!, $organizationName: String!) {
  Refetch(namespace: $namespace, organizationName: $organizationName)
}
    `;

/**
 * __useRefetchSubscription__
 *
 * To run a query within a React component, call `useRefetchSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRefetchSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefetchSubscription({
 *   variables: {
 *      namespace: // value for 'namespace'
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useRefetchSubscription(baseOptions: Apollo.SubscriptionHookOptions<RefetchSubscription, RefetchSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RefetchSubscription, RefetchSubscriptionVariables>(RefetchDocument, options);
      }
export type RefetchSubscriptionHookResult = ReturnType<typeof useRefetchSubscription>;
export type RefetchSubscriptionResult = Apollo.SubscriptionResult<RefetchSubscription>;
export const RegionDocument = gql`
    query Region($region: String!) {
  region: Region(region: $region) {
    region
    displayName
    polygon
  }
}
    `;

/**
 * __useRegionQuery__
 *
 * To run a query within a React component, call `useRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionQuery({
 *   variables: {
 *      region: // value for 'region'
 *   },
 * });
 */
export function useRegionQuery(baseOptions: Apollo.QueryHookOptions<RegionQuery, RegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
      }
export function useRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionQuery, RegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
        }
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = Apollo.QueryResult<RegionQuery, RegionQueryVariables>;
export const RegionListDocument = gql`
    query RegionList {
  regions: RegionList {
    _id
    items {
      region
      displayName
      polygon
    }
    count
  }
}
    `;

/**
 * __useRegionListQuery__
 *
 * To run a query within a React component, call `useRegionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionListQuery(baseOptions?: Apollo.QueryHookOptions<RegionListQuery, RegionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionListQuery, RegionListQueryVariables>(RegionListDocument, options);
      }
export function useRegionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionListQuery, RegionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionListQuery, RegionListQueryVariables>(RegionListDocument, options);
        }
export type RegionListQueryHookResult = ReturnType<typeof useRegionListQuery>;
export type RegionListLazyQueryHookResult = ReturnType<typeof useRegionListLazyQuery>;
export type RegionListQueryResult = Apollo.QueryResult<RegionListQuery, RegionListQueryVariables>;
export const StatisticsDocument = gql`
    query Statistics($queryLabel: StatisticsQueryLabel!, $queryPeriod: StatisticsQueryPeriod!, $fetchSeries: Boolean!) {
  statistics: Statistics(
    queryLabel: $queryLabel
    queryPeriod: $queryPeriod
    fetchSeries: $fetchSeries
  ) {
    label
    period
    total
    change
    series @include(if: $fetchSeries) {
      period
      value
    }
  }
}
    `;

/**
 * __useStatisticsQuery__
 *
 * To run a query within a React component, call `useStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatisticsQuery({
 *   variables: {
 *      queryLabel: // value for 'queryLabel'
 *      queryPeriod: // value for 'queryPeriod'
 *      fetchSeries: // value for 'fetchSeries'
 *   },
 * });
 */
export function useStatisticsQuery(baseOptions: Apollo.QueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
      }
export function useStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatisticsQuery, StatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatisticsQuery, StatisticsQueryVariables>(StatisticsDocument, options);
        }
export type StatisticsQueryHookResult = ReturnType<typeof useStatisticsQuery>;
export type StatisticsLazyQueryHookResult = ReturnType<typeof useStatisticsLazyQuery>;
export type StatisticsQueryResult = Apollo.QueryResult<StatisticsQuery, StatisticsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me: Me {
    _id
    id
    firstName
    lastName
    email
    phoneNumber
    roles {
      role
      organizationId
      organization {
        name
        displayName
        image
      }
    }
    courier {
      id
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserUpdateProfileDocument = gql`
    mutation UserUpdateProfile($data: UpdateProfileInput!) {
  UserUpdateProfile(data: $data) {
    _id
    firstName
    lastName
    phoneNumber
  }
}
    `;
export type UserUpdateProfileMutationFn = Apollo.MutationFunction<UserUpdateProfileMutation, UserUpdateProfileMutationVariables>;

/**
 * __useUserUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUserUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateProfileMutation, { data, loading, error }] = useUserUpdateProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateProfileMutation, UserUpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateProfileMutation, UserUpdateProfileMutationVariables>(UserUpdateProfileDocument, options);
      }
export type UserUpdateProfileMutationHookResult = ReturnType<typeof useUserUpdateProfileMutation>;
export type UserUpdateProfileMutationResult = Apollo.MutationResult<UserUpdateProfileMutation>;
export type UserUpdateProfileMutationOptions = Apollo.BaseMutationOptions<UserUpdateProfileMutation, UserUpdateProfileMutationVariables>;
export const UserGetDocument = gql`
    query UserGet($userId: String!) {
  user: User(userId: $userId) {
    _id
    id
    firstName
    lastName
    email
    phoneNumber
    roles {
      role
    }
  }
}
    `;

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions: Apollo.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
      }
export function useUserGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, options);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = Apollo.QueryResult<UserGetQuery, UserGetQueryVariables>;