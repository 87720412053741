export const SELF_SERVICE_ROUTES = {
  selfService: {
    root: ['self-service'] as const,
    organization: {
      root: (organization?: string) =>
        [
          ...SELF_SERVICE_ROUTES.selfService.root,
          organization ? organization : ':organization',
        ] as const,
      newOrder: (organization?: string) =>
        [...SELF_SERVICE_ROUTES.selfService.organization.root(organization ? organization : ':organization'), 'new-order'] as const,
      orders: {
        root: () => [...SELF_SERVICE_ROUTES.selfService.organization.root(), 'orders'] as const,
        list: () => [...SELF_SERVICE_ROUTES.selfService.organization.orders.root(), 'list'] as const,
        details: (id?: string) =>
          [
            ...SELF_SERVICE_ROUTES.selfService.organization.orders.root(),
            id ? `${id}/details` : ':id/details',
          ] as const,
      },
      dashboard: () => [...SELF_SERVICE_ROUTES.selfService.organization.root(), 'dashboard'] as const,
      settings: {
        root: () => [...SELF_SERVICE_ROUTES.selfService.organization.root(), 'api'] as const,
        apiTokens: {
          root: () =>
            [...SELF_SERVICE_ROUTES.selfService.organization.settings.root(), 'api-tokens'] as const,
          create: () =>
            [
              ...SELF_SERVICE_ROUTES.selfService.organization.settings.apiTokens.root(),
              'create',
            ] as const,
        },
        webhooks: {
          root: () =>
            [...SELF_SERVICE_ROUTES.selfService.organization.settings.root(), 'webhooks'] as const,
          create: () =>
            [
              ...SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.root(),
              'create',
            ] as const,
          details: {
            root: (id?: string) =>
              [
                ...SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.root(),
                id ? `${id}` : ':id',
              ] as const,
            update: (id?: string) =>
              [...SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.details.root(id), 'details'] as const,
            invocations: {
              root: (id?: string) =>
                [...SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.details.root(id), 'invocations'] as const,
              details: (id?: string, invocationId?: string) =>
                [
                  ...SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.details.invocations.root(id),
                  invocationId ? `${invocationId}` : ':invocationId'
                ]
            }
          },
        },
        apiPlayground: {
          root: () => [...SELF_SERVICE_ROUTES.selfService.organization.settings.root(), 'playground'] as const,
        }
      },
    }
  }
}
