import { useEffect, useState } from "react"

export function useMapRefocus(refocus: () => void) {
    const [dragging, setDragging] = useState(false)
    useEffect(() => {
      if (dragging) return
      const timeout = setTimeout(() => {
        refocus()
      }, 5000)
      return () => clearTimeout(timeout)
    }, [dragging,refocus])
  
    return {
      dragging,
      onDragStart: () => {
        setDragging(true)
      },
      onDragEnd: () => {
        setDragging(false)
      },
    }
  }
  