import { DefaultGenerics, Navigate, Route } from '@tanstack/react-location'
import { apiManagementRouterForSelfService } from 'modules/settings/router'
import { relativeRoute } from '../../general/constants/routes'
import { SELF_SERVICE_ROUTES } from '../../general/constants/selfServiceRoutes'
import AppGuard from '../auth/guards/AppGuard'
import RedirectAppGuard from '../auth/guards/RedirectAppGuard'

export const selfServiceRoutes: Route<DefaultGenerics> = {
  element: <AppGuard />,
  path: relativeRoute(SELF_SERVICE_ROUTES.selfService.root),
  children: [
    {
      path: relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.root()),
      element: () =>
        // todo: use selfService shell??
        import('../merchant-app-shell').then((el) => <el.default />),
      children: [
        {
          path: relativeRoute(
            SELF_SERVICE_ROUTES.selfService.organization.newOrder()
          ),
          element: () =>
            import('../self-service/new-order').then((el) => <el.default />),
        },
        {
          path: relativeRoute(
            SELF_SERVICE_ROUTES.selfService.organization.dashboard()
          ),
          element: () => import('../dashboard').then((el) => <el.default />),
        },
        {
          path: relativeRoute(
            SELF_SERVICE_ROUTES.selfService.organization.orders.root()
          ),
          children: [
            {
              path: relativeRoute(
                SELF_SERVICE_ROUTES.selfService.organization.orders.list()
              ),
              element: () =>
                import('../self-service/orders-list').then((el) => (
                  <el.default />
                )),
            },
            {
              path: relativeRoute(
                SELF_SERVICE_ROUTES.selfService.organization.orders.details()
              ),
              element: () =>
                import('../self-service/order-details').then((el) => (
                  <el.default />
                )),
            },
            {
              element: (
                <Navigate
                  to={relativeRoute(
                    SELF_SERVICE_ROUTES.selfService.organization.orders.list()
                  )}
                />
              ),
            },
          ],
        },
        ...apiManagementRouterForSelfService,
        {
          element: (
            <Navigate
              to={relativeRoute(
                SELF_SERVICE_ROUTES.selfService.organization.newOrder()
              )}
            />
          ),
        },
      ],
    },
    {
      element: <RedirectAppGuard />,
    },
  ],
}
