import { makeContextValueComponent } from '@barbora-delivery/merchant-ui'
import { logout } from 'firebaseApp'
import { LoaderWindow } from 'general/components/elements/LoaderWindow'
import { MeQuery, useMeQuery } from 'graphql/generated'
import { FC, useEffect } from 'react'
import { useLoggedInContext } from './FirebaseProvider'

export type ExpressUser = NonNullable<MeQuery['me']>

const { CurrentUserContextProvider, useCurrentUserContext } =
  makeContextValueComponent<ExpressUser | null>()('currentUser')

export { useCurrentUserContext, CurrentUserContextProvider }

export const CurrentUserProvider: FC = ({ children }) => {
  const loggedIn = useLoggedInContext()

  const { data, loading, error } = useMeQuery({
    fetchPolicy: loggedIn ? 'cache-first' : 'cache-only',
  })

  useEffect(() => {
    if (error) {
      logout()
    }
  }, [error])

  if (loading) {
    return <LoaderWindow />
  }

  return (
    <CurrentUserContextProvider value={data?.me || null}>
      {children}
    </CurrentUserContextProvider>
  )
}

export const useExpressUser = () => {
  const user = useCurrentUserContext()
  if (user == null) {
    throw new Error('User not found')
  }
  return user
}
