import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'

export const useLinkWithOrganization = () => {
  const [organization] = useOrganizationContext()

  const link = (route: string) => {
    if (!organization) {
      throw new Error('Organization not found')
    }
    return route.replace(':organization', organization)
  }

  return link
}
