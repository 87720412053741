import { AuthLayout } from 'general/components/layout/AuthLayout'
import { NavigateToRoot } from 'general/components/router/NavigateToRoot'
import { FC } from 'react'
import { Outlet } from '@tanstack/react-location'
import { useLoggedInContext } from '../auth-providers/FirebaseProvider'

const SetupGuard: FC = () => {
  const loggedIn = useLoggedInContext()

  if (!loggedIn) {
    return <NavigateToRoot />
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  )
}

export default SetupGuard
