export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AgeLimitation: string;
  Language: string;
  OrderFailureReason: string;
  OrderServiceDateTime: any;
  OrderStatus: "CREATED" | "COMPLETED" | "PLANNED" | "NEED_COURIER" | "COURIER_MISSING" | "ACTIVE" | "FAILED" | "STARTED";
  TaskETAType: any;
  TaskStatus: string;
  TaskType: "PICKUP" | "DROP_OFF";
  TimeZone: string;
  _float8: number;
  _jsonb: unknown;
  _text: string;
  float8: number;
  jsonb: any;
  numeric: number;
  time: any;
  timestamp: string;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

/** columns and relationships of "AccessToken" */
export type Accesstoken = {
  __typename?: 'Accesstoken';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastAccessAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  role: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  tokenDisplayName?: Maybe<Scalars['String']>;
  tokenHash: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "AccessToken" */
export type AccesstokenAggregate = {
  __typename?: 'AccesstokenAggregate';
  aggregate?: Maybe<AccesstokenAggregateFields>;
  nodes: Array<Accesstoken>;
};

/** aggregate fields of "AccessToken" */
export type AccesstokenAggregateFields = {
  __typename?: 'AccesstokenAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AccesstokenMaxFields>;
  min?: Maybe<AccesstokenMinFields>;
};


/** aggregate fields of "AccessToken" */
export type AccesstokenAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccesstokenSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "AccessToken" */
export type AccesstokenAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccesstokenMaxOrderBy>;
  min?: InputMaybe<AccesstokenMinOrderBy>;
};

/** input type for inserting array relation for remote table "AccessToken" */
export type AccesstokenArrRelInsertInput = {
  data: Array<AccesstokenInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccesstokenOnConflict>;
};

/** Boolean expression to filter rows from the table "AccessToken". All fields are combined with a logical 'AND'. */
export type AccesstokenBoolExp = {
  _and?: InputMaybe<Array<AccesstokenBoolExp>>;
  _not?: InputMaybe<AccesstokenBoolExp>;
  _or?: InputMaybe<Array<AccesstokenBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastAccessAt?: InputMaybe<TimestamptzComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  token?: InputMaybe<StringComparisonExp>;
  tokenDisplayName?: InputMaybe<StringComparisonExp>;
  tokenHash?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "AccessToken" */
export enum AccesstokenConstraint {
  /** unique or primary key constraint on columns "id" */
  AccessTokenPkey = 'AccessToken_pkey',
  /** unique or primary key constraint on columns "tokenHash" */
  AccessTokenTokenHashKey = 'AccessToken_tokenHash_key'
}

/** input type for inserting data into table "AccessToken" */
export type AccesstokenInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastAccessAt?: InputMaybe<Scalars['timestamptz']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  tokenDisplayName?: InputMaybe<Scalars['String']>;
  tokenHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AccesstokenMaxFields = {
  __typename?: 'AccesstokenMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastAccessAt?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenDisplayName?: Maybe<Scalars['String']>;
  tokenHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "AccessToken" */
export type AccesstokenMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessAt?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  tokenDisplayName?: InputMaybe<OrderBy>;
  tokenHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AccesstokenMinFields = {
  __typename?: 'AccesstokenMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastAccessAt?: Maybe<Scalars['timestamptz']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenDisplayName?: Maybe<Scalars['String']>;
  tokenHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "AccessToken" */
export type AccesstokenMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessAt?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  tokenDisplayName?: InputMaybe<OrderBy>;
  tokenHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "AccessToken" */
export type AccesstokenMutationResponse = {
  __typename?: 'AccesstokenMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accesstoken>;
};

/** on_conflict condition type for table "AccessToken" */
export type AccesstokenOnConflict = {
  constraint: AccesstokenConstraint;
  update_columns?: Array<AccesstokenUpdateColumn>;
  where?: InputMaybe<AccesstokenBoolExp>;
};

/** Ordering options when selecting data from "AccessToken". */
export type AccesstokenOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastAccessAt?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  token?: InputMaybe<OrderBy>;
  tokenDisplayName?: InputMaybe<OrderBy>;
  tokenHash?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: accessToken */
export type AccesstokenPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "AccessToken" */
export enum AccesstokenSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastAccessAt = 'lastAccessAt',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token',
  /** column name */
  TokenDisplayName = 'tokenDisplayName',
  /** column name */
  TokenHash = 'tokenHash',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "AccessToken" */
export type AccesstokenSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastAccessAt?: InputMaybe<Scalars['timestamptz']>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  tokenDisplayName?: InputMaybe<Scalars['String']>;
  tokenHash?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "AccessToken" */
export enum AccesstokenUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastAccessAt = 'lastAccessAt',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  Token = 'token',
  /** column name */
  TokenDisplayName = 'tokenDisplayName',
  /** column name */
  TokenHash = 'tokenHash',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "Address" */
export type Address = {
  __typename?: 'Address';
  address: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  coordinates?: Maybe<Scalars['_float8']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  info?: Maybe<Scalars['jsonb']>;
  meta?: Maybe<Scalars['_jsonb']>;
  notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasksAggregate: TaskAggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Address" */
export type AddressInfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Address" */
export type AddressTasksArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


/** columns and relationships of "Address" */
export type AddressTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};

/** aggregated selection of "Address" */
export type AddressAggregate = {
  __typename?: 'AddressAggregate';
  aggregate?: Maybe<AddressAggregateFields>;
  nodes: Array<Address>;
};

/** aggregate fields of "Address" */
export type AddressAggregateFields = {
  __typename?: 'AddressAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AddressMaxFields>;
  min?: Maybe<AddressMinFields>;
};


/** aggregate fields of "Address" */
export type AddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AddressAppendInput = {
  info?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Address". All fields are combined with a logical 'AND'. */
export type AddressBoolExp = {
  _and?: InputMaybe<Array<AddressBoolExp>>;
  _not?: InputMaybe<AddressBoolExp>;
  _or?: InputMaybe<Array<AddressBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  contactName?: InputMaybe<StringComparisonExp>;
  contactPhone?: InputMaybe<StringComparisonExp>;
  coordinates?: InputMaybe<_Float8ComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  info?: InputMaybe<JsonbComparisonExp>;
  meta?: InputMaybe<_JsonbComparisonExp>;
  notes?: InputMaybe<StringComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Address" */
export enum AddressConstraint {
  /** unique or primary key constraint on columns "contactPhone", "address" */
  AddressAddressContactPhoneKey = 'Address_address_contactPhone_key',
  /** unique or primary key constraint on columns "contactPhone", "address" */
  AddressContactPhoneAddressKey = 'Address_contactPhone_address_key',
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'Address_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AddressDeleteAtPathInput = {
  info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AddressDeleteElemInput = {
  info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AddressDeleteKeyInput = {
  info?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "Address" */
export type AddressInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Scalars['_float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['jsonb']>;
  meta?: InputMaybe<Scalars['_jsonb']>;
  notes?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AddressMaxFields = {
  __typename?: 'AddressMaxFields';
  address?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type AddressMinFields = {
  __typename?: 'AddressMinFields';
  address?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "Address" */
export type AddressMutationResponse = {
  __typename?: 'AddressMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "Address" */
export type AddressObjRelInsertInput = {
  data: AddressInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AddressOnConflict>;
};

/** on_conflict condition type for table "Address" */
export type AddressOnConflict = {
  constraint: AddressConstraint;
  update_columns?: Array<AddressUpdateColumn>;
  where?: InputMaybe<AddressBoolExp>;
};

/** Ordering options when selecting data from "Address". */
export type AddressOrderBy = {
  address?: InputMaybe<OrderBy>;
  contactName?: InputMaybe<OrderBy>;
  contactPhone?: InputMaybe<OrderBy>;
  coordinates?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderBy>;
  meta?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: address */
export type AddressPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AddressPrependInput = {
  info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Address" */
export enum AddressSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhone = 'contactPhone',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Meta = 'meta',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "Address" */
export type AddressSetInput = {
  address?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  coordinates?: InputMaybe<Scalars['_float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['jsonb']>;
  meta?: InputMaybe<Scalars['_jsonb']>;
  notes?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "Address" */
export enum AddressUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  ContactName = 'contactName',
  /** column name */
  ContactPhone = 'contactPhone',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Meta = 'meta',
  /** column name */
  Notes = 'notes',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Boolean expression to compare columns of type "AgeLimitation". All fields are combined with logical 'AND'. */
export type AgelimitationComparisonExp = {
  _eq?: InputMaybe<Scalars['AgeLimitation']>;
  _gt?: InputMaybe<Scalars['AgeLimitation']>;
  _gte?: InputMaybe<Scalars['AgeLimitation']>;
  _in?: InputMaybe<Array<Scalars['AgeLimitation']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['AgeLimitation']>;
  _lte?: InputMaybe<Scalars['AgeLimitation']>;
  _neq?: InputMaybe<Scalars['AgeLimitation']>;
  _nin?: InputMaybe<Array<Scalars['AgeLimitation']>>;
};

export type AuthOutput = {
  __typename?: 'AuthOutput';
  role: Role;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _cast?: InputMaybe<Boolean_Cast_Exp>;
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Boolean_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

export enum COrderFailureReason {
  CourierCarAccident = 'COURIER_CAR_ACCIDENT',
  CourierVechicleFailure = 'COURIER_VECHICLE_FAILURE',
  CustomerNotFound = 'CUSTOMER_NOT_FOUND',
  MerchantOrderNotFound = 'MERCHANT_ORDER_NOT_FOUND',
  Other = 'OTHER',
  Terminated = 'TERMINATED'
}

/** columns and relationships of "cancellation_policies" */
export type CancellationPolicies = {
  __typename?: 'CancellationPolicies';
  cancellationFee: Scalars['numeric'];
  /** An object relationship */
  cancellationPolicyType: CancellationPolicyTypes;
  id: Scalars['uuid'];
  name: Scalars['String'];
  policyType: CancellationPolicyTypesEnum;
  /** An array relationship */
  pricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  pricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
};


/** columns and relationships of "cancellation_policies" */
export type CancellationPoliciesPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "cancellation_policies" */
export type CancellationPoliciesPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** aggregated selection of "cancellation_policies" */
export type CancellationPoliciesAggregate = {
  __typename?: 'CancellationPoliciesAggregate';
  aggregate?: Maybe<CancellationPoliciesAggregateFields>;
  nodes: Array<CancellationPolicies>;
};

/** aggregate fields of "cancellation_policies" */
export type CancellationPoliciesAggregateFields = {
  __typename?: 'CancellationPoliciesAggregateFields';
  avg?: Maybe<CancellationPoliciesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CancellationPoliciesMaxFields>;
  min?: Maybe<CancellationPoliciesMinFields>;
  stddev?: Maybe<CancellationPoliciesStddevFields>;
  stddevPop?: Maybe<CancellationPoliciesStddevPopFields>;
  stddevSamp?: Maybe<CancellationPoliciesStddevSampFields>;
  sum?: Maybe<CancellationPoliciesSumFields>;
  varPop?: Maybe<CancellationPoliciesVarPopFields>;
  varSamp?: Maybe<CancellationPoliciesVarSampFields>;
  variance?: Maybe<CancellationPoliciesVarianceFields>;
};


/** aggregate fields of "cancellation_policies" */
export type CancellationPoliciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cancellation_policies" */
export type CancellationPoliciesAggregateOrderBy = {
  avg?: InputMaybe<CancellationPoliciesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CancellationPoliciesMaxOrderBy>;
  min?: InputMaybe<CancellationPoliciesMinOrderBy>;
  stddev?: InputMaybe<CancellationPoliciesStddevOrderBy>;
  stddev_pop?: InputMaybe<CancellationPoliciesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CancellationPoliciesStddevSampOrderBy>;
  sum?: InputMaybe<CancellationPoliciesSumOrderBy>;
  var_pop?: InputMaybe<CancellationPoliciesVarPopOrderBy>;
  var_samp?: InputMaybe<CancellationPoliciesVarSampOrderBy>;
  variance?: InputMaybe<CancellationPoliciesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "cancellation_policies" */
export type CancellationPoliciesArrRelInsertInput = {
  data: Array<CancellationPoliciesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CancellationPoliciesOnConflict>;
};

/** aggregate avg on columns */
export type CancellationPoliciesAvgFields = {
  __typename?: 'CancellationPoliciesAvgFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cancellation_policies" */
export type CancellationPoliciesAvgOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "cancellation_policies". All fields are combined with a logical 'AND'. */
export type CancellationPoliciesBoolExp = {
  _and?: InputMaybe<Array<CancellationPoliciesBoolExp>>;
  _not?: InputMaybe<CancellationPoliciesBoolExp>;
  _or?: InputMaybe<Array<CancellationPoliciesBoolExp>>;
  cancellationFee?: InputMaybe<NumericComparisonExp>;
  cancellationPolicyType?: InputMaybe<CancellationPolicyTypesBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  policyType?: InputMaybe<CancellationpolicytypesenumComparisonExp>;
  pricingPolicies?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** unique or primary key constraints on table "cancellation_policies" */
export enum CancellationPoliciesConstraint {
  /** unique or primary key constraint on columns "id" */
  CancellationPoliciesPkey = 'cancellation_policies_pkey'
}

/** input type for incrementing numeric columns in table "cancellation_policies" */
export type CancellationPoliciesIncInput = {
  cancellationFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "cancellation_policies" */
export type CancellationPoliciesInsertInput = {
  cancellationFee?: InputMaybe<Scalars['numeric']>;
  cancellationPolicyType?: InputMaybe<CancellationPolicyTypesObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  policyType?: InputMaybe<CancellationPolicyTypesEnum>;
  pricingPolicies?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
};

/** aggregate max on columns */
export type CancellationPoliciesMaxFields = {
  __typename?: 'CancellationPoliciesMaxFields';
  cancellationFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cancellation_policies" */
export type CancellationPoliciesMaxOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CancellationPoliciesMinFields = {
  __typename?: 'CancellationPoliciesMinFields';
  cancellationFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cancellation_policies" */
export type CancellationPoliciesMinOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "cancellation_policies" */
export type CancellationPoliciesMutationResponse = {
  __typename?: 'CancellationPoliciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CancellationPolicies>;
};

/** input type for inserting object relation for remote table "cancellation_policies" */
export type CancellationPoliciesObjRelInsertInput = {
  data: CancellationPoliciesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CancellationPoliciesOnConflict>;
};

/** on_conflict condition type for table "cancellation_policies" */
export type CancellationPoliciesOnConflict = {
  constraint: CancellationPoliciesConstraint;
  update_columns?: Array<CancellationPoliciesUpdateColumn>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};

/** Ordering options when selecting data from "cancellation_policies". */
export type CancellationPoliciesOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
  cancellationPolicyType?: InputMaybe<CancellationPolicyTypesOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  policyType?: InputMaybe<OrderBy>;
  pricingPolicies_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
};

/** primary key columns input for table: cancellation_policies */
export type CancellationPoliciesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "cancellation_policies" */
export enum CancellationPoliciesSelectColumn {
  /** column name */
  CancellationFee = 'cancellationFee',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PolicyType = 'policyType'
}

/** input type for updating data in table "cancellation_policies" */
export type CancellationPoliciesSetInput = {
  cancellationFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  policyType?: InputMaybe<CancellationPolicyTypesEnum>;
};

/** aggregate stddev on columns */
export type CancellationPoliciesStddevFields = {
  __typename?: 'CancellationPoliciesStddevFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cancellation_policies" */
export type CancellationPoliciesStddevOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CancellationPoliciesStddevPopFields = {
  __typename?: 'CancellationPoliciesStddevPopFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cancellation_policies" */
export type CancellationPoliciesStddevPopOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CancellationPoliciesStddevSampFields = {
  __typename?: 'CancellationPoliciesStddevSampFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cancellation_policies" */
export type CancellationPoliciesStddevSampOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CancellationPoliciesSumFields = {
  __typename?: 'CancellationPoliciesSumFields';
  cancellationFee?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "cancellation_policies" */
export type CancellationPoliciesSumOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** update columns of table "cancellation_policies" */
export enum CancellationPoliciesUpdateColumn {
  /** column name */
  CancellationFee = 'cancellationFee',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PolicyType = 'policyType'
}

/** aggregate var_pop on columns */
export type CancellationPoliciesVarPopFields = {
  __typename?: 'CancellationPoliciesVarPopFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cancellation_policies" */
export type CancellationPoliciesVarPopOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CancellationPoliciesVarSampFields = {
  __typename?: 'CancellationPoliciesVarSampFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cancellation_policies" */
export type CancellationPoliciesVarSampOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CancellationPoliciesVarianceFields = {
  __typename?: 'CancellationPoliciesVarianceFields';
  cancellationFee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cancellation_policies" */
export type CancellationPoliciesVarianceOrderBy = {
  cancellationFee?: InputMaybe<OrderBy>;
};

/** columns and relationships of "cancellation_policy_types" */
export type CancellationPolicyTypes = {
  __typename?: 'CancellationPolicyTypes';
  /** An array relationship */
  cancellationPolicies: Array<CancellationPolicies>;
  /** An aggregate relationship */
  cancellationPoliciesAggregate: CancellationPoliciesAggregate;
  description: Scalars['String'];
  value: Scalars['String'];
};


/** columns and relationships of "cancellation_policy_types" */
export type CancellationPolicyTypesCancellationPoliciesArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};


/** columns and relationships of "cancellation_policy_types" */
export type CancellationPolicyTypesCancellationPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};

/** aggregated selection of "cancellation_policy_types" */
export type CancellationPolicyTypesAggregate = {
  __typename?: 'CancellationPolicyTypesAggregate';
  aggregate?: Maybe<CancellationPolicyTypesAggregateFields>;
  nodes: Array<CancellationPolicyTypes>;
};

/** aggregate fields of "cancellation_policy_types" */
export type CancellationPolicyTypesAggregateFields = {
  __typename?: 'CancellationPolicyTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<CancellationPolicyTypesMaxFields>;
  min?: Maybe<CancellationPolicyTypesMinFields>;
};


/** aggregate fields of "cancellation_policy_types" */
export type CancellationPolicyTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CancellationPolicyTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cancellation_policy_types". All fields are combined with a logical 'AND'. */
export type CancellationPolicyTypesBoolExp = {
  _and?: InputMaybe<Array<CancellationPolicyTypesBoolExp>>;
  _not?: InputMaybe<CancellationPolicyTypesBoolExp>;
  _or?: InputMaybe<Array<CancellationPolicyTypesBoolExp>>;
  cancellationPolicies?: InputMaybe<CancellationPoliciesBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "cancellation_policy_types" */
export enum CancellationPolicyTypesConstraint {
  /** unique or primary key constraint on columns "value" */
  CancellationPolicyTypesPkey = 'cancellation_policy_types_pkey'
}

export enum CancellationPolicyTypesEnum {
  /** Cancellation policies expressed in absolute values */
  Absolute = 'ABSOLUTE',
  /** Cancellation policies expressed in relative terms */
  Relative = 'RELATIVE'
}

/** input type for inserting data into table "cancellation_policy_types" */
export type CancellationPolicyTypesInsertInput = {
  cancellationPolicies?: InputMaybe<CancellationPoliciesArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CancellationPolicyTypesMaxFields = {
  __typename?: 'CancellationPolicyTypesMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CancellationPolicyTypesMinFields = {
  __typename?: 'CancellationPolicyTypesMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cancellation_policy_types" */
export type CancellationPolicyTypesMutationResponse = {
  __typename?: 'CancellationPolicyTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CancellationPolicyTypes>;
};

/** input type for inserting object relation for remote table "cancellation_policy_types" */
export type CancellationPolicyTypesObjRelInsertInput = {
  data: CancellationPolicyTypesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CancellationPolicyTypesOnConflict>;
};

/** on_conflict condition type for table "cancellation_policy_types" */
export type CancellationPolicyTypesOnConflict = {
  constraint: CancellationPolicyTypesConstraint;
  update_columns?: Array<CancellationPolicyTypesUpdateColumn>;
  where?: InputMaybe<CancellationPolicyTypesBoolExp>;
};

/** Ordering options when selecting data from "cancellation_policy_types". */
export type CancellationPolicyTypesOrderBy = {
  cancellationPolicies_aggregate?: InputMaybe<CancellationPoliciesAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: cancellation_policy_types */
export type CancellationPolicyTypesPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "cancellation_policy_types" */
export enum CancellationPolicyTypesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cancellation_policy_types" */
export type CancellationPolicyTypesSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cancellation_policy_types" */
export enum CancellationPolicyTypesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "CancellationPolicyTypesEnum". All fields are combined with logical 'AND'. */
export type CancellationpolicytypesenumComparisonExp = {
  _eq?: InputMaybe<CancellationPolicyTypesEnum>;
  _in?: InputMaybe<Array<CancellationPolicyTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CancellationPolicyTypesEnum>;
  _nin?: InputMaybe<Array<CancellationPolicyTypesEnum>>;
};

export type CoordinateInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** columns and relationships of "Courier" */
export type Courier = {
  __typename?: 'Courier';
  breakStart?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  courierProfile?: Maybe<Courierprofile>;
  /** An array relationship */
  courierReports: Array<Courierreport>;
  /** An aggregate relationship */
  courierReportsAggregate: CourierreportAggregate;
  createdAt: Scalars['timestamptz'];
  /** A computed field, executes function "courier_display_name" */
  displayName?: Maybe<Scalars['String']>;
  fmc?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isApproved?: Maybe<Scalars['Boolean']>;
  isOnline: Scalars['Boolean'];
  lastLocation?: Maybe<Scalars['_float8']>;
  lastLocationUpdateTime?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  ordersAggregate: OrderAggregate;
  region?: Maybe<Scalars['String']>;
  /** An array relationship */
  trips: Array<Trip>;
  /** An aggregate relationship */
  tripsAggregate: TripAggregate;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  /** An array relationship */
  vehicles: Array<Vehicle>;
  /** An aggregate relationship */
  vehiclesAggregate: VehicleAggregate;
};


/** columns and relationships of "Courier" */
export type CourierCourierReportsArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierCourierReportsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierOrdersArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierTripsArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierTripsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierVehiclesArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};


/** columns and relationships of "Courier" */
export type CourierVehiclesAggregateArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};

/** aggregated selection of "Courier" */
export type CourierAggregate = {
  __typename?: 'CourierAggregate';
  aggregate?: Maybe<CourierAggregateFields>;
  nodes: Array<Courier>;
};

/** aggregate fields of "Courier" */
export type CourierAggregateFields = {
  __typename?: 'CourierAggregateFields';
  avg?: Maybe<CourierAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourierMaxFields>;
  min?: Maybe<CourierMinFields>;
  stddev?: Maybe<CourierStddevFields>;
  stddevPop?: Maybe<CourierStddevPopFields>;
  stddevSamp?: Maybe<CourierStddevSampFields>;
  sum?: Maybe<CourierSumFields>;
  varPop?: Maybe<CourierVarPopFields>;
  varSamp?: Maybe<CourierVarSampFields>;
  variance?: Maybe<CourierVarianceFields>;
};


/** aggregate fields of "Courier" */
export type CourierAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourierSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CourierAvgFields = {
  __typename?: 'CourierAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Courier". All fields are combined with a logical 'AND'. */
export type CourierBoolExp = {
  _and?: InputMaybe<Array<CourierBoolExp>>;
  _not?: InputMaybe<CourierBoolExp>;
  _or?: InputMaybe<Array<CourierBoolExp>>;
  breakStart?: InputMaybe<TimestampComparisonExp>;
  courierProfile?: InputMaybe<CourierprofileBoolExp>;
  courierReports?: InputMaybe<CourierreportBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  fmc?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  isApproved?: InputMaybe<BooleanComparisonExp>;
  isOnline?: InputMaybe<BooleanComparisonExp>;
  lastLocation?: InputMaybe<_Float8ComparisonExp>;
  lastLocationUpdateTime?: InputMaybe<TimestamptzComparisonExp>;
  orders?: InputMaybe<OrderBoolExp>;
  region?: InputMaybe<StringComparisonExp>;
  trips?: InputMaybe<TripBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  vehicles?: InputMaybe<VehicleBoolExp>;
};

/** unique or primary key constraints on table "Courier" */
export enum CourierConstraint {
  /** unique or primary key constraint on columns "id" */
  CourierPkey = 'Courier_pkey',
  /** unique or primary key constraint on columns "userId" */
  CourierUserIdKey = 'Courier_userId_key'
}

/** input type for incrementing numeric columns in table "Courier" */
export type CourierIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "courier_info" */
export type CourierInfo = {
  __typename?: 'CourierInfo';
  courierid?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
};

/** aggregated selection of "courier_info" */
export type CourierInfoAggregate = {
  __typename?: 'CourierInfoAggregate';
  aggregate?: Maybe<CourierInfoAggregateFields>;
  nodes: Array<CourierInfo>;
};

/** aggregate fields of "courier_info" */
export type CourierInfoAggregateFields = {
  __typename?: 'CourierInfoAggregateFields';
  avg?: Maybe<CourierInfoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourierInfoMaxFields>;
  min?: Maybe<CourierInfoMinFields>;
  stddev?: Maybe<CourierInfoStddevFields>;
  stddevPop?: Maybe<CourierInfoStddevPopFields>;
  stddevSamp?: Maybe<CourierInfoStddevSampFields>;
  sum?: Maybe<CourierInfoSumFields>;
  varPop?: Maybe<CourierInfoVarPopFields>;
  varSamp?: Maybe<CourierInfoVarSampFields>;
  variance?: Maybe<CourierInfoVarianceFields>;
};


/** aggregate fields of "courier_info" */
export type CourierInfoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourierInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CourierInfoAvgFields = {
  __typename?: 'CourierInfoAvgFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "courier_info". All fields are combined with a logical 'AND'. */
export type CourierInfoBoolExp = {
  _and?: InputMaybe<Array<CourierInfoBoolExp>>;
  _not?: InputMaybe<CourierInfoBoolExp>;
  _or?: InputMaybe<Array<CourierInfoBoolExp>>;
  courierid?: InputMaybe<IntComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  userid?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "courier_info" */
export type CourierInfoInsertInput = {
  courierid?: InputMaybe<Scalars['Int']>;
  displayName?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CourierInfoMaxFields = {
  __typename?: 'CourierInfoMaxFields';
  courierid?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CourierInfoMinFields = {
  __typename?: 'CourierInfoMinFields';
  courierid?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  userid?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "courier_info" */
export type CourierInfoObjRelInsertInput = {
  data: CourierInfoInsertInput;
};

/** Ordering options when selecting data from "courier_info". */
export type CourierInfoOrderBy = {
  courierid?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  userid?: InputMaybe<OrderBy>;
};

/** select columns of table "courier_info" */
export enum CourierInfoSelectColumn {
  /** column name */
  Courierid = 'courierid',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Userid = 'userid'
}

/** aggregate stddev on columns */
export type CourierInfoStddevFields = {
  __typename?: 'CourierInfoStddevFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CourierInfoStddevPopFields = {
  __typename?: 'CourierInfoStddevPopFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CourierInfoStddevSampFields = {
  __typename?: 'CourierInfoStddevSampFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CourierInfoSumFields = {
  __typename?: 'CourierInfoSumFields';
  courierid?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type CourierInfoVarPopFields = {
  __typename?: 'CourierInfoVarPopFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CourierInfoVarSampFields = {
  __typename?: 'CourierInfoVarSampFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CourierInfoVarianceFields = {
  __typename?: 'CourierInfoVarianceFields';
  courierid?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Courier" */
export type CourierInsertInput = {
  breakStart?: InputMaybe<Scalars['timestamp']>;
  courierProfile?: InputMaybe<CourierprofileObjRelInsertInput>;
  courierReports?: InputMaybe<CourierreportArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fmc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isOnline?: InputMaybe<Scalars['Boolean']>;
  lastLocation?: InputMaybe<Scalars['_float8']>;
  lastLocationUpdateTime?: InputMaybe<Scalars['timestamptz']>;
  orders?: InputMaybe<OrderArrRelInsertInput>;
  region?: InputMaybe<Scalars['String']>;
  trips?: InputMaybe<TripArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  vehicles?: InputMaybe<VehicleArrRelInsertInput>;
};

/** aggregate max on columns */
export type CourierMaxFields = {
  __typename?: 'CourierMaxFields';
  breakStart?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fmc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLocationUpdateTime?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CourierMinFields = {
  __typename?: 'CourierMinFields';
  breakStart?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fmc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLocationUpdateTime?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Courier" */
export type CourierMutationResponse = {
  __typename?: 'CourierMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courier>;
};

/** input type for inserting object relation for remote table "Courier" */
export type CourierObjRelInsertInput = {
  data: CourierInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CourierOnConflict>;
};

/** on_conflict condition type for table "Courier" */
export type CourierOnConflict = {
  constraint: CourierConstraint;
  update_columns?: Array<CourierUpdateColumn>;
  where?: InputMaybe<CourierBoolExp>;
};

/** Ordering options when selecting data from "Courier". */
export type CourierOrderBy = {
  breakStart?: InputMaybe<OrderBy>;
  courierProfile?: InputMaybe<CourierprofileOrderBy>;
  courierReports_aggregate?: InputMaybe<CourierreportAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  fmc?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isApproved?: InputMaybe<OrderBy>;
  isOnline?: InputMaybe<OrderBy>;
  lastLocation?: InputMaybe<OrderBy>;
  lastLocationUpdateTime?: InputMaybe<OrderBy>;
  orders_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  region?: InputMaybe<OrderBy>;
  trips_aggregate?: InputMaybe<TripAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  vehicles_aggregate?: InputMaybe<VehicleAggregateOrderBy>;
};

/** columns and relationships of "courier_payout_settings" */
export type CourierPayoutSettings = {
  __typename?: 'CourierPayoutSettings';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  distanceFee: Scalars['numeric'];
  dropoffFee: Scalars['numeric'];
  id: Scalars['Int'];
  minimumCourierPayout: Scalars['numeric'];
  pickupFee: Scalars['numeric'];
  /** An object relationship */
  region: Region;
  regionId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy?: Maybe<Scalars['String']>;
  /** An object relationship */
  updatedByUser?: Maybe<User>;
  /** An object relationship */
  user: User;
  validFrom: Scalars['timestamptz'];
  validTo: Scalars['timestamptz'];
};

/** aggregated selection of "courier_payout_settings" */
export type CourierPayoutSettingsAggregate = {
  __typename?: 'CourierPayoutSettingsAggregate';
  aggregate?: Maybe<CourierPayoutSettingsAggregateFields>;
  nodes: Array<CourierPayoutSettings>;
};

/** aggregate fields of "courier_payout_settings" */
export type CourierPayoutSettingsAggregateFields = {
  __typename?: 'CourierPayoutSettingsAggregateFields';
  avg?: Maybe<CourierPayoutSettingsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourierPayoutSettingsMaxFields>;
  min?: Maybe<CourierPayoutSettingsMinFields>;
  stddev?: Maybe<CourierPayoutSettingsStddevFields>;
  stddevPop?: Maybe<CourierPayoutSettingsStddevPopFields>;
  stddevSamp?: Maybe<CourierPayoutSettingsStddevSampFields>;
  sum?: Maybe<CourierPayoutSettingsSumFields>;
  varPop?: Maybe<CourierPayoutSettingsVarPopFields>;
  varSamp?: Maybe<CourierPayoutSettingsVarSampFields>;
  variance?: Maybe<CourierPayoutSettingsVarianceFields>;
};


/** aggregate fields of "courier_payout_settings" */
export type CourierPayoutSettingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "courier_payout_settings" */
export type CourierPayoutSettingsAggregateOrderBy = {
  avg?: InputMaybe<CourierPayoutSettingsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CourierPayoutSettingsMaxOrderBy>;
  min?: InputMaybe<CourierPayoutSettingsMinOrderBy>;
  stddev?: InputMaybe<CourierPayoutSettingsStddevOrderBy>;
  stddev_pop?: InputMaybe<CourierPayoutSettingsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CourierPayoutSettingsStddevSampOrderBy>;
  sum?: InputMaybe<CourierPayoutSettingsSumOrderBy>;
  var_pop?: InputMaybe<CourierPayoutSettingsVarPopOrderBy>;
  var_samp?: InputMaybe<CourierPayoutSettingsVarSampOrderBy>;
  variance?: InputMaybe<CourierPayoutSettingsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "courier_payout_settings" */
export type CourierPayoutSettingsArrRelInsertInput = {
  data: Array<CourierPayoutSettingsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CourierPayoutSettingsOnConflict>;
};

/** aggregate avg on columns */
export type CourierPayoutSettingsAvgFields = {
  __typename?: 'CourierPayoutSettingsAvgFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsAvgOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "courier_payout_settings". All fields are combined with a logical 'AND'. */
export type CourierPayoutSettingsBoolExp = {
  _and?: InputMaybe<Array<CourierPayoutSettingsBoolExp>>;
  _not?: InputMaybe<CourierPayoutSettingsBoolExp>;
  _or?: InputMaybe<Array<CourierPayoutSettingsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  distanceFee?: InputMaybe<NumericComparisonExp>;
  dropoffFee?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  minimumCourierPayout?: InputMaybe<NumericComparisonExp>;
  pickupFee?: InputMaybe<NumericComparisonExp>;
  region?: InputMaybe<RegionBoolExp>;
  regionId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  updatedByUser?: InputMaybe<UserBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  validFrom?: InputMaybe<TimestamptzComparisonExp>;
  validTo?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "courier_payout_settings" */
export enum CourierPayoutSettingsConstraint {
  /** unique or primary key constraint on columns "id" */
  CourierPayoutSettingsPkey = 'CourierPayoutSettings_pkey'
}

/** input type for incrementing numeric columns in table "courier_payout_settings" */
export type CourierPayoutSettingsIncInput = {
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "courier_payout_settings" */
export type CourierPayoutSettingsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
  region?: InputMaybe<RegionObjRelInsertInput>;
  regionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  updatedByUser?: InputMaybe<UserObjRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  validTo?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CourierPayoutSettingsMaxFields = {
  __typename?: 'CourierPayoutSettingsMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
  regionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validTo?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CourierPayoutSettingsMinFields = {
  __typename?: 'CourierPayoutSettingsMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
  regionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validTo?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "courier_payout_settings" */
export type CourierPayoutSettingsMutationResponse = {
  __typename?: 'CourierPayoutSettingsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CourierPayoutSettings>;
};

/** on_conflict condition type for table "courier_payout_settings" */
export type CourierPayoutSettingsOnConflict = {
  constraint: CourierPayoutSettingsConstraint;
  update_columns?: Array<CourierPayoutSettingsUpdateColumn>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};

/** Ordering options when selecting data from "courier_payout_settings". */
export type CourierPayoutSettingsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
  region?: InputMaybe<RegionOrderBy>;
  regionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  updatedByUser?: InputMaybe<UserOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courier_payout_settings */
export type CourierPayoutSettingsPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "courier_payout_settings" */
export enum CourierPayoutSettingsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  DropoffFee = 'dropoffFee',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumCourierPayout = 'minimumCourierPayout',
  /** column name */
  PickupFee = 'pickupFee',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

/** input type for updating data in table "courier_payout_settings" */
export type CourierPayoutSettingsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
  regionId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  validFrom?: InputMaybe<Scalars['timestamptz']>;
  validTo?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CourierPayoutSettingsStddevFields = {
  __typename?: 'CourierPayoutSettingsStddevFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsStddevOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CourierPayoutSettingsStddevPopFields = {
  __typename?: 'CourierPayoutSettingsStddevPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsStddevPopOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CourierPayoutSettingsStddevSampFields = {
  __typename?: 'CourierPayoutSettingsStddevSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsStddevSampOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CourierPayoutSettingsSumFields = {
  __typename?: 'CourierPayoutSettingsSumFields';
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsSumOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** update columns of table "courier_payout_settings" */
export enum CourierPayoutSettingsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  DropoffFee = 'dropoffFee',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumCourierPayout = 'minimumCourierPayout',
  /** column name */
  PickupFee = 'pickupFee',
  /** column name */
  RegionId = 'regionId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

/** aggregate var_pop on columns */
export type CourierPayoutSettingsVarPopFields = {
  __typename?: 'CourierPayoutSettingsVarPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsVarPopOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CourierPayoutSettingsVarSampFields = {
  __typename?: 'CourierPayoutSettingsVarSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsVarSampOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CourierPayoutSettingsVarianceFields = {
  __typename?: 'CourierPayoutSettingsVarianceFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "courier_payout_settings" */
export type CourierPayoutSettingsVarianceOrderBy = {
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courier */
export type CourierPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "Courier" */
export enum CourierSelectColumn {
  /** column name */
  BreakStart = 'breakStart',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fmc = 'fmc',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IsOnline = 'isOnline',
  /** column name */
  LastLocation = 'lastLocation',
  /** column name */
  LastLocationUpdateTime = 'lastLocationUpdateTime',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Courier" */
export type CourierSetInput = {
  breakStart?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fmc?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  isOnline?: InputMaybe<Scalars['Boolean']>;
  lastLocation?: InputMaybe<Scalars['_float8']>;
  lastLocationUpdateTime?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CourierStddevFields = {
  __typename?: 'CourierStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CourierStddevPopFields = {
  __typename?: 'CourierStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CourierStddevSampFields = {
  __typename?: 'CourierStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CourierSumFields = {
  __typename?: 'CourierSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "Courier" */
export enum CourierUpdateColumn {
  /** column name */
  BreakStart = 'breakStart',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Fmc = 'fmc',
  /** column name */
  Id = 'id',
  /** column name */
  IsApproved = 'isApproved',
  /** column name */
  IsOnline = 'isOnline',
  /** column name */
  LastLocation = 'lastLocation',
  /** column name */
  LastLocationUpdateTime = 'lastLocationUpdateTime',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type CourierVarPopFields = {
  __typename?: 'CourierVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CourierVarSampFields = {
  __typename?: 'CourierVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CourierVarianceFields = {
  __typename?: 'CourierVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CourierProfile" */
export type Courierprofile = {
  __typename?: 'Courierprofile';
  activityCode: Scalars['String'];
  /** An object relationship */
  courier: Courier;
  courierId: Scalars['Int'];
  createdAt: Scalars['timestamp'];
  iban: Scalars['String'];
  identificationNumber: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  validated: Scalars['Boolean'];
};

/** aggregated selection of "CourierProfile" */
export type CourierprofileAggregate = {
  __typename?: 'CourierprofileAggregate';
  aggregate?: Maybe<CourierprofileAggregateFields>;
  nodes: Array<Courierprofile>;
};

/** aggregate fields of "CourierProfile" */
export type CourierprofileAggregateFields = {
  __typename?: 'CourierprofileAggregateFields';
  avg?: Maybe<CourierprofileAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourierprofileMaxFields>;
  min?: Maybe<CourierprofileMinFields>;
  stddev?: Maybe<CourierprofileStddevFields>;
  stddevPop?: Maybe<CourierprofileStddevPopFields>;
  stddevSamp?: Maybe<CourierprofileStddevSampFields>;
  sum?: Maybe<CourierprofileSumFields>;
  varPop?: Maybe<CourierprofileVarPopFields>;
  varSamp?: Maybe<CourierprofileVarSampFields>;
  variance?: Maybe<CourierprofileVarianceFields>;
};


/** aggregate fields of "CourierProfile" */
export type CourierprofileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourierprofileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CourierprofileAvgFields = {
  __typename?: 'CourierprofileAvgFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CourierProfile". All fields are combined with a logical 'AND'. */
export type CourierprofileBoolExp = {
  _and?: InputMaybe<Array<CourierprofileBoolExp>>;
  _not?: InputMaybe<CourierprofileBoolExp>;
  _or?: InputMaybe<Array<CourierprofileBoolExp>>;
  activityCode?: InputMaybe<StringComparisonExp>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  iban?: InputMaybe<StringComparisonExp>;
  identificationNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  validated?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "CourierProfile" */
export enum CourierprofileConstraint {
  /** unique or primary key constraint on columns "courierId" */
  CourierProfileCourierIdKey = 'CourierProfile_courierId_key'
}

/** input type for incrementing numeric columns in table "CourierProfile" */
export type CourierprofileIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CourierProfile" */
export type CourierprofileInsertInput = {
  activityCode?: InputMaybe<Scalars['String']>;
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  iban?: InputMaybe<Scalars['String']>;
  identificationNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  validated?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type CourierprofileMaxFields = {
  __typename?: 'CourierprofileMaxFields';
  activityCode?: Maybe<Scalars['String']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type CourierprofileMinFields = {
  __typename?: 'CourierprofileMinFields';
  activityCode?: Maybe<Scalars['String']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  identificationNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "CourierProfile" */
export type CourierprofileMutationResponse = {
  __typename?: 'CourierprofileMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courierprofile>;
};

/** input type for inserting object relation for remote table "CourierProfile" */
export type CourierprofileObjRelInsertInput = {
  data: CourierprofileInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CourierprofileOnConflict>;
};

/** on_conflict condition type for table "CourierProfile" */
export type CourierprofileOnConflict = {
  constraint: CourierprofileConstraint;
  update_columns?: Array<CourierprofileUpdateColumn>;
  where?: InputMaybe<CourierprofileBoolExp>;
};

/** Ordering options when selecting data from "CourierProfile". */
export type CourierprofileOrderBy = {
  activityCode?: InputMaybe<OrderBy>;
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  iban?: InputMaybe<OrderBy>;
  identificationNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  validated?: InputMaybe<OrderBy>;
};

/** select columns of table "CourierProfile" */
export enum CourierprofileSelectColumn {
  /** column name */
  ActivityCode = 'activityCode',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Iban = 'iban',
  /** column name */
  IdentificationNumber = 'identificationNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validated = 'validated'
}

/** input type for updating data in table "CourierProfile" */
export type CourierprofileSetInput = {
  activityCode?: InputMaybe<Scalars['String']>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  iban?: InputMaybe<Scalars['String']>;
  identificationNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  validated?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type CourierprofileStddevFields = {
  __typename?: 'CourierprofileStddevFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CourierprofileStddevPopFields = {
  __typename?: 'CourierprofileStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CourierprofileStddevSampFields = {
  __typename?: 'CourierprofileStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CourierprofileSumFields = {
  __typename?: 'CourierprofileSumFields';
  courierId?: Maybe<Scalars['Int']>;
};

/** update columns of table "CourierProfile" */
export enum CourierprofileUpdateColumn {
  /** column name */
  ActivityCode = 'activityCode',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Iban = 'iban',
  /** column name */
  IdentificationNumber = 'identificationNumber',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validated = 'validated'
}

/** aggregate var_pop on columns */
export type CourierprofileVarPopFields = {
  __typename?: 'CourierprofileVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CourierprofileVarSampFields = {
  __typename?: 'CourierprofileVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CourierprofileVarianceFields = {
  __typename?: 'CourierprofileVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CourierReport" */
export type Courierreport = {
  __typename?: 'Courierreport';
  /** An object relationship */
  courier: Courier;
  courierId: Scalars['Int'];
  createdAt: Scalars['timestamp'];
  from: Scalars['timestamp'];
  id: Scalars['Int'];
  mailSent: Scalars['Boolean'];
  /** An object relationship */
  pdf?: Maybe<Pdf>;
  pdfId?: Maybe<Scalars['uuid']>;
  robolabsInvoiceId?: Maybe<Scalars['uuid']>;
  to: Scalars['timestamp'];
};

/** aggregated selection of "CourierReport" */
export type CourierreportAggregate = {
  __typename?: 'CourierreportAggregate';
  aggregate?: Maybe<CourierreportAggregateFields>;
  nodes: Array<Courierreport>;
};

/** aggregate fields of "CourierReport" */
export type CourierreportAggregateFields = {
  __typename?: 'CourierreportAggregateFields';
  avg?: Maybe<CourierreportAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CourierreportMaxFields>;
  min?: Maybe<CourierreportMinFields>;
  stddev?: Maybe<CourierreportStddevFields>;
  stddevPop?: Maybe<CourierreportStddevPopFields>;
  stddevSamp?: Maybe<CourierreportStddevSampFields>;
  sum?: Maybe<CourierreportSumFields>;
  varPop?: Maybe<CourierreportVarPopFields>;
  varSamp?: Maybe<CourierreportVarSampFields>;
  variance?: Maybe<CourierreportVarianceFields>;
};


/** aggregate fields of "CourierReport" */
export type CourierreportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CourierreportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "CourierReport" */
export type CourierreportAggregateOrderBy = {
  avg?: InputMaybe<CourierreportAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CourierreportMaxOrderBy>;
  min?: InputMaybe<CourierreportMinOrderBy>;
  stddev?: InputMaybe<CourierreportStddevOrderBy>;
  stddev_pop?: InputMaybe<CourierreportStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CourierreportStddevSampOrderBy>;
  sum?: InputMaybe<CourierreportSumOrderBy>;
  var_pop?: InputMaybe<CourierreportVarPopOrderBy>;
  var_samp?: InputMaybe<CourierreportVarSampOrderBy>;
  variance?: InputMaybe<CourierreportVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "CourierReport" */
export type CourierreportArrRelInsertInput = {
  data: Array<CourierreportInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CourierreportOnConflict>;
};

/** aggregate avg on columns */
export type CourierreportAvgFields = {
  __typename?: 'CourierreportAvgFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "CourierReport" */
export type CourierreportAvgOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "CourierReport". All fields are combined with a logical 'AND'. */
export type CourierreportBoolExp = {
  _and?: InputMaybe<Array<CourierreportBoolExp>>;
  _not?: InputMaybe<CourierreportBoolExp>;
  _or?: InputMaybe<Array<CourierreportBoolExp>>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  from?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  mailSent?: InputMaybe<BooleanComparisonExp>;
  pdf?: InputMaybe<PdfBoolExp>;
  pdfId?: InputMaybe<UuidComparisonExp>;
  robolabsInvoiceId?: InputMaybe<UuidComparisonExp>;
  to?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "CourierReport" */
export enum CourierreportConstraint {
  /** unique or primary key constraint on columns "id" */
  CourierReportPkey = 'CourierReport_pkey'
}

/** input type for incrementing numeric columns in table "CourierReport" */
export type CourierreportIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "CourierReport" */
export type CourierreportInsertInput = {
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  from?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  mailSent?: InputMaybe<Scalars['Boolean']>;
  pdf?: InputMaybe<PdfObjRelInsertInput>;
  pdfId?: InputMaybe<Scalars['uuid']>;
  robolabsInvoiceId?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type CourierreportMaxFields = {
  __typename?: 'CourierreportMaxFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  pdfId?: Maybe<Scalars['uuid']>;
  robolabsInvoiceId?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "CourierReport" */
export type CourierreportMaxOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pdfId?: InputMaybe<OrderBy>;
  robolabsInvoiceId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CourierreportMinFields = {
  __typename?: 'CourierreportMinFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  pdfId?: Maybe<Scalars['uuid']>;
  robolabsInvoiceId?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "CourierReport" */
export type CourierreportMinOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  pdfId?: InputMaybe<OrderBy>;
  robolabsInvoiceId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "CourierReport" */
export type CourierreportMutationResponse = {
  __typename?: 'CourierreportMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courierreport>;
};

/** input type for inserting object relation for remote table "CourierReport" */
export type CourierreportObjRelInsertInput = {
  data: CourierreportInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CourierreportOnConflict>;
};

/** on_conflict condition type for table "CourierReport" */
export type CourierreportOnConflict = {
  constraint: CourierreportConstraint;
  update_columns?: Array<CourierreportUpdateColumn>;
  where?: InputMaybe<CourierreportBoolExp>;
};

/** Ordering options when selecting data from "CourierReport". */
export type CourierreportOrderBy = {
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mailSent?: InputMaybe<OrderBy>;
  pdf?: InputMaybe<PdfOrderBy>;
  pdfId?: InputMaybe<OrderBy>;
  robolabsInvoiceId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: courierReport */
export type CourierreportPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "CourierReport" */
export enum CourierreportSelectColumn {
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  MailSent = 'mailSent',
  /** column name */
  PdfId = 'pdfId',
  /** column name */
  RobolabsInvoiceId = 'robolabsInvoiceId',
  /** column name */
  To = 'to'
}

/** input type for updating data in table "CourierReport" */
export type CourierreportSetInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  from?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  mailSent?: InputMaybe<Scalars['Boolean']>;
  pdfId?: InputMaybe<Scalars['uuid']>;
  robolabsInvoiceId?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type CourierreportStddevFields = {
  __typename?: 'CourierreportStddevFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "CourierReport" */
export type CourierreportStddevOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CourierreportStddevPopFields = {
  __typename?: 'CourierreportStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "CourierReport" */
export type CourierreportStddevPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CourierreportStddevSampFields = {
  __typename?: 'CourierreportStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "CourierReport" */
export type CourierreportStddevSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type CourierreportSumFields = {
  __typename?: 'CourierreportSumFields';
  courierId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "CourierReport" */
export type CourierreportSumOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "CourierReport" */
export enum CourierreportUpdateColumn {
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  MailSent = 'mailSent',
  /** column name */
  PdfId = 'pdfId',
  /** column name */
  RobolabsInvoiceId = 'robolabsInvoiceId',
  /** column name */
  To = 'to'
}

/** aggregate var_pop on columns */
export type CourierreportVarPopFields = {
  __typename?: 'CourierreportVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "CourierReport" */
export type CourierreportVarPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CourierreportVarSampFields = {
  __typename?: 'CourierreportVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "CourierReport" */
export type CourierreportVarSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CourierreportVarianceFields = {
  __typename?: 'CourierreportVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "CourierReport" */
export type CourierreportVarianceOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "csv_export" */
export type CsvExport = {
  __typename?: 'CsvExport';
  createdAt: Scalars['time'];
  done: Scalars['Boolean'];
  filters?: Maybe<Scalars['jsonb']>;
  id: Scalars['Int'];
  organizationId: Scalars['String'];
  progress: Scalars['Int'];
  total: Scalars['Int'];
  updatedAt: Scalars['timetz'];
  url?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};


/** columns and relationships of "csv_export" */
export type CsvExportFiltersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "csv_export" */
export type CsvExportAggregate = {
  __typename?: 'CsvExportAggregate';
  aggregate?: Maybe<CsvExportAggregateFields>;
  nodes: Array<CsvExport>;
};

/** aggregate fields of "csv_export" */
export type CsvExportAggregateFields = {
  __typename?: 'CsvExportAggregateFields';
  avg?: Maybe<CsvExportAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CsvExportMaxFields>;
  min?: Maybe<CsvExportMinFields>;
  stddev?: Maybe<CsvExportStddevFields>;
  stddevPop?: Maybe<CsvExportStddevPopFields>;
  stddevSamp?: Maybe<CsvExportStddevSampFields>;
  sum?: Maybe<CsvExportSumFields>;
  varPop?: Maybe<CsvExportVarPopFields>;
  varSamp?: Maybe<CsvExportVarSampFields>;
  variance?: Maybe<CsvExportVarianceFields>;
};


/** aggregate fields of "csv_export" */
export type CsvExportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CsvExportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CsvExportAppendInput = {
  filters?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type CsvExportAvgFields = {
  __typename?: 'CsvExportAvgFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "csv_export". All fields are combined with a logical 'AND'. */
export type CsvExportBoolExp = {
  _and?: InputMaybe<Array<CsvExportBoolExp>>;
  _not?: InputMaybe<CsvExportBoolExp>;
  _or?: InputMaybe<Array<CsvExportBoolExp>>;
  createdAt?: InputMaybe<TimeComparisonExp>;
  done?: InputMaybe<BooleanComparisonExp>;
  filters?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  progress?: InputMaybe<IntComparisonExp>;
  total?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimetzComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "csv_export" */
export enum CsvExportConstraint {
  /** unique or primary key constraint on columns "id" */
  CsvExportPkey = 'csv_export_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CsvExportDeleteAtPathInput = {
  filters?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CsvExportDeleteElemInput = {
  filters?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CsvExportDeleteKeyInput = {
  filters?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "csv_export" */
export type CsvExportIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  progress?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "csv_export" */
export type CsvExportInsertInput = {
  createdAt?: InputMaybe<Scalars['time']>;
  done?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
  progress?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timetz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CsvExportMaxFields = {
  __typename?: 'CsvExportMaxFields';
  id?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timetz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CsvExportMinFields = {
  __typename?: 'CsvExportMinFields';
  id?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timetz']>;
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "csv_export" */
export type CsvExportMutationResponse = {
  __typename?: 'CsvExportMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CsvExport>;
};

/** on_conflict condition type for table "csv_export" */
export type CsvExportOnConflict = {
  constraint: CsvExportConstraint;
  update_columns?: Array<CsvExportUpdateColumn>;
  where?: InputMaybe<CsvExportBoolExp>;
};

/** Ordering options when selecting data from "csv_export". */
export type CsvExportOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  done?: InputMaybe<OrderBy>;
  filters?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  progress?: InputMaybe<OrderBy>;
  total?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: csv_export */
export type CsvExportPkColumnsInput = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CsvExportPrependInput = {
  filters?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "csv_export" */
export enum CsvExportSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Done = 'done',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Progress = 'progress',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "csv_export" */
export type CsvExportSetInput = {
  createdAt?: InputMaybe<Scalars['time']>;
  done?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
  progress?: InputMaybe<Scalars['Int']>;
  total?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timetz']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type CsvExportStddevFields = {
  __typename?: 'CsvExportStddevFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CsvExportStddevPopFields = {
  __typename?: 'CsvExportStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CsvExportStddevSampFields = {
  __typename?: 'CsvExportStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CsvExportSumFields = {
  __typename?: 'CsvExportSumFields';
  id?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** update columns of table "csv_export" */
export enum CsvExportUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Done = 'done',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Progress = 'progress',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type CsvExportVarPopFields = {
  __typename?: 'CsvExportVarPopFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CsvExportVarSampFields = {
  __typename?: 'CsvExportVarSampFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CsvExportVarianceFields = {
  __typename?: 'CsvExportVarianceFields';
  id?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "delivery_types" */
export type DeliveryTypes = {
  __typename?: 'DeliveryTypes';
  description: Scalars['String'];
  /** An array relationship */
  organizationPricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  organizationPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
  value: Scalars['String'];
};


/** columns and relationships of "delivery_types" */
export type DeliveryTypesOrganizationPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "delivery_types" */
export type DeliveryTypesOrganizationPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** aggregated selection of "delivery_types" */
export type DeliveryTypesAggregate = {
  __typename?: 'DeliveryTypesAggregate';
  aggregate?: Maybe<DeliveryTypesAggregateFields>;
  nodes: Array<DeliveryTypes>;
};

/** aggregate fields of "delivery_types" */
export type DeliveryTypesAggregateFields = {
  __typename?: 'DeliveryTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DeliveryTypesMaxFields>;
  min?: Maybe<DeliveryTypesMinFields>;
};


/** aggregate fields of "delivery_types" */
export type DeliveryTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "delivery_types". All fields are combined with a logical 'AND'. */
export type DeliveryTypesBoolExp = {
  _and?: InputMaybe<Array<DeliveryTypesBoolExp>>;
  _not?: InputMaybe<DeliveryTypesBoolExp>;
  _or?: InputMaybe<Array<DeliveryTypesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
  value?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "delivery_types" */
export enum DeliveryTypesConstraint {
  /** unique or primary key constraint on columns "value" */
  PricingStrategyTypesPkey = 'pricing_strategy_types_pkey'
}

export enum DeliveryTypesEnum {
  /** Express delivery */
  Express = 'EXPRESS',
  /** Next day delivery */
  NextDay = 'NEXT_DAY',
  /** Same day delivery */
  SameDay = 'SAME_DAY'
}

/** input type for inserting data into table "delivery_types" */
export type DeliveryTypesInsertInput = {
  description?: InputMaybe<Scalars['String']>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DeliveryTypesMaxFields = {
  __typename?: 'DeliveryTypesMaxFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DeliveryTypesMinFields = {
  __typename?: 'DeliveryTypesMinFields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "delivery_types" */
export type DeliveryTypesMutationResponse = {
  __typename?: 'DeliveryTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<DeliveryTypes>;
};

/** input type for inserting object relation for remote table "delivery_types" */
export type DeliveryTypesObjRelInsertInput = {
  data: DeliveryTypesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DeliveryTypesOnConflict>;
};

/** on_conflict condition type for table "delivery_types" */
export type DeliveryTypesOnConflict = {
  constraint: DeliveryTypesConstraint;
  update_columns?: Array<DeliveryTypesUpdateColumn>;
  where?: InputMaybe<DeliveryTypesBoolExp>;
};

/** Ordering options when selecting data from "delivery_types". */
export type DeliveryTypesOrderBy = {
  description?: InputMaybe<OrderBy>;
  organizationPricingPolicies_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: delivery_types */
export type DeliveryTypesPkColumnsInput = {
  value: Scalars['String'];
};

/** select columns of table "delivery_types" */
export enum DeliveryTypesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "delivery_types" */
export type DeliveryTypesSetInput = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "delivery_types" */
export enum DeliveryTypesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "DeliveryRate" */
export type Deliveryrate = {
  __typename?: 'Deliveryrate';
  createdAt: Scalars['timestamp'];
  distanceFee: Scalars['float8'];
  from?: Maybe<Scalars['timestamp']>;
  id: Scalars['String'];
  oneTimefee: Scalars['float8'];
  to?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  trips: Array<Trip>;
  /** An aggregate relationship */
  tripsAggregate: TripAggregate;
  type: Scalars['TaskType'];
};


/** columns and relationships of "DeliveryRate" */
export type DeliveryrateTripsArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


/** columns and relationships of "DeliveryRate" */
export type DeliveryrateTripsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};

/** aggregated selection of "DeliveryRate" */
export type DeliveryrateAggregate = {
  __typename?: 'DeliveryrateAggregate';
  aggregate?: Maybe<DeliveryrateAggregateFields>;
  nodes: Array<Deliveryrate>;
};

/** aggregate fields of "DeliveryRate" */
export type DeliveryrateAggregateFields = {
  __typename?: 'DeliveryrateAggregateFields';
  avg?: Maybe<DeliveryrateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DeliveryrateMaxFields>;
  min?: Maybe<DeliveryrateMinFields>;
  stddev?: Maybe<DeliveryrateStddevFields>;
  stddevPop?: Maybe<DeliveryrateStddevPopFields>;
  stddevSamp?: Maybe<DeliveryrateStddevSampFields>;
  sum?: Maybe<DeliveryrateSumFields>;
  varPop?: Maybe<DeliveryrateVarPopFields>;
  varSamp?: Maybe<DeliveryrateVarSampFields>;
  variance?: Maybe<DeliveryrateVarianceFields>;
};


/** aggregate fields of "DeliveryRate" */
export type DeliveryrateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DeliveryrateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DeliveryrateAvgFields = {
  __typename?: 'DeliveryrateAvgFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "DeliveryRate". All fields are combined with a logical 'AND'. */
export type DeliveryrateBoolExp = {
  _and?: InputMaybe<Array<DeliveryrateBoolExp>>;
  _not?: InputMaybe<DeliveryrateBoolExp>;
  _or?: InputMaybe<Array<DeliveryrateBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  distanceFee?: InputMaybe<Float8ComparisonExp>;
  from?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  oneTimefee?: InputMaybe<Float8ComparisonExp>;
  to?: InputMaybe<TimestampComparisonExp>;
  trips?: InputMaybe<TripBoolExp>;
  type?: InputMaybe<TasktypeComparisonExp>;
};

/** unique or primary key constraints on table "DeliveryRate" */
export enum DeliveryrateConstraint {
  /** unique or primary key constraint on columns "id" */
  DeliveryRatePkey = 'DeliveryRate_pkey'
}

/** input type for incrementing numeric columns in table "DeliveryRate" */
export type DeliveryrateIncInput = {
  distanceFee?: InputMaybe<Scalars['float8']>;
  oneTimefee?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "DeliveryRate" */
export type DeliveryrateInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  distanceFee?: InputMaybe<Scalars['float8']>;
  from?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  oneTimefee?: InputMaybe<Scalars['float8']>;
  to?: InputMaybe<Scalars['timestamp']>;
  trips?: InputMaybe<TripArrRelInsertInput>;
  type?: InputMaybe<Scalars['TaskType']>;
};

/** aggregate max on columns */
export type DeliveryrateMaxFields = {
  __typename?: 'DeliveryrateMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  distanceFee?: Maybe<Scalars['float8']>;
  from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  oneTimefee?: Maybe<Scalars['float8']>;
  to?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['TaskType']>;
};

/** aggregate min on columns */
export type DeliveryrateMinFields = {
  __typename?: 'DeliveryrateMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  distanceFee?: Maybe<Scalars['float8']>;
  from?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  oneTimefee?: Maybe<Scalars['float8']>;
  to?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['TaskType']>;
};

/** response of any mutation on the table "DeliveryRate" */
export type DeliveryrateMutationResponse = {
  __typename?: 'DeliveryrateMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deliveryrate>;
};

/** input type for inserting object relation for remote table "DeliveryRate" */
export type DeliveryrateObjRelInsertInput = {
  data: DeliveryrateInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<DeliveryrateOnConflict>;
};

/** on_conflict condition type for table "DeliveryRate" */
export type DeliveryrateOnConflict = {
  constraint: DeliveryrateConstraint;
  update_columns?: Array<DeliveryrateUpdateColumn>;
  where?: InputMaybe<DeliveryrateBoolExp>;
};

/** Ordering options when selecting data from "DeliveryRate". */
export type DeliveryrateOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  distanceFee?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  oneTimefee?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
  trips_aggregate?: InputMaybe<TripAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: deliveryRate */
export type DeliveryratePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "DeliveryRate" */
export enum DeliveryrateSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  OneTimefee = 'oneTimefee',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "DeliveryRate" */
export type DeliveryrateSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  distanceFee?: InputMaybe<Scalars['float8']>;
  from?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  oneTimefee?: InputMaybe<Scalars['float8']>;
  to?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['TaskType']>;
};

/** aggregate stddev on columns */
export type DeliveryrateStddevFields = {
  __typename?: 'DeliveryrateStddevFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type DeliveryrateStddevPopFields = {
  __typename?: 'DeliveryrateStddevPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type DeliveryrateStddevSampFields = {
  __typename?: 'DeliveryrateStddevSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type DeliveryrateSumFields = {
  __typename?: 'DeliveryrateSumFields';
  distanceFee?: Maybe<Scalars['float8']>;
  oneTimefee?: Maybe<Scalars['float8']>;
};

/** update columns of table "DeliveryRate" */
export enum DeliveryrateUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  OneTimefee = 'oneTimefee',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type DeliveryrateVarPopFields = {
  __typename?: 'DeliveryrateVarPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type DeliveryrateVarSampFields = {
  __typename?: 'DeliveryrateVarSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DeliveryrateVarianceFields = {
  __typename?: 'DeliveryrateVarianceFields';
  distanceFee?: Maybe<Scalars['Float']>;
  oneTimefee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "DeliveryTypesEnum". All fields are combined with logical 'AND'. */
export type DeliverytypesenumComparisonExp = {
  _eq?: InputMaybe<DeliveryTypesEnum>;
  _in?: InputMaybe<Array<DeliveryTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<DeliveryTypesEnum>;
  _nin?: InputMaybe<Array<DeliveryTypesEnum>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _cast?: InputMaybe<Float8_Cast_Exp>;
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "GlobalSettings" */
export type Globalsettings = {
  __typename?: 'Globalsettings';
  createdAt: Scalars['timestamp'];
  key: Scalars['String'];
  updateAt: Scalars['timestamp'];
  value: Scalars['jsonb'];
};


/** columns and relationships of "GlobalSettings" */
export type GlobalsettingsValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "GlobalSettings" */
export type GlobalsettingsAggregate = {
  __typename?: 'GlobalsettingsAggregate';
  aggregate?: Maybe<GlobalsettingsAggregateFields>;
  nodes: Array<Globalsettings>;
};

/** aggregate fields of "GlobalSettings" */
export type GlobalsettingsAggregateFields = {
  __typename?: 'GlobalsettingsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<GlobalsettingsMaxFields>;
  min?: Maybe<GlobalsettingsMinFields>;
};


/** aggregate fields of "GlobalSettings" */
export type GlobalsettingsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GlobalsettingsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GlobalsettingsAppendInput = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "GlobalSettings". All fields are combined with a logical 'AND'. */
export type GlobalsettingsBoolExp = {
  _and?: InputMaybe<Array<GlobalsettingsBoolExp>>;
  _not?: InputMaybe<GlobalsettingsBoolExp>;
  _or?: InputMaybe<Array<GlobalsettingsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  key?: InputMaybe<StringComparisonExp>;
  updateAt?: InputMaybe<TimestampComparisonExp>;
  value?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "GlobalSettings" */
export enum GlobalsettingsConstraint {
  /** unique or primary key constraint on columns "key" */
  GlobalSettingsPkey = 'GlobalSettings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GlobalsettingsDeleteAtPathInput = {
  value?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GlobalsettingsDeleteElemInput = {
  value?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GlobalsettingsDeleteKeyInput = {
  value?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "GlobalSettings" */
export type GlobalsettingsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type GlobalsettingsMaxFields = {
  __typename?: 'GlobalsettingsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type GlobalsettingsMinFields = {
  __typename?: 'GlobalsettingsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  key?: Maybe<Scalars['String']>;
  updateAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "GlobalSettings" */
export type GlobalsettingsMutationResponse = {
  __typename?: 'GlobalsettingsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Globalsettings>;
};

/** on_conflict condition type for table "GlobalSettings" */
export type GlobalsettingsOnConflict = {
  constraint: GlobalsettingsConstraint;
  update_columns?: Array<GlobalsettingsUpdateColumn>;
  where?: InputMaybe<GlobalsettingsBoolExp>;
};

/** Ordering options when selecting data from "GlobalSettings". */
export type GlobalsettingsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  key?: InputMaybe<OrderBy>;
  updateAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: GlobalSettings */
export type GlobalsettingsPkColumnsInput = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GlobalsettingsPrependInput = {
  value?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "GlobalSettings" */
export enum GlobalsettingsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdateAt = 'updateAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "GlobalSettings" */
export type GlobalsettingsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  key?: InputMaybe<Scalars['String']>;
  updateAt?: InputMaybe<Scalars['timestamp']>;
  value?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "GlobalSettings" */
export enum GlobalsettingsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdateAt = 'updateAt',
  /** column name */
  Value = 'value'
}

export type HealthOutput = {
  __typename?: 'HealthOutput';
  ok: Scalars['Boolean'];
};

/** columns and relationships of "insurance_policies" */
export type InsurancePolicies = {
  __typename?: 'InsurancePolicies';
  id: Scalars['uuid'];
  insuranceAmount: Scalars['numeric'];
  insuranceFee: Scalars['numeric'];
  name: Scalars['String'];
  /** An array relationship */
  pricingPolicies: Array<PricingInsuranceBridge>;
  /** An aggregate relationship */
  pricingPoliciesAggregate: PricingInsuranceBridgeAggregate;
};


/** columns and relationships of "insurance_policies" */
export type InsurancePoliciesPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


/** columns and relationships of "insurance_policies" */
export type InsurancePoliciesPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};

/** aggregated selection of "insurance_policies" */
export type InsurancePoliciesAggregate = {
  __typename?: 'InsurancePoliciesAggregate';
  aggregate?: Maybe<InsurancePoliciesAggregateFields>;
  nodes: Array<InsurancePolicies>;
};

/** aggregate fields of "insurance_policies" */
export type InsurancePoliciesAggregateFields = {
  __typename?: 'InsurancePoliciesAggregateFields';
  avg?: Maybe<InsurancePoliciesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<InsurancePoliciesMaxFields>;
  min?: Maybe<InsurancePoliciesMinFields>;
  stddev?: Maybe<InsurancePoliciesStddevFields>;
  stddevPop?: Maybe<InsurancePoliciesStddevPopFields>;
  stddevSamp?: Maybe<InsurancePoliciesStddevSampFields>;
  sum?: Maybe<InsurancePoliciesSumFields>;
  varPop?: Maybe<InsurancePoliciesVarPopFields>;
  varSamp?: Maybe<InsurancePoliciesVarSampFields>;
  variance?: Maybe<InsurancePoliciesVarianceFields>;
};


/** aggregate fields of "insurance_policies" */
export type InsurancePoliciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_policies" */
export type InsurancePoliciesAggregateOrderBy = {
  avg?: InputMaybe<InsurancePoliciesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InsurancePoliciesMaxOrderBy>;
  min?: InputMaybe<InsurancePoliciesMinOrderBy>;
  stddev?: InputMaybe<InsurancePoliciesStddevOrderBy>;
  stddev_pop?: InputMaybe<InsurancePoliciesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InsurancePoliciesStddevSampOrderBy>;
  sum?: InputMaybe<InsurancePoliciesSumOrderBy>;
  var_pop?: InputMaybe<InsurancePoliciesVarPopOrderBy>;
  var_samp?: InputMaybe<InsurancePoliciesVarSampOrderBy>;
  variance?: InputMaybe<InsurancePoliciesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "insurance_policies" */
export type InsurancePoliciesArrRelInsertInput = {
  data: Array<InsurancePoliciesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InsurancePoliciesOnConflict>;
};

/** aggregate avg on columns */
export type InsurancePoliciesAvgFields = {
  __typename?: 'InsurancePoliciesAvgFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insurance_policies" */
export type InsurancePoliciesAvgOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "insurance_policies". All fields are combined with a logical 'AND'. */
export type InsurancePoliciesBoolExp = {
  _and?: InputMaybe<Array<InsurancePoliciesBoolExp>>;
  _not?: InputMaybe<InsurancePoliciesBoolExp>;
  _or?: InputMaybe<Array<InsurancePoliciesBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  insuranceAmount?: InputMaybe<NumericComparisonExp>;
  insuranceFee?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pricingPolicies?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};

/** unique or primary key constraints on table "insurance_policies" */
export enum InsurancePoliciesConstraint {
  /** unique or primary key constraint on columns "id" */
  InsurancePoliciesPkey = 'insurance_policies_pkey'
}

/** input type for incrementing numeric columns in table "insurance_policies" */
export type InsurancePoliciesIncInput = {
  insuranceAmount?: InputMaybe<Scalars['numeric']>;
  insuranceFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "insurance_policies" */
export type InsurancePoliciesInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  insuranceAmount?: InputMaybe<Scalars['numeric']>;
  insuranceFee?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  pricingPolicies?: InputMaybe<PricingInsuranceBridgeArrRelInsertInput>;
};

/** aggregate max on columns */
export type InsurancePoliciesMaxFields = {
  __typename?: 'InsurancePoliciesMaxFields';
  id?: Maybe<Scalars['uuid']>;
  insuranceAmount?: Maybe<Scalars['numeric']>;
  insuranceFee?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "insurance_policies" */
export type InsurancePoliciesMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InsurancePoliciesMinFields = {
  __typename?: 'InsurancePoliciesMinFields';
  id?: Maybe<Scalars['uuid']>;
  insuranceAmount?: Maybe<Scalars['numeric']>;
  insuranceFee?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "insurance_policies" */
export type InsurancePoliciesMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "insurance_policies" */
export type InsurancePoliciesMutationResponse = {
  __typename?: 'InsurancePoliciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<InsurancePolicies>;
};

/** on_conflict condition type for table "insurance_policies" */
export type InsurancePoliciesOnConflict = {
  constraint: InsurancePoliciesConstraint;
  update_columns?: Array<InsurancePoliciesUpdateColumn>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};

/** Ordering options when selecting data from "insurance_policies". */
export type InsurancePoliciesOrderBy = {
  id?: InputMaybe<OrderBy>;
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pricingPolicies_aggregate?: InputMaybe<PricingInsuranceBridgeAggregateOrderBy>;
};

/** primary key columns input for table: insurance_policies */
export type InsurancePoliciesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "insurance_policies" */
export enum InsurancePoliciesSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceAmount = 'insuranceAmount',
  /** column name */
  InsuranceFee = 'insuranceFee',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "insurance_policies" */
export type InsurancePoliciesSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  insuranceAmount?: InputMaybe<Scalars['numeric']>;
  insuranceFee?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type InsurancePoliciesStddevFields = {
  __typename?: 'InsurancePoliciesStddevFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insurance_policies" */
export type InsurancePoliciesStddevOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type InsurancePoliciesStddevPopFields = {
  __typename?: 'InsurancePoliciesStddevPopFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insurance_policies" */
export type InsurancePoliciesStddevPopOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type InsurancePoliciesStddevSampFields = {
  __typename?: 'InsurancePoliciesStddevSampFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insurance_policies" */
export type InsurancePoliciesStddevSampOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type InsurancePoliciesSumFields = {
  __typename?: 'InsurancePoliciesSumFields';
  insuranceAmount?: Maybe<Scalars['numeric']>;
  insuranceFee?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "insurance_policies" */
export type InsurancePoliciesSumOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** update columns of table "insurance_policies" */
export enum InsurancePoliciesUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceAmount = 'insuranceAmount',
  /** column name */
  InsuranceFee = 'insuranceFee',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type InsurancePoliciesVarPopFields = {
  __typename?: 'InsurancePoliciesVarPopFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insurance_policies" */
export type InsurancePoliciesVarPopOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type InsurancePoliciesVarSampFields = {
  __typename?: 'InsurancePoliciesVarSampFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insurance_policies" */
export type InsurancePoliciesVarSampOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InsurancePoliciesVarianceFields = {
  __typename?: 'InsurancePoliciesVarianceFields';
  insuranceAmount?: Maybe<Scalars['Float']>;
  insuranceFee?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insurance_policies" */
export type InsurancePoliciesVarianceOrderBy = {
  insuranceAmount?: InputMaybe<OrderBy>;
  insuranceFee?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _cast?: InputMaybe<Int_Cast_Exp>;
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Int_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "Invite" */
export type Invite = {
  __typename?: 'Invite';
  email: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  role: Scalars['String'];
};

/** aggregated selection of "Invite" */
export type InviteAggregate = {
  __typename?: 'InviteAggregate';
  aggregate?: Maybe<InviteAggregateFields>;
  nodes: Array<Invite>;
};

/** aggregate fields of "Invite" */
export type InviteAggregateFields = {
  __typename?: 'InviteAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<InviteMaxFields>;
  min?: Maybe<InviteMinFields>;
};


/** aggregate fields of "Invite" */
export type InviteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Invite" */
export type InviteAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InviteMaxOrderBy>;
  min?: InputMaybe<InviteMinOrderBy>;
};

/** input type for inserting array relation for remote table "Invite" */
export type InviteArrRelInsertInput = {
  data: Array<InviteInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<InviteOnConflict>;
};

/** Boolean expression to filter rows from the table "Invite". All fields are combined with a logical 'AND'. */
export type InviteBoolExp = {
  _and?: InputMaybe<Array<InviteBoolExp>>;
  _not?: InputMaybe<InviteBoolExp>;
  _or?: InputMaybe<Array<InviteBoolExp>>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "Invite" */
export enum InviteConstraint {
  /** unique or primary key constraint on columns "id" */
  InvitePkey = 'Invite_pkey'
}

/** input type for inserting data into table "Invite" */
export type InviteInsertInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type InviteMaxFields = {
  __typename?: 'InviteMaxFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Invite" */
export type InviteMaxOrderBy = {
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InviteMinFields = {
  __typename?: 'InviteMinFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Invite" */
export type InviteMinOrderBy = {
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Invite" */
export type InviteMutationResponse = {
  __typename?: 'InviteMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invite>;
};

/** on_conflict condition type for table "Invite" */
export type InviteOnConflict = {
  constraint: InviteConstraint;
  update_columns?: Array<InviteUpdateColumn>;
  where?: InputMaybe<InviteBoolExp>;
};

/** Ordering options when selecting data from "Invite". */
export type InviteOrderBy = {
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: invite */
export type InvitePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Invite" */
export enum InviteSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "Invite" */
export type InviteSetInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Invite" */
export enum InviteUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role'
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Boolean expression to compare columns of type "Language". All fields are combined with logical 'AND'. */
export type LanguageComparisonExp = {
  _eq?: InputMaybe<Scalars['Language']>;
  _gt?: InputMaybe<Scalars['Language']>;
  _gte?: InputMaybe<Scalars['Language']>;
  _in?: InputMaybe<Array<Scalars['Language']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Language']>;
  _lte?: InputMaybe<Scalars['Language']>;
  _neq?: InputMaybe<Scalars['Language']>;
  _nin?: InputMaybe<Array<Scalars['Language']>>;
};

/** columns and relationships of "live_map_lock" */
export type LiveMapLock = {
  __typename?: 'LiveMapLock';
  createdAt: Scalars['timestamptz'];
  region: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
  windowId: Scalars['String'];
};

/** aggregated selection of "live_map_lock" */
export type LiveMapLockAggregate = {
  __typename?: 'LiveMapLockAggregate';
  aggregate?: Maybe<LiveMapLockAggregateFields>;
  nodes: Array<LiveMapLock>;
};

/** aggregate fields of "live_map_lock" */
export type LiveMapLockAggregateFields = {
  __typename?: 'LiveMapLockAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<LiveMapLockMaxFields>;
  min?: Maybe<LiveMapLockMinFields>;
};


/** aggregate fields of "live_map_lock" */
export type LiveMapLockAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LiveMapLockSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "live_map_lock". All fields are combined with a logical 'AND'. */
export type LiveMapLockBoolExp = {
  _and?: InputMaybe<Array<LiveMapLockBoolExp>>;
  _not?: InputMaybe<LiveMapLockBoolExp>;
  _or?: InputMaybe<Array<LiveMapLockBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  region?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  windowId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "live_map_lock" */
export enum LiveMapLockConstraint {
  /** unique or primary key constraint on columns "region" */
  LiveMapLockPkey = 'live_map_lock_pkey'
}

/** input type for inserting data into table "live_map_lock" */
export type LiveMapLockInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
  windowId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LiveMapLockMaxFields = {
  __typename?: 'LiveMapLockMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  windowId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LiveMapLockMinFields = {
  __typename?: 'LiveMapLockMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['String']>;
  windowId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "live_map_lock" */
export type LiveMapLockMutationResponse = {
  __typename?: 'LiveMapLockMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LiveMapLock>;
};

/** on_conflict condition type for table "live_map_lock" */
export type LiveMapLockOnConflict = {
  constraint: LiveMapLockConstraint;
  update_columns?: Array<LiveMapLockUpdateColumn>;
  where?: InputMaybe<LiveMapLockBoolExp>;
};

/** Ordering options when selecting data from "live_map_lock". */
export type LiveMapLockOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
  windowId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: live_map_lock */
export type LiveMapLockPkColumnsInput = {
  region: Scalars['String'];
};

/** select columns of table "live_map_lock" */
export enum LiveMapLockSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WindowId = 'windowId'
}

/** input type for updating data in table "live_map_lock" */
export type LiveMapLockSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['String']>;
  windowId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "live_map_lock" */
export enum LiveMapLockUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WindowId = 'windowId'
}

/** columns and relationships of "Member" */
export type Member = {
  __typename?: 'Member';
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['timestamp'];
  /** An object relationship */
  user: User;
  userId: Scalars['String'];
};

/** aggregated selection of "Member" */
export type MemberAggregate = {
  __typename?: 'MemberAggregate';
  aggregate?: Maybe<MemberAggregateFields>;
  nodes: Array<Member>;
};

/** aggregate fields of "Member" */
export type MemberAggregateFields = {
  __typename?: 'MemberAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<MemberMaxFields>;
  min?: Maybe<MemberMinFields>;
};


/** aggregate fields of "Member" */
export type MemberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MemberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Member" */
export type MemberAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MemberMaxOrderBy>;
  min?: InputMaybe<MemberMinOrderBy>;
};

/** input type for inserting array relation for remote table "Member" */
export type MemberArrRelInsertInput = {
  data: Array<MemberInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<MemberOnConflict>;
};

/** Boolean expression to filter rows from the table "Member". All fields are combined with a logical 'AND'. */
export type MemberBoolExp = {
  _and?: InputMaybe<Array<MemberBoolExp>>;
  _not?: InputMaybe<MemberBoolExp>;
  _or?: InputMaybe<Array<MemberBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  role?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "Member" */
export enum MemberConstraint {
  /** unique or primary key constraint on columns "userId", "organizationId" */
  MemberUserIdOrganizationIdKey = 'Member_userId_organizationId_key'
}

/** input type for inserting data into table "Member" */
export type MemberInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type MemberMaxFields = {
  __typename?: 'MemberMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Member" */
export type MemberMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MemberMinFields = {
  __typename?: 'MemberMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  organizationId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  userId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Member" */
export type MemberMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Member" */
export type MemberMutationResponse = {
  __typename?: 'MemberMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Member>;
};

/** on_conflict condition type for table "Member" */
export type MemberOnConflict = {
  constraint: MemberConstraint;
  update_columns?: Array<MemberUpdateColumn>;
  where?: InputMaybe<MemberBoolExp>;
};

/** Ordering options when selecting data from "Member". */
export type MemberOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "Member" */
export enum MemberSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Member" */
export type MemberSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  organizationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  userId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Member" */
export enum MemberUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "multiple_stops_policies" */
export type MultipleStopsPolicies = {
  __typename?: 'MultipleStopsPolicies';
  additionalStopPrice: Scalars['numeric'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  organizationPricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  organizationPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
};


/** columns and relationships of "multiple_stops_policies" */
export type MultipleStopsPoliciesOrganizationPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "multiple_stops_policies" */
export type MultipleStopsPoliciesOrganizationPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** aggregated selection of "multiple_stops_policies" */
export type MultipleStopsPoliciesAggregate = {
  __typename?: 'MultipleStopsPoliciesAggregate';
  aggregate?: Maybe<MultipleStopsPoliciesAggregateFields>;
  nodes: Array<MultipleStopsPolicies>;
};

/** aggregate fields of "multiple_stops_policies" */
export type MultipleStopsPoliciesAggregateFields = {
  __typename?: 'MultipleStopsPoliciesAggregateFields';
  avg?: Maybe<MultipleStopsPoliciesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MultipleStopsPoliciesMaxFields>;
  min?: Maybe<MultipleStopsPoliciesMinFields>;
  stddev?: Maybe<MultipleStopsPoliciesStddevFields>;
  stddevPop?: Maybe<MultipleStopsPoliciesStddevPopFields>;
  stddevSamp?: Maybe<MultipleStopsPoliciesStddevSampFields>;
  sum?: Maybe<MultipleStopsPoliciesSumFields>;
  varPop?: Maybe<MultipleStopsPoliciesVarPopFields>;
  varSamp?: Maybe<MultipleStopsPoliciesVarSampFields>;
  variance?: Maybe<MultipleStopsPoliciesVarianceFields>;
};


/** aggregate fields of "multiple_stops_policies" */
export type MultipleStopsPoliciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MultipleStopsPoliciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type MultipleStopsPoliciesAvgFields = {
  __typename?: 'MultipleStopsPoliciesAvgFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "multiple_stops_policies". All fields are combined with a logical 'AND'. */
export type MultipleStopsPoliciesBoolExp = {
  _and?: InputMaybe<Array<MultipleStopsPoliciesBoolExp>>;
  _not?: InputMaybe<MultipleStopsPoliciesBoolExp>;
  _or?: InputMaybe<Array<MultipleStopsPoliciesBoolExp>>;
  additionalStopPrice?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** unique or primary key constraints on table "multiple_stops_policies" */
export enum MultipleStopsPoliciesConstraint {
  /** unique or primary key constraint on columns "id" */
  MultipleStepsPoliciesPkey = 'multiple_steps_policies_pkey'
}

/** input type for incrementing numeric columns in table "multiple_stops_policies" */
export type MultipleStopsPoliciesIncInput = {
  additionalStopPrice?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "multiple_stops_policies" */
export type MultipleStopsPoliciesInsertInput = {
  additionalStopPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
};

/** aggregate max on columns */
export type MultipleStopsPoliciesMaxFields = {
  __typename?: 'MultipleStopsPoliciesMaxFields';
  additionalStopPrice?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type MultipleStopsPoliciesMinFields = {
  __typename?: 'MultipleStopsPoliciesMinFields';
  additionalStopPrice?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "multiple_stops_policies" */
export type MultipleStopsPoliciesMutationResponse = {
  __typename?: 'MultipleStopsPoliciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<MultipleStopsPolicies>;
};

/** input type for inserting object relation for remote table "multiple_stops_policies" */
export type MultipleStopsPoliciesObjRelInsertInput = {
  data: MultipleStopsPoliciesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<MultipleStopsPoliciesOnConflict>;
};

/** on_conflict condition type for table "multiple_stops_policies" */
export type MultipleStopsPoliciesOnConflict = {
  constraint: MultipleStopsPoliciesConstraint;
  update_columns?: Array<MultipleStopsPoliciesUpdateColumn>;
  where?: InputMaybe<MultipleStopsPoliciesBoolExp>;
};

/** Ordering options when selecting data from "multiple_stops_policies". */
export type MultipleStopsPoliciesOrderBy = {
  additionalStopPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationPricingPolicies_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
};

/** primary key columns input for table: multiple_stops_policies */
export type MultipleStopsPoliciesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "multiple_stops_policies" */
export enum MultipleStopsPoliciesSelectColumn {
  /** column name */
  AdditionalStopPrice = 'additionalStopPrice',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "multiple_stops_policies" */
export type MultipleStopsPoliciesSetInput = {
  additionalStopPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type MultipleStopsPoliciesStddevFields = {
  __typename?: 'MultipleStopsPoliciesStddevFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type MultipleStopsPoliciesStddevPopFields = {
  __typename?: 'MultipleStopsPoliciesStddevPopFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type MultipleStopsPoliciesStddevSampFields = {
  __typename?: 'MultipleStopsPoliciesStddevSampFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type MultipleStopsPoliciesSumFields = {
  __typename?: 'MultipleStopsPoliciesSumFields';
  additionalStopPrice?: Maybe<Scalars['numeric']>;
};

/** update columns of table "multiple_stops_policies" */
export enum MultipleStopsPoliciesUpdateColumn {
  /** column name */
  AdditionalStopPrice = 'additionalStopPrice',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type MultipleStopsPoliciesVarPopFields = {
  __typename?: 'MultipleStopsPoliciesVarPopFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type MultipleStopsPoliciesVarSampFields = {
  __typename?: 'MultipleStopsPoliciesVarSampFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type MultipleStopsPoliciesVarianceFields = {
  __typename?: 'MultipleStopsPoliciesVarianceFields';
  additionalStopPrice?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  completeTask: Scalars['Boolean'];
  failTask: Scalars['Boolean'];
  finishBreak: Scalars['Boolean'];
  setPresance: Scalars['Boolean'];
  startTask: Scalars['Boolean'];
  updateLocation: Scalars['Boolean'];
};


export type MutationCompleteTaskArgs = {
  taskId: Scalars['Int'];
};


export type MutationFailTaskArgs = {
  notes?: InputMaybe<Scalars['String']>;
  reason: COrderFailureReason;
  taskId: Scalars['Int'];
};


export type MutationSetPresanceArgs = {
  isOnline: Scalars['Boolean'];
};


export type MutationStartTaskArgs = {
  taskId: Scalars['Int'];
};


export type MutationUpdateLocationArgs = {
  location: CoordinateInput;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _cast?: InputMaybe<Numeric_Cast_Exp>;
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "Order" */
export type Order = {
  __typename?: 'Order';
  /** An array relationship */
  OrderEvents: Array<Orderevents>;
  ageLimitation: Scalars['AgeLimitation'];
  /** indicates if order was created using asap feature */
  asap?: Maybe<Scalars['Boolean']>;
  barcode?: Maybe<Scalars['String']>;
  completeBefore?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  courier?: Maybe<Courier>;
  courierId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  courierInfo?: Maybe<CourierInfo>;
  /** An object relationship */
  courierPayout?: Maybe<OrderDeliveryPrice>;
  createdAt: Scalars['timestamptz'];
  failureNote?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['OrderFailureReason']>;
  id: Scalars['String'];
  /** An object relationship */
  info?: Maybe<OrderInfo>;
  isLate?: Maybe<Scalars['Boolean']>;
  /** An aggregate relationship */
  orderEventsAggregate: OrdereventsAggregate;
  orderId: Scalars['String'];
  /** An array relationship */
  orderStatusEvents: Array<OrderStatusEvents>;
  /** An aggregate relationship */
  orderStatusEventsAggregate: OrderStatusEventsAggregate;
  /** An object relationship */
  orderTracking?: Maybe<Ordertracking>;
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  /** An object relationship */
  price?: Maybe<OrderPrice>;
  region?: Maybe<Scalars['String']>;
  shortOrderId?: Maybe<Scalars['String']>;
  startAfter?: Maybe<Scalars['timestamptz']>;
  status: Scalars['OrderStatus'];
  /** An object relationship */
  stickerPdf?: Maybe<Pdf>;
  stickerPdfId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tasks: Array<Task>;
  /** An aggregate relationship */
  tasksAggregate: TaskAggregate;
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "Order" */
export type OrderOrderEventsArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


/** columns and relationships of "Order" */
export type OrderOrderEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


/** columns and relationships of "Order" */
export type OrderOrderStatusEventsArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


/** columns and relationships of "Order" */
export type OrderOrderStatusEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


/** columns and relationships of "Order" */
export type OrderTasksArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


/** columns and relationships of "Order" */
export type OrderTasksAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};

/** aggregated selection of "Order" */
export type OrderAggregate = {
  __typename?: 'OrderAggregate';
  aggregate?: Maybe<OrderAggregateFields>;
  nodes: Array<Order>;
};

/** aggregate fields of "Order" */
export type OrderAggregateFields = {
  __typename?: 'OrderAggregateFields';
  avg?: Maybe<OrderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderMaxFields>;
  min?: Maybe<OrderMinFields>;
  stddev?: Maybe<OrderStddevFields>;
  stddevPop?: Maybe<OrderStddevPopFields>;
  stddevSamp?: Maybe<OrderStddevSampFields>;
  sum?: Maybe<OrderSumFields>;
  varPop?: Maybe<OrderVarPopFields>;
  varSamp?: Maybe<OrderVarSampFields>;
  variance?: Maybe<OrderVarianceFields>;
};


/** aggregate fields of "Order" */
export type OrderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Order" */
export type OrderAggregateOrderBy = {
  avg?: InputMaybe<OrderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderMaxOrderBy>;
  min?: InputMaybe<OrderMinOrderBy>;
  stddev?: InputMaybe<OrderStddevOrderBy>;
  stddev_pop?: InputMaybe<OrderStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrderStddevSampOrderBy>;
  sum?: InputMaybe<OrderSumOrderBy>;
  var_pop?: InputMaybe<OrderVarPopOrderBy>;
  var_samp?: InputMaybe<OrderVarSampOrderBy>;
  variance?: InputMaybe<OrderVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "Order" */
export type OrderArrRelInsertInput = {
  data: Array<OrderInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOnConflict>;
};

/** aggregate avg on columns */
export type OrderAvgFields = {
  __typename?: 'OrderAvgFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Order" */
export type OrderAvgOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Order". All fields are combined with a logical 'AND'. */
export type OrderBoolExp = {
  OrderEvents?: InputMaybe<OrdereventsBoolExp>;
  _and?: InputMaybe<Array<OrderBoolExp>>;
  _not?: InputMaybe<OrderBoolExp>;
  _or?: InputMaybe<Array<OrderBoolExp>>;
  ageLimitation?: InputMaybe<AgelimitationComparisonExp>;
  asap?: InputMaybe<BooleanComparisonExp>;
  barcode?: InputMaybe<StringComparisonExp>;
  completeBefore?: InputMaybe<TimestampComparisonExp>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  courierInfo?: InputMaybe<CourierInfoBoolExp>;
  courierPayout?: InputMaybe<OrderDeliveryPriceBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  failureNote?: InputMaybe<StringComparisonExp>;
  failureReason?: InputMaybe<OrderfailurereasonComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  info?: InputMaybe<OrderInfoBoolExp>;
  isLate?: InputMaybe<BooleanComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  orderStatusEvents?: InputMaybe<OrderStatusEventsBoolExp>;
  orderTracking?: InputMaybe<OrdertrackingBoolExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<OrderPriceBoolExp>;
  region?: InputMaybe<StringComparisonExp>;
  shortOrderId?: InputMaybe<StringComparisonExp>;
  startAfter?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<OrderstatusComparisonExp>;
  stickerPdf?: InputMaybe<PdfBoolExp>;
  stickerPdfId?: InputMaybe<UuidComparisonExp>;
  tasks?: InputMaybe<TaskBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "Order" */
export enum OrderConstraint {
  /** unique or primary key constraint on columns "barcode" */
  OrderBarcodeKey = 'Order_barcode_key',
  /** unique or primary key constraint on columns "orderId" */
  OrderOrderIdKey = 'Order_orderId_key',
  /** unique or primary key constraint on columns "id" */
  OrderPkey = 'Order_pkey'
}

/** columns and relationships of "order_delivery_price" */
export type OrderDeliveryPrice = {
  __typename?: 'OrderDeliveryPrice';
  /** An object relationship */
  courier?: Maybe<Courier>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  /** An object relationship */
  order?: Maybe<Order>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "order_delivery_price" */
export type OrderDeliveryPriceAggregate = {
  __typename?: 'OrderDeliveryPriceAggregate';
  aggregate?: Maybe<OrderDeliveryPriceAggregateFields>;
  nodes: Array<OrderDeliveryPrice>;
};

/** aggregate fields of "order_delivery_price" */
export type OrderDeliveryPriceAggregateFields = {
  __typename?: 'OrderDeliveryPriceAggregateFields';
  avg?: Maybe<OrderDeliveryPriceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderDeliveryPriceMaxFields>;
  min?: Maybe<OrderDeliveryPriceMinFields>;
  stddev?: Maybe<OrderDeliveryPriceStddevFields>;
  stddevPop?: Maybe<OrderDeliveryPriceStddevPopFields>;
  stddevSamp?: Maybe<OrderDeliveryPriceStddevSampFields>;
  sum?: Maybe<OrderDeliveryPriceSumFields>;
  varPop?: Maybe<OrderDeliveryPriceVarPopFields>;
  varSamp?: Maybe<OrderDeliveryPriceVarSampFields>;
  variance?: Maybe<OrderDeliveryPriceVarianceFields>;
};


/** aggregate fields of "order_delivery_price" */
export type OrderDeliveryPriceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderDeliveryPriceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type OrderDeliveryPriceAvgFields = {
  __typename?: 'OrderDeliveryPriceAvgFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_delivery_price". All fields are combined with a logical 'AND'. */
export type OrderDeliveryPriceBoolExp = {
  _and?: InputMaybe<Array<OrderDeliveryPriceBoolExp>>;
  _not?: InputMaybe<OrderDeliveryPriceBoolExp>;
  _or?: InputMaybe<Array<OrderDeliveryPriceBoolExp>>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  sum?: InputMaybe<NumericComparisonExp>;
};

/** input type for inserting data into table "order_delivery_price" */
export type OrderDeliveryPriceInsertInput = {
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  sum?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type OrderDeliveryPriceMaxFields = {
  __typename?: 'OrderDeliveryPriceMaxFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type OrderDeliveryPriceMinFields = {
  __typename?: 'OrderDeliveryPriceMinFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** input type for inserting object relation for remote table "order_delivery_price" */
export type OrderDeliveryPriceObjRelInsertInput = {
  data: OrderDeliveryPriceInsertInput;
};

/** Ordering options when selecting data from "order_delivery_price". */
export type OrderDeliveryPriceOrderBy = {
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  sum?: InputMaybe<OrderBy>;
};

/** select columns of table "order_delivery_price" */
export enum OrderDeliveryPriceSelectColumn {
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Sum = 'sum'
}

/** aggregate stddev on columns */
export type OrderDeliveryPriceStddevFields = {
  __typename?: 'OrderDeliveryPriceStddevFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderDeliveryPriceStddevPopFields = {
  __typename?: 'OrderDeliveryPriceStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderDeliveryPriceStddevSampFields = {
  __typename?: 'OrderDeliveryPriceStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderDeliveryPriceSumFields = {
  __typename?: 'OrderDeliveryPriceSumFields';
  courierId?: Maybe<Scalars['Int']>;
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type OrderDeliveryPriceVarPopFields = {
  __typename?: 'OrderDeliveryPriceVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderDeliveryPriceVarSampFields = {
  __typename?: 'OrderDeliveryPriceVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderDeliveryPriceVarianceFields = {
  __typename?: 'OrderDeliveryPriceVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "Order" */
export type OrderIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "order_info" */
export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** An object relationship */
  Order: Order;
  createdAt: Scalars['timestamptz'];
  dropoffNotes?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  orderId: Scalars['String'];
  pickupNotes?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_info" */
export type OrderInfoAggregate = {
  __typename?: 'OrderInfoAggregate';
  aggregate?: Maybe<OrderInfoAggregateFields>;
  nodes: Array<OrderInfo>;
};

/** aggregate fields of "order_info" */
export type OrderInfoAggregateFields = {
  __typename?: 'OrderInfoAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrderInfoMaxFields>;
  min?: Maybe<OrderInfoMinFields>;
};


/** aggregate fields of "order_info" */
export type OrderInfoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_info". All fields are combined with a logical 'AND'. */
export type OrderInfoBoolExp = {
  Order?: InputMaybe<OrderBoolExp>;
  _and?: InputMaybe<Array<OrderInfoBoolExp>>;
  _not?: InputMaybe<OrderInfoBoolExp>;
  _or?: InputMaybe<Array<OrderInfoBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  dropoffNotes?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  pickupNotes?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "order_info" */
export enum OrderInfoConstraint {
  /** unique or primary key constraint on columns "order_id" */
  OrderInfoOrderIdKey = 'order_info_order_id_key',
  /** unique or primary key constraint on columns "id" */
  OrderInfoPkey = 'order_info_pkey'
}

/** input type for inserting data into table "order_info" */
export type OrderInfoInsertInput = {
  Order?: InputMaybe<OrderObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dropoffNotes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['String']>;
  pickupNotes?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderInfoMaxFields = {
  __typename?: 'OrderInfoMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dropoffNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
  pickupNotes?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderInfoMinFields = {
  __typename?: 'OrderInfoMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dropoffNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
  pickupNotes?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_info" */
export type OrderInfoMutationResponse = {
  __typename?: 'OrderInfoMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderInfo>;
};

/** input type for inserting object relation for remote table "order_info" */
export type OrderInfoObjRelInsertInput = {
  data: OrderInfoInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderInfoOnConflict>;
};

/** on_conflict condition type for table "order_info" */
export type OrderInfoOnConflict = {
  constraint: OrderInfoConstraint;
  update_columns?: Array<OrderInfoUpdateColumn>;
  where?: InputMaybe<OrderInfoBoolExp>;
};

/** Ordering options when selecting data from "order_info". */
export type OrderInfoOrderBy = {
  Order?: InputMaybe<OrderOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  dropoffNotes?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  pickupNotes?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order_info */
export type OrderInfoPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "order_info" */
export enum OrderInfoSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DropoffNotes = 'dropoffNotes',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PickupNotes = 'pickupNotes'
}

/** input type for updating data in table "order_info" */
export type OrderInfoSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dropoffNotes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['String']>;
  pickupNotes?: InputMaybe<Scalars['String']>;
};

/** update columns of table "order_info" */
export enum OrderInfoUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DropoffNotes = 'dropoffNotes',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PickupNotes = 'pickupNotes'
}

/** input type for inserting data into table "Order" */
export type OrderInsertInput = {
  OrderEvents?: InputMaybe<OrdereventsArrRelInsertInput>;
  ageLimitation?: InputMaybe<Scalars['AgeLimitation']>;
  barcode?: InputMaybe<Scalars['String']>;
  completeBefore?: InputMaybe<Scalars['timestamp']>;
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  courierInfo?: InputMaybe<CourierInfoObjRelInsertInput>;
  courierPayout?: InputMaybe<OrderDeliveryPriceObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  failureNote?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['OrderFailureReason']>;
  id?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<OrderInfoObjRelInsertInput>;
  isLate?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['String']>;
  orderStatusEvents?: InputMaybe<OrderStatusEventsArrRelInsertInput>;
  orderTracking?: InputMaybe<OrdertrackingObjRelInsertInput>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<OrderPriceObjRelInsertInput>;
  region?: InputMaybe<Scalars['String']>;
  shortOrderId?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['OrderStatus']>;
  stickerPdf?: InputMaybe<PdfObjRelInsertInput>;
  stickerPdfId?: InputMaybe<Scalars['uuid']>;
  tasks?: InputMaybe<TaskArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type OrderMaxFields = {
  __typename?: 'OrderMaxFields';
  ageLimitation?: Maybe<Scalars['AgeLimitation']>;
  barcode?: Maybe<Scalars['String']>;
  completeBefore?: Maybe<Scalars['timestamp']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  failureNote?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['OrderFailureReason']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  shortOrderId?: Maybe<Scalars['String']>;
  startAfter?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['OrderStatus']>;
  stickerPdfId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "Order" */
export type OrderMaxOrderBy = {
  ageLimitation?: InputMaybe<OrderBy>;
  barcode?: InputMaybe<OrderBy>;
  completeBefore?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  failureNote?: InputMaybe<OrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  shortOrderId?: InputMaybe<OrderBy>;
  startAfter?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stickerPdfId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrderMinFields = {
  __typename?: 'OrderMinFields';
  ageLimitation?: Maybe<Scalars['AgeLimitation']>;
  barcode?: Maybe<Scalars['String']>;
  completeBefore?: Maybe<Scalars['timestamp']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  failureNote?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['OrderFailureReason']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  shortOrderId?: Maybe<Scalars['String']>;
  startAfter?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['OrderStatus']>;
  stickerPdfId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "Order" */
export type OrderMinOrderBy = {
  ageLimitation?: InputMaybe<OrderBy>;
  barcode?: InputMaybe<OrderBy>;
  completeBefore?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  failureNote?: InputMaybe<OrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  region?: InputMaybe<OrderBy>;
  shortOrderId?: InputMaybe<OrderBy>;
  startAfter?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stickerPdfId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Order" */
export type OrderMutationResponse = {
  __typename?: 'OrderMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Order>;
};

/** input type for inserting object relation for remote table "Order" */
export type OrderObjRelInsertInput = {
  data: OrderInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderOnConflict>;
};

/** on_conflict condition type for table "Order" */
export type OrderOnConflict = {
  constraint: OrderConstraint;
  update_columns?: Array<OrderUpdateColumn>;
  where?: InputMaybe<OrderBoolExp>;
};

/** Ordering options when selecting data from "Order". */
export type OrderOrderBy = {
  OrderEvents_aggregate?: InputMaybe<OrdereventsAggregateOrderBy>;
  ageLimitation?: InputMaybe<OrderBy>;
  asap?: InputMaybe<OrderBy>;
  barcode?: InputMaybe<OrderBy>;
  completeBefore?: InputMaybe<OrderBy>;
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  courierInfo?: InputMaybe<CourierInfoOrderBy>;
  courierPayout?: InputMaybe<OrderDeliveryPriceOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  failureNote?: InputMaybe<OrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  info?: InputMaybe<OrderInfoOrderBy>;
  isLate?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  orderStatusEvents_aggregate?: InputMaybe<OrderStatusEventsAggregateOrderBy>;
  orderTracking?: InputMaybe<OrdertrackingOrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderPriceOrderBy>;
  region?: InputMaybe<OrderBy>;
  shortOrderId?: InputMaybe<OrderBy>;
  startAfter?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stickerPdf?: InputMaybe<PdfOrderBy>;
  stickerPdfId?: InputMaybe<OrderBy>;
  tasks_aggregate?: InputMaybe<TaskAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order */
export type OrderPkColumnsInput = {
  id: Scalars['String'];
};

/** columns and relationships of "order_price" */
export type OrderPrice = {
  __typename?: 'OrderPrice';
  additionalPolicies?: Maybe<Scalars['numeric']>;
  additionalStops?: Maybe<Scalars['numeric']>;
  calculatedBasicPrice: Scalars['numeric'];
  cancellationFee?: Maybe<Scalars['numeric']>;
  distance?: Maybe<Scalars['numeric']>;
  finalPrice: Scalars['numeric'];
  id: Scalars['uuid'];
  insurancePrice?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  order?: Maybe<Order>;
  orderId: Scalars['String'];
  pricingPolicy: Scalars['uuid'];
  recalculationHistory: Scalars['jsonb'];
  totalDeliveryPrice: Scalars['numeric'];
};


/** columns and relationships of "order_price" */
export type OrderPriceRecalculationHistoryArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "order_price" */
export type OrderPriceAggregate = {
  __typename?: 'OrderPriceAggregate';
  aggregate?: Maybe<OrderPriceAggregateFields>;
  nodes: Array<OrderPrice>;
};

/** aggregate fields of "order_price" */
export type OrderPriceAggregateFields = {
  __typename?: 'OrderPriceAggregateFields';
  avg?: Maybe<OrderPriceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderPriceMaxFields>;
  min?: Maybe<OrderPriceMinFields>;
  stddev?: Maybe<OrderPriceStddevFields>;
  stddevPop?: Maybe<OrderPriceStddevPopFields>;
  stddevSamp?: Maybe<OrderPriceStddevSampFields>;
  sum?: Maybe<OrderPriceSumFields>;
  varPop?: Maybe<OrderPriceVarPopFields>;
  varSamp?: Maybe<OrderPriceVarSampFields>;
  variance?: Maybe<OrderPriceVarianceFields>;
};


/** aggregate fields of "order_price" */
export type OrderPriceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderPriceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrderPriceAppendInput = {
  recalculationHistory?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type OrderPriceAvgFields = {
  __typename?: 'OrderPriceAvgFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "order_price". All fields are combined with a logical 'AND'. */
export type OrderPriceBoolExp = {
  _and?: InputMaybe<Array<OrderPriceBoolExp>>;
  _not?: InputMaybe<OrderPriceBoolExp>;
  _or?: InputMaybe<Array<OrderPriceBoolExp>>;
  additionalPolicies?: InputMaybe<NumericComparisonExp>;
  additionalStops?: InputMaybe<NumericComparisonExp>;
  calculatedBasicPrice?: InputMaybe<NumericComparisonExp>;
  cancellationFee?: InputMaybe<NumericComparisonExp>;
  distance?: InputMaybe<NumericComparisonExp>;
  finalPrice?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insurancePrice?: InputMaybe<NumericComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  pricingPolicy?: InputMaybe<UuidComparisonExp>;
  recalculationHistory?: InputMaybe<JsonbComparisonExp>;
  totalDeliveryPrice?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "order_price" */
export enum OrderPriceConstraint {
  /** unique or primary key constraint on columns "orderId" */
  OrderPriceOrderIdKey = 'order_price_orderId_key',
  /** unique or primary key constraint on columns "id" */
  OrderPricePkey = 'order_price_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrderPriceDeleteAtPathInput = {
  recalculationHistory?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrderPriceDeleteElemInput = {
  recalculationHistory?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrderPriceDeleteKeyInput = {
  recalculationHistory?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "order_price" */
export type OrderPriceIncInput = {
  additionalPolicies?: InputMaybe<Scalars['numeric']>;
  additionalStops?: InputMaybe<Scalars['numeric']>;
  calculatedBasicPrice?: InputMaybe<Scalars['numeric']>;
  cancellationFee?: InputMaybe<Scalars['numeric']>;
  distance?: InputMaybe<Scalars['numeric']>;
  finalPrice?: InputMaybe<Scalars['numeric']>;
  insurancePrice?: InputMaybe<Scalars['numeric']>;
  totalDeliveryPrice?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_price" */
export type OrderPriceInsertInput = {
  additionalPolicies?: InputMaybe<Scalars['numeric']>;
  additionalStops?: InputMaybe<Scalars['numeric']>;
  calculatedBasicPrice?: InputMaybe<Scalars['numeric']>;
  cancellationFee?: InputMaybe<Scalars['numeric']>;
  distance?: InputMaybe<Scalars['numeric']>;
  finalPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurancePrice?: InputMaybe<Scalars['numeric']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['String']>;
  pricingPolicy?: InputMaybe<Scalars['uuid']>;
  recalculationHistory?: InputMaybe<Scalars['jsonb']>;
  totalDeliveryPrice?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type OrderPriceMaxFields = {
  __typename?: 'OrderPriceMaxFields';
  additionalPolicies?: Maybe<Scalars['numeric']>;
  additionalStops?: Maybe<Scalars['numeric']>;
  calculatedBasicPrice?: Maybe<Scalars['numeric']>;
  cancellationFee?: Maybe<Scalars['numeric']>;
  distance?: Maybe<Scalars['numeric']>;
  finalPrice?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurancePrice?: Maybe<Scalars['numeric']>;
  orderId?: Maybe<Scalars['String']>;
  pricingPolicy?: Maybe<Scalars['uuid']>;
  totalDeliveryPrice?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type OrderPriceMinFields = {
  __typename?: 'OrderPriceMinFields';
  additionalPolicies?: Maybe<Scalars['numeric']>;
  additionalStops?: Maybe<Scalars['numeric']>;
  calculatedBasicPrice?: Maybe<Scalars['numeric']>;
  cancellationFee?: Maybe<Scalars['numeric']>;
  distance?: Maybe<Scalars['numeric']>;
  finalPrice?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  insurancePrice?: Maybe<Scalars['numeric']>;
  orderId?: Maybe<Scalars['String']>;
  pricingPolicy?: Maybe<Scalars['uuid']>;
  totalDeliveryPrice?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "order_price" */
export type OrderPriceMutationResponse = {
  __typename?: 'OrderPriceMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderPrice>;
};

/** input type for inserting object relation for remote table "order_price" */
export type OrderPriceObjRelInsertInput = {
  data: OrderPriceInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrderPriceOnConflict>;
};

/** on_conflict condition type for table "order_price" */
export type OrderPriceOnConflict = {
  constraint: OrderPriceConstraint;
  update_columns?: Array<OrderPriceUpdateColumn>;
  where?: InputMaybe<OrderPriceBoolExp>;
};

/** Ordering options when selecting data from "order_price". */
export type OrderPriceOrderBy = {
  additionalPolicies?: InputMaybe<OrderBy>;
  additionalStops?: InputMaybe<OrderBy>;
  calculatedBasicPrice?: InputMaybe<OrderBy>;
  cancellationFee?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  finalPrice?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePrice?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  pricingPolicy?: InputMaybe<OrderBy>;
  recalculationHistory?: InputMaybe<OrderBy>;
  totalDeliveryPrice?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order_price */
export type OrderPricePkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrderPricePrependInput = {
  recalculationHistory?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "order_price" */
export enum OrderPriceSelectColumn {
  /** column name */
  AdditionalPolicies = 'additionalPolicies',
  /** column name */
  AdditionalStops = 'additionalStops',
  /** column name */
  CalculatedBasicPrice = 'calculatedBasicPrice',
  /** column name */
  CancellationFee = 'cancellationFee',
  /** column name */
  Distance = 'distance',
  /** column name */
  FinalPrice = 'finalPrice',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePrice = 'insurancePrice',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PricingPolicy = 'pricingPolicy',
  /** column name */
  RecalculationHistory = 'recalculationHistory',
  /** column name */
  TotalDeliveryPrice = 'totalDeliveryPrice'
}

/** input type for updating data in table "order_price" */
export type OrderPriceSetInput = {
  additionalPolicies?: InputMaybe<Scalars['numeric']>;
  additionalStops?: InputMaybe<Scalars['numeric']>;
  calculatedBasicPrice?: InputMaybe<Scalars['numeric']>;
  cancellationFee?: InputMaybe<Scalars['numeric']>;
  distance?: InputMaybe<Scalars['numeric']>;
  finalPrice?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  insurancePrice?: InputMaybe<Scalars['numeric']>;
  orderId?: InputMaybe<Scalars['String']>;
  pricingPolicy?: InputMaybe<Scalars['uuid']>;
  recalculationHistory?: InputMaybe<Scalars['jsonb']>;
  totalDeliveryPrice?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type OrderPriceStddevFields = {
  __typename?: 'OrderPriceStddevFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type OrderPriceStddevPopFields = {
  __typename?: 'OrderPriceStddevPopFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type OrderPriceStddevSampFields = {
  __typename?: 'OrderPriceStddevSampFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type OrderPriceSumFields = {
  __typename?: 'OrderPriceSumFields';
  additionalPolicies?: Maybe<Scalars['numeric']>;
  additionalStops?: Maybe<Scalars['numeric']>;
  calculatedBasicPrice?: Maybe<Scalars['numeric']>;
  cancellationFee?: Maybe<Scalars['numeric']>;
  distance?: Maybe<Scalars['numeric']>;
  finalPrice?: Maybe<Scalars['numeric']>;
  insurancePrice?: Maybe<Scalars['numeric']>;
  totalDeliveryPrice?: Maybe<Scalars['numeric']>;
};

/** update columns of table "order_price" */
export enum OrderPriceUpdateColumn {
  /** column name */
  AdditionalPolicies = 'additionalPolicies',
  /** column name */
  AdditionalStops = 'additionalStops',
  /** column name */
  CalculatedBasicPrice = 'calculatedBasicPrice',
  /** column name */
  CancellationFee = 'cancellationFee',
  /** column name */
  Distance = 'distance',
  /** column name */
  FinalPrice = 'finalPrice',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePrice = 'insurancePrice',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PricingPolicy = 'pricingPolicy',
  /** column name */
  RecalculationHistory = 'recalculationHistory',
  /** column name */
  TotalDeliveryPrice = 'totalDeliveryPrice'
}

/** aggregate var_pop on columns */
export type OrderPriceVarPopFields = {
  __typename?: 'OrderPriceVarPopFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type OrderPriceVarSampFields = {
  __typename?: 'OrderPriceVarSampFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type OrderPriceVarianceFields = {
  __typename?: 'OrderPriceVarianceFields';
  additionalPolicies?: Maybe<Scalars['Float']>;
  additionalStops?: Maybe<Scalars['Float']>;
  calculatedBasicPrice?: Maybe<Scalars['Float']>;
  cancellationFee?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  finalPrice?: Maybe<Scalars['Float']>;
  insurancePrice?: Maybe<Scalars['Float']>;
  totalDeliveryPrice?: Maybe<Scalars['Float']>;
};

/** select columns of table "Order" */
export enum OrderSelectColumn {
  /** column name */
  AgeLimitation = 'ageLimitation',
  /** column name */
  Barcode = 'barcode',
  /** column name */
  CompleteBefore = 'completeBefore',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FailureNote = 'failureNote',
  /** column name */
  FailureReason = 'failureReason',
  /** column name */
  Id = 'id',
  /** column name */
  IsLate = 'isLate',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Region = 'region',
  /** column name */
  ShortOrderId = 'shortOrderId',
  /** column name */
  StartAfter = 'startAfter',
  /** column name */
  Status = 'status',
  /** column name */
  StickerPdfId = 'stickerPdfId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type OrderServiceAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  coordinates?: InputMaybe<OrderServiceCoordinatesInput>;
  flat?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type OrderServiceCoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type OrderServiceDropOffInput = {
  address: OrderServiceAddressInput;
};

export type OrderServiceOrderCreateObject = {
  dropOff: OrderServiceDropOffInput;
  orderId: Scalars['String'];
  pickup: OrderServicePickupInput;
  /** Non unique Short ID (For display purposes) */
  shortId: Scalars['String'];
  startAfter: Scalars['OrderServiceDateTime'];
};

export type OrderServicePickupInput = {
  address: OrderServiceAddressInput;
};

/** input type for updating data in table "Order" */
export type OrderSetInput = {
  ageLimitation?: InputMaybe<Scalars['AgeLimitation']>;
  barcode?: InputMaybe<Scalars['String']>;
  completeBefore?: InputMaybe<Scalars['timestamp']>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  failureNote?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['OrderFailureReason']>;
  id?: InputMaybe<Scalars['String']>;
  isLate?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  shortOrderId?: InputMaybe<Scalars['String']>;
  startAfter?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['OrderStatus']>;
  stickerPdfId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** columns and relationships of "order_status_events" */
export type OrderStatusEvents = {
  __typename?: 'OrderStatusEvents';
  /** An object relationship */
  courier?: Maybe<Courier>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  order: Order;
  orderId: Scalars['String'];
  type: OrderStatusTypesEnum;
};

/** aggregated selection of "order_status_events" */
export type OrderStatusEventsAggregate = {
  __typename?: 'OrderStatusEventsAggregate';
  aggregate?: Maybe<OrderStatusEventsAggregateFields>;
  nodes: Array<OrderStatusEvents>;
};

/** aggregate fields of "order_status_events" */
export type OrderStatusEventsAggregateFields = {
  __typename?: 'OrderStatusEventsAggregateFields';
  avg?: Maybe<OrderStatusEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OrderStatusEventsMaxFields>;
  min?: Maybe<OrderStatusEventsMinFields>;
  stddev?: Maybe<OrderStatusEventsStddevFields>;
  stddevPop?: Maybe<OrderStatusEventsStddevPopFields>;
  stddevSamp?: Maybe<OrderStatusEventsStddevSampFields>;
  sum?: Maybe<OrderStatusEventsSumFields>;
  varPop?: Maybe<OrderStatusEventsVarPopFields>;
  varSamp?: Maybe<OrderStatusEventsVarSampFields>;
  variance?: Maybe<OrderStatusEventsVarianceFields>;
};


/** aggregate fields of "order_status_events" */
export type OrderStatusEventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_status_events" */
export type OrderStatusEventsAggregateOrderBy = {
  avg?: InputMaybe<OrderStatusEventsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrderStatusEventsMaxOrderBy>;
  min?: InputMaybe<OrderStatusEventsMinOrderBy>;
  stddev?: InputMaybe<OrderStatusEventsStddevOrderBy>;
  stddev_pop?: InputMaybe<OrderStatusEventsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrderStatusEventsStddevSampOrderBy>;
  sum?: InputMaybe<OrderStatusEventsSumOrderBy>;
  var_pop?: InputMaybe<OrderStatusEventsVarPopOrderBy>;
  var_samp?: InputMaybe<OrderStatusEventsVarSampOrderBy>;
  variance?: InputMaybe<OrderStatusEventsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "order_status_events" */
export type OrderStatusEventsArrRelInsertInput = {
  data: Array<OrderStatusEventsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrderStatusEventsOnConflict>;
};

/** aggregate avg on columns */
export type OrderStatusEventsAvgFields = {
  __typename?: 'OrderStatusEventsAvgFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_status_events" */
export type OrderStatusEventsAvgOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "order_status_events". All fields are combined with a logical 'AND'. */
export type OrderStatusEventsBoolExp = {
  _and?: InputMaybe<Array<OrderStatusEventsBoolExp>>;
  _not?: InputMaybe<OrderStatusEventsBoolExp>;
  _or?: InputMaybe<Array<OrderStatusEventsBoolExp>>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<OrderstatustypesenumComparisonExp>;
};

/** unique or primary key constraints on table "order_status_events" */
export enum OrderStatusEventsConstraint {
  /** unique or primary key constraint on columns "id" */
  OrderStatusEventsPkey = 'order_status_events_pkey'
}

/** input type for incrementing numeric columns in table "order_status_events" */
export type OrderStatusEventsIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "order_status_events" */
export type OrderStatusEventsInsertInput = {
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrderStatusTypesEnum>;
};

/** aggregate max on columns */
export type OrderStatusEventsMaxFields = {
  __typename?: 'OrderStatusEventsMaxFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_status_events" */
export type OrderStatusEventsMaxOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrderStatusEventsMinFields = {
  __typename?: 'OrderStatusEventsMinFields';
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_status_events" */
export type OrderStatusEventsMinOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "order_status_events" */
export type OrderStatusEventsMutationResponse = {
  __typename?: 'OrderStatusEventsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderStatusEvents>;
};

/** on_conflict condition type for table "order_status_events" */
export type OrderStatusEventsOnConflict = {
  constraint: OrderStatusEventsConstraint;
  update_columns?: Array<OrderStatusEventsUpdateColumn>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};

/** Ordering options when selecting data from "order_status_events". */
export type OrderStatusEventsOrderBy = {
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order_status_events */
export type OrderStatusEventsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "order_status_events" */
export enum OrderStatusEventsSelectColumn {
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "order_status_events" */
export type OrderStatusEventsSetInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<OrderStatusTypesEnum>;
};

/** aggregate stddev on columns */
export type OrderStatusEventsStddevFields = {
  __typename?: 'OrderStatusEventsStddevFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_status_events" */
export type OrderStatusEventsStddevOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrderStatusEventsStddevPopFields = {
  __typename?: 'OrderStatusEventsStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_status_events" */
export type OrderStatusEventsStddevPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrderStatusEventsStddevSampFields = {
  __typename?: 'OrderStatusEventsStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_status_events" */
export type OrderStatusEventsStddevSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrderStatusEventsSumFields = {
  __typename?: 'OrderStatusEventsSumFields';
  courierId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_status_events" */
export type OrderStatusEventsSumOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** update columns of table "order_status_events" */
export enum OrderStatusEventsUpdateColumn {
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type OrderStatusEventsVarPopFields = {
  __typename?: 'OrderStatusEventsVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_status_events" */
export type OrderStatusEventsVarPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrderStatusEventsVarSampFields = {
  __typename?: 'OrderStatusEventsVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_status_events" */
export type OrderStatusEventsVarSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrderStatusEventsVarianceFields = {
  __typename?: 'OrderStatusEventsVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_status_events" */
export type OrderStatusEventsVarianceOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "order_status_types" */
export type OrderStatusTypes = {
  __typename?: 'OrderStatusTypes';
  type: Scalars['String'];
};

/** aggregated selection of "order_status_types" */
export type OrderStatusTypesAggregate = {
  __typename?: 'OrderStatusTypesAggregate';
  aggregate?: Maybe<OrderStatusTypesAggregateFields>;
  nodes: Array<OrderStatusTypes>;
};

/** aggregate fields of "order_status_types" */
export type OrderStatusTypesAggregateFields = {
  __typename?: 'OrderStatusTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrderStatusTypesMaxFields>;
  min?: Maybe<OrderStatusTypesMinFields>;
};


/** aggregate fields of "order_status_types" */
export type OrderStatusTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrderStatusTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "order_status_types". All fields are combined with a logical 'AND'. */
export type OrderStatusTypesBoolExp = {
  _and?: InputMaybe<Array<OrderStatusTypesBoolExp>>;
  _not?: InputMaybe<OrderStatusTypesBoolExp>;
  _or?: InputMaybe<Array<OrderStatusTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "order_status_types" */
export enum OrderStatusTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  OrderStatusTypesPkey = 'order_status_types_pkey'
}

export enum OrderStatusTypesEnum {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  CourierMissing = 'COURIER_MISSING',
  Created = 'CREATED',
  Failed = 'FAILED',
  NeedCourier = 'NEED_COURIER',
  Planned = 'PLANNED',
  Priority = 'PRIORITY',
  Started = 'STARTED'
}

/** input type for inserting data into table "order_status_types" */
export type OrderStatusTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderStatusTypesMaxFields = {
  __typename?: 'OrderStatusTypesMaxFields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrderStatusTypesMinFields = {
  __typename?: 'OrderStatusTypesMinFields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "order_status_types" */
export type OrderStatusTypesMutationResponse = {
  __typename?: 'OrderStatusTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrderStatusTypes>;
};

/** on_conflict condition type for table "order_status_types" */
export type OrderStatusTypesOnConflict = {
  constraint: OrderStatusTypesConstraint;
  update_columns?: Array<OrderStatusTypesUpdateColumn>;
  where?: InputMaybe<OrderStatusTypesBoolExp>;
};

/** Ordering options when selecting data from "order_status_types". */
export type OrderStatusTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: order_status_types */
export type OrderStatusTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "order_status_types" */
export enum OrderStatusTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "order_status_types" */
export type OrderStatusTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "order_status_types" */
export enum OrderStatusTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type OrderStddevFields = {
  __typename?: 'OrderStddevFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Order" */
export type OrderStddevOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrderStddevPopFields = {
  __typename?: 'OrderStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Order" */
export type OrderStddevPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrderStddevSampFields = {
  __typename?: 'OrderStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Order" */
export type OrderStddevSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrderSumFields = {
  __typename?: 'OrderSumFields';
  courierId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Order" */
export type OrderSumOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** update columns of table "Order" */
export enum OrderUpdateColumn {
  /** column name */
  AgeLimitation = 'ageLimitation',
  /** column name */
  Barcode = 'barcode',
  /** column name */
  CompleteBefore = 'completeBefore',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FailureNote = 'failureNote',
  /** column name */
  FailureReason = 'failureReason',
  /** column name */
  Id = 'id',
  /** column name */
  IsLate = 'isLate',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Region = 'region',
  /** column name */
  ShortOrderId = 'shortOrderId',
  /** column name */
  StartAfter = 'startAfter',
  /** column name */
  Status = 'status',
  /** column name */
  StickerPdfId = 'stickerPdfId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type OrderVarPopFields = {
  __typename?: 'OrderVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Order" */
export type OrderVarPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrderVarSampFields = {
  __typename?: 'OrderVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Order" */
export type OrderVarSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrderVarianceFields = {
  __typename?: 'OrderVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Order" */
export type OrderVarianceOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "OrderEvents" */
export type Orderevents = {
  __typename?: 'Orderevents';
  createdAt: Scalars['timestamp'];
  id: Scalars['String'];
  orderId: Scalars['String'];
  payload: Scalars['jsonb'];
  type: Scalars['String'];
};


/** columns and relationships of "OrderEvents" */
export type OrdereventsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "OrderEvents" */
export type OrdereventsAggregate = {
  __typename?: 'OrdereventsAggregate';
  aggregate?: Maybe<OrdereventsAggregateFields>;
  nodes: Array<Orderevents>;
};

/** aggregate fields of "OrderEvents" */
export type OrdereventsAggregateFields = {
  __typename?: 'OrdereventsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrdereventsMaxFields>;
  min?: Maybe<OrdereventsMinFields>;
};


/** aggregate fields of "OrderEvents" */
export type OrdereventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrdereventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "OrderEvents" */
export type OrdereventsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrdereventsMaxOrderBy>;
  min?: InputMaybe<OrdereventsMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrdereventsAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "OrderEvents" */
export type OrdereventsArrRelInsertInput = {
  data: Array<OrdereventsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrdereventsOnConflict>;
};

/** Boolean expression to filter rows from the table "OrderEvents". All fields are combined with a logical 'AND'. */
export type OrdereventsBoolExp = {
  _and?: InputMaybe<Array<OrdereventsBoolExp>>;
  _not?: InputMaybe<OrdereventsBoolExp>;
  _or?: InputMaybe<Array<OrdereventsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  payload?: InputMaybe<JsonbComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "OrderEvents" */
export enum OrdereventsConstraint {
  /** unique or primary key constraint on columns "id" */
  OrderEventsPkey = 'OrderEvents_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrdereventsDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrdereventsDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrdereventsDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "OrderEvents" */
export type OrdereventsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrdereventsMaxFields = {
  __typename?: 'OrdereventsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "OrderEvents" */
export type OrdereventsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrdereventsMinFields = {
  __typename?: 'OrdereventsMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "OrderEvents" */
export type OrdereventsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "OrderEvents" */
export type OrdereventsMutationResponse = {
  __typename?: 'OrdereventsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Orderevents>;
};

/** on_conflict condition type for table "OrderEvents" */
export type OrdereventsOnConflict = {
  constraint: OrdereventsConstraint;
  update_columns?: Array<OrdereventsUpdateColumn>;
  where?: InputMaybe<OrdereventsBoolExp>;
};

/** Ordering options when selecting data from "OrderEvents". */
export type OrdereventsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: orderEvents */
export type OrdereventsPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrdereventsPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "OrderEvents" */
export enum OrdereventsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "OrderEvents" */
export type OrdereventsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "OrderEvents" */
export enum OrdereventsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Payload = 'payload',
  /** column name */
  Type = 'type'
}

/** Boolean expression to compare columns of type "OrderFailureReason". All fields are combined with logical 'AND'. */
export type OrderfailurereasonComparisonExp = {
  _eq?: InputMaybe<Scalars['OrderFailureReason']>;
  _gt?: InputMaybe<Scalars['OrderFailureReason']>;
  _gte?: InputMaybe<Scalars['OrderFailureReason']>;
  _in?: InputMaybe<Array<Scalars['OrderFailureReason']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['OrderFailureReason']>;
  _lte?: InputMaybe<Scalars['OrderFailureReason']>;
  _neq?: InputMaybe<Scalars['OrderFailureReason']>;
  _nin?: InputMaybe<Array<Scalars['OrderFailureReason']>>;
};

/** Boolean expression to compare columns of type "OrderStatus". All fields are combined with logical 'AND'. */
export type OrderstatusComparisonExp = {
  _eq?: InputMaybe<Scalars['OrderStatus']>;
  _gt?: InputMaybe<Scalars['OrderStatus']>;
  _gte?: InputMaybe<Scalars['OrderStatus']>;
  _in?: InputMaybe<Array<Scalars['OrderStatus']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['OrderStatus']>;
  _lte?: InputMaybe<Scalars['OrderStatus']>;
  _neq?: InputMaybe<Scalars['OrderStatus']>;
  _nin?: InputMaybe<Array<Scalars['OrderStatus']>>;
};

/** Boolean expression to compare columns of type "OrderStatusTypesEnum". All fields are combined with logical 'AND'. */
export type OrderstatustypesenumComparisonExp = {
  _eq?: InputMaybe<OrderStatusTypesEnum>;
  _in?: InputMaybe<Array<OrderStatusTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OrderStatusTypesEnum>;
  _nin?: InputMaybe<Array<OrderStatusTypesEnum>>;
};

/** columns and relationships of "OrderTracking" */
export type Ordertracking = {
  __typename?: 'Ordertracking';
  /** An object relationship */
  Order: Order;
  id: Scalars['String'];
  orderId: Scalars['String'];
};

/** aggregated selection of "OrderTracking" */
export type OrdertrackingAggregate = {
  __typename?: 'OrdertrackingAggregate';
  aggregate?: Maybe<OrdertrackingAggregateFields>;
  nodes: Array<Ordertracking>;
};

/** aggregate fields of "OrderTracking" */
export type OrdertrackingAggregateFields = {
  __typename?: 'OrdertrackingAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrdertrackingMaxFields>;
  min?: Maybe<OrdertrackingMinFields>;
};


/** aggregate fields of "OrderTracking" */
export type OrdertrackingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrdertrackingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "OrderTracking". All fields are combined with a logical 'AND'. */
export type OrdertrackingBoolExp = {
  Order?: InputMaybe<OrderBoolExp>;
  _and?: InputMaybe<Array<OrdertrackingBoolExp>>;
  _not?: InputMaybe<OrdertrackingBoolExp>;
  _or?: InputMaybe<Array<OrdertrackingBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "OrderTracking" */
export enum OrdertrackingConstraint {
  /** unique or primary key constraint on columns "orderId" */
  OrderTrackingOrderIdKey = 'OrderTracking_orderId_key',
  /** unique or primary key constraint on columns "id" */
  OrderTrackingPkey = 'OrderTracking_pkey'
}

/** input type for inserting data into table "OrderTracking" */
export type OrdertrackingInsertInput = {
  Order?: InputMaybe<OrderObjRelInsertInput>;
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrdertrackingMaxFields = {
  __typename?: 'OrdertrackingMaxFields';
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type OrdertrackingMinFields = {
  __typename?: 'OrdertrackingMinFields';
  id?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "OrderTracking" */
export type OrdertrackingMutationResponse = {
  __typename?: 'OrdertrackingMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ordertracking>;
};

/** input type for inserting object relation for remote table "OrderTracking" */
export type OrdertrackingObjRelInsertInput = {
  data: OrdertrackingInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrdertrackingOnConflict>;
};

/** on_conflict condition type for table "OrderTracking" */
export type OrdertrackingOnConflict = {
  constraint: OrdertrackingConstraint;
  update_columns?: Array<OrdertrackingUpdateColumn>;
  where?: InputMaybe<OrdertrackingBoolExp>;
};

/** Ordering options when selecting data from "OrderTracking". */
export type OrdertrackingOrderBy = {
  Order?: InputMaybe<OrderOrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: OrderTracking */
export type OrdertrackingPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "OrderTracking" */
export enum OrdertrackingSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId'
}

/** input type for updating data in table "OrderTracking" */
export type OrdertrackingSetInput = {
  id?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
};

/** update columns of table "OrderTracking" */
export enum OrdertrackingUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId'
}

/** columns and relationships of "OrderValidationError" */
export type Ordervalidationerror = {
  __typename?: 'Ordervalidationerror';
  createdAt: Scalars['timestamp'];
  description: Scalars['String'];
  id: Scalars['String'];
  merchantOrderId: Scalars['String'];
  payload: Scalars['jsonb'];
  resolvedAt?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "OrderValidationError" */
export type OrdervalidationerrorPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "OrderValidationError" */
export type OrdervalidationerrorAggregate = {
  __typename?: 'OrdervalidationerrorAggregate';
  aggregate?: Maybe<OrdervalidationerrorAggregateFields>;
  nodes: Array<Ordervalidationerror>;
};

/** aggregate fields of "OrderValidationError" */
export type OrdervalidationerrorAggregateFields = {
  __typename?: 'OrdervalidationerrorAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrdervalidationerrorMaxFields>;
  min?: Maybe<OrdervalidationerrorMinFields>;
};


/** aggregate fields of "OrderValidationError" */
export type OrdervalidationerrorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrdervalidationerrorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrdervalidationerrorAppendInput = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "OrderValidationError". All fields are combined with a logical 'AND'. */
export type OrdervalidationerrorBoolExp = {
  _and?: InputMaybe<Array<OrdervalidationerrorBoolExp>>;
  _not?: InputMaybe<OrdervalidationerrorBoolExp>;
  _or?: InputMaybe<Array<OrdervalidationerrorBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  merchantOrderId?: InputMaybe<StringComparisonExp>;
  payload?: InputMaybe<JsonbComparisonExp>;
  resolvedAt?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "OrderValidationError" */
export enum OrdervalidationerrorConstraint {
  /** unique or primary key constraint on columns "id" */
  OrderValidationErrorPkey = 'OrderValidationError_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrdervalidationerrorDeleteAtPathInput = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrdervalidationerrorDeleteElemInput = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrdervalidationerrorDeleteKeyInput = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "OrderValidationError" */
export type OrdervalidationerrorInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  merchantOrderId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  resolvedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type OrdervalidationerrorMaxFields = {
  __typename?: 'OrdervalidationerrorMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantOrderId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type OrdervalidationerrorMinFields = {
  __typename?: 'OrdervalidationerrorMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  merchantOrderId?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "OrderValidationError" */
export type OrdervalidationerrorMutationResponse = {
  __typename?: 'OrdervalidationerrorMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ordervalidationerror>;
};

/** on_conflict condition type for table "OrderValidationError" */
export type OrdervalidationerrorOnConflict = {
  constraint: OrdervalidationerrorConstraint;
  update_columns?: Array<OrdervalidationerrorUpdateColumn>;
  where?: InputMaybe<OrdervalidationerrorBoolExp>;
};

/** Ordering options when selecting data from "OrderValidationError". */
export type OrdervalidationerrorOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  merchantOrderId?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  resolvedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: OrderValidationError */
export type OrdervalidationerrorPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrdervalidationerrorPrependInput = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "OrderValidationError" */
export enum OrdervalidationerrorSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantOrderId = 'merchantOrderId',
  /** column name */
  Payload = 'payload',
  /** column name */
  ResolvedAt = 'resolvedAt'
}

/** input type for updating data in table "OrderValidationError" */
export type OrdervalidationerrorSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  merchantOrderId?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  resolvedAt?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "OrderValidationError" */
export enum OrdervalidationerrorUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantOrderId = 'merchantOrderId',
  /** column name */
  Payload = 'payload',
  /** column name */
  ResolvedAt = 'resolvedAt'
}

/** columns and relationships of "Organization" */
export type Organization = {
  __typename?: 'Organization';
  /** An array relationship */
  accessTokens: Array<Accesstoken>;
  /** An aggregate relationship */
  accessTokensAggregate: AccesstokenAggregate;
  createdAt: Scalars['timestamp'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  image: Scalars['String'];
  /** An array relationship */
  invites: Array<Invite>;
  /** An aggregate relationship */
  invitesAggregate: InviteAggregate;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  membersAggregate: MemberAggregate;
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Order>;
  /** An aggregate relationship */
  ordersAggregate: OrderAggregate;
  /** An aggregate relationship */
  orgPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
  /** An array relationship */
  org_pricing_policies: Array<OrganizationPricingPolicies>;
  /** An object relationship */
  organizationConfig?: Maybe<Organizationconfig>;
  /** An array relationship */
  organizationProfile: Array<Organizationprofile>;
  /** An aggregate relationship */
  organizationProfileAggregate: OrganizationprofileAggregate;
  settings?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamp'];
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooksAggregate: WebhooksAggregate;
};


/** columns and relationships of "Organization" */
export type OrganizationAccessTokensArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationAccessTokensAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationInvitesArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationInvitesAggregateArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationMembersArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrdersArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrgPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrg_Pricing_PoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrganizationProfileArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationOrganizationProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationSettingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "Organization" */
export type OrganizationWebhooksArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};


/** columns and relationships of "Organization" */
export type OrganizationWebhooksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};

/** aggregated selection of "Organization" */
export type OrganizationAggregate = {
  __typename?: 'OrganizationAggregate';
  aggregate?: Maybe<OrganizationAggregateFields>;
  nodes: Array<Organization>;
};

/** aggregate fields of "Organization" */
export type OrganizationAggregateFields = {
  __typename?: 'OrganizationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationMaxFields>;
  min?: Maybe<OrganizationMinFields>;
};


/** aggregate fields of "Organization" */
export type OrganizationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrganizationAppendInput = {
  settings?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Organization". All fields are combined with a logical 'AND'. */
export type OrganizationBoolExp = {
  _and?: InputMaybe<Array<OrganizationBoolExp>>;
  _not?: InputMaybe<OrganizationBoolExp>;
  _or?: InputMaybe<Array<OrganizationBoolExp>>;
  accessTokens?: InputMaybe<AccesstokenBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  invites?: InputMaybe<InviteBoolExp>;
  members?: InputMaybe<MemberBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  orders?: InputMaybe<OrderBoolExp>;
  org_pricing_policies?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
  organizationConfig?: InputMaybe<OrganizationconfigBoolExp>;
  organizationProfile?: InputMaybe<OrganizationprofileBoolExp>;
  settings?: InputMaybe<JsonbComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  webhooks?: InputMaybe<WebhooksBoolExp>;
};

/** unique or primary key constraints on table "Organization" */
export enum OrganizationConstraint {
  /** unique or primary key constraint on columns "name" */
  OrganizationNameKey = 'Organization_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationPkey = 'Organization_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrganizationDeleteAtPathInput = {
  settings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrganizationDeleteElemInput = {
  settings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrganizationDeleteKeyInput = {
  settings?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "Organization" */
export type OrganizationInsertInput = {
  accessTokens?: InputMaybe<AccesstokenArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  invites?: InputMaybe<InviteArrRelInsertInput>;
  members?: InputMaybe<MemberArrRelInsertInput>;
  name?: InputMaybe<Scalars['String']>;
  orders?: InputMaybe<OrderArrRelInsertInput>;
  org_pricing_policies?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
  organizationConfig?: InputMaybe<OrganizationconfigObjRelInsertInput>;
  organizationProfile?: InputMaybe<OrganizationprofileArrRelInsertInput>;
  settings?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  webhooks?: InputMaybe<WebhooksArrRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizationMaxFields = {
  __typename?: 'OrganizationMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type OrganizationMinFields = {
  __typename?: 'OrganizationMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "Organization" */
export type OrganizationMutationResponse = {
  __typename?: 'OrganizationMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "Organization" */
export type OrganizationObjRelInsertInput = {
  data: OrganizationInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationOnConflict>;
};

/** on_conflict condition type for table "Organization" */
export type OrganizationOnConflict = {
  constraint: OrganizationConstraint;
  update_columns?: Array<OrganizationUpdateColumn>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** Ordering options when selecting data from "Organization". */
export type OrganizationOrderBy = {
  accessTokens_aggregate?: InputMaybe<AccesstokenAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  invites_aggregate?: InputMaybe<InviteAggregateOrderBy>;
  members_aggregate?: InputMaybe<MemberAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  orders_aggregate?: InputMaybe<OrderAggregateOrderBy>;
  org_pricing_policies_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
  organizationConfig?: InputMaybe<OrganizationconfigOrderBy>;
  organizationProfile_aggregate?: InputMaybe<OrganizationprofileAggregateOrderBy>;
  settings?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhooks_aggregate?: InputMaybe<WebhooksAggregateOrderBy>;
};

/** primary key columns input for table: organization */
export type OrganizationPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrganizationPrependInput = {
  settings?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "organization_pricing_policies" */
export type OrganizationPricingPolicies = {
  __typename?: 'OrganizationPricingPolicies';
  /** An object relationship */
  cancellationPolicy?: Maybe<CancellationPolicies>;
  cancellationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  deliveryType: DeliveryTypesEnum;
  /** An object relationship */
  deliveryTypes: DeliveryTypes;
  id: Scalars['uuid'];
  /** An array relationship */
  insurancePolicies: Array<PricingInsuranceBridge>;
  /** An aggregate relationship */
  insurancePoliciesAggregate: PricingInsuranceBridgeAggregate;
  /** An object relationship */
  multipleStopsPolicy?: Maybe<MultipleStopsPolicies>;
  multipleStopsPolicyId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId: Scalars['String'];
  /** An object relationship */
  pricingRules?: Maybe<PricingRules>;
  pricingRulesId: Scalars['uuid'];
  validFrom: Scalars['timestamp'];
  validTo?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "organization_pricing_policies" */
export type OrganizationPricingPoliciesInsurancePoliciesArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


/** columns and relationships of "organization_pricing_policies" */
export type OrganizationPricingPoliciesInsurancePoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};

/** aggregated selection of "organization_pricing_policies" */
export type OrganizationPricingPoliciesAggregate = {
  __typename?: 'OrganizationPricingPoliciesAggregate';
  aggregate?: Maybe<OrganizationPricingPoliciesAggregateFields>;
  nodes: Array<OrganizationPricingPolicies>;
};

/** aggregate fields of "organization_pricing_policies" */
export type OrganizationPricingPoliciesAggregateFields = {
  __typename?: 'OrganizationPricingPoliciesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationPricingPoliciesMaxFields>;
  min?: Maybe<OrganizationPricingPoliciesMinFields>;
};


/** aggregate fields of "organization_pricing_policies" */
export type OrganizationPricingPoliciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_pricing_policies" */
export type OrganizationPricingPoliciesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizationPricingPoliciesMaxOrderBy>;
  min?: InputMaybe<OrganizationPricingPoliciesMinOrderBy>;
};

/** input type for inserting array relation for remote table "organization_pricing_policies" */
export type OrganizationPricingPoliciesArrRelInsertInput = {
  data: Array<OrganizationPricingPoliciesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationPricingPoliciesOnConflict>;
};

/** Boolean expression to filter rows from the table "organization_pricing_policies". All fields are combined with a logical 'AND'. */
export type OrganizationPricingPoliciesBoolExp = {
  _and?: InputMaybe<Array<OrganizationPricingPoliciesBoolExp>>;
  _not?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
  _or?: InputMaybe<Array<OrganizationPricingPoliciesBoolExp>>;
  cancellationPolicy?: InputMaybe<CancellationPoliciesBoolExp>;
  cancellationPolicyId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deliveryType?: InputMaybe<DeliverytypesenumComparisonExp>;
  deliveryTypes?: InputMaybe<DeliveryTypesBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  insurancePolicies?: InputMaybe<PricingInsuranceBridgeBoolExp>;
  multipleStopsPolicy?: InputMaybe<MultipleStopsPoliciesBoolExp>;
  multipleStopsPolicyId?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  pricingRules?: InputMaybe<PricingRulesBoolExp>;
  pricingRulesId?: InputMaybe<UuidComparisonExp>;
  validFrom?: InputMaybe<TimestampComparisonExp>;
  validTo?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "organization_pricing_policies" */
export enum OrganizationPricingPoliciesConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationPricingPoliciesPkey = 'organization_pricing_policies_pkey'
}

/** input type for inserting data into table "organization_pricing_policies" */
export type OrganizationPricingPoliciesInsertInput = {
  cancellationPolicy?: InputMaybe<CancellationPoliciesObjRelInsertInput>;
  cancellationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryType?: InputMaybe<DeliveryTypesEnum>;
  deliveryTypes?: InputMaybe<DeliveryTypesObjRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']>;
  insurancePolicies?: InputMaybe<PricingInsuranceBridgeArrRelInsertInput>;
  multipleStopsPolicy?: InputMaybe<MultipleStopsPoliciesObjRelInsertInput>;
  multipleStopsPolicyId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  pricingRules?: InputMaybe<PricingRulesObjRelInsertInput>;
  pricingRulesId?: InputMaybe<Scalars['uuid']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validTo?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type OrganizationPricingPoliciesMaxFields = {
  __typename?: 'OrganizationPricingPoliciesMaxFields';
  cancellationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  multipleStopsPolicyId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricingRulesId?: Maybe<Scalars['uuid']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validTo?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "organization_pricing_policies" */
export type OrganizationPricingPoliciesMaxOrderBy = {
  cancellationPolicyId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  multipleStopsPolicyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  pricingRulesId?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationPricingPoliciesMinFields = {
  __typename?: 'OrganizationPricingPoliciesMinFields';
  cancellationPolicyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  multipleStopsPolicyId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  pricingRulesId?: Maybe<Scalars['uuid']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validTo?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "organization_pricing_policies" */
export type OrganizationPricingPoliciesMinOrderBy = {
  cancellationPolicyId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  multipleStopsPolicyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  pricingRulesId?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organization_pricing_policies" */
export type OrganizationPricingPoliciesMutationResponse = {
  __typename?: 'OrganizationPricingPoliciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganizationPricingPolicies>;
};

/** on_conflict condition type for table "organization_pricing_policies" */
export type OrganizationPricingPoliciesOnConflict = {
  constraint: OrganizationPricingPoliciesConstraint;
  update_columns?: Array<OrganizationPricingPoliciesUpdateColumn>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** Ordering options when selecting data from "organization_pricing_policies". */
export type OrganizationPricingPoliciesOrderBy = {
  cancellationPolicy?: InputMaybe<CancellationPoliciesOrderBy>;
  cancellationPolicyId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deliveryType?: InputMaybe<OrderBy>;
  deliveryTypes?: InputMaybe<DeliveryTypesOrderBy>;
  id?: InputMaybe<OrderBy>;
  insurancePolicies_aggregate?: InputMaybe<PricingInsuranceBridgeAggregateOrderBy>;
  multipleStopsPolicy?: InputMaybe<MultipleStopsPoliciesOrderBy>;
  multipleStopsPolicyId?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  pricingRules?: InputMaybe<PricingRulesOrderBy>;
  pricingRulesId?: InputMaybe<OrderBy>;
  validFrom?: InputMaybe<OrderBy>;
  validTo?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organization_pricing_policies */
export type OrganizationPricingPoliciesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "organization_pricing_policies" */
export enum OrganizationPricingPoliciesSelectColumn {
  /** column name */
  CancellationPolicyId = 'cancellationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  Id = 'id',
  /** column name */
  MultipleStopsPolicyId = 'multipleStopsPolicyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PricingRulesId = 'pricingRulesId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

/** input type for updating data in table "organization_pricing_policies" */
export type OrganizationPricingPoliciesSetInput = {
  cancellationPolicyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryType?: InputMaybe<DeliveryTypesEnum>;
  id?: InputMaybe<Scalars['uuid']>;
  multipleStopsPolicyId?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  pricingRulesId?: InputMaybe<Scalars['uuid']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validTo?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "organization_pricing_policies" */
export enum OrganizationPricingPoliciesUpdateColumn {
  /** column name */
  CancellationPolicyId = 'cancellationPolicyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  Id = 'id',
  /** column name */
  MultipleStopsPolicyId = 'multipleStopsPolicyId',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  PricingRulesId = 'pricingRulesId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidTo = 'validTo'
}

/** select columns of table "Organization" */
export enum OrganizationSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "Organization" */
export type OrganizationSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "Organization" */
export enum OrganizationUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "OrganizationConfig" */
export type Organizationconfig = {
  __typename?: 'Organizationconfig';
  active: Scalars['Boolean'];
  id: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  timeZone: Scalars['TimeZone'];
};

/** aggregated selection of "OrganizationConfig" */
export type OrganizationconfigAggregate = {
  __typename?: 'OrganizationconfigAggregate';
  aggregate?: Maybe<OrganizationconfigAggregateFields>;
  nodes: Array<Organizationconfig>;
};

/** aggregate fields of "OrganizationConfig" */
export type OrganizationconfigAggregateFields = {
  __typename?: 'OrganizationconfigAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationconfigMaxFields>;
  min?: Maybe<OrganizationconfigMinFields>;
};


/** aggregate fields of "OrganizationConfig" */
export type OrganizationconfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationconfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "OrganizationConfig". All fields are combined with a logical 'AND'. */
export type OrganizationconfigBoolExp = {
  _and?: InputMaybe<Array<OrganizationconfigBoolExp>>;
  _not?: InputMaybe<OrganizationconfigBoolExp>;
  _or?: InputMaybe<Array<OrganizationconfigBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  timeZone?: InputMaybe<TimezoneComparisonExp>;
};

/** unique or primary key constraints on table "OrganizationConfig" */
export enum OrganizationconfigConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationConfigIdKey = 'OrganizationConfig_id_key',
  /** unique or primary key constraint on columns "organizationId" */
  OrganizationConfigOrganizationIdKey = 'OrganizationConfig_organizationId_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationConfigPkey = 'OrganizationConfig_pkey'
}

/** input type for inserting data into table "OrganizationConfig" */
export type OrganizationconfigInsertInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['TimeZone']>;
};

/** aggregate max on columns */
export type OrganizationconfigMaxFields = {
  __typename?: 'OrganizationconfigMaxFields';
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['TimeZone']>;
};

/** aggregate min on columns */
export type OrganizationconfigMinFields = {
  __typename?: 'OrganizationconfigMinFields';
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['TimeZone']>;
};

/** response of any mutation on the table "OrganizationConfig" */
export type OrganizationconfigMutationResponse = {
  __typename?: 'OrganizationconfigMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizationconfig>;
};

/** input type for inserting object relation for remote table "OrganizationConfig" */
export type OrganizationconfigObjRelInsertInput = {
  data: OrganizationconfigInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationconfigOnConflict>;
};

/** on_conflict condition type for table "OrganizationConfig" */
export type OrganizationconfigOnConflict = {
  constraint: OrganizationconfigConstraint;
  update_columns?: Array<OrganizationconfigUpdateColumn>;
  where?: InputMaybe<OrganizationconfigBoolExp>;
};

/** Ordering options when selecting data from "OrganizationConfig". */
export type OrganizationconfigOrderBy = {
  active?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  timeZone?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organizationConfig */
export type OrganizationconfigPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "OrganizationConfig" */
export enum OrganizationconfigSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  TimeZone = 'timeZone'
}

/** input type for updating data in table "OrganizationConfig" */
export type OrganizationconfigSetInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['TimeZone']>;
};

/** update columns of table "OrganizationConfig" */
export enum OrganizationconfigUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  TimeZone = 'timeZone'
}

/** columns and relationships of "OrganizationProfile" */
export type Organizationprofile = {
  __typename?: 'Organizationprofile';
  address: Scalars['String'];
  companyCode: Scalars['String'];
  companyName: Scalars['String'];
  email: Scalars['String'];
  iban: Scalars['String'];
  id: Scalars['String'];
  vatCode: Scalars['String'];
};

/** aggregated selection of "OrganizationProfile" */
export type OrganizationprofileAggregate = {
  __typename?: 'OrganizationprofileAggregate';
  aggregate?: Maybe<OrganizationprofileAggregateFields>;
  nodes: Array<Organizationprofile>;
};

/** aggregate fields of "OrganizationProfile" */
export type OrganizationprofileAggregateFields = {
  __typename?: 'OrganizationprofileAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<OrganizationprofileMaxFields>;
  min?: Maybe<OrganizationprofileMinFields>;
};


/** aggregate fields of "OrganizationProfile" */
export type OrganizationprofileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "OrganizationProfile" */
export type OrganizationprofileAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizationprofileMaxOrderBy>;
  min?: InputMaybe<OrganizationprofileMinOrderBy>;
};

/** input type for inserting array relation for remote table "OrganizationProfile" */
export type OrganizationprofileArrRelInsertInput = {
  data: Array<OrganizationprofileInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<OrganizationprofileOnConflict>;
};

/** Boolean expression to filter rows from the table "OrganizationProfile". All fields are combined with a logical 'AND'. */
export type OrganizationprofileBoolExp = {
  _and?: InputMaybe<Array<OrganizationprofileBoolExp>>;
  _not?: InputMaybe<OrganizationprofileBoolExp>;
  _or?: InputMaybe<Array<OrganizationprofileBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  companyCode?: InputMaybe<StringComparisonExp>;
  companyName?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  iban?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  vatCode?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "OrganizationProfile" */
export enum OrganizationprofileConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationProfileIdKey = 'OrganizationProfile_id_key'
}

/** input type for inserting data into table "OrganizationProfile" */
export type OrganizationprofileInsertInput = {
  address?: InputMaybe<Scalars['String']>;
  companyCode?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  vatCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrganizationprofileMaxFields = {
  __typename?: 'OrganizationprofileMaxFields';
  address?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  vatCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "OrganizationProfile" */
export type OrganizationprofileMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  iban?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  vatCode?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationprofileMinFields = {
  __typename?: 'OrganizationprofileMinFields';
  address?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  vatCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "OrganizationProfile" */
export type OrganizationprofileMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  iban?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  vatCode?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "OrganizationProfile" */
export type OrganizationprofileMutationResponse = {
  __typename?: 'OrganizationprofileMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizationprofile>;
};

/** on_conflict condition type for table "OrganizationProfile" */
export type OrganizationprofileOnConflict = {
  constraint: OrganizationprofileConstraint;
  update_columns?: Array<OrganizationprofileUpdateColumn>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};

/** Ordering options when selecting data from "OrganizationProfile". */
export type OrganizationprofileOrderBy = {
  address?: InputMaybe<OrderBy>;
  companyCode?: InputMaybe<OrderBy>;
  companyName?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  iban?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  vatCode?: InputMaybe<OrderBy>;
};

/** select columns of table "OrganizationProfile" */
export enum OrganizationprofileSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyCode = 'companyCode',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Email = 'email',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  VatCode = 'vatCode'
}

/** input type for updating data in table "OrganizationProfile" */
export type OrganizationprofileSetInput = {
  address?: InputMaybe<Scalars['String']>;
  companyCode?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  iban?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  vatCode?: InputMaybe<Scalars['String']>;
};

/** update columns of table "OrganizationProfile" */
export enum OrganizationprofileUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyCode = 'companyCode',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  Email = 'email',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  VatCode = 'vatCode'
}

/** PDF storage */
export type Pdf = {
  __typename?: 'Pdf';
  bucket: Scalars['String'];
  /** An object relationship */
  courierReport?: Maybe<Courierreport>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  orderSticker?: Maybe<Order>;
};

/** aggregated selection of "pdf" */
export type PdfAggregate = {
  __typename?: 'PdfAggregate';
  aggregate?: Maybe<PdfAggregateFields>;
  nodes: Array<Pdf>;
};

/** aggregate fields of "pdf" */
export type PdfAggregateFields = {
  __typename?: 'PdfAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PdfMaxFields>;
  min?: Maybe<PdfMinFields>;
};


/** aggregate fields of "pdf" */
export type PdfAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PdfSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "pdf". All fields are combined with a logical 'AND'. */
export type PdfBoolExp = {
  _and?: InputMaybe<Array<PdfBoolExp>>;
  _not?: InputMaybe<PdfBoolExp>;
  _or?: InputMaybe<Array<PdfBoolExp>>;
  bucket?: InputMaybe<StringComparisonExp>;
  courierReport?: InputMaybe<CourierreportBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  orderSticker?: InputMaybe<OrderBoolExp>;
};

/** unique or primary key constraints on table "pdf" */
export enum PdfConstraint {
  /** unique or primary key constraint on columns "id" */
  PdfPkey = 'pdf_pkey'
}

/** input type for inserting data into table "pdf" */
export type PdfInsertInput = {
  bucket?: InputMaybe<Scalars['String']>;
  courierReport?: InputMaybe<CourierreportObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  orderSticker?: InputMaybe<OrderObjRelInsertInput>;
};

/** aggregate max on columns */
export type PdfMaxFields = {
  __typename?: 'PdfMaxFields';
  bucket?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PdfMinFields = {
  __typename?: 'PdfMinFields';
  bucket?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pdf" */
export type PdfMutationResponse = {
  __typename?: 'PdfMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pdf>;
};

/** input type for inserting object relation for remote table "pdf" */
export type PdfObjRelInsertInput = {
  data: PdfInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PdfOnConflict>;
};

/** on_conflict condition type for table "pdf" */
export type PdfOnConflict = {
  constraint: PdfConstraint;
  update_columns?: Array<PdfUpdateColumn>;
  where?: InputMaybe<PdfBoolExp>;
};

/** Ordering options when selecting data from "pdf". */
export type PdfOrderBy = {
  bucket?: InputMaybe<OrderBy>;
  courierReport?: InputMaybe<CourierreportOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  orderSticker?: InputMaybe<OrderOrderBy>;
};

/** primary key columns input for table: pdf */
export type PdfPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "pdf" */
export enum PdfSelectColumn {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "pdf" */
export type PdfSetInput = {
  bucket?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "pdf" */
export enum PdfUpdateColumn {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "price_addons" */
export type PriceAddons = {
  __typename?: 'PriceAddons';
  addon: Scalars['numeric'];
  id: Scalars['uuid'];
  maxDistance?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /** An array relationship */
  pricingRules: Array<PricingRules>;
  /** An aggregate relationship */
  pricingRulesAggregate: PricingRulesAggregate;
};


/** columns and relationships of "price_addons" */
export type PriceAddonsPricingRulesArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};


/** columns and relationships of "price_addons" */
export type PriceAddonsPricingRulesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};

/** aggregated selection of "price_addons" */
export type PriceAddonsAggregate = {
  __typename?: 'PriceAddonsAggregate';
  aggregate?: Maybe<PriceAddonsAggregateFields>;
  nodes: Array<PriceAddons>;
};

/** aggregate fields of "price_addons" */
export type PriceAddonsAggregateFields = {
  __typename?: 'PriceAddonsAggregateFields';
  avg?: Maybe<PriceAddonsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PriceAddonsMaxFields>;
  min?: Maybe<PriceAddonsMinFields>;
  stddev?: Maybe<PriceAddonsStddevFields>;
  stddevPop?: Maybe<PriceAddonsStddevPopFields>;
  stddevSamp?: Maybe<PriceAddonsStddevSampFields>;
  sum?: Maybe<PriceAddonsSumFields>;
  varPop?: Maybe<PriceAddonsVarPopFields>;
  varSamp?: Maybe<PriceAddonsVarSampFields>;
  variance?: Maybe<PriceAddonsVarianceFields>;
};


/** aggregate fields of "price_addons" */
export type PriceAddonsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PriceAddonsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type PriceAddonsAvgFields = {
  __typename?: 'PriceAddonsAvgFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "price_addons". All fields are combined with a logical 'AND'. */
export type PriceAddonsBoolExp = {
  _and?: InputMaybe<Array<PriceAddonsBoolExp>>;
  _not?: InputMaybe<PriceAddonsBoolExp>;
  _or?: InputMaybe<Array<PriceAddonsBoolExp>>;
  addon?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  maxDistance?: InputMaybe<IntComparisonExp>;
  minDistance?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  pricingRules?: InputMaybe<PricingRulesBoolExp>;
};

/** unique or primary key constraints on table "price_addons" */
export enum PriceAddonsConstraint {
  /** unique or primary key constraint on columns "id" */
  PriceAddonsPkey = 'price_addons_pkey'
}

/** input type for incrementing numeric columns in table "price_addons" */
export type PriceAddonsIncInput = {
  addon?: InputMaybe<Scalars['numeric']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  minDistance?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "price_addons" */
export type PriceAddonsInsertInput = {
  addon?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  minDistance?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  pricingRules?: InputMaybe<PricingRulesArrRelInsertInput>;
};

/** aggregate max on columns */
export type PriceAddonsMaxFields = {
  __typename?: 'PriceAddonsMaxFields';
  addon?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  maxDistance?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PriceAddonsMinFields = {
  __typename?: 'PriceAddonsMinFields';
  addon?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  maxDistance?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "price_addons" */
export type PriceAddonsMutationResponse = {
  __typename?: 'PriceAddonsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PriceAddons>;
};

/** input type for inserting object relation for remote table "price_addons" */
export type PriceAddonsObjRelInsertInput = {
  data: PriceAddonsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PriceAddonsOnConflict>;
};

/** on_conflict condition type for table "price_addons" */
export type PriceAddonsOnConflict = {
  constraint: PriceAddonsConstraint;
  update_columns?: Array<PriceAddonsUpdateColumn>;
  where?: InputMaybe<PriceAddonsBoolExp>;
};

/** Ordering options when selecting data from "price_addons". */
export type PriceAddonsOrderBy = {
  addon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDistance?: InputMaybe<OrderBy>;
  minDistance?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  pricingRules_aggregate?: InputMaybe<PricingRulesAggregateOrderBy>;
};

/** primary key columns input for table: price_addons */
export type PriceAddonsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "price_addons" */
export enum PriceAddonsSelectColumn {
  /** column name */
  Addon = 'addon',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDistance = 'maxDistance',
  /** column name */
  MinDistance = 'minDistance',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "price_addons" */
export type PriceAddonsSetInput = {
  addon?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxDistance?: InputMaybe<Scalars['Int']>;
  minDistance?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PriceAddonsStddevFields = {
  __typename?: 'PriceAddonsStddevFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type PriceAddonsStddevPopFields = {
  __typename?: 'PriceAddonsStddevPopFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type PriceAddonsStddevSampFields = {
  __typename?: 'PriceAddonsStddevSampFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type PriceAddonsSumFields = {
  __typename?: 'PriceAddonsSumFields';
  addon?: Maybe<Scalars['numeric']>;
  maxDistance?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
};

/** update columns of table "price_addons" */
export enum PriceAddonsUpdateColumn {
  /** column name */
  Addon = 'addon',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDistance = 'maxDistance',
  /** column name */
  MinDistance = 'minDistance',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type PriceAddonsVarPopFields = {
  __typename?: 'PriceAddonsVarPopFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type PriceAddonsVarSampFields = {
  __typename?: 'PriceAddonsVarSampFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type PriceAddonsVarianceFields = {
  __typename?: 'PriceAddonsVarianceFields';
  addon?: Maybe<Scalars['Float']>;
  maxDistance?: Maybe<Scalars['Float']>;
  minDistance?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "pricing_insurance_bridge" */
export type PricingInsuranceBridge = {
  __typename?: 'PricingInsuranceBridge';
  id: Scalars['uuid'];
  /** An array relationship */
  insurance: Array<InsurancePolicies>;
  /** An aggregate relationship */
  insuranceAggregate: InsurancePoliciesAggregate;
  insurancePolicyId: Scalars['uuid'];
  /** An array relationship */
  pricing: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  pricingAggregate: OrganizationPricingPoliciesAggregate;
  pricingPolicyId: Scalars['uuid'];
};


/** columns and relationships of "pricing_insurance_bridge" */
export type PricingInsuranceBridgeInsuranceArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


/** columns and relationships of "pricing_insurance_bridge" */
export type PricingInsuranceBridgeInsuranceAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


/** columns and relationships of "pricing_insurance_bridge" */
export type PricingInsuranceBridgePricingArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "pricing_insurance_bridge" */
export type PricingInsuranceBridgePricingAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** aggregated selection of "pricing_insurance_bridge" */
export type PricingInsuranceBridgeAggregate = {
  __typename?: 'PricingInsuranceBridgeAggregate';
  aggregate?: Maybe<PricingInsuranceBridgeAggregateFields>;
  nodes: Array<PricingInsuranceBridge>;
};

/** aggregate fields of "pricing_insurance_bridge" */
export type PricingInsuranceBridgeAggregateFields = {
  __typename?: 'PricingInsuranceBridgeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<PricingInsuranceBridgeMaxFields>;
  min?: Maybe<PricingInsuranceBridgeMinFields>;
};


/** aggregate fields of "pricing_insurance_bridge" */
export type PricingInsuranceBridgeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PricingInsuranceBridgeMaxOrderBy>;
  min?: InputMaybe<PricingInsuranceBridgeMinOrderBy>;
};

/** input type for inserting array relation for remote table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeArrRelInsertInput = {
  data: Array<PricingInsuranceBridgeInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PricingInsuranceBridgeOnConflict>;
};

/** Boolean expression to filter rows from the table "pricing_insurance_bridge". All fields are combined with a logical 'AND'. */
export type PricingInsuranceBridgeBoolExp = {
  _and?: InputMaybe<Array<PricingInsuranceBridgeBoolExp>>;
  _not?: InputMaybe<PricingInsuranceBridgeBoolExp>;
  _or?: InputMaybe<Array<PricingInsuranceBridgeBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  insurance?: InputMaybe<InsurancePoliciesBoolExp>;
  insurancePolicyId?: InputMaybe<UuidComparisonExp>;
  pricing?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
  pricingPolicyId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pricing_insurance_bridge" */
export enum PricingInsuranceBridgeConstraint {
  /** unique or primary key constraint on columns "id" */
  PricingInsuranceBridgePkey = 'pricing_insurance_bridge_pkey'
}

/** input type for inserting data into table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  insurance?: InputMaybe<InsurancePoliciesArrRelInsertInput>;
  insurancePolicyId?: InputMaybe<Scalars['uuid']>;
  pricing?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
  pricingPolicyId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PricingInsuranceBridgeMaxFields = {
  __typename?: 'PricingInsuranceBridgeMaxFields';
  id?: Maybe<Scalars['uuid']>;
  insurancePolicyId?: Maybe<Scalars['uuid']>;
  pricingPolicyId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  insurancePolicyId?: InputMaybe<OrderBy>;
  pricingPolicyId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PricingInsuranceBridgeMinFields = {
  __typename?: 'PricingInsuranceBridgeMinFields';
  id?: Maybe<Scalars['uuid']>;
  insurancePolicyId?: Maybe<Scalars['uuid']>;
  pricingPolicyId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  insurancePolicyId?: InputMaybe<OrderBy>;
  pricingPolicyId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeMutationResponse = {
  __typename?: 'PricingInsuranceBridgeMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricingInsuranceBridge>;
};

/** on_conflict condition type for table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeOnConflict = {
  constraint: PricingInsuranceBridgeConstraint;
  update_columns?: Array<PricingInsuranceBridgeUpdateColumn>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};

/** Ordering options when selecting data from "pricing_insurance_bridge". */
export type PricingInsuranceBridgeOrderBy = {
  id?: InputMaybe<OrderBy>;
  insurancePolicyId?: InputMaybe<OrderBy>;
  insurance_aggregate?: InputMaybe<InsurancePoliciesAggregateOrderBy>;
  pricingPolicyId?: InputMaybe<OrderBy>;
  pricing_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
};

/** primary key columns input for table: pricing_insurance_bridge */
export type PricingInsuranceBridgePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "pricing_insurance_bridge" */
export enum PricingInsuranceBridgeSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePolicyId = 'insurancePolicyId',
  /** column name */
  PricingPolicyId = 'pricingPolicyId'
}

/** input type for updating data in table "pricing_insurance_bridge" */
export type PricingInsuranceBridgeSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  insurancePolicyId?: InputMaybe<Scalars['uuid']>;
  pricingPolicyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "pricing_insurance_bridge" */
export enum PricingInsuranceBridgeUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePolicyId = 'insurancePolicyId',
  /** column name */
  PricingPolicyId = 'pricingPolicyId'
}

/** columns and relationships of "pricing_rules" */
export type PricingRules = {
  __typename?: 'PricingRules';
  dynamicPrice?: Maybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Int']>;
  fix?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  maxDistanceM?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['numeric']>;
  minDistanceM?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['numeric']>;
  name: Scalars['String'];
  /** An array relationship */
  organizationPricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  organizationPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
  /** An object relationship */
  priceAddon?: Maybe<PriceAddons>;
  priceAddonId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "pricing_rules" */
export type PricingRulesOrganizationPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


/** columns and relationships of "pricing_rules" */
export type PricingRulesOrganizationPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};

/** aggregated selection of "pricing_rules" */
export type PricingRulesAggregate = {
  __typename?: 'PricingRulesAggregate';
  aggregate?: Maybe<PricingRulesAggregateFields>;
  nodes: Array<PricingRules>;
};

/** aggregate fields of "pricing_rules" */
export type PricingRulesAggregateFields = {
  __typename?: 'PricingRulesAggregateFields';
  avg?: Maybe<PricingRulesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<PricingRulesMaxFields>;
  min?: Maybe<PricingRulesMinFields>;
  stddev?: Maybe<PricingRulesStddevFields>;
  stddevPop?: Maybe<PricingRulesStddevPopFields>;
  stddevSamp?: Maybe<PricingRulesStddevSampFields>;
  sum?: Maybe<PricingRulesSumFields>;
  varPop?: Maybe<PricingRulesVarPopFields>;
  varSamp?: Maybe<PricingRulesVarSampFields>;
  variance?: Maybe<PricingRulesVarianceFields>;
};


/** aggregate fields of "pricing_rules" */
export type PricingRulesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PricingRulesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pricing_rules" */
export type PricingRulesAggregateOrderBy = {
  avg?: InputMaybe<PricingRulesAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PricingRulesMaxOrderBy>;
  min?: InputMaybe<PricingRulesMinOrderBy>;
  stddev?: InputMaybe<PricingRulesStddevOrderBy>;
  stddev_pop?: InputMaybe<PricingRulesStddevPopOrderBy>;
  stddev_samp?: InputMaybe<PricingRulesStddevSampOrderBy>;
  sum?: InputMaybe<PricingRulesSumOrderBy>;
  var_pop?: InputMaybe<PricingRulesVarPopOrderBy>;
  var_samp?: InputMaybe<PricingRulesVarSampOrderBy>;
  variance?: InputMaybe<PricingRulesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "pricing_rules" */
export type PricingRulesArrRelInsertInput = {
  data: Array<PricingRulesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PricingRulesOnConflict>;
};

/** aggregate avg on columns */
export type PricingRulesAvgFields = {
  __typename?: 'PricingRulesAvgFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pricing_rules" */
export type PricingRulesAvgOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "pricing_rules". All fields are combined with a logical 'AND'. */
export type PricingRulesBoolExp = {
  _and?: InputMaybe<Array<PricingRulesBoolExp>>;
  _not?: InputMaybe<PricingRulesBoolExp>;
  _or?: InputMaybe<Array<PricingRulesBoolExp>>;
  dynamicPrice?: InputMaybe<NumericComparisonExp>;
  dynamicPriceDistanceM?: InputMaybe<IntComparisonExp>;
  fix?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  maxDistanceM?: InputMaybe<IntComparisonExp>;
  maxPrice?: InputMaybe<NumericComparisonExp>;
  minDistanceM?: InputMaybe<IntComparisonExp>;
  minPrice?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
  priceAddon?: InputMaybe<PriceAddonsBoolExp>;
  priceAddonId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "pricing_rules" */
export enum PricingRulesConstraint {
  /** unique or primary key constraint on columns "id" */
  PricingRulesPkey = 'pricing_rules_pkey'
}

/** input type for incrementing numeric columns in table "pricing_rules" */
export type PricingRulesIncInput = {
  dynamicPrice?: InputMaybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: InputMaybe<Scalars['Int']>;
  fix?: InputMaybe<Scalars['numeric']>;
  maxDistanceM?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['numeric']>;
  minDistanceM?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "pricing_rules" */
export type PricingRulesInsertInput = {
  dynamicPrice?: InputMaybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: InputMaybe<Scalars['Int']>;
  fix?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxDistanceM?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['numeric']>;
  minDistanceM?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  organizationPricingPolicies?: InputMaybe<OrganizationPricingPoliciesArrRelInsertInput>;
  priceAddon?: InputMaybe<PriceAddonsObjRelInsertInput>;
  priceAddonId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PricingRulesMaxFields = {
  __typename?: 'PricingRulesMaxFields';
  dynamicPrice?: Maybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Int']>;
  fix?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  maxDistanceM?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['numeric']>;
  minDistanceM?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  priceAddonId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "pricing_rules" */
export type PricingRulesMaxOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priceAddonId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PricingRulesMinFields = {
  __typename?: 'PricingRulesMinFields';
  dynamicPrice?: Maybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Int']>;
  fix?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  maxDistanceM?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['numeric']>;
  minDistanceM?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  priceAddonId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "pricing_rules" */
export type PricingRulesMinOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  priceAddonId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "pricing_rules" */
export type PricingRulesMutationResponse = {
  __typename?: 'PricingRulesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PricingRules>;
};

/** input type for inserting object relation for remote table "pricing_rules" */
export type PricingRulesObjRelInsertInput = {
  data: PricingRulesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PricingRulesOnConflict>;
};

/** on_conflict condition type for table "pricing_rules" */
export type PricingRulesOnConflict = {
  constraint: PricingRulesConstraint;
  update_columns?: Array<PricingRulesUpdateColumn>;
  where?: InputMaybe<PricingRulesBoolExp>;
};

/** Ordering options when selecting data from "pricing_rules". */
export type PricingRulesOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationPricingPolicies_aggregate?: InputMaybe<OrganizationPricingPoliciesAggregateOrderBy>;
  priceAddon?: InputMaybe<PriceAddonsOrderBy>;
  priceAddonId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pricing_rules */
export type PricingRulesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "pricing_rules" */
export enum PricingRulesSelectColumn {
  /** column name */
  DynamicPrice = 'dynamicPrice',
  /** column name */
  DynamicPriceDistanceM = 'dynamicPriceDistanceM',
  /** column name */
  Fix = 'fix',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDistanceM = 'maxDistanceM',
  /** column name */
  MaxPrice = 'maxPrice',
  /** column name */
  MinDistanceM = 'minDistanceM',
  /** column name */
  MinPrice = 'minPrice',
  /** column name */
  Name = 'name',
  /** column name */
  PriceAddonId = 'priceAddonId'
}

/** input type for updating data in table "pricing_rules" */
export type PricingRulesSetInput = {
  dynamicPrice?: InputMaybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: InputMaybe<Scalars['Int']>;
  fix?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  maxDistanceM?: InputMaybe<Scalars['Int']>;
  maxPrice?: InputMaybe<Scalars['numeric']>;
  minDistanceM?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  priceAddonId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type PricingRulesStddevFields = {
  __typename?: 'PricingRulesStddevFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pricing_rules" */
export type PricingRulesStddevOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type PricingRulesStddevPopFields = {
  __typename?: 'PricingRulesStddevPopFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pricing_rules" */
export type PricingRulesStddevPopOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type PricingRulesStddevSampFields = {
  __typename?: 'PricingRulesStddevSampFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pricing_rules" */
export type PricingRulesStddevSampOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type PricingRulesSumFields = {
  __typename?: 'PricingRulesSumFields';
  dynamicPrice?: Maybe<Scalars['numeric']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Int']>;
  fix?: Maybe<Scalars['numeric']>;
  maxDistanceM?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['numeric']>;
  minDistanceM?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "pricing_rules" */
export type PricingRulesSumOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** update columns of table "pricing_rules" */
export enum PricingRulesUpdateColumn {
  /** column name */
  DynamicPrice = 'dynamicPrice',
  /** column name */
  DynamicPriceDistanceM = 'dynamicPriceDistanceM',
  /** column name */
  Fix = 'fix',
  /** column name */
  Id = 'id',
  /** column name */
  MaxDistanceM = 'maxDistanceM',
  /** column name */
  MaxPrice = 'maxPrice',
  /** column name */
  MinDistanceM = 'minDistanceM',
  /** column name */
  MinPrice = 'minPrice',
  /** column name */
  Name = 'name',
  /** column name */
  PriceAddonId = 'priceAddonId'
}

/** aggregate var_pop on columns */
export type PricingRulesVarPopFields = {
  __typename?: 'PricingRulesVarPopFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pricing_rules" */
export type PricingRulesVarPopOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type PricingRulesVarSampFields = {
  __typename?: 'PricingRulesVarSampFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pricing_rules" */
export type PricingRulesVarSampOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type PricingRulesVarianceFields = {
  __typename?: 'PricingRulesVarianceFields';
  dynamicPrice?: Maybe<Scalars['Float']>;
  dynamicPriceDistanceM?: Maybe<Scalars['Float']>;
  fix?: Maybe<Scalars['Float']>;
  maxDistanceM?: Maybe<Scalars['Float']>;
  maxPrice?: Maybe<Scalars['Float']>;
  minDistanceM?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pricing_rules" */
export type PricingRulesVarianceOrderBy = {
  dynamicPrice?: InputMaybe<OrderBy>;
  dynamicPriceDistanceM?: InputMaybe<OrderBy>;
  fix?: InputMaybe<OrderBy>;
  maxDistanceM?: InputMaybe<OrderBy>;
  maxPrice?: InputMaybe<OrderBy>;
  minDistanceM?: InputMaybe<OrderBy>;
  minPrice?: InputMaybe<OrderBy>;
};

/** columns and relationships of "processes" */
export type Processes = {
  __typename?: 'Processes';
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tick?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "processes" */
export type ProcessesAggregate = {
  __typename?: 'ProcessesAggregate';
  aggregate?: Maybe<ProcessesAggregateFields>;
  nodes: Array<Processes>;
};

/** aggregate fields of "processes" */
export type ProcessesAggregateFields = {
  __typename?: 'ProcessesAggregateFields';
  avg?: Maybe<ProcessesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProcessesMaxFields>;
  min?: Maybe<ProcessesMinFields>;
  stddev?: Maybe<ProcessesStddevFields>;
  stddevPop?: Maybe<ProcessesStddevPopFields>;
  stddevSamp?: Maybe<ProcessesStddevSampFields>;
  sum?: Maybe<ProcessesSumFields>;
  varPop?: Maybe<ProcessesVarPopFields>;
  varSamp?: Maybe<ProcessesVarSampFields>;
  variance?: Maybe<ProcessesVarianceFields>;
};


/** aggregate fields of "processes" */
export type ProcessesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProcessesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type ProcessesAvgFields = {
  __typename?: 'ProcessesAvgFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "processes". All fields are combined with a logical 'AND'. */
export type ProcessesBoolExp = {
  _and?: InputMaybe<Array<ProcessesBoolExp>>;
  _not?: InputMaybe<ProcessesBoolExp>;
  _or?: InputMaybe<Array<ProcessesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  tick?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "processes" */
export enum ProcessesConstraint {
  /** unique or primary key constraint on columns "id" */
  ProcessesPkey = 'processes_pkey'
}

/** input type for incrementing numeric columns in table "processes" */
export type ProcessesIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  tick?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "processes" */
export type ProcessesInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProcessesMaxFields = {
  __typename?: 'ProcessesMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tick?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type ProcessesMinFields = {
  __typename?: 'ProcessesMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tick?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "processes" */
export type ProcessesMutationResponse = {
  __typename?: 'ProcessesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Processes>;
};

/** on_conflict condition type for table "processes" */
export type ProcessesOnConflict = {
  constraint: ProcessesConstraint;
  update_columns?: Array<ProcessesUpdateColumn>;
  where?: InputMaybe<ProcessesBoolExp>;
};

/** Ordering options when selecting data from "processes". */
export type ProcessesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  tick?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: processes */
export type ProcessesPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "processes" */
export enum ProcessesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tick = 'tick',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "processes" */
export type ProcessesSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  tick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProcessesStddevFields = {
  __typename?: 'ProcessesStddevFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ProcessesStddevPopFields = {
  __typename?: 'ProcessesStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ProcessesStddevSampFields = {
  __typename?: 'ProcessesStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type ProcessesSumFields = {
  __typename?: 'ProcessesSumFields';
  id?: Maybe<Scalars['Int']>;
  tick?: Maybe<Scalars['Int']>;
};

/** update columns of table "processes" */
export enum ProcessesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Tick = 'tick',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type ProcessesVarPopFields = {
  __typename?: 'ProcessesVarPopFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ProcessesVarSampFields = {
  __typename?: 'ProcessesVarSampFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ProcessesVarianceFields = {
  __typename?: 'ProcessesVarianceFields';
  id?: Maybe<Scalars['Float']>;
  tick?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  courierSeriviceHealth: HealthOutput;
};

export enum Role {
  Courier = 'COURIER',
  Guest = 'GUEST',
  OrganizationAdmin = 'ORGANIZATION_ADMIN',
  OrganizationMember = 'ORGANIZATION_MEMBER',
  SuperAdmin = 'SUPER_ADMIN',
  SuperMember = 'SUPER_MEMBER',
  User = 'USER'
}

/** columns and relationships of "Region" */
export type Region = {
  __typename?: 'Region';
  /** An array relationship */
  CourierPayoutSettings: Array<CourierPayoutSettings>;
  /** An aggregate relationship */
  courierPayoutSettingsAggregate: CourierPayoutSettingsAggregate;
  createdAt: Scalars['timestamp'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  polygon: Scalars['jsonb'];
  /** An object relationship */
  region_info?: Maybe<RegionInfo>;
};


/** columns and relationships of "Region" */
export type RegionCourierPayoutSettingsArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


/** columns and relationships of "Region" */
export type RegionCourierPayoutSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


/** columns and relationships of "Region" */
export type RegionPolygonArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Region" */
export type RegionAggregate = {
  __typename?: 'RegionAggregate';
  aggregate?: Maybe<RegionAggregateFields>;
  nodes: Array<Region>;
};

/** aggregate fields of "Region" */
export type RegionAggregateFields = {
  __typename?: 'RegionAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RegionMaxFields>;
  min?: Maybe<RegionMinFields>;
};


/** aggregate fields of "Region" */
export type RegionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RegionAppendInput = {
  polygon?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Region". All fields are combined with a logical 'AND'. */
export type RegionBoolExp = {
  CourierPayoutSettings?: InputMaybe<CourierPayoutSettingsBoolExp>;
  _and?: InputMaybe<Array<RegionBoolExp>>;
  _not?: InputMaybe<RegionBoolExp>;
  _or?: InputMaybe<Array<RegionBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  polygon?: InputMaybe<JsonbComparisonExp>;
  region_info?: InputMaybe<RegionInfoBoolExp>;
};

/** unique or primary key constraints on table "Region" */
export enum RegionConstraint {
  /** unique or primary key constraint on columns "id" */
  RegionPkey = 'Region_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RegionDeleteAtPathInput = {
  polygon?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RegionDeleteElemInput = {
  polygon?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RegionDeleteKeyInput = {
  polygon?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "region_info" */
export type RegionInfo = {
  __typename?: 'RegionInfo';
  /** An object relationship */
  Region: Region;
  distanceFee: Scalars['numeric'];
  dropoffFee: Scalars['numeric'];
  id: Scalars['Int'];
  minimumCourierPayout: Scalars['numeric'];
  ordersCanBeCreated: Scalars['Boolean'];
  ordersPerCourier: Scalars['numeric'];
  pickupFee: Scalars['numeric'];
  regionId: Scalars['String'];
};

/** aggregated selection of "region_info" */
export type RegionInfoAggregate = {
  __typename?: 'RegionInfoAggregate';
  aggregate?: Maybe<RegionInfoAggregateFields>;
  nodes: Array<RegionInfo>;
};

/** aggregate fields of "region_info" */
export type RegionInfoAggregateFields = {
  __typename?: 'RegionInfoAggregateFields';
  avg?: Maybe<RegionInfoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<RegionInfoMaxFields>;
  min?: Maybe<RegionInfoMinFields>;
  stddev?: Maybe<RegionInfoStddevFields>;
  stddevPop?: Maybe<RegionInfoStddevPopFields>;
  stddevSamp?: Maybe<RegionInfoStddevSampFields>;
  sum?: Maybe<RegionInfoSumFields>;
  varPop?: Maybe<RegionInfoVarPopFields>;
  varSamp?: Maybe<RegionInfoVarSampFields>;
  variance?: Maybe<RegionInfoVarianceFields>;
};


/** aggregate fields of "region_info" */
export type RegionInfoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RegionInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type RegionInfoAvgFields = {
  __typename?: 'RegionInfoAvgFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "region_info". All fields are combined with a logical 'AND'. */
export type RegionInfoBoolExp = {
  Region?: InputMaybe<RegionBoolExp>;
  _and?: InputMaybe<Array<RegionInfoBoolExp>>;
  _not?: InputMaybe<RegionInfoBoolExp>;
  _or?: InputMaybe<Array<RegionInfoBoolExp>>;
  distanceFee?: InputMaybe<NumericComparisonExp>;
  dropoffFee?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  minimumCourierPayout?: InputMaybe<NumericComparisonExp>;
  ordersCanBeCreated?: InputMaybe<BooleanComparisonExp>;
  ordersPerCourier?: InputMaybe<NumericComparisonExp>;
  pickupFee?: InputMaybe<NumericComparisonExp>;
  regionId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "region_info" */
export enum RegionInfoConstraint {
  /** unique or primary key constraint on columns "id" */
  RegionInfoPkey = 'region_info_pkey',
  /** unique or primary key constraint on columns "regionId" */
  RegionInfoRegionIdKey = 'region_info_regionId_key'
}

/** input type for incrementing numeric columns in table "region_info" */
export type RegionInfoIncInput = {
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  ordersPerCourier?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "region_info" */
export type RegionInfoInsertInput = {
  Region?: InputMaybe<RegionObjRelInsertInput>;
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  ordersCanBeCreated?: InputMaybe<Scalars['Boolean']>;
  ordersPerCourier?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
  regionId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RegionInfoMaxFields = {
  __typename?: 'RegionInfoMaxFields';
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  ordersPerCourier?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
  regionId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RegionInfoMinFields = {
  __typename?: 'RegionInfoMinFields';
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  ordersPerCourier?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
  regionId?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "region_info" */
export type RegionInfoMutationResponse = {
  __typename?: 'RegionInfoMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RegionInfo>;
};

/** input type for inserting object relation for remote table "region_info" */
export type RegionInfoObjRelInsertInput = {
  data: RegionInfoInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<RegionInfoOnConflict>;
};

/** on_conflict condition type for table "region_info" */
export type RegionInfoOnConflict = {
  constraint: RegionInfoConstraint;
  update_columns?: Array<RegionInfoUpdateColumn>;
  where?: InputMaybe<RegionInfoBoolExp>;
};

/** Ordering options when selecting data from "region_info". */
export type RegionInfoOrderBy = {
  Region?: InputMaybe<RegionOrderBy>;
  distanceFee?: InputMaybe<OrderBy>;
  dropoffFee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimumCourierPayout?: InputMaybe<OrderBy>;
  ordersCanBeCreated?: InputMaybe<OrderBy>;
  ordersPerCourier?: InputMaybe<OrderBy>;
  pickupFee?: InputMaybe<OrderBy>;
  regionId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: region_info */
export type RegionInfoPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "region_info" */
export enum RegionInfoSelectColumn {
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  DropoffFee = 'dropoffFee',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumCourierPayout = 'minimumCourierPayout',
  /** column name */
  OrdersCanBeCreated = 'ordersCanBeCreated',
  /** column name */
  OrdersPerCourier = 'ordersPerCourier',
  /** column name */
  PickupFee = 'pickupFee',
  /** column name */
  RegionId = 'regionId'
}

/** input type for updating data in table "region_info" */
export type RegionInfoSetInput = {
  distanceFee?: InputMaybe<Scalars['numeric']>;
  dropoffFee?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumCourierPayout?: InputMaybe<Scalars['numeric']>;
  ordersCanBeCreated?: InputMaybe<Scalars['Boolean']>;
  ordersPerCourier?: InputMaybe<Scalars['numeric']>;
  pickupFee?: InputMaybe<Scalars['numeric']>;
  regionId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type RegionInfoStddevFields = {
  __typename?: 'RegionInfoStddevFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type RegionInfoStddevPopFields = {
  __typename?: 'RegionInfoStddevPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type RegionInfoStddevSampFields = {
  __typename?: 'RegionInfoStddevSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type RegionInfoSumFields = {
  __typename?: 'RegionInfoSumFields';
  distanceFee?: Maybe<Scalars['numeric']>;
  dropoffFee?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  minimumCourierPayout?: Maybe<Scalars['numeric']>;
  ordersPerCourier?: Maybe<Scalars['numeric']>;
  pickupFee?: Maybe<Scalars['numeric']>;
};

/** update columns of table "region_info" */
export enum RegionInfoUpdateColumn {
  /** column name */
  DistanceFee = 'distanceFee',
  /** column name */
  DropoffFee = 'dropoffFee',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumCourierPayout = 'minimumCourierPayout',
  /** column name */
  OrdersCanBeCreated = 'ordersCanBeCreated',
  /** column name */
  OrdersPerCourier = 'ordersPerCourier',
  /** column name */
  PickupFee = 'pickupFee',
  /** column name */
  RegionId = 'regionId'
}

/** aggregate var_pop on columns */
export type RegionInfoVarPopFields = {
  __typename?: 'RegionInfoVarPopFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type RegionInfoVarSampFields = {
  __typename?: 'RegionInfoVarSampFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type RegionInfoVarianceFields = {
  __typename?: 'RegionInfoVarianceFields';
  distanceFee?: Maybe<Scalars['Float']>;
  dropoffFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  minimumCourierPayout?: Maybe<Scalars['Float']>;
  ordersPerCourier?: Maybe<Scalars['Float']>;
  pickupFee?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "Region" */
export type RegionInsertInput = {
  CourierPayoutSettings?: InputMaybe<CourierPayoutSettingsArrRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  polygon?: InputMaybe<Scalars['jsonb']>;
  region_info?: InputMaybe<RegionInfoObjRelInsertInput>;
};

/** aggregate max on columns */
export type RegionMaxFields = {
  __typename?: 'RegionMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RegionMinFields = {
  __typename?: 'RegionMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Region" */
export type RegionMutationResponse = {
  __typename?: 'RegionMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Region>;
};

/** input type for inserting object relation for remote table "Region" */
export type RegionObjRelInsertInput = {
  data: RegionInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<RegionOnConflict>;
};

/** on_conflict condition type for table "Region" */
export type RegionOnConflict = {
  constraint: RegionConstraint;
  update_columns?: Array<RegionUpdateColumn>;
  where?: InputMaybe<RegionBoolExp>;
};

/** Ordering options when selecting data from "Region". */
export type RegionOrderBy = {
  CourierPayoutSettings_aggregate?: InputMaybe<CourierPayoutSettingsAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  polygon?: InputMaybe<OrderBy>;
  region_info?: InputMaybe<RegionInfoOrderBy>;
};

/** primary key columns input for table: region */
export type RegionPkColumnsInput = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RegionPrependInput = {
  polygon?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Region" */
export enum RegionSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Polygon = 'polygon'
}

/** input type for updating data in table "Region" */
export type RegionSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  polygon?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "Region" */
export enum RegionUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Polygon = 'polygon'
}

/** Robolabs invoice refrances */
export type RobolabsInvoice = {
  __typename?: 'RobolabsInvoice';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  status: RobolabsInvoiceStatusEnum;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "robolabs_invoice" */
export type RobolabsInvoiceAggregate = {
  __typename?: 'RobolabsInvoiceAggregate';
  aggregate?: Maybe<RobolabsInvoiceAggregateFields>;
  nodes: Array<RobolabsInvoice>;
};

/** aggregate fields of "robolabs_invoice" */
export type RobolabsInvoiceAggregateFields = {
  __typename?: 'RobolabsInvoiceAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RobolabsInvoiceMaxFields>;
  min?: Maybe<RobolabsInvoiceMinFields>;
};


/** aggregate fields of "robolabs_invoice" */
export type RobolabsInvoiceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RobolabsInvoiceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "robolabs_invoice". All fields are combined with a logical 'AND'. */
export type RobolabsInvoiceBoolExp = {
  _and?: InputMaybe<Array<RobolabsInvoiceBoolExp>>;
  _not?: InputMaybe<RobolabsInvoiceBoolExp>;
  _or?: InputMaybe<Array<RobolabsInvoiceBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  status?: InputMaybe<RobolabsinvoicestatusenumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "robolabs_invoice" */
export enum RobolabsInvoiceConstraint {
  /** unique or primary key constraint on columns "id" */
  RobolabsInvoicePkey = 'robolabs_invoice_pkey'
}

/** input type for inserting data into table "robolabs_invoice" */
export type RobolabsInvoiceInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<RobolabsInvoiceStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type RobolabsInvoiceMaxFields = {
  __typename?: 'RobolabsInvoiceMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type RobolabsInvoiceMinFields = {
  __typename?: 'RobolabsInvoiceMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "robolabs_invoice" */
export type RobolabsInvoiceMutationResponse = {
  __typename?: 'RobolabsInvoiceMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RobolabsInvoice>;
};

/** on_conflict condition type for table "robolabs_invoice" */
export type RobolabsInvoiceOnConflict = {
  constraint: RobolabsInvoiceConstraint;
  update_columns?: Array<RobolabsInvoiceUpdateColumn>;
  where?: InputMaybe<RobolabsInvoiceBoolExp>;
};

/** Ordering options when selecting data from "robolabs_invoice". */
export type RobolabsInvoiceOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: robolabs_invoice */
export type RobolabsInvoicePkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "robolabs_invoice" */
export enum RobolabsInvoiceSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "robolabs_invoice" */
export type RobolabsInvoiceSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<RobolabsInvoiceStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "robolabs_invoice_status" */
export type RobolabsInvoiceStatus = {
  __typename?: 'RobolabsInvoiceStatus';
  status: Scalars['String'];
};

/** aggregated selection of "robolabs_invoice_status" */
export type RobolabsInvoiceStatusAggregate = {
  __typename?: 'RobolabsInvoiceStatusAggregate';
  aggregate?: Maybe<RobolabsInvoiceStatusAggregateFields>;
  nodes: Array<RobolabsInvoiceStatus>;
};

/** aggregate fields of "robolabs_invoice_status" */
export type RobolabsInvoiceStatusAggregateFields = {
  __typename?: 'RobolabsInvoiceStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<RobolabsInvoiceStatusMaxFields>;
  min?: Maybe<RobolabsInvoiceStatusMinFields>;
};


/** aggregate fields of "robolabs_invoice_status" */
export type RobolabsInvoiceStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RobolabsInvoiceStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "robolabs_invoice_status". All fields are combined with a logical 'AND'. */
export type RobolabsInvoiceStatusBoolExp = {
  _and?: InputMaybe<Array<RobolabsInvoiceStatusBoolExp>>;
  _not?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
  _or?: InputMaybe<Array<RobolabsInvoiceStatusBoolExp>>;
  status?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "robolabs_invoice_status" */
export enum RobolabsInvoiceStatusConstraint {
  /** unique or primary key constraint on columns "status" */
  RobolabsInvoiceStatusPkey = 'robolabs_invoice_status_pkey'
}

export enum RobolabsInvoiceStatusEnum {
  Created = 'CREATED'
}

/** input type for inserting data into table "robolabs_invoice_status" */
export type RobolabsInvoiceStatusInsertInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type RobolabsInvoiceStatusMaxFields = {
  __typename?: 'RobolabsInvoiceStatusMaxFields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type RobolabsInvoiceStatusMinFields = {
  __typename?: 'RobolabsInvoiceStatusMinFields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "robolabs_invoice_status" */
export type RobolabsInvoiceStatusMutationResponse = {
  __typename?: 'RobolabsInvoiceStatusMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<RobolabsInvoiceStatus>;
};

/** on_conflict condition type for table "robolabs_invoice_status" */
export type RobolabsInvoiceStatusOnConflict = {
  constraint: RobolabsInvoiceStatusConstraint;
  update_columns?: Array<RobolabsInvoiceStatusUpdateColumn>;
  where?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
};

/** Ordering options when selecting data from "robolabs_invoice_status". */
export type RobolabsInvoiceStatusOrderBy = {
  status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: robolabs_invoice_status */
export type RobolabsInvoiceStatusPkColumnsInput = {
  status: Scalars['String'];
};

/** select columns of table "robolabs_invoice_status" */
export enum RobolabsInvoiceStatusSelectColumn {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "robolabs_invoice_status" */
export type RobolabsInvoiceStatusSetInput = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "robolabs_invoice_status" */
export enum RobolabsInvoiceStatusUpdateColumn {
  /** column name */
  Status = 'status'
}

/** update columns of table "robolabs_invoice" */
export enum RobolabsInvoiceUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Boolean expression to compare columns of type "RobolabsInvoiceStatusEnum". All fields are combined with logical 'AND'. */
export type RobolabsinvoicestatusenumComparisonExp = {
  _eq?: InputMaybe<RobolabsInvoiceStatusEnum>;
  _in?: InputMaybe<Array<RobolabsInvoiceStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<RobolabsInvoiceStatusEnum>;
  _nin?: InputMaybe<Array<RobolabsInvoiceStatusEnum>>;
};

/** columns and relationships of "sms_messages" */
export type SmsMessages = {
  __typename?: 'SmsMessages';
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  orderId: Scalars['String'];
  to: Scalars['String'];
  type: SmsMessagesTypesEnum;
};

/** aggregated selection of "sms_messages" */
export type SmsMessagesAggregate = {
  __typename?: 'SmsMessagesAggregate';
  aggregate?: Maybe<SmsMessagesAggregateFields>;
  nodes: Array<SmsMessages>;
};

/** aggregate fields of "sms_messages" */
export type SmsMessagesAggregateFields = {
  __typename?: 'SmsMessagesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SmsMessagesMaxFields>;
  min?: Maybe<SmsMessagesMinFields>;
};


/** aggregate fields of "sms_messages" */
export type SmsMessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmsMessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sms_messages". All fields are combined with a logical 'AND'. */
export type SmsMessagesBoolExp = {
  _and?: InputMaybe<Array<SmsMessagesBoolExp>>;
  _not?: InputMaybe<SmsMessagesBoolExp>;
  _or?: InputMaybe<Array<SmsMessagesBoolExp>>;
  content?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  to?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<SmsmessagestypesenumComparisonExp>;
};

/** unique or primary key constraints on table "sms_messages" */
export enum SmsMessagesConstraint {
  /** unique or primary key constraint on columns "order_id", "type" */
  SmsMessagesOrderIdTypeKey = 'sms_messages_order_id_type_key',
  /** unique or primary key constraint on columns "id" */
  SmsMessagesPkey = 'sms_messages_pkey'
}

/** input type for inserting data into table "sms_messages" */
export type SmsMessagesInsertInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SmsMessagesTypesEnum>;
};

/** aggregate max on columns */
export type SmsMessagesMaxFields = {
  __typename?: 'SmsMessagesMaxFields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SmsMessagesMinFields = {
  __typename?: 'SmsMessagesMinFields';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  orderId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sms_messages" */
export type SmsMessagesMutationResponse = {
  __typename?: 'SmsMessagesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmsMessages>;
};

/** on_conflict condition type for table "sms_messages" */
export type SmsMessagesOnConflict = {
  constraint: SmsMessagesConstraint;
  update_columns?: Array<SmsMessagesUpdateColumn>;
  where?: InputMaybe<SmsMessagesBoolExp>;
};

/** Ordering options when selecting data from "sms_messages". */
export type SmsMessagesOrderBy = {
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sms_messages */
export type SmsMessagesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "sms_messages" */
export enum SmsMessagesSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "sms_messages" */
export type SmsMessagesSetInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  orderId?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SmsMessagesTypesEnum>;
};

/** columns and relationships of "sms_messages_types" */
export type SmsMessagesTypes = {
  __typename?: 'SmsMessagesTypes';
  type: Scalars['String'];
};

/** aggregated selection of "sms_messages_types" */
export type SmsMessagesTypesAggregate = {
  __typename?: 'SmsMessagesTypesAggregate';
  aggregate?: Maybe<SmsMessagesTypesAggregateFields>;
  nodes: Array<SmsMessagesTypes>;
};

/** aggregate fields of "sms_messages_types" */
export type SmsMessagesTypesAggregateFields = {
  __typename?: 'SmsMessagesTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SmsMessagesTypesMaxFields>;
  min?: Maybe<SmsMessagesTypesMinFields>;
};


/** aggregate fields of "sms_messages_types" */
export type SmsMessagesTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SmsMessagesTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sms_messages_types". All fields are combined with a logical 'AND'. */
export type SmsMessagesTypesBoolExp = {
  _and?: InputMaybe<Array<SmsMessagesTypesBoolExp>>;
  _not?: InputMaybe<SmsMessagesTypesBoolExp>;
  _or?: InputMaybe<Array<SmsMessagesTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "sms_messages_types" */
export enum SmsMessagesTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  SmsMessagesTypesPkey = 'sms_messages_types_pkey'
}

export enum SmsMessagesTypesEnum {
  OrderEta = 'ORDER_ETA',
  OrderFailed = 'ORDER_FAILED',
  OrderStarted = 'ORDER_STARTED'
}

/** input type for inserting data into table "sms_messages_types" */
export type SmsMessagesTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SmsMessagesTypesMaxFields = {
  __typename?: 'SmsMessagesTypesMaxFields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SmsMessagesTypesMinFields = {
  __typename?: 'SmsMessagesTypesMinFields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "sms_messages_types" */
export type SmsMessagesTypesMutationResponse = {
  __typename?: 'SmsMessagesTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<SmsMessagesTypes>;
};

/** on_conflict condition type for table "sms_messages_types" */
export type SmsMessagesTypesOnConflict = {
  constraint: SmsMessagesTypesConstraint;
  update_columns?: Array<SmsMessagesTypesUpdateColumn>;
  where?: InputMaybe<SmsMessagesTypesBoolExp>;
};

/** Ordering options when selecting data from "sms_messages_types". */
export type SmsMessagesTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sms_messages_types */
export type SmsMessagesTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "sms_messages_types" */
export enum SmsMessagesTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "sms_messages_types" */
export type SmsMessagesTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sms_messages_types" */
export enum SmsMessagesTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

/** update columns of table "sms_messages" */
export enum SmsMessagesUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

/** Boolean expression to compare columns of type "SmsMessagesTypesEnum". All fields are combined with logical 'AND'. */
export type SmsmessagestypesenumComparisonExp = {
  _eq?: InputMaybe<SmsMessagesTypesEnum>;
  _in?: InputMaybe<Array<SmsMessagesTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SmsMessagesTypesEnum>;
  _nin?: InputMaybe<Array<SmsMessagesTypesEnum>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "Task" */
export type Task = {
  __typename?: 'Task';
  /** An object relationship */
  address: Address;
  addressId: Scalars['String'];
  assignedAt?: Maybe<Scalars['timestamp']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  nearBy?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  order: Order;
  orderId: Scalars['String'];
  startedAt?: Maybe<Scalars['timestamp']>;
  status: Scalars['TaskStatus'];
  /** An array relationship */
  taskETA: Array<Tasketa>;
  /** An aggregate relationship */
  taskETAAggregate: TasketaAggregate;
  /** An object relationship */
  taskSeq?: Maybe<TaskSeq>;
  taskType: Scalars['TaskType'];
  /** An array relationship */
  trips: Array<Trip>;
  /** An aggregate relationship */
  tripsAggregate: TripAggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Task" */
export type TaskTaskEtaArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


/** columns and relationships of "Task" */
export type TaskTaskEtaAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


/** columns and relationships of "Task" */
export type TaskTripsArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


/** columns and relationships of "Task" */
export type TaskTripsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};

/** aggregated selection of "Task" */
export type TaskAggregate = {
  __typename?: 'TaskAggregate';
  aggregate?: Maybe<TaskAggregateFields>;
  nodes: Array<Task>;
};

/** aggregate fields of "Task" */
export type TaskAggregateFields = {
  __typename?: 'TaskAggregateFields';
  avg?: Maybe<TaskAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TaskMaxFields>;
  min?: Maybe<TaskMinFields>;
  stddev?: Maybe<TaskStddevFields>;
  stddevPop?: Maybe<TaskStddevPopFields>;
  stddevSamp?: Maybe<TaskStddevSampFields>;
  sum?: Maybe<TaskSumFields>;
  varPop?: Maybe<TaskVarPopFields>;
  varSamp?: Maybe<TaskVarSampFields>;
  variance?: Maybe<TaskVarianceFields>;
};


/** aggregate fields of "Task" */
export type TaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Task" */
export type TaskAggregateOrderBy = {
  avg?: InputMaybe<TaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaskMaxOrderBy>;
  min?: InputMaybe<TaskMinOrderBy>;
  stddev?: InputMaybe<TaskStddevOrderBy>;
  stddev_pop?: InputMaybe<TaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TaskStddevSampOrderBy>;
  sum?: InputMaybe<TaskSumOrderBy>;
  var_pop?: InputMaybe<TaskVarPopOrderBy>;
  var_samp?: InputMaybe<TaskVarSampOrderBy>;
  variance?: InputMaybe<TaskVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "Task" */
export type TaskArrRelInsertInput = {
  data: Array<TaskInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TaskOnConflict>;
};

/** aggregate avg on columns */
export type TaskAvgFields = {
  __typename?: 'TaskAvgFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Task" */
export type TaskAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Task". All fields are combined with a logical 'AND'. */
export type TaskBoolExp = {
  _and?: InputMaybe<Array<TaskBoolExp>>;
  _not?: InputMaybe<TaskBoolExp>;
  _or?: InputMaybe<Array<TaskBoolExp>>;
  address?: InputMaybe<AddressBoolExp>;
  addressId?: InputMaybe<StringComparisonExp>;
  assignedAt?: InputMaybe<TimestampComparisonExp>;
  completedAt?: InputMaybe<TimestampComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  nearBy?: InputMaybe<BooleanComparisonExp>;
  order?: InputMaybe<OrderBoolExp>;
  orderId?: InputMaybe<StringComparisonExp>;
  startedAt?: InputMaybe<TimestampComparisonExp>;
  status?: InputMaybe<TaskstatusComparisonExp>;
  taskETA?: InputMaybe<TasketaBoolExp>;
  taskSeq?: InputMaybe<TaskSeqBoolExp>;
  taskType?: InputMaybe<TasktypeComparisonExp>;
  trips?: InputMaybe<TripBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Task" */
export enum TaskConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'Task_pkey'
}

/** input type for incrementing numeric columns in table "Task" */
export type TaskIncInput = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Task" */
export type TaskInsertInput = {
  address?: InputMaybe<AddressObjRelInsertInput>;
  addressId?: InputMaybe<Scalars['String']>;
  assignedAt?: InputMaybe<Scalars['timestamp']>;
  completedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nearBy?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<OrderObjRelInsertInput>;
  orderId?: InputMaybe<Scalars['String']>;
  startedAt?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['TaskStatus']>;
  taskETA?: InputMaybe<TasketaArrRelInsertInput>;
  taskSeq?: InputMaybe<TaskSeqObjRelInsertInput>;
  taskType?: InputMaybe<Scalars['TaskType']>;
  trips?: InputMaybe<TripArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TaskMaxFields = {
  __typename?: 'TaskMaxFields';
  addressId?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['timestamp']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['TaskStatus']>;
  taskType?: Maybe<Scalars['TaskType']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Task" */
export type TaskMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  assignedAt?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  taskType?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TaskMinFields = {
  __typename?: 'TaskMinFields';
  addressId?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['timestamp']>;
  completedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['TaskStatus']>;
  taskType?: Maybe<Scalars['TaskType']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Task" */
export type TaskMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  assignedAt?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderId?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  taskType?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Task" */
export type TaskMutationResponse = {
  __typename?: 'TaskMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task>;
};

/** input type for inserting object relation for remote table "Task" */
export type TaskObjRelInsertInput = {
  data: TaskInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TaskOnConflict>;
};

/** on_conflict condition type for table "Task" */
export type TaskOnConflict = {
  constraint: TaskConstraint;
  update_columns?: Array<TaskUpdateColumn>;
  where?: InputMaybe<TaskBoolExp>;
};

/** Ordering options when selecting data from "Task". */
export type TaskOrderBy = {
  address?: InputMaybe<AddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  assignedAt?: InputMaybe<OrderBy>;
  completedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  nearBy?: InputMaybe<OrderBy>;
  order?: InputMaybe<OrderOrderBy>;
  orderId?: InputMaybe<OrderBy>;
  startedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  taskETA_aggregate?: InputMaybe<TasketaAggregateOrderBy>;
  taskSeq?: InputMaybe<TaskSeqOrderBy>;
  taskType?: InputMaybe<OrderBy>;
  trips_aggregate?: InputMaybe<TripAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: task */
export type TaskPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "Task" */
export enum TaskSelectColumn {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  AssignedAt = 'assignedAt',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NearBy = 'nearBy',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "task_seq" */
export type TaskSeq = {
  __typename?: 'TaskSeq';
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  seq: Scalars['Int'];
  /** An object relationship */
  task: Task;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "task_seq" */
export type TaskSeqAggregate = {
  __typename?: 'TaskSeqAggregate';
  aggregate?: Maybe<TaskSeqAggregateFields>;
  nodes: Array<TaskSeq>;
};

/** aggregate fields of "task_seq" */
export type TaskSeqAggregateFields = {
  __typename?: 'TaskSeqAggregateFields';
  avg?: Maybe<TaskSeqAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TaskSeqMaxFields>;
  min?: Maybe<TaskSeqMinFields>;
  stddev?: Maybe<TaskSeqStddevFields>;
  stddevPop?: Maybe<TaskSeqStddevPopFields>;
  stddevSamp?: Maybe<TaskSeqStddevSampFields>;
  sum?: Maybe<TaskSeqSumFields>;
  varPop?: Maybe<TaskSeqVarPopFields>;
  varSamp?: Maybe<TaskSeqVarSampFields>;
  variance?: Maybe<TaskSeqVarianceFields>;
};


/** aggregate fields of "task_seq" */
export type TaskSeqAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaskSeqSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type TaskSeqAvgFields = {
  __typename?: 'TaskSeqAvgFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "task_seq". All fields are combined with a logical 'AND'. */
export type TaskSeqBoolExp = {
  _and?: InputMaybe<Array<TaskSeqBoolExp>>;
  _not?: InputMaybe<TaskSeqBoolExp>;
  _or?: InputMaybe<Array<TaskSeqBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  seq?: InputMaybe<IntComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "task_seq" */
export enum TaskSeqConstraint {
  /** unique or primary key constraint on columns "id" */
  TaskSeqPkey = 'task_seq_pkey'
}

/** input type for incrementing numeric columns in table "task_seq" */
export type TaskSeqIncInput = {
  id?: InputMaybe<Scalars['Int']>;
  seq?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "task_seq" */
export type TaskSeqInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  seq?: InputMaybe<Scalars['Int']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TaskSeqMaxFields = {
  __typename?: 'TaskSeqMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  seq?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type TaskSeqMinFields = {
  __typename?: 'TaskSeqMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  seq?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "task_seq" */
export type TaskSeqMutationResponse = {
  __typename?: 'TaskSeqMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<TaskSeq>;
};

/** input type for inserting object relation for remote table "task_seq" */
export type TaskSeqObjRelInsertInput = {
  data: TaskSeqInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TaskSeqOnConflict>;
};

/** on_conflict condition type for table "task_seq" */
export type TaskSeqOnConflict = {
  constraint: TaskSeqConstraint;
  update_columns?: Array<TaskSeqUpdateColumn>;
  where?: InputMaybe<TaskSeqBoolExp>;
};

/** Ordering options when selecting data from "task_seq". */
export type TaskSeqOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  seq?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: task_seq */
export type TaskSeqPkColumnsInput = {
  id: Scalars['Int'];
};

/** select columns of table "task_seq" */
export enum TaskSeqSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Seq = 'seq',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "task_seq" */
export type TaskSeqSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  seq?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TaskSeqStddevFields = {
  __typename?: 'TaskSeqStddevFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type TaskSeqStddevPopFields = {
  __typename?: 'TaskSeqStddevPopFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type TaskSeqStddevSampFields = {
  __typename?: 'TaskSeqStddevSampFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type TaskSeqSumFields = {
  __typename?: 'TaskSeqSumFields';
  id?: Maybe<Scalars['Int']>;
  seq?: Maybe<Scalars['Int']>;
};

/** update columns of table "task_seq" */
export enum TaskSeqUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Seq = 'seq',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type TaskSeqVarPopFields = {
  __typename?: 'TaskSeqVarPopFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type TaskSeqVarSampFields = {
  __typename?: 'TaskSeqVarSampFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type TaskSeqVarianceFields = {
  __typename?: 'TaskSeqVarianceFields';
  id?: Maybe<Scalars['Float']>;
  seq?: Maybe<Scalars['Float']>;
};

/** input type for updating data in table "Task" */
export type TaskSetInput = {
  addressId?: InputMaybe<Scalars['String']>;
  assignedAt?: InputMaybe<Scalars['timestamp']>;
  completedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  nearBy?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['String']>;
  startedAt?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['TaskStatus']>;
  taskType?: InputMaybe<Scalars['TaskType']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TaskStddevFields = {
  __typename?: 'TaskStddevFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Task" */
export type TaskStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TaskStddevPopFields = {
  __typename?: 'TaskStddevPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Task" */
export type TaskStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TaskStddevSampFields = {
  __typename?: 'TaskStddevSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Task" */
export type TaskStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TaskSumFields = {
  __typename?: 'TaskSumFields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Task" */
export type TaskSumOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "Task" */
export enum TaskUpdateColumn {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  AssignedAt = 'assignedAt',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  NearBy = 'nearBy',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type TaskVarPopFields = {
  __typename?: 'TaskVarPopFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Task" */
export type TaskVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TaskVarSampFields = {
  __typename?: 'TaskVarSampFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Task" */
export type TaskVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TaskVarianceFields = {
  __typename?: 'TaskVarianceFields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Task" */
export type TaskVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "TaskETA" */
export type Tasketa = {
  __typename?: 'Tasketa';
  arrival: Scalars['timestamptz'];
  createdAt: Scalars['timestamp'];
  distance: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  task: Task;
  type: Scalars['TaskETAType'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "TaskETA" */
export type TasketaAggregate = {
  __typename?: 'TasketaAggregate';
  aggregate?: Maybe<TasketaAggregateFields>;
  nodes: Array<Tasketa>;
};

/** aggregate fields of "TaskETA" */
export type TasketaAggregateFields = {
  __typename?: 'TasketaAggregateFields';
  avg?: Maybe<TasketaAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TasketaMaxFields>;
  min?: Maybe<TasketaMinFields>;
  stddev?: Maybe<TasketaStddevFields>;
  stddevPop?: Maybe<TasketaStddevPopFields>;
  stddevSamp?: Maybe<TasketaStddevSampFields>;
  sum?: Maybe<TasketaSumFields>;
  varPop?: Maybe<TasketaVarPopFields>;
  varSamp?: Maybe<TasketaVarSampFields>;
  variance?: Maybe<TasketaVarianceFields>;
};


/** aggregate fields of "TaskETA" */
export type TasketaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TasketaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "TaskETA" */
export type TasketaAggregateOrderBy = {
  avg?: InputMaybe<TasketaAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TasketaMaxOrderBy>;
  min?: InputMaybe<TasketaMinOrderBy>;
  stddev?: InputMaybe<TasketaStddevOrderBy>;
  stddev_pop?: InputMaybe<TasketaStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TasketaStddevSampOrderBy>;
  sum?: InputMaybe<TasketaSumOrderBy>;
  var_pop?: InputMaybe<TasketaVarPopOrderBy>;
  var_samp?: InputMaybe<TasketaVarSampOrderBy>;
  variance?: InputMaybe<TasketaVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "TaskETA" */
export type TasketaArrRelInsertInput = {
  data: Array<TasketaInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TasketaOnConflict>;
};

/** aggregate avg on columns */
export type TasketaAvgFields = {
  __typename?: 'TasketaAvgFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "TaskETA" */
export type TasketaAvgOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "TaskETA". All fields are combined with a logical 'AND'. */
export type TasketaBoolExp = {
  _and?: InputMaybe<Array<TasketaBoolExp>>;
  _not?: InputMaybe<TasketaBoolExp>;
  _or?: InputMaybe<Array<TasketaBoolExp>>;
  arrival?: InputMaybe<TimestamptzComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  distance?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  type?: InputMaybe<TasketatypeComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "TaskETA" */
export enum TasketaConstraint {
  /** unique or primary key constraint on columns "id", "type" */
  TaskEtaIdTypeKey = 'TaskETA_id_type_key'
}

/** input type for incrementing numeric columns in table "TaskETA" */
export type TasketaIncInput = {
  distance?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "TaskETA" */
export type TasketaInsertInput = {
  arrival?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  distance?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  type?: InputMaybe<Scalars['TaskETAType']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TasketaMaxFields = {
  __typename?: 'TasketaMaxFields';
  arrival?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  distance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['TaskETAType']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "TaskETA" */
export type TasketaMaxOrderBy = {
  arrival?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TasketaMinFields = {
  __typename?: 'TasketaMinFields';
  arrival?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  distance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['TaskETAType']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "TaskETA" */
export type TasketaMinOrderBy = {
  arrival?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "TaskETA" */
export type TasketaMutationResponse = {
  __typename?: 'TasketaMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasketa>;
};

/** on_conflict condition type for table "TaskETA" */
export type TasketaOnConflict = {
  constraint: TasketaConstraint;
  update_columns?: Array<TasketaUpdateColumn>;
  where?: InputMaybe<TasketaBoolExp>;
};

/** Ordering options when selecting data from "TaskETA". */
export type TasketaOrderBy = {
  arrival?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "TaskETA" */
export enum TasketaSelectColumn {
  /** column name */
  Arrival = 'arrival',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "TaskETA" */
export type TasketaSetInput = {
  arrival?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  distance?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['TaskETAType']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TasketaStddevFields = {
  __typename?: 'TasketaStddevFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "TaskETA" */
export type TasketaStddevOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TasketaStddevPopFields = {
  __typename?: 'TasketaStddevPopFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "TaskETA" */
export type TasketaStddevPopOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TasketaStddevSampFields = {
  __typename?: 'TasketaStddevSampFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "TaskETA" */
export type TasketaStddevSampOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TasketaSumFields = {
  __typename?: 'TasketaSumFields';
  distance?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "TaskETA" */
export type TasketaSumOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "TaskETA" */
export enum TasketaUpdateColumn {
  /** column name */
  Arrival = 'arrival',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type TasketaVarPopFields = {
  __typename?: 'TasketaVarPopFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "TaskETA" */
export type TasketaVarPopOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TasketaVarSampFields = {
  __typename?: 'TasketaVarSampFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "TaskETA" */
export type TasketaVarSampOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TasketaVarianceFields = {
  __typename?: 'TasketaVarianceFields';
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "TaskETA" */
export type TasketaVarianceOrderBy = {
  distance?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "TaskETAType". All fields are combined with logical 'AND'. */
export type TasketatypeComparisonExp = {
  _eq?: InputMaybe<Scalars['TaskETAType']>;
  _gt?: InputMaybe<Scalars['TaskETAType']>;
  _gte?: InputMaybe<Scalars['TaskETAType']>;
  _in?: InputMaybe<Array<Scalars['TaskETAType']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['TaskETAType']>;
  _lte?: InputMaybe<Scalars['TaskETAType']>;
  _neq?: InputMaybe<Scalars['TaskETAType']>;
  _nin?: InputMaybe<Array<Scalars['TaskETAType']>>;
};

/** Boolean expression to compare columns of type "TaskStatus". All fields are combined with logical 'AND'. */
export type TaskstatusComparisonExp = {
  _eq?: InputMaybe<Scalars['TaskStatus']>;
  _gt?: InputMaybe<Scalars['TaskStatus']>;
  _gte?: InputMaybe<Scalars['TaskStatus']>;
  _in?: InputMaybe<Array<Scalars['TaskStatus']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['TaskStatus']>;
  _lte?: InputMaybe<Scalars['TaskStatus']>;
  _neq?: InputMaybe<Scalars['TaskStatus']>;
  _nin?: InputMaybe<Array<Scalars['TaskStatus']>>;
};

/** Boolean expression to compare columns of type "TaskType". All fields are combined with logical 'AND'. */
export type TasktypeComparisonExp = {
  _eq?: InputMaybe<Scalars['TaskType']>;
  _gt?: InputMaybe<Scalars['TaskType']>;
  _gte?: InputMaybe<Scalars['TaskType']>;
  _in?: InputMaybe<Array<Scalars['TaskType']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['TaskType']>;
  _lte?: InputMaybe<Scalars['TaskType']>;
  _neq?: InputMaybe<Scalars['TaskType']>;
  _nin?: InputMaybe<Array<Scalars['TaskType']>>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']>;
  _gt?: InputMaybe<Scalars['time']>;
  _gte?: InputMaybe<Scalars['time']>;
  _in?: InputMaybe<Array<Scalars['time']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['time']>;
  _lte?: InputMaybe<Scalars['time']>;
  _neq?: InputMaybe<Scalars['time']>;
  _nin?: InputMaybe<Array<Scalars['time']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _cast?: InputMaybe<Timestamp_Cast_Exp>;
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _cast?: InputMaybe<Timestamptz_Cast_Exp>;
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type TimetzComparisonExp = {
  _cast?: InputMaybe<Timetz_Cast_Exp>;
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** Boolean expression to compare columns of type "TimeZone". All fields are combined with logical 'AND'. */
export type TimezoneComparisonExp = {
  _eq?: InputMaybe<Scalars['TimeZone']>;
  _gt?: InputMaybe<Scalars['TimeZone']>;
  _gte?: InputMaybe<Scalars['TimeZone']>;
  _in?: InputMaybe<Array<Scalars['TimeZone']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['TimeZone']>;
  _lte?: InputMaybe<Scalars['TimeZone']>;
  _neq?: InputMaybe<Scalars['TimeZone']>;
  _nin?: InputMaybe<Array<Scalars['TimeZone']>>;
};

export enum TokenRole {
  Admin = 'ADMIN',
  ApiUserBasic = 'API_USER_BASIC'
}

/** columns and relationships of "Trip" */
export type Trip = {
  __typename?: 'Trip';
  completedAt?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  courier: Courier;
  courierId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  deliveryRate: Deliveryrate;
  distance: Scalars['float8'];
  duration: Scalars['Int'];
  geometry: Scalars['String'];
  locationUpdate?: Maybe<Scalars['_jsonb']>;
  rateId: Scalars['String'];
  /** An object relationship */
  task: Task;
  taskId: Scalars['Int'];
  updateAt: Scalars['timestamptz'];
};

/** aggregated selection of "Trip" */
export type TripAggregate = {
  __typename?: 'TripAggregate';
  aggregate?: Maybe<TripAggregateFields>;
  nodes: Array<Trip>;
};

/** aggregate fields of "Trip" */
export type TripAggregateFields = {
  __typename?: 'TripAggregateFields';
  avg?: Maybe<TripAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<TripMaxFields>;
  min?: Maybe<TripMinFields>;
  stddev?: Maybe<TripStddevFields>;
  stddevPop?: Maybe<TripStddevPopFields>;
  stddevSamp?: Maybe<TripStddevSampFields>;
  sum?: Maybe<TripSumFields>;
  varPop?: Maybe<TripVarPopFields>;
  varSamp?: Maybe<TripVarSampFields>;
  variance?: Maybe<TripVarianceFields>;
};


/** aggregate fields of "Trip" */
export type TripAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TripSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Trip" */
export type TripAggregateOrderBy = {
  avg?: InputMaybe<TripAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TripMaxOrderBy>;
  min?: InputMaybe<TripMinOrderBy>;
  stddev?: InputMaybe<TripStddevOrderBy>;
  stddev_pop?: InputMaybe<TripStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TripStddevSampOrderBy>;
  sum?: InputMaybe<TripSumOrderBy>;
  var_pop?: InputMaybe<TripVarPopOrderBy>;
  var_samp?: InputMaybe<TripVarSampOrderBy>;
  variance?: InputMaybe<TripVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "Trip" */
export type TripArrRelInsertInput = {
  data: Array<TripInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TripOnConflict>;
};

/** aggregate avg on columns */
export type TripAvgFields = {
  __typename?: 'TripAvgFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Trip" */
export type TripAvgOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Trip". All fields are combined with a logical 'AND'. */
export type TripBoolExp = {
  _and?: InputMaybe<Array<TripBoolExp>>;
  _not?: InputMaybe<TripBoolExp>;
  _or?: InputMaybe<Array<TripBoolExp>>;
  completedAt?: InputMaybe<TimestampComparisonExp>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deliveryRate?: InputMaybe<DeliveryrateBoolExp>;
  distance?: InputMaybe<Float8ComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  geometry?: InputMaybe<StringComparisonExp>;
  locationUpdate?: InputMaybe<_JsonbComparisonExp>;
  rateId?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<TaskBoolExp>;
  taskId?: InputMaybe<IntComparisonExp>;
  updateAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "Trip" */
export enum TripConstraint {
  /** unique or primary key constraint on columns "courierId", "taskId" */
  TripTaskIdCourierIdKey = 'Trip_taskId_courierId_key'
}

/** input type for incrementing numeric columns in table "Trip" */
export type TripIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
  distance?: InputMaybe<Scalars['float8']>;
  duration?: InputMaybe<Scalars['Int']>;
  taskId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Trip" */
export type TripInsertInput = {
  completedAt?: InputMaybe<Scalars['timestamp']>;
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  deliveryRate?: InputMaybe<DeliveryrateObjRelInsertInput>;
  distance?: InputMaybe<Scalars['float8']>;
  duration?: InputMaybe<Scalars['Int']>;
  geometry?: InputMaybe<Scalars['String']>;
  locationUpdate?: InputMaybe<Scalars['_jsonb']>;
  rateId?: InputMaybe<Scalars['String']>;
  task?: InputMaybe<TaskObjRelInsertInput>;
  taskId?: InputMaybe<Scalars['Int']>;
  updateAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TripMaxFields = {
  __typename?: 'TripMaxFields';
  completedAt?: Maybe<Scalars['timestamp']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  distance?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  geometry?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Int']>;
  updateAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Trip" */
export type TripMaxOrderBy = {
  completedAt?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  geometry?: InputMaybe<OrderBy>;
  rateId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updateAt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TripMinFields = {
  __typename?: 'TripMinFields';
  completedAt?: Maybe<Scalars['timestamp']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  distance?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  geometry?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['Int']>;
  updateAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Trip" */
export type TripMinOrderBy = {
  completedAt?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  geometry?: InputMaybe<OrderBy>;
  rateId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updateAt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Trip" */
export type TripMutationResponse = {
  __typename?: 'TripMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trip>;
};

/** on_conflict condition type for table "Trip" */
export type TripOnConflict = {
  constraint: TripConstraint;
  update_columns?: Array<TripUpdateColumn>;
  where?: InputMaybe<TripBoolExp>;
};

/** Ordering options when selecting data from "Trip". */
export type TripOrderBy = {
  completedAt?: InputMaybe<OrderBy>;
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deliveryRate?: InputMaybe<DeliveryrateOrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  geometry?: InputMaybe<OrderBy>;
  locationUpdate?: InputMaybe<OrderBy>;
  rateId?: InputMaybe<OrderBy>;
  task?: InputMaybe<TaskOrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updateAt?: InputMaybe<OrderBy>;
};

/** select columns of table "Trip" */
export enum TripSelectColumn {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Distance = 'distance',
  /** column name */
  Duration = 'duration',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  LocationUpdate = 'locationUpdate',
  /** column name */
  RateId = 'rateId',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdateAt = 'updateAt'
}

/** input type for updating data in table "Trip" */
export type TripSetInput = {
  completedAt?: InputMaybe<Scalars['timestamp']>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  distance?: InputMaybe<Scalars['float8']>;
  duration?: InputMaybe<Scalars['Int']>;
  geometry?: InputMaybe<Scalars['String']>;
  locationUpdate?: InputMaybe<Scalars['_jsonb']>;
  rateId?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['Int']>;
  updateAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type TripStddevFields = {
  __typename?: 'TripStddevFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Trip" */
export type TripStddevOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TripStddevPopFields = {
  __typename?: 'TripStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Trip" */
export type TripStddevPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TripStddevSampFields = {
  __typename?: 'TripStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Trip" */
export type TripStddevSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type TripSumFields = {
  __typename?: 'TripSumFields';
  courierId?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['float8']>;
  duration?: Maybe<Scalars['Int']>;
  taskId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Trip" */
export type TripSumOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** update columns of table "Trip" */
export enum TripUpdateColumn {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Distance = 'distance',
  /** column name */
  Duration = 'duration',
  /** column name */
  Geometry = 'geometry',
  /** column name */
  LocationUpdate = 'locationUpdate',
  /** column name */
  RateId = 'rateId',
  /** column name */
  TaskId = 'taskId',
  /** column name */
  UpdateAt = 'updateAt'
}

/** aggregate var_pop on columns */
export type TripVarPopFields = {
  __typename?: 'TripVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Trip" */
export type TripVarPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TripVarSampFields = {
  __typename?: 'TripVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Trip" */
export type TripVarSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TripVarianceFields = {
  __typename?: 'TripVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  taskId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Trip" */
export type TripVarianceOrderBy = {
  courierId?: InputMaybe<OrderBy>;
  distance?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  /** An array relationship */
  CourierPayoutSettings: Array<CourierPayoutSettings>;
  /** An object relationship */
  courier?: Maybe<Courier>;
  /** An aggregate relationship */
  courierPayoutSettingsAggregate: CourierPayoutSettingsAggregate;
  createdAt: Scalars['timestamp'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** Returns user full name */
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: Scalars['Language'];
  lastName: Scalars['String'];
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  membersAggregate: MemberAggregate;
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['timestamp'];
};


/** columns and relationships of "User" */
export type UserCourierPayoutSettingsArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


/** columns and relationships of "User" */
export type UserCourierPayoutSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


/** columns and relationships of "User" */
export type UserMembersArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


/** columns and relationships of "User" */
export type UserMembersAggregateArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};

/** aggregated selection of "User" */
export type UserAggregate = {
  __typename?: 'UserAggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type UserAggregateFields = {
  __typename?: 'UserAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
};


/** aggregate fields of "User" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  CourierPayoutSettings?: InputMaybe<CourierPayoutSettingsBoolExp>;
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  courier?: InputMaybe<CourierBoolExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<LanguageComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  members?: InputMaybe<MemberBoolExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "User" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'User_email_key',
  /** unique or primary key constraint on columns "phoneNumber" */
  UserPhoneNumberKey = 'User_phoneNumber_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'User_pkey'
}

/** input type for inserting data into table "User" */
export type UserInsertInput = {
  CourierPayoutSettings?: InputMaybe<CourierPayoutSettingsArrRelInsertInput>;
  courier?: InputMaybe<CourierObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['Language']>;
  lastName?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<MemberArrRelInsertInput>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'UserMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['Language']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'UserMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['Language']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "User" */
export type UserMutationResponse = {
  __typename?: 'UserMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "User" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "User". */
export type UserOrderBy = {
  CourierPayoutSettings_aggregate?: InputMaybe<CourierPayoutSettingsAggregateOrderBy>;
  courier?: InputMaybe<CourierOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  members_aggregate?: InputMaybe<MemberAggregateOrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user */
export type UserPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "User" */
export enum UserSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "User" */
export type UserSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['Language']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "User" */
export enum UserUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _cast?: InputMaybe<Uuid_Cast_Exp>;
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "Vehicle" */
export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']>;
  /** An object relationship */
  courier: Courier;
  courierId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  licencePlate?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  vehicleDescription: Scalars['String'];
  vehicleType: Scalars['String'];
};

/** aggregated selection of "Vehicle" */
export type VehicleAggregate = {
  __typename?: 'VehicleAggregate';
  aggregate?: Maybe<VehicleAggregateFields>;
  nodes: Array<Vehicle>;
};

/** aggregate fields of "Vehicle" */
export type VehicleAggregateFields = {
  __typename?: 'VehicleAggregateFields';
  avg?: Maybe<VehicleAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<VehicleMaxFields>;
  min?: Maybe<VehicleMinFields>;
  stddev?: Maybe<VehicleStddevFields>;
  stddevPop?: Maybe<VehicleStddevPopFields>;
  stddevSamp?: Maybe<VehicleStddevSampFields>;
  sum?: Maybe<VehicleSumFields>;
  varPop?: Maybe<VehicleVarPopFields>;
  varSamp?: Maybe<VehicleVarSampFields>;
  variance?: Maybe<VehicleVarianceFields>;
};


/** aggregate fields of "Vehicle" */
export type VehicleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VehicleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Vehicle" */
export type VehicleAggregateOrderBy = {
  avg?: InputMaybe<VehicleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<VehicleMaxOrderBy>;
  min?: InputMaybe<VehicleMinOrderBy>;
  stddev?: InputMaybe<VehicleStddevOrderBy>;
  stddev_pop?: InputMaybe<VehicleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<VehicleStddevSampOrderBy>;
  sum?: InputMaybe<VehicleSumOrderBy>;
  var_pop?: InputMaybe<VehicleVarPopOrderBy>;
  var_samp?: InputMaybe<VehicleVarSampOrderBy>;
  variance?: InputMaybe<VehicleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "Vehicle" */
export type VehicleArrRelInsertInput = {
  data: Array<VehicleInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VehicleOnConflict>;
};

/** aggregate avg on columns */
export type VehicleAvgFields = {
  __typename?: 'VehicleAvgFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Vehicle" */
export type VehicleAvgOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "Vehicle". All fields are combined with a logical 'AND'. */
export type VehicleBoolExp = {
  _and?: InputMaybe<Array<VehicleBoolExp>>;
  _not?: InputMaybe<VehicleBoolExp>;
  _or?: InputMaybe<Array<VehicleBoolExp>>;
  color?: InputMaybe<StringComparisonExp>;
  courier?: InputMaybe<CourierBoolExp>;
  courierId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  licencePlate?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  vehicleDescription?: InputMaybe<StringComparisonExp>;
  vehicleType?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "Vehicle" */
export enum VehicleConstraint {
  /** unique or primary key constraint on columns "licencePlate" */
  VehicleLicencePlateKey = 'Vehicle_licencePlate_key',
  /** unique or primary key constraint on columns "id" */
  VehiclePkey = 'Vehicle_pkey'
}

/** input type for incrementing numeric columns in table "Vehicle" */
export type VehicleIncInput = {
  courierId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Vehicle" */
export type VehicleInsertInput = {
  color?: InputMaybe<Scalars['String']>;
  courier?: InputMaybe<CourierObjRelInsertInput>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  licencePlate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleDescription?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type VehicleMaxFields = {
  __typename?: 'VehicleMaxFields';
  color?: Maybe<Scalars['String']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  licencePlate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleDescription?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Vehicle" */
export type VehicleMaxOrderBy = {
  color?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licencePlate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vehicleDescription?: InputMaybe<OrderBy>;
  vehicleType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type VehicleMinFields = {
  __typename?: 'VehicleMinFields';
  color?: Maybe<Scalars['String']>;
  courierId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  licencePlate?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vehicleDescription?: Maybe<Scalars['String']>;
  vehicleType?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Vehicle" */
export type VehicleMinOrderBy = {
  color?: InputMaybe<OrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licencePlate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vehicleDescription?: InputMaybe<OrderBy>;
  vehicleType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "Vehicle" */
export type VehicleMutationResponse = {
  __typename?: 'VehicleMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vehicle>;
};

/** on_conflict condition type for table "Vehicle" */
export type VehicleOnConflict = {
  constraint: VehicleConstraint;
  update_columns?: Array<VehicleUpdateColumn>;
  where?: InputMaybe<VehicleBoolExp>;
};

/** Ordering options when selecting data from "Vehicle". */
export type VehicleOrderBy = {
  color?: InputMaybe<OrderBy>;
  courier?: InputMaybe<CourierOrderBy>;
  courierId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  licencePlate?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  vehicleDescription?: InputMaybe<OrderBy>;
  vehicleType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: vehicle */
export type VehiclePkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Vehicle" */
export enum VehicleSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LicencePlate = 'licencePlate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleDescription = 'vehicleDescription',
  /** column name */
  VehicleType = 'vehicleType'
}

/** input type for updating data in table "Vehicle" */
export type VehicleSetInput = {
  color?: InputMaybe<Scalars['String']>;
  courierId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  licencePlate?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  vehicleDescription?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type VehicleStddevFields = {
  __typename?: 'VehicleStddevFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Vehicle" */
export type VehicleStddevOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type VehicleStddevPopFields = {
  __typename?: 'VehicleStddevPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Vehicle" */
export type VehicleStddevPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type VehicleStddevSampFields = {
  __typename?: 'VehicleStddevSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Vehicle" */
export type VehicleStddevSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type VehicleSumFields = {
  __typename?: 'VehicleSumFields';
  courierId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Vehicle" */
export type VehicleSumOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** update columns of table "Vehicle" */
export enum VehicleUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  CourierId = 'courierId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LicencePlate = 'licencePlate',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VehicleDescription = 'vehicleDescription',
  /** column name */
  VehicleType = 'vehicleType'
}

/** aggregate var_pop on columns */
export type VehicleVarPopFields = {
  __typename?: 'VehicleVarPopFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Vehicle" */
export type VehicleVarPopOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type VehicleVarSampFields = {
  __typename?: 'VehicleVarSampFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Vehicle" */
export type VehicleVarSampOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type VehicleVarianceFields = {
  __typename?: 'VehicleVarianceFields';
  courierId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Vehicle" */
export type VehicleVarianceOrderBy = {
  courierId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "Webhook" */
export type Webhook = {
  __typename?: 'Webhook';
  id: Scalars['String'];
  /** An object relationship */
  organization: Organization;
  organizationId: Scalars['String'];
  types?: Maybe<Scalars['_text']>;
  url: Scalars['String'];
};

/** aggregated selection of "Webhook" */
export type WebhookAggregate = {
  __typename?: 'WebhookAggregate';
  aggregate?: Maybe<WebhookAggregateFields>;
  nodes: Array<Webhook>;
};

/** aggregate fields of "Webhook" */
export type WebhookAggregateFields = {
  __typename?: 'WebhookAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<WebhookMaxFields>;
  min?: Maybe<WebhookMinFields>;
};


/** aggregate fields of "Webhook" */
export type WebhookAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WebhookSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Webhook". All fields are combined with a logical 'AND'. */
export type WebhookBoolExp = {
  _and?: InputMaybe<Array<WebhookBoolExp>>;
  _not?: InputMaybe<WebhookBoolExp>;
  _or?: InputMaybe<Array<WebhookBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  types?: InputMaybe<_TextComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "Webhook" */
export enum WebhookConstraint {
  /** unique or primary key constraint on columns "id" */
  WebhookPkey = 'Webhook_pkey'
}

/** input type for inserting data into table "Webhook" */
export type WebhookInsertInput = {
  id?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Scalars['_text']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Webhook invocation list */
export type WebhookInvocations = {
  __typename?: 'WebhookInvocations';
  attemptedAt?: Maybe<Scalars['timestamptz']>;
  attempts: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  request: Scalars['jsonb'];
  response?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  webhook: Webhooks;
  webhookId: Scalars['uuid'];
};


/** Webhook invocation list */
export type WebhookInvocationsRequestArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Webhook invocation list */
export type WebhookInvocationsResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "webhook_invocations" */
export type WebhookInvocationsAggregate = {
  __typename?: 'WebhookInvocationsAggregate';
  aggregate?: Maybe<WebhookInvocationsAggregateFields>;
  nodes: Array<WebhookInvocations>;
};

/** aggregate fields of "webhook_invocations" */
export type WebhookInvocationsAggregateFields = {
  __typename?: 'WebhookInvocationsAggregateFields';
  avg?: Maybe<WebhookInvocationsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WebhookInvocationsMaxFields>;
  min?: Maybe<WebhookInvocationsMinFields>;
  stddev?: Maybe<WebhookInvocationsStddevFields>;
  stddevPop?: Maybe<WebhookInvocationsStddevPopFields>;
  stddevSamp?: Maybe<WebhookInvocationsStddevSampFields>;
  sum?: Maybe<WebhookInvocationsSumFields>;
  varPop?: Maybe<WebhookInvocationsVarPopFields>;
  varSamp?: Maybe<WebhookInvocationsVarSampFields>;
  variance?: Maybe<WebhookInvocationsVarianceFields>;
};


/** aggregate fields of "webhook_invocations" */
export type WebhookInvocationsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webhook_invocations" */
export type WebhookInvocationsAggregateOrderBy = {
  avg?: InputMaybe<WebhookInvocationsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WebhookInvocationsMaxOrderBy>;
  min?: InputMaybe<WebhookInvocationsMinOrderBy>;
  stddev?: InputMaybe<WebhookInvocationsStddevOrderBy>;
  stddev_pop?: InputMaybe<WebhookInvocationsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WebhookInvocationsStddevSampOrderBy>;
  sum?: InputMaybe<WebhookInvocationsSumOrderBy>;
  var_pop?: InputMaybe<WebhookInvocationsVarPopOrderBy>;
  var_samp?: InputMaybe<WebhookInvocationsVarSampOrderBy>;
  variance?: InputMaybe<WebhookInvocationsVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type WebhookInvocationsAppendInput = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "webhook_invocations" */
export type WebhookInvocationsArrRelInsertInput = {
  data: Array<WebhookInvocationsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WebhookInvocationsOnConflict>;
};

/** aggregate avg on columns */
export type WebhookInvocationsAvgFields = {
  __typename?: 'WebhookInvocationsAvgFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "webhook_invocations" */
export type WebhookInvocationsAvgOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "webhook_invocations". All fields are combined with a logical 'AND'. */
export type WebhookInvocationsBoolExp = {
  _and?: InputMaybe<Array<WebhookInvocationsBoolExp>>;
  _not?: InputMaybe<WebhookInvocationsBoolExp>;
  _or?: InputMaybe<Array<WebhookInvocationsBoolExp>>;
  attemptedAt?: InputMaybe<TimestamptzComparisonExp>;
  attempts?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  request?: InputMaybe<JsonbComparisonExp>;
  response?: InputMaybe<JsonbComparisonExp>;
  status?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  webhook?: InputMaybe<WebhooksBoolExp>;
  webhookId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "webhook_invocations" */
export enum WebhookInvocationsConstraint {
  /** unique or primary key constraint on columns "id" */
  WebhookInvocationsPkey = 'webhook_invocations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type WebhookInvocationsDeleteAtPathInput = {
  request?: InputMaybe<Array<Scalars['String']>>;
  response?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type WebhookInvocationsDeleteElemInput = {
  request?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type WebhookInvocationsDeleteKeyInput = {
  request?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "webhook_invocations" */
export type WebhookInvocationsIncInput = {
  attempts?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "webhook_invocations" */
export type WebhookInvocationsInsertInput = {
  attemptedAt?: InputMaybe<Scalars['timestamptz']>;
  attempts?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webhook?: InputMaybe<WebhooksObjRelInsertInput>;
  webhookId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WebhookInvocationsMaxFields = {
  __typename?: 'WebhookInvocationsMaxFields';
  attemptedAt?: Maybe<Scalars['timestamptz']>;
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  webhookId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webhook_invocations" */
export type WebhookInvocationsMaxOrderBy = {
  attemptedAt?: InputMaybe<OrderBy>;
  attempts?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WebhookInvocationsMinFields = {
  __typename?: 'WebhookInvocationsMinFields';
  attemptedAt?: Maybe<Scalars['timestamptz']>;
  attempts?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  webhookId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webhook_invocations" */
export type WebhookInvocationsMinOrderBy = {
  attemptedAt?: InputMaybe<OrderBy>;
  attempts?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "webhook_invocations" */
export type WebhookInvocationsMutationResponse = {
  __typename?: 'WebhookInvocationsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WebhookInvocations>;
};

/** on_conflict condition type for table "webhook_invocations" */
export type WebhookInvocationsOnConflict = {
  constraint: WebhookInvocationsConstraint;
  update_columns?: Array<WebhookInvocationsUpdateColumn>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};

/** Ordering options when selecting data from "webhook_invocations". */
export type WebhookInvocationsOrderBy = {
  attemptedAt?: InputMaybe<OrderBy>;
  attempts?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  request?: InputMaybe<OrderBy>;
  response?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  webhook?: InputMaybe<WebhooksOrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: webhook_invocations */
export type WebhookInvocationsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type WebhookInvocationsPrependInput = {
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "webhook_invocations" */
export enum WebhookInvocationsSelectColumn {
  /** column name */
  AttemptedAt = 'attemptedAt',
  /** column name */
  Attempts = 'attempts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Request = 'request',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebhookId = 'webhookId'
}

/** input type for updating data in table "webhook_invocations" */
export type WebhookInvocationsSetInput = {
  attemptedAt?: InputMaybe<Scalars['timestamptz']>;
  attempts?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  request?: InputMaybe<Scalars['jsonb']>;
  response?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  webhookId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type WebhookInvocationsStddevFields = {
  __typename?: 'WebhookInvocationsStddevFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "webhook_invocations" */
export type WebhookInvocationsStddevOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type WebhookInvocationsStddevPopFields = {
  __typename?: 'WebhookInvocationsStddevPopFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "webhook_invocations" */
export type WebhookInvocationsStddevPopOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type WebhookInvocationsStddevSampFields = {
  __typename?: 'WebhookInvocationsStddevSampFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "webhook_invocations" */
export type WebhookInvocationsStddevSampOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type WebhookInvocationsSumFields = {
  __typename?: 'WebhookInvocationsSumFields';
  attempts?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "webhook_invocations" */
export type WebhookInvocationsSumOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** update columns of table "webhook_invocations" */
export enum WebhookInvocationsUpdateColumn {
  /** column name */
  AttemptedAt = 'attemptedAt',
  /** column name */
  Attempts = 'attempts',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Request = 'request',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebhookId = 'webhookId'
}

/** aggregate var_pop on columns */
export type WebhookInvocationsVarPopFields = {
  __typename?: 'WebhookInvocationsVarPopFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "webhook_invocations" */
export type WebhookInvocationsVarPopOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type WebhookInvocationsVarSampFields = {
  __typename?: 'WebhookInvocationsVarSampFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "webhook_invocations" */
export type WebhookInvocationsVarSampOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WebhookInvocationsVarianceFields = {
  __typename?: 'WebhookInvocationsVarianceFields';
  attempts?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "webhook_invocations" */
export type WebhookInvocationsVarianceOrderBy = {
  attempts?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type WebhookMaxFields = {
  __typename?: 'WebhookMaxFields';
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WebhookMinFields = {
  __typename?: 'WebhookMinFields';
  id?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Webhook" */
export type WebhookMutationResponse = {
  __typename?: 'WebhookMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webhook>;
};

/** on_conflict condition type for table "Webhook" */
export type WebhookOnConflict = {
  constraint: WebhookConstraint;
  update_columns?: Array<WebhookUpdateColumn>;
  where?: InputMaybe<WebhookBoolExp>;
};

/** Ordering options when selecting data from "Webhook". */
export type WebhookOrderBy = {
  id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  types?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: webhook */
export type WebhookPkColumnsInput = {
  id: Scalars['String'];
};

/** select columns of table "Webhook" */
export enum WebhookSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Types = 'types',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "Webhook" */
export type WebhookSetInput = {
  id?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Scalars['_text']>;
  url?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "webhook_types" */
export type WebhookTypes = {
  __typename?: 'WebhookTypes';
  type: Scalars['String'];
};

/** aggregated selection of "webhook_types" */
export type WebhookTypesAggregate = {
  __typename?: 'WebhookTypesAggregate';
  aggregate?: Maybe<WebhookTypesAggregateFields>;
  nodes: Array<WebhookTypes>;
};

/** aggregate fields of "webhook_types" */
export type WebhookTypesAggregateFields = {
  __typename?: 'WebhookTypesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<WebhookTypesMaxFields>;
  min?: Maybe<WebhookTypesMinFields>;
};


/** aggregate fields of "webhook_types" */
export type WebhookTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WebhookTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "webhook_types". All fields are combined with a logical 'AND'. */
export type WebhookTypesBoolExp = {
  _and?: InputMaybe<Array<WebhookTypesBoolExp>>;
  _not?: InputMaybe<WebhookTypesBoolExp>;
  _or?: InputMaybe<Array<WebhookTypesBoolExp>>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "webhook_types" */
export enum WebhookTypesConstraint {
  /** unique or primary key constraint on columns "type" */
  WebhookTypesPkey = 'webhook_types_pkey'
}

export enum WebhookTypesEnum {
  OrderActive = 'ORDER_ACTIVE',
  OrderCompleted = 'ORDER_COMPLETED',
  OrderCourierMissing = 'ORDER_COURIER_MISSING',
  OrderCreated = 'ORDER_CREATED',
  OrderFailed = 'ORDER_FAILED',
  OrderNeedCourier = 'ORDER_NEED_COURIER',
  OrderPlanned = 'ORDER_PLANNED',
  OrderStarted = 'ORDER_STARTED'
}

/** input type for inserting data into table "webhook_types" */
export type WebhookTypesInsertInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type WebhookTypesMaxFields = {
  __typename?: 'WebhookTypesMaxFields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type WebhookTypesMinFields = {
  __typename?: 'WebhookTypesMinFields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "webhook_types" */
export type WebhookTypesMutationResponse = {
  __typename?: 'WebhookTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WebhookTypes>;
};

/** on_conflict condition type for table "webhook_types" */
export type WebhookTypesOnConflict = {
  constraint: WebhookTypesConstraint;
  update_columns?: Array<WebhookTypesUpdateColumn>;
  where?: InputMaybe<WebhookTypesBoolExp>;
};

/** Ordering options when selecting data from "webhook_types". */
export type WebhookTypesOrderBy = {
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: webhook_types */
export type WebhookTypesPkColumnsInput = {
  type: Scalars['String'];
};

/** select columns of table "webhook_types" */
export enum WebhookTypesSelectColumn {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "webhook_types" */
export type WebhookTypesSetInput = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "webhook_types" */
export enum WebhookTypesUpdateColumn {
  /** column name */
  Type = 'type'
}

/** update columns of table "Webhook" */
export enum WebhookUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Types = 'types',
  /** column name */
  Url = 'url'
}

/** columns and relationships of "webhooks" */
export type Webhooks = {
  __typename?: 'Webhooks';
  createdAt: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamp'];
  url: Scalars['String'];
  version: Scalars['Int'];
  /** An array relationship */
  webhookInvocations: Array<WebhookInvocations>;
  /** An aggregate relationship */
  webhookInvocationsAggregate: WebhookInvocationsAggregate;
  /** An array relationship */
  webhooksTriggerConditions: Array<WebhooksTriggerConditions>;
  /** An aggregate relationship */
  webhooksTriggerConditionsAggregate: WebhooksTriggerConditionsAggregate;
};


/** columns and relationships of "webhooks" */
export type WebhooksWebhookInvocationsArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


/** columns and relationships of "webhooks" */
export type WebhooksWebhookInvocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


/** columns and relationships of "webhooks" */
export type WebhooksWebhooksTriggerConditionsArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};


/** columns and relationships of "webhooks" */
export type WebhooksWebhooksTriggerConditionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};

/** aggregated selection of "webhooks" */
export type WebhooksAggregate = {
  __typename?: 'WebhooksAggregate';
  aggregate?: Maybe<WebhooksAggregateFields>;
  nodes: Array<Webhooks>;
};

/** aggregate fields of "webhooks" */
export type WebhooksAggregateFields = {
  __typename?: 'WebhooksAggregateFields';
  avg?: Maybe<WebhooksAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WebhooksMaxFields>;
  min?: Maybe<WebhooksMinFields>;
  stddev?: Maybe<WebhooksStddevFields>;
  stddevPop?: Maybe<WebhooksStddevPopFields>;
  stddevSamp?: Maybe<WebhooksStddevSampFields>;
  sum?: Maybe<WebhooksSumFields>;
  varPop?: Maybe<WebhooksVarPopFields>;
  varSamp?: Maybe<WebhooksVarSampFields>;
  variance?: Maybe<WebhooksVarianceFields>;
};


/** aggregate fields of "webhooks" */
export type WebhooksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WebhooksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webhooks" */
export type WebhooksAggregateOrderBy = {
  avg?: InputMaybe<WebhooksAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WebhooksMaxOrderBy>;
  min?: InputMaybe<WebhooksMinOrderBy>;
  stddev?: InputMaybe<WebhooksStddevOrderBy>;
  stddev_pop?: InputMaybe<WebhooksStddevPopOrderBy>;
  stddev_samp?: InputMaybe<WebhooksStddevSampOrderBy>;
  sum?: InputMaybe<WebhooksSumOrderBy>;
  var_pop?: InputMaybe<WebhooksVarPopOrderBy>;
  var_samp?: InputMaybe<WebhooksVarSampOrderBy>;
  variance?: InputMaybe<WebhooksVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "webhooks" */
export type WebhooksArrRelInsertInput = {
  data: Array<WebhooksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WebhooksOnConflict>;
};

/** aggregate avg on columns */
export type WebhooksAvgFields = {
  __typename?: 'WebhooksAvgFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "webhooks" */
export type WebhooksAvgOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "webhooks". All fields are combined with a logical 'AND'. */
export type WebhooksBoolExp = {
  _and?: InputMaybe<Array<WebhooksBoolExp>>;
  _not?: InputMaybe<WebhooksBoolExp>;
  _or?: InputMaybe<Array<WebhooksBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organizationId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<IntComparisonExp>;
  webhookInvocations?: InputMaybe<WebhookInvocationsBoolExp>;
  webhooksTriggerConditions?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};

/** unique or primary key constraints on table "webhooks" */
export enum WebhooksConstraint {
  /** unique or primary key constraint on columns "id" */
  WebhooksPkey = 'webhooks_pkey'
}

/** input type for incrementing numeric columns in table "webhooks" */
export type WebhooksIncInput = {
  version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "webhooks" */
export type WebhooksInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  webhookInvocations?: InputMaybe<WebhookInvocationsArrRelInsertInput>;
  webhooksTriggerConditions?: InputMaybe<WebhooksTriggerConditionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type WebhooksMaxFields = {
  __typename?: 'WebhooksMaxFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "webhooks" */
export type WebhooksMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WebhooksMinFields = {
  __typename?: 'WebhooksMinFields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamp']>;
  url?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "webhooks" */
export type WebhooksMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "webhooks" */
export type WebhooksMutationResponse = {
  __typename?: 'WebhooksMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Webhooks>;
};

/** input type for inserting object relation for remote table "webhooks" */
export type WebhooksObjRelInsertInput = {
  data: WebhooksInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<WebhooksOnConflict>;
};

/** on_conflict condition type for table "webhooks" */
export type WebhooksOnConflict = {
  constraint: WebhooksConstraint;
  update_columns?: Array<WebhooksUpdateColumn>;
  where?: InputMaybe<WebhooksBoolExp>;
};

/** Ordering options when selecting data from "webhooks". */
export type WebhooksOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organizationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  webhookInvocations_aggregate?: InputMaybe<WebhookInvocationsAggregateOrderBy>;
  webhooksTriggerConditions_aggregate?: InputMaybe<WebhooksTriggerConditionsAggregateOrderBy>;
};

/** primary key columns input for table: webhooks */
export type WebhooksPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "webhooks" */
export enum WebhooksSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "webhooks" */
export type WebhooksSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamp']>;
  url?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type WebhooksStddevFields = {
  __typename?: 'WebhooksStddevFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "webhooks" */
export type WebhooksStddevOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type WebhooksStddevPopFields = {
  __typename?: 'WebhooksStddevPopFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "webhooks" */
export type WebhooksStddevPopOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type WebhooksStddevSampFields = {
  __typename?: 'WebhooksStddevSampFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "webhooks" */
export type WebhooksStddevSampOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type WebhooksSumFields = {
  __typename?: 'WebhooksSumFields';
  version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "webhooks" */
export type WebhooksSumOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "webhooks_trigger_conditions" */
export type WebhooksTriggerConditions = {
  __typename?: 'WebhooksTriggerConditions';
  id: Scalars['uuid'];
  type: WebhookTypesEnum;
  /** An object relationship */
  webhook: Webhooks;
  webhookId: Scalars['uuid'];
};

/** aggregated selection of "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsAggregate = {
  __typename?: 'WebhooksTriggerConditionsAggregate';
  aggregate?: Maybe<WebhooksTriggerConditionsAggregateFields>;
  nodes: Array<WebhooksTriggerConditions>;
};

/** aggregate fields of "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsAggregateFields = {
  __typename?: 'WebhooksTriggerConditionsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<WebhooksTriggerConditionsMaxFields>;
  min?: Maybe<WebhooksTriggerConditionsMinFields>;
};


/** aggregate fields of "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<WebhooksTriggerConditionsMaxOrderBy>;
  min?: InputMaybe<WebhooksTriggerConditionsMinOrderBy>;
};

/** input type for inserting array relation for remote table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsArrRelInsertInput = {
  data: Array<WebhooksTriggerConditionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<WebhooksTriggerConditionsOnConflict>;
};

/** Boolean expression to filter rows from the table "webhooks_trigger_conditions". All fields are combined with a logical 'AND'. */
export type WebhooksTriggerConditionsBoolExp = {
  _and?: InputMaybe<Array<WebhooksTriggerConditionsBoolExp>>;
  _not?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
  _or?: InputMaybe<Array<WebhooksTriggerConditionsBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<WebhooktypesenumComparisonExp>;
  webhook?: InputMaybe<WebhooksBoolExp>;
  webhookId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "webhooks_trigger_conditions" */
export enum WebhooksTriggerConditionsConstraint {
  /** unique or primary key constraint on columns "id" */
  WebhooksTriggerConditionsIdKey = 'webhooks_trigger_conditions_id_key',
  /** unique or primary key constraint on columns "webhook_id", "type" */
  WebhooksTriggerConditionsTypeWebhookIdKey = 'webhooks_trigger_conditions_type_webhook_id_key'
}

/** input type for inserting data into table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsInsertInput = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<WebhookTypesEnum>;
  webhook?: InputMaybe<WebhooksObjRelInsertInput>;
  webhookId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type WebhooksTriggerConditionsMaxFields = {
  __typename?: 'WebhooksTriggerConditionsMaxFields';
  id?: Maybe<Scalars['uuid']>;
  webhookId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type WebhooksTriggerConditionsMinFields = {
  __typename?: 'WebhooksTriggerConditionsMinFields';
  id?: Maybe<Scalars['uuid']>;
  webhookId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsMutationResponse = {
  __typename?: 'WebhooksTriggerConditionsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<WebhooksTriggerConditions>;
};

/** on_conflict condition type for table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsOnConflict = {
  constraint: WebhooksTriggerConditionsConstraint;
  update_columns?: Array<WebhooksTriggerConditionsUpdateColumn>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};

/** Ordering options when selecting data from "webhooks_trigger_conditions". */
export type WebhooksTriggerConditionsOrderBy = {
  id?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  webhook?: InputMaybe<WebhooksOrderBy>;
  webhookId?: InputMaybe<OrderBy>;
};

/** select columns of table "webhooks_trigger_conditions" */
export enum WebhooksTriggerConditionsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  WebhookId = 'webhookId'
}

/** input type for updating data in table "webhooks_trigger_conditions" */
export type WebhooksTriggerConditionsSetInput = {
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<WebhookTypesEnum>;
  webhookId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "webhooks_trigger_conditions" */
export enum WebhooksTriggerConditionsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  WebhookId = 'webhookId'
}

/** update columns of table "webhooks" */
export enum WebhooksUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type WebhooksVarPopFields = {
  __typename?: 'WebhooksVarPopFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "webhooks" */
export type WebhooksVarPopOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type WebhooksVarSampFields = {
  __typename?: 'WebhooksVarSampFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "webhooks" */
export type WebhooksVarSampOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type WebhooksVarianceFields = {
  __typename?: 'WebhooksVarianceFields';
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "webhooks" */
export type WebhooksVarianceOrderBy = {
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "WebhookTypesEnum". All fields are combined with logical 'AND'. */
export type WebhooktypesenumComparisonExp = {
  _eq?: InputMaybe<WebhookTypesEnum>;
  _in?: InputMaybe<Array<WebhookTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<WebhookTypesEnum>;
  _nin?: InputMaybe<Array<WebhookTypesEnum>>;
};

/** Boolean expression to compare columns of type "_float8". All fields are combined with logical 'AND'. */
export type _Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['_float8']>;
  _gt?: InputMaybe<Scalars['_float8']>;
  _gte?: InputMaybe<Scalars['_float8']>;
  _in?: InputMaybe<Array<Scalars['_float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_float8']>;
  _lte?: InputMaybe<Scalars['_float8']>;
  _neq?: InputMaybe<Scalars['_float8']>;
  _nin?: InputMaybe<Array<Scalars['_float8']>>;
};

/** Boolean expression to compare columns of type "_jsonb". All fields are combined with logical 'AND'. */
export type _JsonbComparisonExp = {
  _eq?: InputMaybe<Scalars['_jsonb']>;
  _gt?: InputMaybe<Scalars['_jsonb']>;
  _gte?: InputMaybe<Scalars['_jsonb']>;
  _in?: InputMaybe<Array<Scalars['_jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_jsonb']>;
  _lte?: InputMaybe<Scalars['_jsonb']>;
  _neq?: InputMaybe<Scalars['_jsonb']>;
  _nin?: InputMaybe<Array<Scalars['_jsonb']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _TextComparisonExp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

export type Float8_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  courierApp?: Maybe<Mutation>;
  createApiToken: Scalars['Boolean'];
  createOrder: Scalars['Boolean'];
  createOrders: Scalars['Boolean'];
  /** delete data from the table: "AccessToken" */
  deleteAccesstoken?: Maybe<AccesstokenMutationResponse>;
  /** delete single row from the table: "AccessToken" */
  deleteAccesstokenByPk?: Maybe<Accesstoken>;
  /** delete data from the table: "Address" */
  deleteAddress?: Maybe<AddressMutationResponse>;
  /** delete single row from the table: "Address" */
  deleteAddressByPk?: Maybe<Address>;
  /** delete data from the table: "cancellation_policies" */
  deleteCancellationPolicies?: Maybe<CancellationPoliciesMutationResponse>;
  /** delete single row from the table: "cancellation_policies" */
  deleteCancellationPoliciesByPk?: Maybe<CancellationPolicies>;
  /** delete data from the table: "cancellation_policy_types" */
  deleteCancellationPolicyTypes?: Maybe<CancellationPolicyTypesMutationResponse>;
  /** delete single row from the table: "cancellation_policy_types" */
  deleteCancellationPolicyTypesByPk?: Maybe<CancellationPolicyTypes>;
  /** delete data from the table: "Courier" */
  deleteCourier?: Maybe<CourierMutationResponse>;
  /** delete single row from the table: "Courier" */
  deleteCourierByPk?: Maybe<Courier>;
  /** delete data from the table: "courier_payout_settings" */
  deleteCourierPayoutSettings?: Maybe<CourierPayoutSettingsMutationResponse>;
  /** delete single row from the table: "courier_payout_settings" */
  deleteCourierPayoutSettingsByPk?: Maybe<CourierPayoutSettings>;
  /** delete data from the table: "CourierProfile" */
  deleteCourierprofile?: Maybe<CourierprofileMutationResponse>;
  /** delete data from the table: "CourierReport" */
  deleteCourierreport?: Maybe<CourierreportMutationResponse>;
  /** delete single row from the table: "CourierReport" */
  deleteCourierreportByPk?: Maybe<Courierreport>;
  /** delete data from the table: "csv_export" */
  deleteCsvExport?: Maybe<CsvExportMutationResponse>;
  /** delete single row from the table: "csv_export" */
  deleteCsvExportByPk?: Maybe<CsvExport>;
  /** delete data from the table: "delivery_types" */
  deleteDeliveryTypes?: Maybe<DeliveryTypesMutationResponse>;
  /** delete single row from the table: "delivery_types" */
  deleteDeliveryTypesByPk?: Maybe<DeliveryTypes>;
  /** delete data from the table: "DeliveryRate" */
  deleteDeliveryrate?: Maybe<DeliveryrateMutationResponse>;
  /** delete single row from the table: "DeliveryRate" */
  deleteDeliveryrateByPk?: Maybe<Deliveryrate>;
  /** delete data from the table: "GlobalSettings" */
  deleteGlobalsettings?: Maybe<GlobalsettingsMutationResponse>;
  /** delete single row from the table: "GlobalSettings" */
  deleteGlobalsettingsByPk?: Maybe<Globalsettings>;
  /** delete data from the table: "insurance_policies" */
  deleteInsurancePolicies?: Maybe<InsurancePoliciesMutationResponse>;
  /** delete single row from the table: "insurance_policies" */
  deleteInsurancePoliciesByPk?: Maybe<InsurancePolicies>;
  /** delete data from the table: "Invite" */
  deleteInvite?: Maybe<InviteMutationResponse>;
  /** delete single row from the table: "Invite" */
  deleteInviteByPk?: Maybe<Invite>;
  /** delete data from the table: "live_map_lock" */
  deleteLiveMapLock?: Maybe<LiveMapLockMutationResponse>;
  /** delete single row from the table: "live_map_lock" */
  deleteLiveMapLockByPk?: Maybe<LiveMapLock>;
  /** delete data from the table: "Member" */
  deleteMember?: Maybe<MemberMutationResponse>;
  /** delete data from the table: "multiple_stops_policies" */
  deleteMultipleStopsPolicies?: Maybe<MultipleStopsPoliciesMutationResponse>;
  /** delete single row from the table: "multiple_stops_policies" */
  deleteMultipleStopsPoliciesByPk?: Maybe<MultipleStopsPolicies>;
  /** delete data from the table: "Order" */
  deleteOrder?: Maybe<OrderMutationResponse>;
  /** delete single row from the table: "Order" */
  deleteOrderByPk?: Maybe<Order>;
  /** delete data from the table: "order_info" */
  deleteOrderInfo?: Maybe<OrderInfoMutationResponse>;
  /** delete single row from the table: "order_info" */
  deleteOrderInfoByPk?: Maybe<OrderInfo>;
  /** delete data from the table: "order_price" */
  deleteOrderPrice?: Maybe<OrderPriceMutationResponse>;
  /** delete single row from the table: "order_price" */
  deleteOrderPriceByPk?: Maybe<OrderPrice>;
  /** delete data from the table: "order_status_events" */
  deleteOrderStatusEvents?: Maybe<OrderStatusEventsMutationResponse>;
  /** delete single row from the table: "order_status_events" */
  deleteOrderStatusEventsByPk?: Maybe<OrderStatusEvents>;
  /** delete data from the table: "order_status_types" */
  deleteOrderStatusTypes?: Maybe<OrderStatusTypesMutationResponse>;
  /** delete single row from the table: "order_status_types" */
  deleteOrderStatusTypesByPk?: Maybe<OrderStatusTypes>;
  /** delete data from the table: "OrderEvents" */
  deleteOrderevents?: Maybe<OrdereventsMutationResponse>;
  /** delete single row from the table: "OrderEvents" */
  deleteOrdereventsByPk?: Maybe<Orderevents>;
  /** delete data from the table: "OrderTracking" */
  deleteOrdertracking?: Maybe<OrdertrackingMutationResponse>;
  /** delete single row from the table: "OrderTracking" */
  deleteOrdertrackingByPk?: Maybe<Ordertracking>;
  /** delete data from the table: "OrderValidationError" */
  deleteOrdervalidationerror?: Maybe<OrdervalidationerrorMutationResponse>;
  /** delete single row from the table: "OrderValidationError" */
  deleteOrdervalidationerrorByPk?: Maybe<Ordervalidationerror>;
  /** delete data from the table: "Organization" */
  deleteOrganization?: Maybe<OrganizationMutationResponse>;
  /** delete single row from the table: "Organization" */
  deleteOrganizationByPk?: Maybe<Organization>;
  /** delete data from the table: "organization_pricing_policies" */
  deleteOrganizationPricingPolicies?: Maybe<OrganizationPricingPoliciesMutationResponse>;
  /** delete single row from the table: "organization_pricing_policies" */
  deleteOrganizationPricingPoliciesByPk?: Maybe<OrganizationPricingPolicies>;
  /** delete data from the table: "OrganizationConfig" */
  deleteOrganizationconfig?: Maybe<OrganizationconfigMutationResponse>;
  /** delete single row from the table: "OrganizationConfig" */
  deleteOrganizationconfigByPk?: Maybe<Organizationconfig>;
  /** delete data from the table: "OrganizationProfile" */
  deleteOrganizationprofile?: Maybe<OrganizationprofileMutationResponse>;
  /** delete data from the table: "pdf" */
  deletePdf?: Maybe<PdfMutationResponse>;
  /** delete single row from the table: "pdf" */
  deletePdfByPk?: Maybe<Pdf>;
  /** delete data from the table: "price_addons" */
  deletePriceAddons?: Maybe<PriceAddonsMutationResponse>;
  /** delete single row from the table: "price_addons" */
  deletePriceAddonsByPk?: Maybe<PriceAddons>;
  /** delete data from the table: "pricing_insurance_bridge" */
  deletePricingInsuranceBridge?: Maybe<PricingInsuranceBridgeMutationResponse>;
  /** delete single row from the table: "pricing_insurance_bridge" */
  deletePricingInsuranceBridgeByPk?: Maybe<PricingInsuranceBridge>;
  /** delete data from the table: "pricing_rules" */
  deletePricingRules?: Maybe<PricingRulesMutationResponse>;
  /** delete single row from the table: "pricing_rules" */
  deletePricingRulesByPk?: Maybe<PricingRules>;
  /** delete data from the table: "processes" */
  deleteProcesses?: Maybe<ProcessesMutationResponse>;
  /** delete single row from the table: "processes" */
  deleteProcessesByPk?: Maybe<Processes>;
  /** delete data from the table: "Region" */
  deleteRegion?: Maybe<RegionMutationResponse>;
  /** delete single row from the table: "Region" */
  deleteRegionByPk?: Maybe<Region>;
  /** delete data from the table: "region_info" */
  deleteRegionInfo?: Maybe<RegionInfoMutationResponse>;
  /** delete single row from the table: "region_info" */
  deleteRegionInfoByPk?: Maybe<RegionInfo>;
  /** delete data from the table: "robolabs_invoice" */
  deleteRobolabsInvoice?: Maybe<RobolabsInvoiceMutationResponse>;
  /** delete single row from the table: "robolabs_invoice" */
  deleteRobolabsInvoiceByPk?: Maybe<RobolabsInvoice>;
  /** delete data from the table: "robolabs_invoice_status" */
  deleteRobolabsInvoiceStatus?: Maybe<RobolabsInvoiceStatusMutationResponse>;
  /** delete single row from the table: "robolabs_invoice_status" */
  deleteRobolabsInvoiceStatusByPk?: Maybe<RobolabsInvoiceStatus>;
  /** delete data from the table: "sms_messages" */
  deleteSmsMessages?: Maybe<SmsMessagesMutationResponse>;
  /** delete single row from the table: "sms_messages" */
  deleteSmsMessagesByPk?: Maybe<SmsMessages>;
  /** delete data from the table: "sms_messages_types" */
  deleteSmsMessagesTypes?: Maybe<SmsMessagesTypesMutationResponse>;
  /** delete single row from the table: "sms_messages_types" */
  deleteSmsMessagesTypesByPk?: Maybe<SmsMessagesTypes>;
  /** delete data from the table: "Task" */
  deleteTask?: Maybe<TaskMutationResponse>;
  /** delete single row from the table: "Task" */
  deleteTaskByPk?: Maybe<Task>;
  /** delete data from the table: "task_seq" */
  deleteTaskSeq?: Maybe<TaskSeqMutationResponse>;
  /** delete single row from the table: "task_seq" */
  deleteTaskSeqByPk?: Maybe<TaskSeq>;
  /** delete data from the table: "TaskETA" */
  deleteTasketa?: Maybe<TasketaMutationResponse>;
  /** delete data from the table: "Trip" */
  deleteTrip?: Maybe<TripMutationResponse>;
  /** delete data from the table: "User" */
  deleteUser?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "User" */
  deleteUserByPk?: Maybe<User>;
  /** delete data from the table: "Vehicle" */
  deleteVehicle?: Maybe<VehicleMutationResponse>;
  /** delete single row from the table: "Vehicle" */
  deleteVehicleByPk?: Maybe<Vehicle>;
  /** delete data from the table: "Webhook" */
  deleteWebhook?: Maybe<WebhookMutationResponse>;
  /** delete single row from the table: "Webhook" */
  deleteWebhookByPk?: Maybe<Webhook>;
  /** delete data from the table: "webhook_invocations" */
  deleteWebhookInvocations?: Maybe<WebhookInvocationsMutationResponse>;
  /** delete single row from the table: "webhook_invocations" */
  deleteWebhookInvocationsByPk?: Maybe<WebhookInvocations>;
  /** delete data from the table: "webhook_types" */
  deleteWebhookTypes?: Maybe<WebhookTypesMutationResponse>;
  /** delete single row from the table: "webhook_types" */
  deleteWebhookTypesByPk?: Maybe<WebhookTypes>;
  /** delete data from the table: "webhooks" */
  deleteWebhooks?: Maybe<WebhooksMutationResponse>;
  /** delete single row from the table: "webhooks" */
  deleteWebhooksByPk?: Maybe<Webhooks>;
  /** delete data from the table: "webhooks_trigger_conditions" */
  deleteWebhooksTriggerConditions?: Maybe<WebhooksTriggerConditionsMutationResponse>;
  /** insert data into the table: "AccessToken" */
  insertAccesstoken?: Maybe<AccesstokenMutationResponse>;
  /** insert a single row into the table: "AccessToken" */
  insertAccesstokenOne?: Maybe<Accesstoken>;
  /** insert data into the table: "Address" */
  insertAddress?: Maybe<AddressMutationResponse>;
  /** insert a single row into the table: "Address" */
  insertAddressOne?: Maybe<Address>;
  /** insert data into the table: "cancellation_policies" */
  insertCancellationPolicies?: Maybe<CancellationPoliciesMutationResponse>;
  /** insert a single row into the table: "cancellation_policies" */
  insertCancellationPoliciesOne?: Maybe<CancellationPolicies>;
  /** insert data into the table: "cancellation_policy_types" */
  insertCancellationPolicyTypes?: Maybe<CancellationPolicyTypesMutationResponse>;
  /** insert a single row into the table: "cancellation_policy_types" */
  insertCancellationPolicyTypesOne?: Maybe<CancellationPolicyTypes>;
  /** insert data into the table: "Courier" */
  insertCourier?: Maybe<CourierMutationResponse>;
  /** insert a single row into the table: "Courier" */
  insertCourierOne?: Maybe<Courier>;
  /** insert data into the table: "courier_payout_settings" */
  insertCourierPayoutSettings?: Maybe<CourierPayoutSettingsMutationResponse>;
  /** insert a single row into the table: "courier_payout_settings" */
  insertCourierPayoutSettingsOne?: Maybe<CourierPayoutSettings>;
  /** insert data into the table: "CourierProfile" */
  insertCourierprofile?: Maybe<CourierprofileMutationResponse>;
  /** insert a single row into the table: "CourierProfile" */
  insertCourierprofileOne?: Maybe<Courierprofile>;
  /** insert data into the table: "CourierReport" */
  insertCourierreport?: Maybe<CourierreportMutationResponse>;
  /** insert a single row into the table: "CourierReport" */
  insertCourierreportOne?: Maybe<Courierreport>;
  /** insert data into the table: "csv_export" */
  insertCsvExport?: Maybe<CsvExportMutationResponse>;
  /** insert a single row into the table: "csv_export" */
  insertCsvExportOne?: Maybe<CsvExport>;
  /** insert data into the table: "delivery_types" */
  insertDeliveryTypes?: Maybe<DeliveryTypesMutationResponse>;
  /** insert a single row into the table: "delivery_types" */
  insertDeliveryTypesOne?: Maybe<DeliveryTypes>;
  /** insert data into the table: "DeliveryRate" */
  insertDeliveryrate?: Maybe<DeliveryrateMutationResponse>;
  /** insert a single row into the table: "DeliveryRate" */
  insertDeliveryrateOne?: Maybe<Deliveryrate>;
  /** insert data into the table: "GlobalSettings" */
  insertGlobalsettings?: Maybe<GlobalsettingsMutationResponse>;
  /** insert a single row into the table: "GlobalSettings" */
  insertGlobalsettingsOne?: Maybe<Globalsettings>;
  /** insert data into the table: "insurance_policies" */
  insertInsurancePolicies?: Maybe<InsurancePoliciesMutationResponse>;
  /** insert a single row into the table: "insurance_policies" */
  insertInsurancePoliciesOne?: Maybe<InsurancePolicies>;
  /** insert data into the table: "Invite" */
  insertInvite?: Maybe<InviteMutationResponse>;
  /** insert a single row into the table: "Invite" */
  insertInviteOne?: Maybe<Invite>;
  /** insert data into the table: "live_map_lock" */
  insertLiveMapLock?: Maybe<LiveMapLockMutationResponse>;
  /** insert a single row into the table: "live_map_lock" */
  insertLiveMapLockOne?: Maybe<LiveMapLock>;
  /** insert data into the table: "Member" */
  insertMember?: Maybe<MemberMutationResponse>;
  /** insert a single row into the table: "Member" */
  insertMemberOne?: Maybe<Member>;
  /** insert data into the table: "multiple_stops_policies" */
  insertMultipleStopsPolicies?: Maybe<MultipleStopsPoliciesMutationResponse>;
  /** insert a single row into the table: "multiple_stops_policies" */
  insertMultipleStopsPoliciesOne?: Maybe<MultipleStopsPolicies>;
  /** insert data into the table: "Order" */
  insertOrder?: Maybe<OrderMutationResponse>;
  /** insert data into the table: "order_info" */
  insertOrderInfo?: Maybe<OrderInfoMutationResponse>;
  /** insert a single row into the table: "order_info" */
  insertOrderInfoOne?: Maybe<OrderInfo>;
  /** insert a single row into the table: "Order" */
  insertOrderOne?: Maybe<Order>;
  /** insert data into the table: "order_price" */
  insertOrderPrice?: Maybe<OrderPriceMutationResponse>;
  /** insert a single row into the table: "order_price" */
  insertOrderPriceOne?: Maybe<OrderPrice>;
  /** insert data into the table: "order_status_events" */
  insertOrderStatusEvents?: Maybe<OrderStatusEventsMutationResponse>;
  /** insert a single row into the table: "order_status_events" */
  insertOrderStatusEventsOne?: Maybe<OrderStatusEvents>;
  /** insert data into the table: "order_status_types" */
  insertOrderStatusTypes?: Maybe<OrderStatusTypesMutationResponse>;
  /** insert a single row into the table: "order_status_types" */
  insertOrderStatusTypesOne?: Maybe<OrderStatusTypes>;
  /** insert data into the table: "OrderEvents" */
  insertOrderevents?: Maybe<OrdereventsMutationResponse>;
  /** insert a single row into the table: "OrderEvents" */
  insertOrdereventsOne?: Maybe<Orderevents>;
  /** insert data into the table: "OrderTracking" */
  insertOrdertracking?: Maybe<OrdertrackingMutationResponse>;
  /** insert a single row into the table: "OrderTracking" */
  insertOrdertrackingOne?: Maybe<Ordertracking>;
  /** insert data into the table: "OrderValidationError" */
  insertOrdervalidationerror?: Maybe<OrdervalidationerrorMutationResponse>;
  /** insert a single row into the table: "OrderValidationError" */
  insertOrdervalidationerrorOne?: Maybe<Ordervalidationerror>;
  /** insert data into the table: "Organization" */
  insertOrganization?: Maybe<OrganizationMutationResponse>;
  /** insert a single row into the table: "Organization" */
  insertOrganizationOne?: Maybe<Organization>;
  /** insert data into the table: "organization_pricing_policies" */
  insertOrganizationPricingPolicies?: Maybe<OrganizationPricingPoliciesMutationResponse>;
  /** insert a single row into the table: "organization_pricing_policies" */
  insertOrganizationPricingPoliciesOne?: Maybe<OrganizationPricingPolicies>;
  /** insert data into the table: "OrganizationConfig" */
  insertOrganizationconfig?: Maybe<OrganizationconfigMutationResponse>;
  /** insert a single row into the table: "OrganizationConfig" */
  insertOrganizationconfigOne?: Maybe<Organizationconfig>;
  /** insert data into the table: "OrganizationProfile" */
  insertOrganizationprofile?: Maybe<OrganizationprofileMutationResponse>;
  /** insert a single row into the table: "OrganizationProfile" */
  insertOrganizationprofileOne?: Maybe<Organizationprofile>;
  /** insert data into the table: "pdf" */
  insertPdf?: Maybe<PdfMutationResponse>;
  /** insert a single row into the table: "pdf" */
  insertPdfOne?: Maybe<Pdf>;
  /** insert data into the table: "price_addons" */
  insertPriceAddons?: Maybe<PriceAddonsMutationResponse>;
  /** insert a single row into the table: "price_addons" */
  insertPriceAddonsOne?: Maybe<PriceAddons>;
  /** insert data into the table: "pricing_insurance_bridge" */
  insertPricingInsuranceBridge?: Maybe<PricingInsuranceBridgeMutationResponse>;
  /** insert a single row into the table: "pricing_insurance_bridge" */
  insertPricingInsuranceBridgeOne?: Maybe<PricingInsuranceBridge>;
  /** insert data into the table: "pricing_rules" */
  insertPricingRules?: Maybe<PricingRulesMutationResponse>;
  /** insert a single row into the table: "pricing_rules" */
  insertPricingRulesOne?: Maybe<PricingRules>;
  /** insert data into the table: "processes" */
  insertProcesses?: Maybe<ProcessesMutationResponse>;
  /** insert a single row into the table: "processes" */
  insertProcessesOne?: Maybe<Processes>;
  /** insert data into the table: "Region" */
  insertRegion?: Maybe<RegionMutationResponse>;
  /** insert data into the table: "region_info" */
  insertRegionInfo?: Maybe<RegionInfoMutationResponse>;
  /** insert a single row into the table: "region_info" */
  insertRegionInfoOne?: Maybe<RegionInfo>;
  /** insert a single row into the table: "Region" */
  insertRegionOne?: Maybe<Region>;
  /** insert data into the table: "robolabs_invoice" */
  insertRobolabsInvoice?: Maybe<RobolabsInvoiceMutationResponse>;
  /** insert a single row into the table: "robolabs_invoice" */
  insertRobolabsInvoiceOne?: Maybe<RobolabsInvoice>;
  /** insert data into the table: "robolabs_invoice_status" */
  insertRobolabsInvoiceStatus?: Maybe<RobolabsInvoiceStatusMutationResponse>;
  /** insert a single row into the table: "robolabs_invoice_status" */
  insertRobolabsInvoiceStatusOne?: Maybe<RobolabsInvoiceStatus>;
  /** insert data into the table: "sms_messages" */
  insertSmsMessages?: Maybe<SmsMessagesMutationResponse>;
  /** insert a single row into the table: "sms_messages" */
  insertSmsMessagesOne?: Maybe<SmsMessages>;
  /** insert data into the table: "sms_messages_types" */
  insertSmsMessagesTypes?: Maybe<SmsMessagesTypesMutationResponse>;
  /** insert a single row into the table: "sms_messages_types" */
  insertSmsMessagesTypesOne?: Maybe<SmsMessagesTypes>;
  /** insert data into the table: "Task" */
  insertTask?: Maybe<TaskMutationResponse>;
  /** insert a single row into the table: "Task" */
  insertTaskOne?: Maybe<Task>;
  /** insert data into the table: "task_seq" */
  insertTaskSeq?: Maybe<TaskSeqMutationResponse>;
  /** insert a single row into the table: "task_seq" */
  insertTaskSeqOne?: Maybe<TaskSeq>;
  /** insert data into the table: "TaskETA" */
  insertTasketa?: Maybe<TasketaMutationResponse>;
  /** insert a single row into the table: "TaskETA" */
  insertTasketaOne?: Maybe<Tasketa>;
  /** insert data into the table: "Trip" */
  insertTrip?: Maybe<TripMutationResponse>;
  /** insert a single row into the table: "Trip" */
  insertTripOne?: Maybe<Trip>;
  /** insert data into the table: "User" */
  insertUser?: Maybe<UserMutationResponse>;
  /** insert a single row into the table: "User" */
  insertUserOne?: Maybe<User>;
  /** insert data into the table: "Vehicle" */
  insertVehicle?: Maybe<VehicleMutationResponse>;
  /** insert a single row into the table: "Vehicle" */
  insertVehicleOne?: Maybe<Vehicle>;
  /** insert data into the table: "Webhook" */
  insertWebhook?: Maybe<WebhookMutationResponse>;
  /** insert data into the table: "webhook_invocations" */
  insertWebhookInvocations?: Maybe<WebhookInvocationsMutationResponse>;
  /** insert a single row into the table: "webhook_invocations" */
  insertWebhookInvocationsOne?: Maybe<WebhookInvocations>;
  /** insert a single row into the table: "Webhook" */
  insertWebhookOne?: Maybe<Webhook>;
  /** insert data into the table: "webhook_types" */
  insertWebhookTypes?: Maybe<WebhookTypesMutationResponse>;
  /** insert a single row into the table: "webhook_types" */
  insertWebhookTypesOne?: Maybe<WebhookTypes>;
  /** insert data into the table: "webhooks" */
  insertWebhooks?: Maybe<WebhooksMutationResponse>;
  /** insert a single row into the table: "webhooks" */
  insertWebhooksOne?: Maybe<Webhooks>;
  /** insert data into the table: "webhooks_trigger_conditions" */
  insertWebhooksTriggerConditions?: Maybe<WebhooksTriggerConditionsMutationResponse>;
  /** insert a single row into the table: "webhooks_trigger_conditions" */
  insertWebhooksTriggerConditionsOne?: Maybe<WebhooksTriggerConditions>;
  /** update data of the table: "AccessToken" */
  updateAccesstoken?: Maybe<AccesstokenMutationResponse>;
  /** update single row of the table: "AccessToken" */
  updateAccesstokenByPk?: Maybe<Accesstoken>;
  /** update data of the table: "Address" */
  updateAddress?: Maybe<AddressMutationResponse>;
  /** update single row of the table: "Address" */
  updateAddressByPk?: Maybe<Address>;
  /** update data of the table: "cancellation_policies" */
  updateCancellationPolicies?: Maybe<CancellationPoliciesMutationResponse>;
  /** update single row of the table: "cancellation_policies" */
  updateCancellationPoliciesByPk?: Maybe<CancellationPolicies>;
  /** update data of the table: "cancellation_policy_types" */
  updateCancellationPolicyTypes?: Maybe<CancellationPolicyTypesMutationResponse>;
  /** update single row of the table: "cancellation_policy_types" */
  updateCancellationPolicyTypesByPk?: Maybe<CancellationPolicyTypes>;
  /** update data of the table: "Courier" */
  updateCourier?: Maybe<CourierMutationResponse>;
  /** update single row of the table: "Courier" */
  updateCourierByPk?: Maybe<Courier>;
  /** update data of the table: "courier_payout_settings" */
  updateCourierPayoutSettings?: Maybe<CourierPayoutSettingsMutationResponse>;
  /** update single row of the table: "courier_payout_settings" */
  updateCourierPayoutSettingsByPk?: Maybe<CourierPayoutSettings>;
  /** update data of the table: "CourierProfile" */
  updateCourierprofile?: Maybe<CourierprofileMutationResponse>;
  /** update data of the table: "CourierReport" */
  updateCourierreport?: Maybe<CourierreportMutationResponse>;
  /** update single row of the table: "CourierReport" */
  updateCourierreportByPk?: Maybe<Courierreport>;
  /** update data of the table: "csv_export" */
  updateCsvExport?: Maybe<CsvExportMutationResponse>;
  /** update single row of the table: "csv_export" */
  updateCsvExportByPk?: Maybe<CsvExport>;
  /** update data of the table: "delivery_types" */
  updateDeliveryTypes?: Maybe<DeliveryTypesMutationResponse>;
  /** update single row of the table: "delivery_types" */
  updateDeliveryTypesByPk?: Maybe<DeliveryTypes>;
  /** update data of the table: "DeliveryRate" */
  updateDeliveryrate?: Maybe<DeliveryrateMutationResponse>;
  /** update single row of the table: "DeliveryRate" */
  updateDeliveryrateByPk?: Maybe<Deliveryrate>;
  /** update data of the table: "GlobalSettings" */
  updateGlobalsettings?: Maybe<GlobalsettingsMutationResponse>;
  /** update single row of the table: "GlobalSettings" */
  updateGlobalsettingsByPk?: Maybe<Globalsettings>;
  /** update data of the table: "insurance_policies" */
  updateInsurancePolicies?: Maybe<InsurancePoliciesMutationResponse>;
  /** update single row of the table: "insurance_policies" */
  updateInsurancePoliciesByPk?: Maybe<InsurancePolicies>;
  /** update data of the table: "Invite" */
  updateInvite?: Maybe<InviteMutationResponse>;
  /** update single row of the table: "Invite" */
  updateInviteByPk?: Maybe<Invite>;
  /** update data of the table: "live_map_lock" */
  updateLiveMapLock?: Maybe<LiveMapLockMutationResponse>;
  /** update single row of the table: "live_map_lock" */
  updateLiveMapLockByPk?: Maybe<LiveMapLock>;
  /** update data of the table: "Member" */
  updateMember?: Maybe<MemberMutationResponse>;
  /** update data of the table: "multiple_stops_policies" */
  updateMultipleStopsPolicies?: Maybe<MultipleStopsPoliciesMutationResponse>;
  /** update single row of the table: "multiple_stops_policies" */
  updateMultipleStopsPoliciesByPk?: Maybe<MultipleStopsPolicies>;
  /** update data of the table: "Order" */
  updateOrder?: Maybe<OrderMutationResponse>;
  /** update single row of the table: "Order" */
  updateOrderByPk?: Maybe<Order>;
  /** update data of the table: "order_info" */
  updateOrderInfo?: Maybe<OrderInfoMutationResponse>;
  /** update single row of the table: "order_info" */
  updateOrderInfoByPk?: Maybe<OrderInfo>;
  /** update data of the table: "order_price" */
  updateOrderPrice?: Maybe<OrderPriceMutationResponse>;
  /** update single row of the table: "order_price" */
  updateOrderPriceByPk?: Maybe<OrderPrice>;
  /** update data of the table: "order_status_events" */
  updateOrderStatusEvents?: Maybe<OrderStatusEventsMutationResponse>;
  /** update single row of the table: "order_status_events" */
  updateOrderStatusEventsByPk?: Maybe<OrderStatusEvents>;
  /** update data of the table: "order_status_types" */
  updateOrderStatusTypes?: Maybe<OrderStatusTypesMutationResponse>;
  /** update single row of the table: "order_status_types" */
  updateOrderStatusTypesByPk?: Maybe<OrderStatusTypes>;
  /** update data of the table: "OrderEvents" */
  updateOrderevents?: Maybe<OrdereventsMutationResponse>;
  /** update single row of the table: "OrderEvents" */
  updateOrdereventsByPk?: Maybe<Orderevents>;
  /** update data of the table: "OrderTracking" */
  updateOrdertracking?: Maybe<OrdertrackingMutationResponse>;
  /** update single row of the table: "OrderTracking" */
  updateOrdertrackingByPk?: Maybe<Ordertracking>;
  /** update data of the table: "OrderValidationError" */
  updateOrdervalidationerror?: Maybe<OrdervalidationerrorMutationResponse>;
  /** update single row of the table: "OrderValidationError" */
  updateOrdervalidationerrorByPk?: Maybe<Ordervalidationerror>;
  /** update data of the table: "Organization" */
  updateOrganization?: Maybe<OrganizationMutationResponse>;
  /** update single row of the table: "Organization" */
  updateOrganizationByPk?: Maybe<Organization>;
  /** update data of the table: "organization_pricing_policies" */
  updateOrganizationPricingPolicies?: Maybe<OrganizationPricingPoliciesMutationResponse>;
  /** update single row of the table: "organization_pricing_policies" */
  updateOrganizationPricingPoliciesByPk?: Maybe<OrganizationPricingPolicies>;
  /** update data of the table: "OrganizationConfig" */
  updateOrganizationconfig?: Maybe<OrganizationconfigMutationResponse>;
  /** update single row of the table: "OrganizationConfig" */
  updateOrganizationconfigByPk?: Maybe<Organizationconfig>;
  /** update data of the table: "OrganizationProfile" */
  updateOrganizationprofile?: Maybe<OrganizationprofileMutationResponse>;
  /** update data of the table: "pdf" */
  updatePdf?: Maybe<PdfMutationResponse>;
  /** update single row of the table: "pdf" */
  updatePdfByPk?: Maybe<Pdf>;
  /** update data of the table: "price_addons" */
  updatePriceAddons?: Maybe<PriceAddonsMutationResponse>;
  /** update single row of the table: "price_addons" */
  updatePriceAddonsByPk?: Maybe<PriceAddons>;
  /** update data of the table: "pricing_insurance_bridge" */
  updatePricingInsuranceBridge?: Maybe<PricingInsuranceBridgeMutationResponse>;
  /** update single row of the table: "pricing_insurance_bridge" */
  updatePricingInsuranceBridgeByPk?: Maybe<PricingInsuranceBridge>;
  /** update data of the table: "pricing_rules" */
  updatePricingRules?: Maybe<PricingRulesMutationResponse>;
  /** update single row of the table: "pricing_rules" */
  updatePricingRulesByPk?: Maybe<PricingRules>;
  /** update data of the table: "processes" */
  updateProcesses?: Maybe<ProcessesMutationResponse>;
  /** update single row of the table: "processes" */
  updateProcessesByPk?: Maybe<Processes>;
  /** update data of the table: "Region" */
  updateRegion?: Maybe<RegionMutationResponse>;
  /** update single row of the table: "Region" */
  updateRegionByPk?: Maybe<Region>;
  /** update data of the table: "region_info" */
  updateRegionInfo?: Maybe<RegionInfoMutationResponse>;
  /** update single row of the table: "region_info" */
  updateRegionInfoByPk?: Maybe<RegionInfo>;
  /** update data of the table: "robolabs_invoice" */
  updateRobolabsInvoice?: Maybe<RobolabsInvoiceMutationResponse>;
  /** update single row of the table: "robolabs_invoice" */
  updateRobolabsInvoiceByPk?: Maybe<RobolabsInvoice>;
  /** update data of the table: "robolabs_invoice_status" */
  updateRobolabsInvoiceStatus?: Maybe<RobolabsInvoiceStatusMutationResponse>;
  /** update single row of the table: "robolabs_invoice_status" */
  updateRobolabsInvoiceStatusByPk?: Maybe<RobolabsInvoiceStatus>;
  /** update data of the table: "sms_messages" */
  updateSmsMessages?: Maybe<SmsMessagesMutationResponse>;
  /** update single row of the table: "sms_messages" */
  updateSmsMessagesByPk?: Maybe<SmsMessages>;
  /** update data of the table: "sms_messages_types" */
  updateSmsMessagesTypes?: Maybe<SmsMessagesTypesMutationResponse>;
  /** update single row of the table: "sms_messages_types" */
  updateSmsMessagesTypesByPk?: Maybe<SmsMessagesTypes>;
  /** update data of the table: "Task" */
  updateTask?: Maybe<TaskMutationResponse>;
  /** update single row of the table: "Task" */
  updateTaskByPk?: Maybe<Task>;
  /** update data of the table: "task_seq" */
  updateTaskSeq?: Maybe<TaskSeqMutationResponse>;
  /** update single row of the table: "task_seq" */
  updateTaskSeqByPk?: Maybe<TaskSeq>;
  /** update data of the table: "TaskETA" */
  updateTasketa?: Maybe<TasketaMutationResponse>;
  /** update data of the table: "Trip" */
  updateTrip?: Maybe<TripMutationResponse>;
  /** update data of the table: "User" */
  updateUser?: Maybe<UserMutationResponse>;
  /** update single row of the table: "User" */
  updateUserByPk?: Maybe<User>;
  /** update data of the table: "Vehicle" */
  updateVehicle?: Maybe<VehicleMutationResponse>;
  /** update single row of the table: "Vehicle" */
  updateVehicleByPk?: Maybe<Vehicle>;
  /** update data of the table: "Webhook" */
  updateWebhook?: Maybe<WebhookMutationResponse>;
  /** update single row of the table: "Webhook" */
  updateWebhookByPk?: Maybe<Webhook>;
  /** update data of the table: "webhook_invocations" */
  updateWebhookInvocations?: Maybe<WebhookInvocationsMutationResponse>;
  /** update single row of the table: "webhook_invocations" */
  updateWebhookInvocationsByPk?: Maybe<WebhookInvocations>;
  /** update data of the table: "webhook_types" */
  updateWebhookTypes?: Maybe<WebhookTypesMutationResponse>;
  /** update single row of the table: "webhook_types" */
  updateWebhookTypesByPk?: Maybe<WebhookTypes>;
  /** update data of the table: "webhooks" */
  updateWebhooks?: Maybe<WebhooksMutationResponse>;
  /** update single row of the table: "webhooks" */
  updateWebhooksByPk?: Maybe<Webhooks>;
  /** update data of the table: "webhooks_trigger_conditions" */
  updateWebhooksTriggerConditions?: Maybe<WebhooksTriggerConditionsMutationResponse>;
};


/** mutation root */
export type Mutation_RootCreateApiTokenArgs = {
  name: Scalars['String'];
  role: TokenRole;
};


/** mutation root */
export type Mutation_RootCreateOrderArgs = {
  data: OrderServiceOrderCreateObject;
};


/** mutation root */
export type Mutation_RootCreateOrdersArgs = {
  asyncMode?: InputMaybe<Scalars['Boolean']>;
  data: Array<OrderServiceOrderCreateObject>;
};


/** mutation root */
export type Mutation_RootDeleteAccesstokenArgs = {
  where: AccesstokenBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccesstokenByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteAddressArgs = {
  where: AddressBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAddressByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteCancellationPoliciesArgs = {
  where: CancellationPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCancellationPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteCancellationPolicyTypesArgs = {
  where: CancellationPolicyTypesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCancellationPolicyTypesByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteCourierArgs = {
  where: CourierBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCourierByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCourierPayoutSettingsArgs = {
  where: CourierPayoutSettingsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCourierPayoutSettingsByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCourierprofileArgs = {
  where: CourierprofileBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCourierreportArgs = {
  where: CourierreportBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCourierreportByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCsvExportArgs = {
  where: CsvExportBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteCsvExportByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteDeliveryTypesArgs = {
  where: DeliveryTypesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDeliveryTypesByPkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteDeliveryrateArgs = {
  where: DeliveryrateBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteDeliveryrateByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteGlobalsettingsArgs = {
  where: GlobalsettingsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteGlobalsettingsByPkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteInsurancePoliciesArgs = {
  where: InsurancePoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteInsurancePoliciesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteInviteArgs = {
  where: InviteBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteInviteByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteLiveMapLockArgs = {
  where: LiveMapLockBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteLiveMapLockByPkArgs = {
  region: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteMemberArgs = {
  where: MemberBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMultipleStopsPoliciesArgs = {
  where: MultipleStopsPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteMultipleStopsPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteOrderArgs = {
  where: OrderBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrderInfoArgs = {
  where: OrderInfoBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderInfoByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteOrderPriceArgs = {
  where: OrderPriceBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderPriceByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteOrderStatusEventsArgs = {
  where: OrderStatusEventsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderStatusEventsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteOrderStatusTypesArgs = {
  where: OrderStatusTypesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrderStatusTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrdereventsArgs = {
  where: OrdereventsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrdereventsByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrdertrackingArgs = {
  where: OrdertrackingBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrdertrackingByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrdervalidationerrorArgs = {
  where: OrdervalidationerrorBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrdervalidationerrorByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrganizationArgs = {
  where: OrganizationBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrganizationByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrganizationPricingPoliciesArgs = {
  where: OrganizationPricingPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrganizationPricingPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteOrganizationconfigArgs = {
  where: OrganizationconfigBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteOrganizationconfigByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteOrganizationprofileArgs = {
  where: OrganizationprofileBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePdfArgs = {
  where: PdfBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePdfByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeletePriceAddonsArgs = {
  where: PriceAddonsBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePriceAddonsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeletePricingInsuranceBridgeArgs = {
  where: PricingInsuranceBridgeBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePricingInsuranceBridgeByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeletePricingRulesArgs = {
  where: PricingRulesBoolExp;
};


/** mutation root */
export type Mutation_RootDeletePricingRulesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteProcessesArgs = {
  where: ProcessesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteProcessesByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteRegionArgs = {
  where: RegionBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRegionByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteRegionInfoArgs = {
  where: RegionInfoBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRegionInfoByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteRobolabsInvoiceArgs = {
  where: RobolabsInvoiceBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRobolabsInvoiceByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteRobolabsInvoiceStatusArgs = {
  where: RobolabsInvoiceStatusBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteRobolabsInvoiceStatusByPkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteSmsMessagesArgs = {
  where: SmsMessagesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSmsMessagesByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteSmsMessagesTypesArgs = {
  where: SmsMessagesTypesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteSmsMessagesTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteTaskArgs = {
  where: TaskBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTaskByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTaskSeqArgs = {
  where: TaskSeqBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTaskSeqByPkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteTasketaArgs = {
  where: TasketaBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteTripArgs = {
  where: TripBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  where: UserBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteUserByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteVehicleArgs = {
  where: VehicleBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteVehicleByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteWebhookArgs = {
  where: WebhookBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteWebhookByPkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteWebhookInvocationsArgs = {
  where: WebhookInvocationsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteWebhookInvocationsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteWebhookTypesArgs = {
  where: WebhookTypesBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteWebhookTypesByPkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteWebhooksArgs = {
  where: WebhooksBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteWebhooksByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteWebhooksTriggerConditionsArgs = {
  where: WebhooksTriggerConditionsBoolExp;
};


/** mutation root */
export type Mutation_RootInsertAccesstokenArgs = {
  objects: Array<AccesstokenInsertInput>;
  onConflict?: InputMaybe<AccesstokenOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccesstokenOneArgs = {
  object: AccesstokenInsertInput;
  onConflict?: InputMaybe<AccesstokenOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAddressArgs = {
  objects: Array<AddressInsertInput>;
  onConflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAddressOneArgs = {
  object: AddressInsertInput;
  onConflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCancellationPoliciesArgs = {
  objects: Array<CancellationPoliciesInsertInput>;
  onConflict?: InputMaybe<CancellationPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCancellationPoliciesOneArgs = {
  object: CancellationPoliciesInsertInput;
  onConflict?: InputMaybe<CancellationPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCancellationPolicyTypesArgs = {
  objects: Array<CancellationPolicyTypesInsertInput>;
  onConflict?: InputMaybe<CancellationPolicyTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCancellationPolicyTypesOneArgs = {
  object: CancellationPolicyTypesInsertInput;
  onConflict?: InputMaybe<CancellationPolicyTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierArgs = {
  objects: Array<CourierInsertInput>;
  onConflict?: InputMaybe<CourierOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierOneArgs = {
  object: CourierInsertInput;
  onConflict?: InputMaybe<CourierOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierPayoutSettingsArgs = {
  objects: Array<CourierPayoutSettingsInsertInput>;
  onConflict?: InputMaybe<CourierPayoutSettingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierPayoutSettingsOneArgs = {
  object: CourierPayoutSettingsInsertInput;
  onConflict?: InputMaybe<CourierPayoutSettingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierprofileArgs = {
  objects: Array<CourierprofileInsertInput>;
  onConflict?: InputMaybe<CourierprofileOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierprofileOneArgs = {
  object: CourierprofileInsertInput;
  onConflict?: InputMaybe<CourierprofileOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierreportArgs = {
  objects: Array<CourierreportInsertInput>;
  onConflict?: InputMaybe<CourierreportOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCourierreportOneArgs = {
  object: CourierreportInsertInput;
  onConflict?: InputMaybe<CourierreportOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCsvExportArgs = {
  objects: Array<CsvExportInsertInput>;
  onConflict?: InputMaybe<CsvExportOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertCsvExportOneArgs = {
  object: CsvExportInsertInput;
  onConflict?: InputMaybe<CsvExportOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDeliveryTypesArgs = {
  objects: Array<DeliveryTypesInsertInput>;
  onConflict?: InputMaybe<DeliveryTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDeliveryTypesOneArgs = {
  object: DeliveryTypesInsertInput;
  onConflict?: InputMaybe<DeliveryTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDeliveryrateArgs = {
  objects: Array<DeliveryrateInsertInput>;
  onConflict?: InputMaybe<DeliveryrateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertDeliveryrateOneArgs = {
  object: DeliveryrateInsertInput;
  onConflict?: InputMaybe<DeliveryrateOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGlobalsettingsArgs = {
  objects: Array<GlobalsettingsInsertInput>;
  onConflict?: InputMaybe<GlobalsettingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertGlobalsettingsOneArgs = {
  object: GlobalsettingsInsertInput;
  onConflict?: InputMaybe<GlobalsettingsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInsurancePoliciesArgs = {
  objects: Array<InsurancePoliciesInsertInput>;
  onConflict?: InputMaybe<InsurancePoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInsurancePoliciesOneArgs = {
  object: InsurancePoliciesInsertInput;
  onConflict?: InputMaybe<InsurancePoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInviteArgs = {
  objects: Array<InviteInsertInput>;
  onConflict?: InputMaybe<InviteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertInviteOneArgs = {
  object: InviteInsertInput;
  onConflict?: InputMaybe<InviteOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLiveMapLockArgs = {
  objects: Array<LiveMapLockInsertInput>;
  onConflict?: InputMaybe<LiveMapLockOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertLiveMapLockOneArgs = {
  object: LiveMapLockInsertInput;
  onConflict?: InputMaybe<LiveMapLockOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMemberArgs = {
  objects: Array<MemberInsertInput>;
  onConflict?: InputMaybe<MemberOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMemberOneArgs = {
  object: MemberInsertInput;
  onConflict?: InputMaybe<MemberOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMultipleStopsPoliciesArgs = {
  objects: Array<MultipleStopsPoliciesInsertInput>;
  onConflict?: InputMaybe<MultipleStopsPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertMultipleStopsPoliciesOneArgs = {
  object: MultipleStopsPoliciesInsertInput;
  onConflict?: InputMaybe<MultipleStopsPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderArgs = {
  objects: Array<OrderInsertInput>;
  onConflict?: InputMaybe<OrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderInfoArgs = {
  objects: Array<OrderInfoInsertInput>;
  onConflict?: InputMaybe<OrderInfoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderInfoOneArgs = {
  object: OrderInfoInsertInput;
  onConflict?: InputMaybe<OrderInfoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderOneArgs = {
  object: OrderInsertInput;
  onConflict?: InputMaybe<OrderOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderPriceArgs = {
  objects: Array<OrderPriceInsertInput>;
  onConflict?: InputMaybe<OrderPriceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderPriceOneArgs = {
  object: OrderPriceInsertInput;
  onConflict?: InputMaybe<OrderPriceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderStatusEventsArgs = {
  objects: Array<OrderStatusEventsInsertInput>;
  onConflict?: InputMaybe<OrderStatusEventsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderStatusEventsOneArgs = {
  object: OrderStatusEventsInsertInput;
  onConflict?: InputMaybe<OrderStatusEventsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderStatusTypesArgs = {
  objects: Array<OrderStatusTypesInsertInput>;
  onConflict?: InputMaybe<OrderStatusTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrderStatusTypesOneArgs = {
  object: OrderStatusTypesInsertInput;
  onConflict?: InputMaybe<OrderStatusTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdereventsArgs = {
  objects: Array<OrdereventsInsertInput>;
  onConflict?: InputMaybe<OrdereventsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdereventsOneArgs = {
  object: OrdereventsInsertInput;
  onConflict?: InputMaybe<OrdereventsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdertrackingArgs = {
  objects: Array<OrdertrackingInsertInput>;
  onConflict?: InputMaybe<OrdertrackingOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdertrackingOneArgs = {
  object: OrdertrackingInsertInput;
  onConflict?: InputMaybe<OrdertrackingOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdervalidationerrorArgs = {
  objects: Array<OrdervalidationerrorInsertInput>;
  onConflict?: InputMaybe<OrdervalidationerrorOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrdervalidationerrorOneArgs = {
  object: OrdervalidationerrorInsertInput;
  onConflict?: InputMaybe<OrdervalidationerrorOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationArgs = {
  objects: Array<OrganizationInsertInput>;
  onConflict?: InputMaybe<OrganizationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationOneArgs = {
  object: OrganizationInsertInput;
  onConflict?: InputMaybe<OrganizationOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationPricingPoliciesArgs = {
  objects: Array<OrganizationPricingPoliciesInsertInput>;
  onConflict?: InputMaybe<OrganizationPricingPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationPricingPoliciesOneArgs = {
  object: OrganizationPricingPoliciesInsertInput;
  onConflict?: InputMaybe<OrganizationPricingPoliciesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationconfigArgs = {
  objects: Array<OrganizationconfigInsertInput>;
  onConflict?: InputMaybe<OrganizationconfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationconfigOneArgs = {
  object: OrganizationconfigInsertInput;
  onConflict?: InputMaybe<OrganizationconfigOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationprofileArgs = {
  objects: Array<OrganizationprofileInsertInput>;
  onConflict?: InputMaybe<OrganizationprofileOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertOrganizationprofileOneArgs = {
  object: OrganizationprofileInsertInput;
  onConflict?: InputMaybe<OrganizationprofileOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPdfArgs = {
  objects: Array<PdfInsertInput>;
  onConflict?: InputMaybe<PdfOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPdfOneArgs = {
  object: PdfInsertInput;
  onConflict?: InputMaybe<PdfOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPriceAddonsArgs = {
  objects: Array<PriceAddonsInsertInput>;
  onConflict?: InputMaybe<PriceAddonsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPriceAddonsOneArgs = {
  object: PriceAddonsInsertInput;
  onConflict?: InputMaybe<PriceAddonsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPricingInsuranceBridgeArgs = {
  objects: Array<PricingInsuranceBridgeInsertInput>;
  onConflict?: InputMaybe<PricingInsuranceBridgeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPricingInsuranceBridgeOneArgs = {
  object: PricingInsuranceBridgeInsertInput;
  onConflict?: InputMaybe<PricingInsuranceBridgeOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPricingRulesArgs = {
  objects: Array<PricingRulesInsertInput>;
  onConflict?: InputMaybe<PricingRulesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertPricingRulesOneArgs = {
  object: PricingRulesInsertInput;
  onConflict?: InputMaybe<PricingRulesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcessesArgs = {
  objects: Array<ProcessesInsertInput>;
  onConflict?: InputMaybe<ProcessesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertProcessesOneArgs = {
  object: ProcessesInsertInput;
  onConflict?: InputMaybe<ProcessesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRegionArgs = {
  objects: Array<RegionInsertInput>;
  onConflict?: InputMaybe<RegionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRegionInfoArgs = {
  objects: Array<RegionInfoInsertInput>;
  onConflict?: InputMaybe<RegionInfoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRegionInfoOneArgs = {
  object: RegionInfoInsertInput;
  onConflict?: InputMaybe<RegionInfoOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRegionOneArgs = {
  object: RegionInsertInput;
  onConflict?: InputMaybe<RegionOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRobolabsInvoiceArgs = {
  objects: Array<RobolabsInvoiceInsertInput>;
  onConflict?: InputMaybe<RobolabsInvoiceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRobolabsInvoiceOneArgs = {
  object: RobolabsInvoiceInsertInput;
  onConflict?: InputMaybe<RobolabsInvoiceOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRobolabsInvoiceStatusArgs = {
  objects: Array<RobolabsInvoiceStatusInsertInput>;
  onConflict?: InputMaybe<RobolabsInvoiceStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertRobolabsInvoiceStatusOneArgs = {
  object: RobolabsInvoiceStatusInsertInput;
  onConflict?: InputMaybe<RobolabsInvoiceStatusOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSmsMessagesArgs = {
  objects: Array<SmsMessagesInsertInput>;
  onConflict?: InputMaybe<SmsMessagesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSmsMessagesOneArgs = {
  object: SmsMessagesInsertInput;
  onConflict?: InputMaybe<SmsMessagesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSmsMessagesTypesArgs = {
  objects: Array<SmsMessagesTypesInsertInput>;
  onConflict?: InputMaybe<SmsMessagesTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertSmsMessagesTypesOneArgs = {
  object: SmsMessagesTypesInsertInput;
  onConflict?: InputMaybe<SmsMessagesTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTaskArgs = {
  objects: Array<TaskInsertInput>;
  onConflict?: InputMaybe<TaskOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTaskOneArgs = {
  object: TaskInsertInput;
  onConflict?: InputMaybe<TaskOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTaskSeqArgs = {
  objects: Array<TaskSeqInsertInput>;
  onConflict?: InputMaybe<TaskSeqOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTaskSeqOneArgs = {
  object: TaskSeqInsertInput;
  onConflict?: InputMaybe<TaskSeqOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTasketaArgs = {
  objects: Array<TasketaInsertInput>;
  onConflict?: InputMaybe<TasketaOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTasketaOneArgs = {
  object: TasketaInsertInput;
  onConflict?: InputMaybe<TasketaOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTripArgs = {
  objects: Array<TripInsertInput>;
  onConflict?: InputMaybe<TripOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertTripOneArgs = {
  object: TripInsertInput;
  onConflict?: InputMaybe<TripOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  onConflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertUserOneArgs = {
  object: UserInsertInput;
  onConflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVehicleArgs = {
  objects: Array<VehicleInsertInput>;
  onConflict?: InputMaybe<VehicleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertVehicleOneArgs = {
  object: VehicleInsertInput;
  onConflict?: InputMaybe<VehicleOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookArgs = {
  objects: Array<WebhookInsertInput>;
  onConflict?: InputMaybe<WebhookOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookInvocationsArgs = {
  objects: Array<WebhookInvocationsInsertInput>;
  onConflict?: InputMaybe<WebhookInvocationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookInvocationsOneArgs = {
  object: WebhookInvocationsInsertInput;
  onConflict?: InputMaybe<WebhookInvocationsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookOneArgs = {
  object: WebhookInsertInput;
  onConflict?: InputMaybe<WebhookOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookTypesArgs = {
  objects: Array<WebhookTypesInsertInput>;
  onConflict?: InputMaybe<WebhookTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhookTypesOneArgs = {
  object: WebhookTypesInsertInput;
  onConflict?: InputMaybe<WebhookTypesOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhooksArgs = {
  objects: Array<WebhooksInsertInput>;
  onConflict?: InputMaybe<WebhooksOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhooksOneArgs = {
  object: WebhooksInsertInput;
  onConflict?: InputMaybe<WebhooksOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhooksTriggerConditionsArgs = {
  objects: Array<WebhooksTriggerConditionsInsertInput>;
  onConflict?: InputMaybe<WebhooksTriggerConditionsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertWebhooksTriggerConditionsOneArgs = {
  object: WebhooksTriggerConditionsInsertInput;
  onConflict?: InputMaybe<WebhooksTriggerConditionsOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAccesstokenArgs = {
  _set?: InputMaybe<AccesstokenSetInput>;
  where: AccesstokenBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccesstokenByPkArgs = {
  _set?: InputMaybe<AccesstokenSetInput>;
  pk_columns: AccesstokenPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAddressArgs = {
  _append?: InputMaybe<AddressAppendInput>;
  _delete_at_path?: InputMaybe<AddressDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AddressDeleteElemInput>;
  _delete_key?: InputMaybe<AddressDeleteKeyInput>;
  _prepend?: InputMaybe<AddressPrependInput>;
  _set?: InputMaybe<AddressSetInput>;
  where: AddressBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAddressByPkArgs = {
  _append?: InputMaybe<AddressAppendInput>;
  _delete_at_path?: InputMaybe<AddressDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AddressDeleteElemInput>;
  _delete_key?: InputMaybe<AddressDeleteKeyInput>;
  _prepend?: InputMaybe<AddressPrependInput>;
  _set?: InputMaybe<AddressSetInput>;
  pk_columns: AddressPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCancellationPoliciesArgs = {
  _inc?: InputMaybe<CancellationPoliciesIncInput>;
  _set?: InputMaybe<CancellationPoliciesSetInput>;
  where: CancellationPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCancellationPoliciesByPkArgs = {
  _inc?: InputMaybe<CancellationPoliciesIncInput>;
  _set?: InputMaybe<CancellationPoliciesSetInput>;
  pk_columns: CancellationPoliciesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCancellationPolicyTypesArgs = {
  _set?: InputMaybe<CancellationPolicyTypesSetInput>;
  where: CancellationPolicyTypesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCancellationPolicyTypesByPkArgs = {
  _set?: InputMaybe<CancellationPolicyTypesSetInput>;
  pk_columns: CancellationPolicyTypesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCourierArgs = {
  _inc?: InputMaybe<CourierIncInput>;
  _set?: InputMaybe<CourierSetInput>;
  where: CourierBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCourierByPkArgs = {
  _inc?: InputMaybe<CourierIncInput>;
  _set?: InputMaybe<CourierSetInput>;
  pk_columns: CourierPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCourierPayoutSettingsArgs = {
  _inc?: InputMaybe<CourierPayoutSettingsIncInput>;
  _set?: InputMaybe<CourierPayoutSettingsSetInput>;
  where: CourierPayoutSettingsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCourierPayoutSettingsByPkArgs = {
  _inc?: InputMaybe<CourierPayoutSettingsIncInput>;
  _set?: InputMaybe<CourierPayoutSettingsSetInput>;
  pk_columns: CourierPayoutSettingsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCourierprofileArgs = {
  _inc?: InputMaybe<CourierprofileIncInput>;
  _set?: InputMaybe<CourierprofileSetInput>;
  where: CourierprofileBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCourierreportArgs = {
  _inc?: InputMaybe<CourierreportIncInput>;
  _set?: InputMaybe<CourierreportSetInput>;
  where: CourierreportBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCourierreportByPkArgs = {
  _inc?: InputMaybe<CourierreportIncInput>;
  _set?: InputMaybe<CourierreportSetInput>;
  pk_columns: CourierreportPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateCsvExportArgs = {
  _append?: InputMaybe<CsvExportAppendInput>;
  _delete_at_path?: InputMaybe<CsvExportDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CsvExportDeleteElemInput>;
  _delete_key?: InputMaybe<CsvExportDeleteKeyInput>;
  _inc?: InputMaybe<CsvExportIncInput>;
  _prepend?: InputMaybe<CsvExportPrependInput>;
  _set?: InputMaybe<CsvExportSetInput>;
  where: CsvExportBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateCsvExportByPkArgs = {
  _append?: InputMaybe<CsvExportAppendInput>;
  _delete_at_path?: InputMaybe<CsvExportDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CsvExportDeleteElemInput>;
  _delete_key?: InputMaybe<CsvExportDeleteKeyInput>;
  _inc?: InputMaybe<CsvExportIncInput>;
  _prepend?: InputMaybe<CsvExportPrependInput>;
  _set?: InputMaybe<CsvExportSetInput>;
  pk_columns: CsvExportPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDeliveryTypesArgs = {
  _set?: InputMaybe<DeliveryTypesSetInput>;
  where: DeliveryTypesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDeliveryTypesByPkArgs = {
  _set?: InputMaybe<DeliveryTypesSetInput>;
  pk_columns: DeliveryTypesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateDeliveryrateArgs = {
  _inc?: InputMaybe<DeliveryrateIncInput>;
  _set?: InputMaybe<DeliveryrateSetInput>;
  where: DeliveryrateBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateDeliveryrateByPkArgs = {
  _inc?: InputMaybe<DeliveryrateIncInput>;
  _set?: InputMaybe<DeliveryrateSetInput>;
  pk_columns: DeliveryratePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateGlobalsettingsArgs = {
  _append?: InputMaybe<GlobalsettingsAppendInput>;
  _delete_at_path?: InputMaybe<GlobalsettingsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GlobalsettingsDeleteElemInput>;
  _delete_key?: InputMaybe<GlobalsettingsDeleteKeyInput>;
  _prepend?: InputMaybe<GlobalsettingsPrependInput>;
  _set?: InputMaybe<GlobalsettingsSetInput>;
  where: GlobalsettingsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateGlobalsettingsByPkArgs = {
  _append?: InputMaybe<GlobalsettingsAppendInput>;
  _delete_at_path?: InputMaybe<GlobalsettingsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GlobalsettingsDeleteElemInput>;
  _delete_key?: InputMaybe<GlobalsettingsDeleteKeyInput>;
  _prepend?: InputMaybe<GlobalsettingsPrependInput>;
  _set?: InputMaybe<GlobalsettingsSetInput>;
  pk_columns: GlobalsettingsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateInsurancePoliciesArgs = {
  _inc?: InputMaybe<InsurancePoliciesIncInput>;
  _set?: InputMaybe<InsurancePoliciesSetInput>;
  where: InsurancePoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateInsurancePoliciesByPkArgs = {
  _inc?: InputMaybe<InsurancePoliciesIncInput>;
  _set?: InputMaybe<InsurancePoliciesSetInput>;
  pk_columns: InsurancePoliciesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateInviteArgs = {
  _set?: InputMaybe<InviteSetInput>;
  where: InviteBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateInviteByPkArgs = {
  _set?: InputMaybe<InviteSetInput>;
  pk_columns: InvitePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateLiveMapLockArgs = {
  _set?: InputMaybe<LiveMapLockSetInput>;
  where: LiveMapLockBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateLiveMapLockByPkArgs = {
  _set?: InputMaybe<LiveMapLockSetInput>;
  pk_columns: LiveMapLockPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateMemberArgs = {
  _set?: InputMaybe<MemberSetInput>;
  where: MemberBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMultipleStopsPoliciesArgs = {
  _inc?: InputMaybe<MultipleStopsPoliciesIncInput>;
  _set?: InputMaybe<MultipleStopsPoliciesSetInput>;
  where: MultipleStopsPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateMultipleStopsPoliciesByPkArgs = {
  _inc?: InputMaybe<MultipleStopsPoliciesIncInput>;
  _set?: InputMaybe<MultipleStopsPoliciesSetInput>;
  pk_columns: MultipleStopsPoliciesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderArgs = {
  _inc?: InputMaybe<OrderIncInput>;
  _set?: InputMaybe<OrderSetInput>;
  where: OrderBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderByPkArgs = {
  _inc?: InputMaybe<OrderIncInput>;
  _set?: InputMaybe<OrderSetInput>;
  pk_columns: OrderPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderInfoArgs = {
  _set?: InputMaybe<OrderInfoSetInput>;
  where: OrderInfoBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderInfoByPkArgs = {
  _set?: InputMaybe<OrderInfoSetInput>;
  pk_columns: OrderInfoPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderPriceArgs = {
  _append?: InputMaybe<OrderPriceAppendInput>;
  _delete_at_path?: InputMaybe<OrderPriceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrderPriceDeleteElemInput>;
  _delete_key?: InputMaybe<OrderPriceDeleteKeyInput>;
  _inc?: InputMaybe<OrderPriceIncInput>;
  _prepend?: InputMaybe<OrderPricePrependInput>;
  _set?: InputMaybe<OrderPriceSetInput>;
  where: OrderPriceBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderPriceByPkArgs = {
  _append?: InputMaybe<OrderPriceAppendInput>;
  _delete_at_path?: InputMaybe<OrderPriceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrderPriceDeleteElemInput>;
  _delete_key?: InputMaybe<OrderPriceDeleteKeyInput>;
  _inc?: InputMaybe<OrderPriceIncInput>;
  _prepend?: InputMaybe<OrderPricePrependInput>;
  _set?: InputMaybe<OrderPriceSetInput>;
  pk_columns: OrderPricePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderStatusEventsArgs = {
  _inc?: InputMaybe<OrderStatusEventsIncInput>;
  _set?: InputMaybe<OrderStatusEventsSetInput>;
  where: OrderStatusEventsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderStatusEventsByPkArgs = {
  _inc?: InputMaybe<OrderStatusEventsIncInput>;
  _set?: InputMaybe<OrderStatusEventsSetInput>;
  pk_columns: OrderStatusEventsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrderStatusTypesArgs = {
  _set?: InputMaybe<OrderStatusTypesSetInput>;
  where: OrderStatusTypesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrderStatusTypesByPkArgs = {
  _set?: InputMaybe<OrderStatusTypesSetInput>;
  pk_columns: OrderStatusTypesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrdereventsArgs = {
  _append?: InputMaybe<OrdereventsAppendInput>;
  _delete_at_path?: InputMaybe<OrdereventsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrdereventsDeleteElemInput>;
  _delete_key?: InputMaybe<OrdereventsDeleteKeyInput>;
  _prepend?: InputMaybe<OrdereventsPrependInput>;
  _set?: InputMaybe<OrdereventsSetInput>;
  where: OrdereventsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrdereventsByPkArgs = {
  _append?: InputMaybe<OrdereventsAppendInput>;
  _delete_at_path?: InputMaybe<OrdereventsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrdereventsDeleteElemInput>;
  _delete_key?: InputMaybe<OrdereventsDeleteKeyInput>;
  _prepend?: InputMaybe<OrdereventsPrependInput>;
  _set?: InputMaybe<OrdereventsSetInput>;
  pk_columns: OrdereventsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrdertrackingArgs = {
  _set?: InputMaybe<OrdertrackingSetInput>;
  where: OrdertrackingBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrdertrackingByPkArgs = {
  _set?: InputMaybe<OrdertrackingSetInput>;
  pk_columns: OrdertrackingPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrdervalidationerrorArgs = {
  _append?: InputMaybe<OrdervalidationerrorAppendInput>;
  _delete_at_path?: InputMaybe<OrdervalidationerrorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrdervalidationerrorDeleteElemInput>;
  _delete_key?: InputMaybe<OrdervalidationerrorDeleteKeyInput>;
  _prepend?: InputMaybe<OrdervalidationerrorPrependInput>;
  _set?: InputMaybe<OrdervalidationerrorSetInput>;
  where: OrdervalidationerrorBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrdervalidationerrorByPkArgs = {
  _append?: InputMaybe<OrdervalidationerrorAppendInput>;
  _delete_at_path?: InputMaybe<OrdervalidationerrorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrdervalidationerrorDeleteElemInput>;
  _delete_key?: InputMaybe<OrdervalidationerrorDeleteKeyInput>;
  _prepend?: InputMaybe<OrdervalidationerrorPrependInput>;
  _set?: InputMaybe<OrdervalidationerrorSetInput>;
  pk_columns: OrdervalidationerrorPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationArgs = {
  _append?: InputMaybe<OrganizationAppendInput>;
  _delete_at_path?: InputMaybe<OrganizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrganizationDeleteElemInput>;
  _delete_key?: InputMaybe<OrganizationDeleteKeyInput>;
  _prepend?: InputMaybe<OrganizationPrependInput>;
  _set?: InputMaybe<OrganizationSetInput>;
  where: OrganizationBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationByPkArgs = {
  _append?: InputMaybe<OrganizationAppendInput>;
  _delete_at_path?: InputMaybe<OrganizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrganizationDeleteElemInput>;
  _delete_key?: InputMaybe<OrganizationDeleteKeyInput>;
  _prepend?: InputMaybe<OrganizationPrependInput>;
  _set?: InputMaybe<OrganizationSetInput>;
  pk_columns: OrganizationPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationPricingPoliciesArgs = {
  _set?: InputMaybe<OrganizationPricingPoliciesSetInput>;
  where: OrganizationPricingPoliciesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationPricingPoliciesByPkArgs = {
  _set?: InputMaybe<OrganizationPricingPoliciesSetInput>;
  pk_columns: OrganizationPricingPoliciesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationconfigArgs = {
  _set?: InputMaybe<OrganizationconfigSetInput>;
  where: OrganizationconfigBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationconfigByPkArgs = {
  _set?: InputMaybe<OrganizationconfigSetInput>;
  pk_columns: OrganizationconfigPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateOrganizationprofileArgs = {
  _set?: InputMaybe<OrganizationprofileSetInput>;
  where: OrganizationprofileBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePdfArgs = {
  _set?: InputMaybe<PdfSetInput>;
  where: PdfBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePdfByPkArgs = {
  _set?: InputMaybe<PdfSetInput>;
  pk_columns: PdfPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePriceAddonsArgs = {
  _inc?: InputMaybe<PriceAddonsIncInput>;
  _set?: InputMaybe<PriceAddonsSetInput>;
  where: PriceAddonsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePriceAddonsByPkArgs = {
  _inc?: InputMaybe<PriceAddonsIncInput>;
  _set?: InputMaybe<PriceAddonsSetInput>;
  pk_columns: PriceAddonsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePricingInsuranceBridgeArgs = {
  _set?: InputMaybe<PricingInsuranceBridgeSetInput>;
  where: PricingInsuranceBridgeBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePricingInsuranceBridgeByPkArgs = {
  _set?: InputMaybe<PricingInsuranceBridgeSetInput>;
  pk_columns: PricingInsuranceBridgePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdatePricingRulesArgs = {
  _inc?: InputMaybe<PricingRulesIncInput>;
  _set?: InputMaybe<PricingRulesSetInput>;
  where: PricingRulesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdatePricingRulesByPkArgs = {
  _inc?: InputMaybe<PricingRulesIncInput>;
  _set?: InputMaybe<PricingRulesSetInput>;
  pk_columns: PricingRulesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateProcessesArgs = {
  _inc?: InputMaybe<ProcessesIncInput>;
  _set?: InputMaybe<ProcessesSetInput>;
  where: ProcessesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateProcessesByPkArgs = {
  _inc?: InputMaybe<ProcessesIncInput>;
  _set?: InputMaybe<ProcessesSetInput>;
  pk_columns: ProcessesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRegionArgs = {
  _append?: InputMaybe<RegionAppendInput>;
  _delete_at_path?: InputMaybe<RegionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegionDeleteElemInput>;
  _delete_key?: InputMaybe<RegionDeleteKeyInput>;
  _prepend?: InputMaybe<RegionPrependInput>;
  _set?: InputMaybe<RegionSetInput>;
  where: RegionBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRegionByPkArgs = {
  _append?: InputMaybe<RegionAppendInput>;
  _delete_at_path?: InputMaybe<RegionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RegionDeleteElemInput>;
  _delete_key?: InputMaybe<RegionDeleteKeyInput>;
  _prepend?: InputMaybe<RegionPrependInput>;
  _set?: InputMaybe<RegionSetInput>;
  pk_columns: RegionPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRegionInfoArgs = {
  _inc?: InputMaybe<RegionInfoIncInput>;
  _set?: InputMaybe<RegionInfoSetInput>;
  where: RegionInfoBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRegionInfoByPkArgs = {
  _inc?: InputMaybe<RegionInfoIncInput>;
  _set?: InputMaybe<RegionInfoSetInput>;
  pk_columns: RegionInfoPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRobolabsInvoiceArgs = {
  _set?: InputMaybe<RobolabsInvoiceSetInput>;
  where: RobolabsInvoiceBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRobolabsInvoiceByPkArgs = {
  _set?: InputMaybe<RobolabsInvoiceSetInput>;
  pk_columns: RobolabsInvoicePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateRobolabsInvoiceStatusArgs = {
  _set?: InputMaybe<RobolabsInvoiceStatusSetInput>;
  where: RobolabsInvoiceStatusBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateRobolabsInvoiceStatusByPkArgs = {
  _set?: InputMaybe<RobolabsInvoiceStatusSetInput>;
  pk_columns: RobolabsInvoiceStatusPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSmsMessagesArgs = {
  _set?: InputMaybe<SmsMessagesSetInput>;
  where: SmsMessagesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSmsMessagesByPkArgs = {
  _set?: InputMaybe<SmsMessagesSetInput>;
  pk_columns: SmsMessagesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateSmsMessagesTypesArgs = {
  _set?: InputMaybe<SmsMessagesTypesSetInput>;
  where: SmsMessagesTypesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateSmsMessagesTypesByPkArgs = {
  _set?: InputMaybe<SmsMessagesTypesSetInput>;
  pk_columns: SmsMessagesTypesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTaskArgs = {
  _inc?: InputMaybe<TaskIncInput>;
  _set?: InputMaybe<TaskSetInput>;
  where: TaskBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTaskByPkArgs = {
  _inc?: InputMaybe<TaskIncInput>;
  _set?: InputMaybe<TaskSetInput>;
  pk_columns: TaskPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTaskSeqArgs = {
  _inc?: InputMaybe<TaskSeqIncInput>;
  _set?: InputMaybe<TaskSeqSetInput>;
  where: TaskSeqBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTaskSeqByPkArgs = {
  _inc?: InputMaybe<TaskSeqIncInput>;
  _set?: InputMaybe<TaskSeqSetInput>;
  pk_columns: TaskSeqPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateTasketaArgs = {
  _inc?: InputMaybe<TasketaIncInput>;
  _set?: InputMaybe<TasketaSetInput>;
  where: TasketaBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateTripArgs = {
  _inc?: InputMaybe<TripIncInput>;
  _set?: InputMaybe<TripSetInput>;
  where: TripBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateUserByPkArgs = {
  _set?: InputMaybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateVehicleArgs = {
  _inc?: InputMaybe<VehicleIncInput>;
  _set?: InputMaybe<VehicleSetInput>;
  where: VehicleBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateVehicleByPkArgs = {
  _inc?: InputMaybe<VehicleIncInput>;
  _set?: InputMaybe<VehicleSetInput>;
  pk_columns: VehiclePkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateWebhookArgs = {
  _set?: InputMaybe<WebhookSetInput>;
  where: WebhookBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateWebhookByPkArgs = {
  _set?: InputMaybe<WebhookSetInput>;
  pk_columns: WebhookPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateWebhookInvocationsArgs = {
  _append?: InputMaybe<WebhookInvocationsAppendInput>;
  _delete_at_path?: InputMaybe<WebhookInvocationsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WebhookInvocationsDeleteElemInput>;
  _delete_key?: InputMaybe<WebhookInvocationsDeleteKeyInput>;
  _inc?: InputMaybe<WebhookInvocationsIncInput>;
  _prepend?: InputMaybe<WebhookInvocationsPrependInput>;
  _set?: InputMaybe<WebhookInvocationsSetInput>;
  where: WebhookInvocationsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateWebhookInvocationsByPkArgs = {
  _append?: InputMaybe<WebhookInvocationsAppendInput>;
  _delete_at_path?: InputMaybe<WebhookInvocationsDeleteAtPathInput>;
  _delete_elem?: InputMaybe<WebhookInvocationsDeleteElemInput>;
  _delete_key?: InputMaybe<WebhookInvocationsDeleteKeyInput>;
  _inc?: InputMaybe<WebhookInvocationsIncInput>;
  _prepend?: InputMaybe<WebhookInvocationsPrependInput>;
  _set?: InputMaybe<WebhookInvocationsSetInput>;
  pk_columns: WebhookInvocationsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateWebhookTypesArgs = {
  _set?: InputMaybe<WebhookTypesSetInput>;
  where: WebhookTypesBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateWebhookTypesByPkArgs = {
  _set?: InputMaybe<WebhookTypesSetInput>;
  pk_columns: WebhookTypesPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateWebhooksArgs = {
  _inc?: InputMaybe<WebhooksIncInput>;
  _set?: InputMaybe<WebhooksSetInput>;
  where: WebhooksBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateWebhooksByPkArgs = {
  _inc?: InputMaybe<WebhooksIncInput>;
  _set?: InputMaybe<WebhooksSetInput>;
  pk_columns: WebhooksPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateWebhooksTriggerConditionsArgs = {
  _set?: InputMaybe<WebhooksTriggerConditionsSetInput>;
  where: WebhooksTriggerConditionsBoolExp;
};

export type Numeric_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ascNullsFirst',
  /** in ascending order, nulls last */
  AscNullsLast = 'ascNullsLast',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'descNullsFirst',
  /** in descending order, nulls last */
  DescNullsLast = 'descNullsLast'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "GlobalSettings" */
  GlobalSettings: Array<Globalsettings>;
  /** fetch data from the table: "OrderTracking" */
  OrderTracking: Array<Ordertracking>;
  /** fetch data from the table: "OrderValidationError" */
  OrderValidationError: Array<Ordervalidationerror>;
  /** fetch data from the table: "AccessToken" */
  accessToken: Array<Accesstoken>;
  /** fetch aggregated fields from the table: "AccessToken" */
  accessTokenAggregate: AccesstokenAggregate;
  /** fetch data from the table: "AccessToken" using primary key columns */
  accessTokenByPk?: Maybe<Accesstoken>;
  /** fetch data from the table: "Address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "Address" using primary key columns */
  addressByPk?: Maybe<Address>;
  auth?: Maybe<AuthOutput>;
  authorizationServiceHealth: Scalars['Int'];
  /** An array relationship */
  cancellationPolicies: Array<CancellationPolicies>;
  /** An aggregate relationship */
  cancellationPoliciesAggregate: CancellationPoliciesAggregate;
  /** fetch data from the table: "cancellation_policies" using primary key columns */
  cancellationPoliciesByPk?: Maybe<CancellationPolicies>;
  /** fetch data from the table: "cancellation_policy_types" */
  cancellationPolicyTypes: Array<CancellationPolicyTypes>;
  /** fetch aggregated fields from the table: "cancellation_policy_types" */
  cancellationPolicyTypesAggregate: CancellationPolicyTypesAggregate;
  /** fetch data from the table: "cancellation_policy_types" using primary key columns */
  cancellationPolicyTypesByPk?: Maybe<CancellationPolicyTypes>;
  /** fetch data from the table: "Courier" */
  courier: Array<Courier>;
  /** fetch aggregated fields from the table: "Courier" */
  courierAggregate: CourierAggregate;
  courierApp?: Maybe<Query>;
  /** fetch data from the table: "Courier" using primary key columns */
  courierByPk?: Maybe<Courier>;
  /** fetch data from the table: "courier_info" */
  courierInfo: Array<CourierInfo>;
  /** fetch aggregated fields from the table: "courier_info" */
  courierInfoAggregate: CourierInfoAggregate;
  /** fetch data from the table: "courier_payout_settings" */
  courierPayoutSettings: Array<CourierPayoutSettings>;
  /** An aggregate relationship */
  courierPayoutSettingsAggregate: CourierPayoutSettingsAggregate;
  /** fetch data from the table: "courier_payout_settings" using primary key columns */
  courierPayoutSettingsByPk?: Maybe<CourierPayoutSettings>;
  /** fetch data from the table: "CourierProfile" */
  courierProfile: Array<Courierprofile>;
  /** fetch aggregated fields from the table: "CourierProfile" */
  courierProfileAggregate: CourierprofileAggregate;
  /** fetch data from the table: "CourierReport" */
  courierReport: Array<Courierreport>;
  /** fetch aggregated fields from the table: "CourierReport" */
  courierReportAggregate: CourierreportAggregate;
  /** fetch data from the table: "CourierReport" using primary key columns */
  courierReportByPk?: Maybe<Courierreport>;
  /** fetch data from the table: "csv_export" */
  csvExport: Array<CsvExport>;
  /** fetch aggregated fields from the table: "csv_export" */
  csvExportAggregate: CsvExportAggregate;
  /** fetch data from the table: "csv_export" using primary key columns */
  csvExportByPk?: Maybe<CsvExport>;
  /** fetch data from the table: "DeliveryRate" */
  deliveryRate: Array<Deliveryrate>;
  /** fetch aggregated fields from the table: "DeliveryRate" */
  deliveryRateAggregate: DeliveryrateAggregate;
  /** fetch data from the table: "DeliveryRate" using primary key columns */
  deliveryRateByPk?: Maybe<Deliveryrate>;
  /** fetch data from the table: "delivery_types" */
  deliveryTypes: Array<DeliveryTypes>;
  /** fetch aggregated fields from the table: "delivery_types" */
  deliveryTypesAggregate: DeliveryTypesAggregate;
  /** fetch data from the table: "delivery_types" using primary key columns */
  deliveryTypesByPk?: Maybe<DeliveryTypes>;
  /** fetch aggregated fields from the table: "GlobalSettings" */
  globalSettingsAggregate: GlobalsettingsAggregate;
  /** fetch data from the table: "GlobalSettings" using primary key columns */
  globalSettingsByPk?: Maybe<Globalsettings>;
  /** fetch data from the table: "insurance_policies" */
  insurancePolicies: Array<InsurancePolicies>;
  /** fetch aggregated fields from the table: "insurance_policies" */
  insurancePoliciesAggregate: InsurancePoliciesAggregate;
  /** fetch data from the table: "insurance_policies" using primary key columns */
  insurancePoliciesByPk?: Maybe<InsurancePolicies>;
  /** fetch data from the table: "Invite" */
  invite: Array<Invite>;
  /** fetch aggregated fields from the table: "Invite" */
  inviteAggregate: InviteAggregate;
  /** fetch data from the table: "Invite" using primary key columns */
  inviteByPk?: Maybe<Invite>;
  /** fetch data from the table: "live_map_lock" */
  liveMapLock: Array<LiveMapLock>;
  /** fetch aggregated fields from the table: "live_map_lock" */
  liveMapLockAggregate: LiveMapLockAggregate;
  /** fetch data from the table: "live_map_lock" using primary key columns */
  liveMapLockByPk?: Maybe<LiveMapLock>;
  /** fetch data from the table: "Member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "Member" */
  memberAggregate: MemberAggregate;
  /** fetch data from the table: "multiple_stops_policies" */
  multipleStopsPolicies: Array<MultipleStopsPolicies>;
  /** fetch aggregated fields from the table: "multiple_stops_policies" */
  multipleStopsPoliciesAggregate: MultipleStopsPoliciesAggregate;
  /** fetch data from the table: "multiple_stops_policies" using primary key columns */
  multipleStopsPoliciesByPk?: Maybe<MultipleStopsPolicies>;
  /** fetch data from the table: "Order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "Order" */
  orderAggregate: OrderAggregate;
  /** fetch data from the table: "Order" using primary key columns */
  orderByPk?: Maybe<Order>;
  /** fetch data from the table: "order_delivery_price" */
  orderDeliveryPrice: Array<OrderDeliveryPrice>;
  /** fetch aggregated fields from the table: "order_delivery_price" */
  orderDeliveryPriceAggregate: OrderDeliveryPriceAggregate;
  /** fetch data from the table: "OrderEvents" */
  orderEvents: Array<Orderevents>;
  /** An aggregate relationship */
  orderEventsAggregate: OrdereventsAggregate;
  /** fetch data from the table: "OrderEvents" using primary key columns */
  orderEventsByPk?: Maybe<Orderevents>;
  /** fetch data from the table: "order_info" */
  orderInfo: Array<OrderInfo>;
  /** fetch aggregated fields from the table: "order_info" */
  orderInfoAggregate: OrderInfoAggregate;
  /** fetch data from the table: "order_info" using primary key columns */
  orderInfoByPk?: Maybe<OrderInfo>;
  /** fetch data from the table: "order_price" */
  orderPrice: Array<OrderPrice>;
  /** fetch aggregated fields from the table: "order_price" */
  orderPriceAggregate: OrderPriceAggregate;
  /** fetch data from the table: "order_price" using primary key columns */
  orderPriceByPk?: Maybe<OrderPrice>;
  orderServiceHealth: Scalars['Float'];
  /** An array relationship */
  orderStatusEvents: Array<OrderStatusEvents>;
  /** An aggregate relationship */
  orderStatusEventsAggregate: OrderStatusEventsAggregate;
  /** fetch data from the table: "order_status_events" using primary key columns */
  orderStatusEventsByPk?: Maybe<OrderStatusEvents>;
  /** fetch data from the table: "order_status_types" */
  orderStatusTypes: Array<OrderStatusTypes>;
  /** fetch aggregated fields from the table: "order_status_types" */
  orderStatusTypesAggregate: OrderStatusTypesAggregate;
  /** fetch data from the table: "order_status_types" using primary key columns */
  orderStatusTypesByPk?: Maybe<OrderStatusTypes>;
  /** fetch aggregated fields from the table: "OrderTracking" */
  orderTrackingAggregate: OrdertrackingAggregate;
  /** fetch data from the table: "OrderTracking" using primary key columns */
  orderTrackingByPk?: Maybe<Ordertracking>;
  /** fetch aggregated fields from the table: "OrderValidationError" */
  orderValidationErrorAggregate: OrdervalidationerrorAggregate;
  /** fetch data from the table: "OrderValidationError" using primary key columns */
  orderValidationErrorByPk?: Maybe<Ordervalidationerror>;
  /** fetch data from the table: "Organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "Organization" */
  organizationAggregate: OrganizationAggregate;
  /** fetch data from the table: "Organization" using primary key columns */
  organizationByPk?: Maybe<Organization>;
  /** fetch data from the table: "OrganizationConfig" */
  organizationConfig: Array<Organizationconfig>;
  /** fetch aggregated fields from the table: "OrganizationConfig" */
  organizationConfigAggregate: OrganizationconfigAggregate;
  /** fetch data from the table: "OrganizationConfig" using primary key columns */
  organizationConfigByPk?: Maybe<Organizationconfig>;
  /** An array relationship */
  organizationPricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  organizationPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
  /** fetch data from the table: "organization_pricing_policies" using primary key columns */
  organizationPricingPoliciesByPk?: Maybe<OrganizationPricingPolicies>;
  /** An array relationship */
  organizationProfile: Array<Organizationprofile>;
  /** An aggregate relationship */
  organizationProfileAggregate: OrganizationprofileAggregate;
  /** fetch data from the table: "pdf" */
  pdf: Array<Pdf>;
  /** fetch aggregated fields from the table: "pdf" */
  pdfAggregate: PdfAggregate;
  /** fetch data from the table: "pdf" using primary key columns */
  pdfByPk?: Maybe<Pdf>;
  /** fetch data from the table: "price_addons" */
  priceAddons: Array<PriceAddons>;
  /** fetch aggregated fields from the table: "price_addons" */
  priceAddonsAggregate: PriceAddonsAggregate;
  /** fetch data from the table: "price_addons" using primary key columns */
  priceAddonsByPk?: Maybe<PriceAddons>;
  /** fetch data from the table: "pricing_insurance_bridge" */
  pricingInsuranceBridge: Array<PricingInsuranceBridge>;
  /** fetch aggregated fields from the table: "pricing_insurance_bridge" */
  pricingInsuranceBridgeAggregate: PricingInsuranceBridgeAggregate;
  /** fetch data from the table: "pricing_insurance_bridge" using primary key columns */
  pricingInsuranceBridgeByPk?: Maybe<PricingInsuranceBridge>;
  /** An array relationship */
  pricingRules: Array<PricingRules>;
  /** An aggregate relationship */
  pricingRulesAggregate: PricingRulesAggregate;
  /** fetch data from the table: "pricing_rules" using primary key columns */
  pricingRulesByPk?: Maybe<PricingRules>;
  /** fetch data from the table: "processes" */
  processes: Array<Processes>;
  /** fetch aggregated fields from the table: "processes" */
  processesAggregate: ProcessesAggregate;
  /** fetch data from the table: "processes" using primary key columns */
  processesByPk?: Maybe<Processes>;
  /** fetch data from the table: "Region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "Region" */
  regionAggregate: RegionAggregate;
  /** fetch data from the table: "Region" using primary key columns */
  regionByPk?: Maybe<Region>;
  /** fetch data from the table: "region_info" */
  regionInfo: Array<RegionInfo>;
  /** fetch aggregated fields from the table: "region_info" */
  regionInfoAggregate: RegionInfoAggregate;
  /** fetch data from the table: "region_info" using primary key columns */
  regionInfoByPk?: Maybe<RegionInfo>;
  /** fetch data from the table: "robolabs_invoice" */
  robolabsInvoice: Array<RobolabsInvoice>;
  /** fetch aggregated fields from the table: "robolabs_invoice" */
  robolabsInvoiceAggregate: RobolabsInvoiceAggregate;
  /** fetch data from the table: "robolabs_invoice" using primary key columns */
  robolabsInvoiceByPk?: Maybe<RobolabsInvoice>;
  /** fetch data from the table: "robolabs_invoice_status" */
  robolabsInvoiceStatus: Array<RobolabsInvoiceStatus>;
  /** fetch aggregated fields from the table: "robolabs_invoice_status" */
  robolabsInvoiceStatusAggregate: RobolabsInvoiceStatusAggregate;
  /** fetch data from the table: "robolabs_invoice_status" using primary key columns */
  robolabsInvoiceStatusByPk?: Maybe<RobolabsInvoiceStatus>;
  /** fetch data from the table: "sms_messages" */
  smsMessages: Array<SmsMessages>;
  /** fetch aggregated fields from the table: "sms_messages" */
  smsMessagesAggregate: SmsMessagesAggregate;
  /** fetch data from the table: "sms_messages" using primary key columns */
  smsMessagesByPk?: Maybe<SmsMessages>;
  /** fetch data from the table: "sms_messages_types" */
  smsMessagesTypes: Array<SmsMessagesTypes>;
  /** fetch aggregated fields from the table: "sms_messages_types" */
  smsMessagesTypesAggregate: SmsMessagesTypesAggregate;
  /** fetch data from the table: "sms_messages_types" using primary key columns */
  smsMessagesTypesByPk?: Maybe<SmsMessagesTypes>;
  /** fetch data from the table: "Task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "Task" */
  taskAggregate: TaskAggregate;
  /** fetch data from the table: "Task" using primary key columns */
  taskByPk?: Maybe<Task>;
  /** fetch data from the table: "TaskETA" */
  taskEta: Array<Tasketa>;
  /** fetch aggregated fields from the table: "TaskETA" */
  taskEtaAggregate: TasketaAggregate;
  /** fetch data from the table: "task_seq" */
  taskSeq: Array<TaskSeq>;
  /** fetch aggregated fields from the table: "task_seq" */
  taskSeqAggregate: TaskSeqAggregate;
  /** fetch data from the table: "task_seq" using primary key columns */
  taskSeqByPk?: Maybe<TaskSeq>;
  /** fetch data from the table: "Trip" */
  trip: Array<Trip>;
  /** fetch aggregated fields from the table: "Trip" */
  tripAggregate: TripAggregate;
  /** fetch data from the table: "User" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "Vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "Vehicle" */
  vehicleAggregate: VehicleAggregate;
  /** fetch data from the table: "Vehicle" using primary key columns */
  vehicleByPk?: Maybe<Vehicle>;
  /** fetch data from the table: "Webhook" */
  webhook: Array<Webhook>;
  /** fetch aggregated fields from the table: "Webhook" */
  webhookAggregate: WebhookAggregate;
  /** fetch data from the table: "Webhook" using primary key columns */
  webhookByPk?: Maybe<Webhook>;
  /** An array relationship */
  webhookInvocations: Array<WebhookInvocations>;
  /** An aggregate relationship */
  webhookInvocationsAggregate: WebhookInvocationsAggregate;
  /** fetch data from the table: "webhook_invocations" using primary key columns */
  webhookInvocationsByPk?: Maybe<WebhookInvocations>;
  /** fetch data from the table: "webhook_types" */
  webhookTypes: Array<WebhookTypes>;
  /** fetch aggregated fields from the table: "webhook_types" */
  webhookTypesAggregate: WebhookTypesAggregate;
  /** fetch data from the table: "webhook_types" using primary key columns */
  webhookTypesByPk?: Maybe<WebhookTypes>;
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooksAggregate: WebhooksAggregate;
  /** fetch data from the table: "webhooks" using primary key columns */
  webhooksByPk?: Maybe<Webhooks>;
  /** An array relationship */
  webhooksTriggerConditions: Array<WebhooksTriggerConditions>;
  /** An aggregate relationship */
  webhooksTriggerConditionsAggregate: WebhooksTriggerConditionsAggregate;
};


export type Query_RootGlobalSettingsArgs = {
  distinctOn?: InputMaybe<Array<GlobalsettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalsettingsOrderBy>>;
  where?: InputMaybe<GlobalsettingsBoolExp>;
};


export type Query_RootOrderTrackingArgs = {
  distinctOn?: InputMaybe<Array<OrdertrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdertrackingOrderBy>>;
  where?: InputMaybe<OrdertrackingBoolExp>;
};


export type Query_RootOrderValidationErrorArgs = {
  distinctOn?: InputMaybe<Array<OrdervalidationerrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdervalidationerrorOrderBy>>;
  where?: InputMaybe<OrdervalidationerrorBoolExp>;
};


export type Query_RootAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


export type Query_RootAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


export type Query_RootAccessTokenByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Query_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Query_RootAddressByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootCancellationPoliciesArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};


export type Query_RootCancellationPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};


export type Query_RootCancellationPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCancellationPolicyTypesArgs = {
  distinctOn?: InputMaybe<Array<CancellationPolicyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPolicyTypesOrderBy>>;
  where?: InputMaybe<CancellationPolicyTypesBoolExp>;
};


export type Query_RootCancellationPolicyTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CancellationPolicyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPolicyTypesOrderBy>>;
  where?: InputMaybe<CancellationPolicyTypesBoolExp>;
};


export type Query_RootCancellationPolicyTypesByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootCourierArgs = {
  distinctOn?: InputMaybe<Array<CourierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierOrderBy>>;
  where?: InputMaybe<CourierBoolExp>;
};


export type Query_RootCourierAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierOrderBy>>;
  where?: InputMaybe<CourierBoolExp>;
};


export type Query_RootCourierByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCourierInfoArgs = {
  distinctOn?: InputMaybe<Array<CourierInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierInfoOrderBy>>;
  where?: InputMaybe<CourierInfoBoolExp>;
};


export type Query_RootCourierInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierInfoOrderBy>>;
  where?: InputMaybe<CourierInfoBoolExp>;
};


export type Query_RootCourierPayoutSettingsArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


export type Query_RootCourierPayoutSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


export type Query_RootCourierPayoutSettingsByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCourierProfileArgs = {
  distinctOn?: InputMaybe<Array<CourierprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierprofileOrderBy>>;
  where?: InputMaybe<CourierprofileBoolExp>;
};


export type Query_RootCourierProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierprofileOrderBy>>;
  where?: InputMaybe<CourierprofileBoolExp>;
};


export type Query_RootCourierReportArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


export type Query_RootCourierReportAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


export type Query_RootCourierReportByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCsvExportArgs = {
  distinctOn?: InputMaybe<Array<CsvExportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CsvExportOrderBy>>;
  where?: InputMaybe<CsvExportBoolExp>;
};


export type Query_RootCsvExportAggregateArgs = {
  distinctOn?: InputMaybe<Array<CsvExportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CsvExportOrderBy>>;
  where?: InputMaybe<CsvExportBoolExp>;
};


export type Query_RootCsvExportByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeliveryRateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryrateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryrateOrderBy>>;
  where?: InputMaybe<DeliveryrateBoolExp>;
};


export type Query_RootDeliveryRateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryrateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryrateOrderBy>>;
  where?: InputMaybe<DeliveryrateBoolExp>;
};


export type Query_RootDeliveryRateByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootDeliveryTypesArgs = {
  distinctOn?: InputMaybe<Array<DeliveryTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryTypesOrderBy>>;
  where?: InputMaybe<DeliveryTypesBoolExp>;
};


export type Query_RootDeliveryTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryTypesOrderBy>>;
  where?: InputMaybe<DeliveryTypesBoolExp>;
};


export type Query_RootDeliveryTypesByPkArgs = {
  value: Scalars['String'];
};


export type Query_RootGlobalSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GlobalsettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalsettingsOrderBy>>;
  where?: InputMaybe<GlobalsettingsBoolExp>;
};


export type Query_RootGlobalSettingsByPkArgs = {
  key: Scalars['String'];
};


export type Query_RootInsurancePoliciesArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


export type Query_RootInsurancePoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


export type Query_RootInsurancePoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInviteArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


export type Query_RootInviteAggregateArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


export type Query_RootInviteByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootLiveMapLockArgs = {
  distinctOn?: InputMaybe<Array<LiveMapLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveMapLockOrderBy>>;
  where?: InputMaybe<LiveMapLockBoolExp>;
};


export type Query_RootLiveMapLockAggregateArgs = {
  distinctOn?: InputMaybe<Array<LiveMapLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveMapLockOrderBy>>;
  where?: InputMaybe<LiveMapLockBoolExp>;
};


export type Query_RootLiveMapLockByPkArgs = {
  region: Scalars['String'];
};


export type Query_RootMemberArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


export type Query_RootMemberAggregateArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


export type Query_RootMultipleStopsPoliciesArgs = {
  distinctOn?: InputMaybe<Array<MultipleStopsPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MultipleStopsPoliciesOrderBy>>;
  where?: InputMaybe<MultipleStopsPoliciesBoolExp>;
};


export type Query_RootMultipleStopsPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MultipleStopsPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MultipleStopsPoliciesOrderBy>>;
  where?: InputMaybe<MultipleStopsPoliciesBoolExp>;
};


export type Query_RootMultipleStopsPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type Query_RootOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type Query_RootOrderByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrderDeliveryPriceArgs = {
  distinctOn?: InputMaybe<Array<OrderDeliveryPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderDeliveryPriceOrderBy>>;
  where?: InputMaybe<OrderDeliveryPriceBoolExp>;
};


export type Query_RootOrderDeliveryPriceAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderDeliveryPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderDeliveryPriceOrderBy>>;
  where?: InputMaybe<OrderDeliveryPriceBoolExp>;
};


export type Query_RootOrderEventsArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


export type Query_RootOrderEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


export type Query_RootOrderEventsByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrderInfoArgs = {
  distinctOn?: InputMaybe<Array<OrderInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderInfoOrderBy>>;
  where?: InputMaybe<OrderInfoBoolExp>;
};


export type Query_RootOrderInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderInfoOrderBy>>;
  where?: InputMaybe<OrderInfoBoolExp>;
};


export type Query_RootOrderInfoByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrderPriceArgs = {
  distinctOn?: InputMaybe<Array<OrderPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderPriceOrderBy>>;
  where?: InputMaybe<OrderPriceBoolExp>;
};


export type Query_RootOrderPriceAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderPriceOrderBy>>;
  where?: InputMaybe<OrderPriceBoolExp>;
};


export type Query_RootOrderPriceByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrderStatusEventsArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


export type Query_RootOrderStatusEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


export type Query_RootOrderStatusEventsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrderStatusTypesArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusTypesOrderBy>>;
  where?: InputMaybe<OrderStatusTypesBoolExp>;
};


export type Query_RootOrderStatusTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusTypesOrderBy>>;
  where?: InputMaybe<OrderStatusTypesBoolExp>;
};


export type Query_RootOrderStatusTypesByPkArgs = {
  type: Scalars['String'];
};


export type Query_RootOrderTrackingAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdertrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdertrackingOrderBy>>;
  where?: InputMaybe<OrdertrackingBoolExp>;
};


export type Query_RootOrderTrackingByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrderValidationErrorAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdervalidationerrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdervalidationerrorOrderBy>>;
  where?: InputMaybe<OrdervalidationerrorBoolExp>;
};


export type Query_RootOrderValidationErrorByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrganizationArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type Query_RootOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type Query_RootOrganizationByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrganizationConfigArgs = {
  distinctOn?: InputMaybe<Array<OrganizationconfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationconfigOrderBy>>;
  where?: InputMaybe<OrganizationconfigBoolExp>;
};


export type Query_RootOrganizationConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationconfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationconfigOrderBy>>;
  where?: InputMaybe<OrganizationconfigBoolExp>;
};


export type Query_RootOrganizationConfigByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootOrganizationPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


export type Query_RootOrganizationPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


export type Query_RootOrganizationPricingPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOrganizationProfileArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


export type Query_RootOrganizationProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


export type Query_RootPdfArgs = {
  distinctOn?: InputMaybe<Array<PdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PdfOrderBy>>;
  where?: InputMaybe<PdfBoolExp>;
};


export type Query_RootPdfAggregateArgs = {
  distinctOn?: InputMaybe<Array<PdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PdfOrderBy>>;
  where?: InputMaybe<PdfBoolExp>;
};


export type Query_RootPdfByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPriceAddonsArgs = {
  distinctOn?: InputMaybe<Array<PriceAddonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PriceAddonsOrderBy>>;
  where?: InputMaybe<PriceAddonsBoolExp>;
};


export type Query_RootPriceAddonsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PriceAddonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PriceAddonsOrderBy>>;
  where?: InputMaybe<PriceAddonsBoolExp>;
};


export type Query_RootPriceAddonsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPricingInsuranceBridgeArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


export type Query_RootPricingInsuranceBridgeAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


export type Query_RootPricingInsuranceBridgeByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPricingRulesArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};


export type Query_RootPricingRulesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};


export type Query_RootPricingRulesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProcessesArgs = {
  distinctOn?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type Query_RootProcessesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type Query_RootProcessesByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRegionArgs = {
  distinctOn?: InputMaybe<Array<RegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionOrderBy>>;
  where?: InputMaybe<RegionBoolExp>;
};


export type Query_RootRegionAggregateArgs = {
  distinctOn?: InputMaybe<Array<RegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionOrderBy>>;
  where?: InputMaybe<RegionBoolExp>;
};


export type Query_RootRegionByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootRegionInfoArgs = {
  distinctOn?: InputMaybe<Array<RegionInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionInfoOrderBy>>;
  where?: InputMaybe<RegionInfoBoolExp>;
};


export type Query_RootRegionInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<RegionInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionInfoOrderBy>>;
  where?: InputMaybe<RegionInfoBoolExp>;
};


export type Query_RootRegionInfoByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRobolabsInvoiceArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceBoolExp>;
};


export type Query_RootRobolabsInvoiceAggregateArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceBoolExp>;
};


export type Query_RootRobolabsInvoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRobolabsInvoiceStatusArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceStatusOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
};


export type Query_RootRobolabsInvoiceStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceStatusOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
};


export type Query_RootRobolabsInvoiceStatusByPkArgs = {
  status: Scalars['String'];
};


export type Query_RootSmsMessagesArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesOrderBy>>;
  where?: InputMaybe<SmsMessagesBoolExp>;
};


export type Query_RootSmsMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesOrderBy>>;
  where?: InputMaybe<SmsMessagesBoolExp>;
};


export type Query_RootSmsMessagesByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSmsMessagesTypesArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesTypesOrderBy>>;
  where?: InputMaybe<SmsMessagesTypesBoolExp>;
};


export type Query_RootSmsMessagesTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesTypesOrderBy>>;
  where?: InputMaybe<SmsMessagesTypesBoolExp>;
};


export type Query_RootSmsMessagesTypesByPkArgs = {
  type: Scalars['String'];
};


export type Query_RootTaskArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


export type Query_RootTaskAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


export type Query_RootTaskByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTaskEtaArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


export type Query_RootTaskEtaAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


export type Query_RootTaskSeqArgs = {
  distinctOn?: InputMaybe<Array<TaskSeqSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskSeqOrderBy>>;
  where?: InputMaybe<TaskSeqBoolExp>;
};


export type Query_RootTaskSeqAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSeqSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskSeqOrderBy>>;
  where?: InputMaybe<TaskSeqBoolExp>;
};


export type Query_RootTaskSeqByPkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTripArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


export type Query_RootTripAggregateArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


export type Query_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Query_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Query_RootUserByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootVehicleArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};


export type Query_RootVehicleAggregateArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};


export type Query_RootVehicleByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootWebhookArgs = {
  distinctOn?: InputMaybe<Array<WebhookSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookOrderBy>>;
  where?: InputMaybe<WebhookBoolExp>;
};


export type Query_RootWebhookAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookOrderBy>>;
  where?: InputMaybe<WebhookBoolExp>;
};


export type Query_RootWebhookByPkArgs = {
  id: Scalars['String'];
};


export type Query_RootWebhookInvocationsArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


export type Query_RootWebhookInvocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


export type Query_RootWebhookInvocationsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWebhookTypesArgs = {
  distinctOn?: InputMaybe<Array<WebhookTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookTypesOrderBy>>;
  where?: InputMaybe<WebhookTypesBoolExp>;
};


export type Query_RootWebhookTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookTypesOrderBy>>;
  where?: InputMaybe<WebhookTypesBoolExp>;
};


export type Query_RootWebhookTypesByPkArgs = {
  type: Scalars['String'];
};


export type Query_RootWebhooksArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};


export type Query_RootWebhooksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};


export type Query_RootWebhooksByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWebhooksTriggerConditionsArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};


export type Query_RootWebhooksTriggerConditionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "GlobalSettings" */
  GlobalSettings: Array<Globalsettings>;
  /** fetch data from the table: "OrderTracking" */
  OrderTracking: Array<Ordertracking>;
  /** fetch data from the table: "OrderValidationError" */
  OrderValidationError: Array<Ordervalidationerror>;
  /** fetch data from the table: "AccessToken" */
  accessToken: Array<Accesstoken>;
  /** fetch aggregated fields from the table: "AccessToken" */
  accessTokenAggregate: AccesstokenAggregate;
  /** fetch data from the table: "AccessToken" using primary key columns */
  accessTokenByPk?: Maybe<Accesstoken>;
  /** fetch data from the table: "Address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  addressAggregate: AddressAggregate;
  /** fetch data from the table: "Address" using primary key columns */
  addressByPk?: Maybe<Address>;
  /** An array relationship */
  cancellationPolicies: Array<CancellationPolicies>;
  /** An aggregate relationship */
  cancellationPoliciesAggregate: CancellationPoliciesAggregate;
  /** fetch data from the table: "cancellation_policies" using primary key columns */
  cancellationPoliciesByPk?: Maybe<CancellationPolicies>;
  /** fetch data from the table: "cancellation_policy_types" */
  cancellationPolicyTypes: Array<CancellationPolicyTypes>;
  /** fetch aggregated fields from the table: "cancellation_policy_types" */
  cancellationPolicyTypesAggregate: CancellationPolicyTypesAggregate;
  /** fetch data from the table: "cancellation_policy_types" using primary key columns */
  cancellationPolicyTypesByPk?: Maybe<CancellationPolicyTypes>;
  /** fetch data from the table: "Courier" */
  courier: Array<Courier>;
  /** fetch aggregated fields from the table: "Courier" */
  courierAggregate: CourierAggregate;
  /** fetch data from the table: "Courier" using primary key columns */
  courierByPk?: Maybe<Courier>;
  /** fetch data from the table: "courier_info" */
  courierInfo: Array<CourierInfo>;
  /** fetch aggregated fields from the table: "courier_info" */
  courierInfoAggregate: CourierInfoAggregate;
  /** fetch data from the table: "courier_payout_settings" */
  courierPayoutSettings: Array<CourierPayoutSettings>;
  /** An aggregate relationship */
  courierPayoutSettingsAggregate: CourierPayoutSettingsAggregate;
  /** fetch data from the table: "courier_payout_settings" using primary key columns */
  courierPayoutSettingsByPk?: Maybe<CourierPayoutSettings>;
  /** fetch data from the table: "CourierProfile" */
  courierProfile: Array<Courierprofile>;
  /** fetch aggregated fields from the table: "CourierProfile" */
  courierProfileAggregate: CourierprofileAggregate;
  /** fetch data from the table: "CourierReport" */
  courierReport: Array<Courierreport>;
  /** fetch aggregated fields from the table: "CourierReport" */
  courierReportAggregate: CourierreportAggregate;
  /** fetch data from the table: "CourierReport" using primary key columns */
  courierReportByPk?: Maybe<Courierreport>;
  /** fetch data from the table: "csv_export" */
  csvExport: Array<CsvExport>;
  /** fetch aggregated fields from the table: "csv_export" */
  csvExportAggregate: CsvExportAggregate;
  /** fetch data from the table: "csv_export" using primary key columns */
  csvExportByPk?: Maybe<CsvExport>;
  /** fetch data from the table: "DeliveryRate" */
  deliveryRate: Array<Deliveryrate>;
  /** fetch aggregated fields from the table: "DeliveryRate" */
  deliveryRateAggregate: DeliveryrateAggregate;
  /** fetch data from the table: "DeliveryRate" using primary key columns */
  deliveryRateByPk?: Maybe<Deliveryrate>;
  /** fetch data from the table: "delivery_types" */
  deliveryTypes: Array<DeliveryTypes>;
  /** fetch aggregated fields from the table: "delivery_types" */
  deliveryTypesAggregate: DeliveryTypesAggregate;
  /** fetch data from the table: "delivery_types" using primary key columns */
  deliveryTypesByPk?: Maybe<DeliveryTypes>;
  /** fetch aggregated fields from the table: "GlobalSettings" */
  globalSettingsAggregate: GlobalsettingsAggregate;
  /** fetch data from the table: "GlobalSettings" using primary key columns */
  globalSettingsByPk?: Maybe<Globalsettings>;
  /** fetch data from the table: "insurance_policies" */
  insurancePolicies: Array<InsurancePolicies>;
  /** fetch aggregated fields from the table: "insurance_policies" */
  insurancePoliciesAggregate: InsurancePoliciesAggregate;
  /** fetch data from the table: "insurance_policies" using primary key columns */
  insurancePoliciesByPk?: Maybe<InsurancePolicies>;
  /** fetch data from the table: "Invite" */
  invite: Array<Invite>;
  /** fetch aggregated fields from the table: "Invite" */
  inviteAggregate: InviteAggregate;
  /** fetch data from the table: "Invite" using primary key columns */
  inviteByPk?: Maybe<Invite>;
  /** fetch data from the table: "live_map_lock" */
  liveMapLock: Array<LiveMapLock>;
  /** fetch aggregated fields from the table: "live_map_lock" */
  liveMapLockAggregate: LiveMapLockAggregate;
  /** fetch data from the table: "live_map_lock" using primary key columns */
  liveMapLockByPk?: Maybe<LiveMapLock>;
  /** fetch data from the table: "Member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "Member" */
  memberAggregate: MemberAggregate;
  /** fetch data from the table: "multiple_stops_policies" */
  multipleStopsPolicies: Array<MultipleStopsPolicies>;
  /** fetch aggregated fields from the table: "multiple_stops_policies" */
  multipleStopsPoliciesAggregate: MultipleStopsPoliciesAggregate;
  /** fetch data from the table: "multiple_stops_policies" using primary key columns */
  multipleStopsPoliciesByPk?: Maybe<MultipleStopsPolicies>;
  /** fetch data from the table: "Order" */
  order: Array<Order>;
  /** fetch aggregated fields from the table: "Order" */
  orderAggregate: OrderAggregate;
  /** fetch data from the table: "Order" using primary key columns */
  orderByPk?: Maybe<Order>;
  /** fetch data from the table: "order_delivery_price" */
  orderDeliveryPrice: Array<OrderDeliveryPrice>;
  /** fetch aggregated fields from the table: "order_delivery_price" */
  orderDeliveryPriceAggregate: OrderDeliveryPriceAggregate;
  /** fetch data from the table: "OrderEvents" */
  orderEvents: Array<Orderevents>;
  /** An aggregate relationship */
  orderEventsAggregate: OrdereventsAggregate;
  /** fetch data from the table: "OrderEvents" using primary key columns */
  orderEventsByPk?: Maybe<Orderevents>;
  /** fetch data from the table: "order_info" */
  orderInfo: Array<OrderInfo>;
  /** fetch aggregated fields from the table: "order_info" */
  orderInfoAggregate: OrderInfoAggregate;
  /** fetch data from the table: "order_info" using primary key columns */
  orderInfoByPk?: Maybe<OrderInfo>;
  /** fetch data from the table: "order_price" */
  orderPrice: Array<OrderPrice>;
  /** fetch aggregated fields from the table: "order_price" */
  orderPriceAggregate: OrderPriceAggregate;
  /** fetch data from the table: "order_price" using primary key columns */
  orderPriceByPk?: Maybe<OrderPrice>;
  /** An array relationship */
  orderStatusEvents: Array<OrderStatusEvents>;
  /** An aggregate relationship */
  orderStatusEventsAggregate: OrderStatusEventsAggregate;
  /** fetch data from the table: "order_status_events" using primary key columns */
  orderStatusEventsByPk?: Maybe<OrderStatusEvents>;
  /** fetch data from the table: "order_status_types" */
  orderStatusTypes: Array<OrderStatusTypes>;
  /** fetch aggregated fields from the table: "order_status_types" */
  orderStatusTypesAggregate: OrderStatusTypesAggregate;
  /** fetch data from the table: "order_status_types" using primary key columns */
  orderStatusTypesByPk?: Maybe<OrderStatusTypes>;
  /** fetch aggregated fields from the table: "OrderTracking" */
  orderTrackingAggregate: OrdertrackingAggregate;
  /** fetch data from the table: "OrderTracking" using primary key columns */
  orderTrackingByPk?: Maybe<Ordertracking>;
  /** fetch aggregated fields from the table: "OrderValidationError" */
  orderValidationErrorAggregate: OrdervalidationerrorAggregate;
  /** fetch data from the table: "OrderValidationError" using primary key columns */
  orderValidationErrorByPk?: Maybe<Ordervalidationerror>;
  /** fetch data from the table: "Organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "Organization" */
  organizationAggregate: OrganizationAggregate;
  /** fetch data from the table: "Organization" using primary key columns */
  organizationByPk?: Maybe<Organization>;
  /** fetch data from the table: "OrganizationConfig" */
  organizationConfig: Array<Organizationconfig>;
  /** fetch aggregated fields from the table: "OrganizationConfig" */
  organizationConfigAggregate: OrganizationconfigAggregate;
  /** fetch data from the table: "OrganizationConfig" using primary key columns */
  organizationConfigByPk?: Maybe<Organizationconfig>;
  /** An array relationship */
  organizationPricingPolicies: Array<OrganizationPricingPolicies>;
  /** An aggregate relationship */
  organizationPricingPoliciesAggregate: OrganizationPricingPoliciesAggregate;
  /** fetch data from the table: "organization_pricing_policies" using primary key columns */
  organizationPricingPoliciesByPk?: Maybe<OrganizationPricingPolicies>;
  /** An array relationship */
  organizationProfile: Array<Organizationprofile>;
  /** An aggregate relationship */
  organizationProfileAggregate: OrganizationprofileAggregate;
  /** fetch data from the table: "pdf" */
  pdf: Array<Pdf>;
  /** fetch aggregated fields from the table: "pdf" */
  pdfAggregate: PdfAggregate;
  /** fetch data from the table: "pdf" using primary key columns */
  pdfByPk?: Maybe<Pdf>;
  /** fetch data from the table: "price_addons" */
  priceAddons: Array<PriceAddons>;
  /** fetch aggregated fields from the table: "price_addons" */
  priceAddonsAggregate: PriceAddonsAggregate;
  /** fetch data from the table: "price_addons" using primary key columns */
  priceAddonsByPk?: Maybe<PriceAddons>;
  /** fetch data from the table: "pricing_insurance_bridge" */
  pricingInsuranceBridge: Array<PricingInsuranceBridge>;
  /** fetch aggregated fields from the table: "pricing_insurance_bridge" */
  pricingInsuranceBridgeAggregate: PricingInsuranceBridgeAggregate;
  /** fetch data from the table: "pricing_insurance_bridge" using primary key columns */
  pricingInsuranceBridgeByPk?: Maybe<PricingInsuranceBridge>;
  /** An array relationship */
  pricingRules: Array<PricingRules>;
  /** An aggregate relationship */
  pricingRulesAggregate: PricingRulesAggregate;
  /** fetch data from the table: "pricing_rules" using primary key columns */
  pricingRulesByPk?: Maybe<PricingRules>;
  /** fetch data from the table: "processes" */
  processes: Array<Processes>;
  /** fetch aggregated fields from the table: "processes" */
  processesAggregate: ProcessesAggregate;
  /** fetch data from the table: "processes" using primary key columns */
  processesByPk?: Maybe<Processes>;
  /** fetch data from the table: "Region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "Region" */
  regionAggregate: RegionAggregate;
  /** fetch data from the table: "Region" using primary key columns */
  regionByPk?: Maybe<Region>;
  /** fetch data from the table: "region_info" */
  regionInfo: Array<RegionInfo>;
  /** fetch aggregated fields from the table: "region_info" */
  regionInfoAggregate: RegionInfoAggregate;
  /** fetch data from the table: "region_info" using primary key columns */
  regionInfoByPk?: Maybe<RegionInfo>;
  /** fetch data from the table: "robolabs_invoice" */
  robolabsInvoice: Array<RobolabsInvoice>;
  /** fetch aggregated fields from the table: "robolabs_invoice" */
  robolabsInvoiceAggregate: RobolabsInvoiceAggregate;
  /** fetch data from the table: "robolabs_invoice" using primary key columns */
  robolabsInvoiceByPk?: Maybe<RobolabsInvoice>;
  /** fetch data from the table: "robolabs_invoice_status" */
  robolabsInvoiceStatus: Array<RobolabsInvoiceStatus>;
  /** fetch aggregated fields from the table: "robolabs_invoice_status" */
  robolabsInvoiceStatusAggregate: RobolabsInvoiceStatusAggregate;
  /** fetch data from the table: "robolabs_invoice_status" using primary key columns */
  robolabsInvoiceStatusByPk?: Maybe<RobolabsInvoiceStatus>;
  /** fetch data from the table: "sms_messages" */
  smsMessages: Array<SmsMessages>;
  /** fetch aggregated fields from the table: "sms_messages" */
  smsMessagesAggregate: SmsMessagesAggregate;
  /** fetch data from the table: "sms_messages" using primary key columns */
  smsMessagesByPk?: Maybe<SmsMessages>;
  /** fetch data from the table: "sms_messages_types" */
  smsMessagesTypes: Array<SmsMessagesTypes>;
  /** fetch aggregated fields from the table: "sms_messages_types" */
  smsMessagesTypesAggregate: SmsMessagesTypesAggregate;
  /** fetch data from the table: "sms_messages_types" using primary key columns */
  smsMessagesTypesByPk?: Maybe<SmsMessagesTypes>;
  /** fetch data from the table: "Task" */
  task: Array<Task>;
  /** fetch aggregated fields from the table: "Task" */
  taskAggregate: TaskAggregate;
  /** fetch data from the table: "Task" using primary key columns */
  taskByPk?: Maybe<Task>;
  /** fetch data from the table: "TaskETA" */
  taskEta: Array<Tasketa>;
  /** fetch aggregated fields from the table: "TaskETA" */
  taskEtaAggregate: TasketaAggregate;
  /** fetch data from the table: "task_seq" */
  taskSeq: Array<TaskSeq>;
  /** fetch aggregated fields from the table: "task_seq" */
  taskSeqAggregate: TaskSeqAggregate;
  /** fetch data from the table: "task_seq" using primary key columns */
  taskSeqByPk?: Maybe<TaskSeq>;
  /** fetch data from the table: "Trip" */
  trip: Array<Trip>;
  /** fetch aggregated fields from the table: "Trip" */
  tripAggregate: TripAggregate;
  /** fetch data from the table: "User" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "User" */
  userAggregate: UserAggregate;
  /** fetch data from the table: "User" using primary key columns */
  userByPk?: Maybe<User>;
  /** fetch data from the table: "Vehicle" */
  vehicle: Array<Vehicle>;
  /** fetch aggregated fields from the table: "Vehicle" */
  vehicleAggregate: VehicleAggregate;
  /** fetch data from the table: "Vehicle" using primary key columns */
  vehicleByPk?: Maybe<Vehicle>;
  /** fetch data from the table: "Webhook" */
  webhook: Array<Webhook>;
  /** fetch aggregated fields from the table: "Webhook" */
  webhookAggregate: WebhookAggregate;
  /** fetch data from the table: "Webhook" using primary key columns */
  webhookByPk?: Maybe<Webhook>;
  /** An array relationship */
  webhookInvocations: Array<WebhookInvocations>;
  /** An aggregate relationship */
  webhookInvocationsAggregate: WebhookInvocationsAggregate;
  /** fetch data from the table: "webhook_invocations" using primary key columns */
  webhookInvocationsByPk?: Maybe<WebhookInvocations>;
  /** fetch data from the table: "webhook_types" */
  webhookTypes: Array<WebhookTypes>;
  /** fetch aggregated fields from the table: "webhook_types" */
  webhookTypesAggregate: WebhookTypesAggregate;
  /** fetch data from the table: "webhook_types" using primary key columns */
  webhookTypesByPk?: Maybe<WebhookTypes>;
  /** An array relationship */
  webhooks: Array<Webhooks>;
  /** An aggregate relationship */
  webhooksAggregate: WebhooksAggregate;
  /** fetch data from the table: "webhooks" using primary key columns */
  webhooksByPk?: Maybe<Webhooks>;
  /** An array relationship */
  webhooksTriggerConditions: Array<WebhooksTriggerConditions>;
  /** An aggregate relationship */
  webhooksTriggerConditionsAggregate: WebhooksTriggerConditionsAggregate;
};


export type Subscription_RootGlobalSettingsArgs = {
  distinctOn?: InputMaybe<Array<GlobalsettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalsettingsOrderBy>>;
  where?: InputMaybe<GlobalsettingsBoolExp>;
};


export type Subscription_RootOrderTrackingArgs = {
  distinctOn?: InputMaybe<Array<OrdertrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdertrackingOrderBy>>;
  where?: InputMaybe<OrdertrackingBoolExp>;
};


export type Subscription_RootOrderValidationErrorArgs = {
  distinctOn?: InputMaybe<Array<OrdervalidationerrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdervalidationerrorOrderBy>>;
  where?: InputMaybe<OrdervalidationerrorBoolExp>;
};


export type Subscription_RootAccessTokenArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


export type Subscription_RootAccessTokenAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccesstokenSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccesstokenOrderBy>>;
  where?: InputMaybe<AccesstokenBoolExp>;
};


export type Subscription_RootAccessTokenByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootAddressArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Subscription_RootAddressAggregateArgs = {
  distinctOn?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type Subscription_RootAddressByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCancellationPoliciesArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};


export type Subscription_RootCancellationPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CancellationPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPoliciesOrderBy>>;
  where?: InputMaybe<CancellationPoliciesBoolExp>;
};


export type Subscription_RootCancellationPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCancellationPolicyTypesArgs = {
  distinctOn?: InputMaybe<Array<CancellationPolicyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPolicyTypesOrderBy>>;
  where?: InputMaybe<CancellationPolicyTypesBoolExp>;
};


export type Subscription_RootCancellationPolicyTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CancellationPolicyTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CancellationPolicyTypesOrderBy>>;
  where?: InputMaybe<CancellationPolicyTypesBoolExp>;
};


export type Subscription_RootCancellationPolicyTypesByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCourierArgs = {
  distinctOn?: InputMaybe<Array<CourierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierOrderBy>>;
  where?: InputMaybe<CourierBoolExp>;
};


export type Subscription_RootCourierAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierOrderBy>>;
  where?: InputMaybe<CourierBoolExp>;
};


export type Subscription_RootCourierByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCourierInfoArgs = {
  distinctOn?: InputMaybe<Array<CourierInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierInfoOrderBy>>;
  where?: InputMaybe<CourierInfoBoolExp>;
};


export type Subscription_RootCourierInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierInfoOrderBy>>;
  where?: InputMaybe<CourierInfoBoolExp>;
};


export type Subscription_RootCourierPayoutSettingsArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


export type Subscription_RootCourierPayoutSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierPayoutSettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierPayoutSettingsOrderBy>>;
  where?: InputMaybe<CourierPayoutSettingsBoolExp>;
};


export type Subscription_RootCourierPayoutSettingsByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCourierProfileArgs = {
  distinctOn?: InputMaybe<Array<CourierprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierprofileOrderBy>>;
  where?: InputMaybe<CourierprofileBoolExp>;
};


export type Subscription_RootCourierProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierprofileOrderBy>>;
  where?: InputMaybe<CourierprofileBoolExp>;
};


export type Subscription_RootCourierReportArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


export type Subscription_RootCourierReportAggregateArgs = {
  distinctOn?: InputMaybe<Array<CourierreportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CourierreportOrderBy>>;
  where?: InputMaybe<CourierreportBoolExp>;
};


export type Subscription_RootCourierReportByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCsvExportArgs = {
  distinctOn?: InputMaybe<Array<CsvExportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CsvExportOrderBy>>;
  where?: InputMaybe<CsvExportBoolExp>;
};


export type Subscription_RootCsvExportAggregateArgs = {
  distinctOn?: InputMaybe<Array<CsvExportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CsvExportOrderBy>>;
  where?: InputMaybe<CsvExportBoolExp>;
};


export type Subscription_RootCsvExportByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDeliveryRateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryrateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryrateOrderBy>>;
  where?: InputMaybe<DeliveryrateBoolExp>;
};


export type Subscription_RootDeliveryRateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryrateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryrateOrderBy>>;
  where?: InputMaybe<DeliveryrateBoolExp>;
};


export type Subscription_RootDeliveryRateByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootDeliveryTypesArgs = {
  distinctOn?: InputMaybe<Array<DeliveryTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryTypesOrderBy>>;
  where?: InputMaybe<DeliveryTypesBoolExp>;
};


export type Subscription_RootDeliveryTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DeliveryTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DeliveryTypesOrderBy>>;
  where?: InputMaybe<DeliveryTypesBoolExp>;
};


export type Subscription_RootDeliveryTypesByPkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGlobalSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<GlobalsettingsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalsettingsOrderBy>>;
  where?: InputMaybe<GlobalsettingsBoolExp>;
};


export type Subscription_RootGlobalSettingsByPkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootInsurancePoliciesArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


export type Subscription_RootInsurancePoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<InsurancePoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InsurancePoliciesOrderBy>>;
  where?: InputMaybe<InsurancePoliciesBoolExp>;
};


export type Subscription_RootInsurancePoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInviteArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


export type Subscription_RootInviteAggregateArgs = {
  distinctOn?: InputMaybe<Array<InviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InviteOrderBy>>;
  where?: InputMaybe<InviteBoolExp>;
};


export type Subscription_RootInviteByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLiveMapLockArgs = {
  distinctOn?: InputMaybe<Array<LiveMapLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveMapLockOrderBy>>;
  where?: InputMaybe<LiveMapLockBoolExp>;
};


export type Subscription_RootLiveMapLockAggregateArgs = {
  distinctOn?: InputMaybe<Array<LiveMapLockSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiveMapLockOrderBy>>;
  where?: InputMaybe<LiveMapLockBoolExp>;
};


export type Subscription_RootLiveMapLockByPkArgs = {
  region: Scalars['String'];
};


export type Subscription_RootMemberArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


export type Subscription_RootMemberAggregateArgs = {
  distinctOn?: InputMaybe<Array<MemberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MemberOrderBy>>;
  where?: InputMaybe<MemberBoolExp>;
};


export type Subscription_RootMultipleStopsPoliciesArgs = {
  distinctOn?: InputMaybe<Array<MultipleStopsPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MultipleStopsPoliciesOrderBy>>;
  where?: InputMaybe<MultipleStopsPoliciesBoolExp>;
};


export type Subscription_RootMultipleStopsPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MultipleStopsPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MultipleStopsPoliciesOrderBy>>;
  where?: InputMaybe<MultipleStopsPoliciesBoolExp>;
};


export type Subscription_RootMultipleStopsPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrderArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type Subscription_RootOrderAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderOrderBy>>;
  where?: InputMaybe<OrderBoolExp>;
};


export type Subscription_RootOrderByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrderDeliveryPriceArgs = {
  distinctOn?: InputMaybe<Array<OrderDeliveryPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderDeliveryPriceOrderBy>>;
  where?: InputMaybe<OrderDeliveryPriceBoolExp>;
};


export type Subscription_RootOrderDeliveryPriceAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderDeliveryPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderDeliveryPriceOrderBy>>;
  where?: InputMaybe<OrderDeliveryPriceBoolExp>;
};


export type Subscription_RootOrderEventsArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


export type Subscription_RootOrderEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdereventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdereventsOrderBy>>;
  where?: InputMaybe<OrdereventsBoolExp>;
};


export type Subscription_RootOrderEventsByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrderInfoArgs = {
  distinctOn?: InputMaybe<Array<OrderInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderInfoOrderBy>>;
  where?: InputMaybe<OrderInfoBoolExp>;
};


export type Subscription_RootOrderInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderInfoOrderBy>>;
  where?: InputMaybe<OrderInfoBoolExp>;
};


export type Subscription_RootOrderInfoByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrderPriceArgs = {
  distinctOn?: InputMaybe<Array<OrderPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderPriceOrderBy>>;
  where?: InputMaybe<OrderPriceBoolExp>;
};


export type Subscription_RootOrderPriceAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderPriceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderPriceOrderBy>>;
  where?: InputMaybe<OrderPriceBoolExp>;
};


export type Subscription_RootOrderPriceByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrderStatusEventsArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


export type Subscription_RootOrderStatusEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusEventsOrderBy>>;
  where?: InputMaybe<OrderStatusEventsBoolExp>;
};


export type Subscription_RootOrderStatusEventsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrderStatusTypesArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusTypesOrderBy>>;
  where?: InputMaybe<OrderStatusTypesBoolExp>;
};


export type Subscription_RootOrderStatusTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrderStatusTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrderStatusTypesOrderBy>>;
  where?: InputMaybe<OrderStatusTypesBoolExp>;
};


export type Subscription_RootOrderStatusTypesByPkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootOrderTrackingAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdertrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdertrackingOrderBy>>;
  where?: InputMaybe<OrdertrackingBoolExp>;
};


export type Subscription_RootOrderTrackingByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrderValidationErrorAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrdervalidationerrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrdervalidationerrorOrderBy>>;
  where?: InputMaybe<OrdervalidationerrorBoolExp>;
};


export type Subscription_RootOrderValidationErrorByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrganizationArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type Subscription_RootOrganizationAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type Subscription_RootOrganizationByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrganizationConfigArgs = {
  distinctOn?: InputMaybe<Array<OrganizationconfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationconfigOrderBy>>;
  where?: InputMaybe<OrganizationconfigBoolExp>;
};


export type Subscription_RootOrganizationConfigAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationconfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationconfigOrderBy>>;
  where?: InputMaybe<OrganizationconfigBoolExp>;
};


export type Subscription_RootOrganizationConfigByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOrganizationPricingPoliciesArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


export type Subscription_RootOrganizationPricingPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationPricingPoliciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationPricingPoliciesOrderBy>>;
  where?: InputMaybe<OrganizationPricingPoliciesBoolExp>;
};


export type Subscription_RootOrganizationPricingPoliciesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOrganizationProfileArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


export type Subscription_RootOrganizationProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganizationprofileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationprofileOrderBy>>;
  where?: InputMaybe<OrganizationprofileBoolExp>;
};


export type Subscription_RootPdfArgs = {
  distinctOn?: InputMaybe<Array<PdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PdfOrderBy>>;
  where?: InputMaybe<PdfBoolExp>;
};


export type Subscription_RootPdfAggregateArgs = {
  distinctOn?: InputMaybe<Array<PdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PdfOrderBy>>;
  where?: InputMaybe<PdfBoolExp>;
};


export type Subscription_RootPdfByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPriceAddonsArgs = {
  distinctOn?: InputMaybe<Array<PriceAddonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PriceAddonsOrderBy>>;
  where?: InputMaybe<PriceAddonsBoolExp>;
};


export type Subscription_RootPriceAddonsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PriceAddonsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PriceAddonsOrderBy>>;
  where?: InputMaybe<PriceAddonsBoolExp>;
};


export type Subscription_RootPriceAddonsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPricingInsuranceBridgeArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


export type Subscription_RootPricingInsuranceBridgeAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingInsuranceBridgeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingInsuranceBridgeOrderBy>>;
  where?: InputMaybe<PricingInsuranceBridgeBoolExp>;
};


export type Subscription_RootPricingInsuranceBridgeByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPricingRulesArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};


export type Subscription_RootPricingRulesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PricingRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PricingRulesOrderBy>>;
  where?: InputMaybe<PricingRulesBoolExp>;
};


export type Subscription_RootPricingRulesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProcessesArgs = {
  distinctOn?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type Subscription_RootProcessesAggregateArgs = {
  distinctOn?: InputMaybe<Array<ProcessesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProcessesOrderBy>>;
  where?: InputMaybe<ProcessesBoolExp>;
};


export type Subscription_RootProcessesByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRegionArgs = {
  distinctOn?: InputMaybe<Array<RegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionOrderBy>>;
  where?: InputMaybe<RegionBoolExp>;
};


export type Subscription_RootRegionAggregateArgs = {
  distinctOn?: InputMaybe<Array<RegionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionOrderBy>>;
  where?: InputMaybe<RegionBoolExp>;
};


export type Subscription_RootRegionByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRegionInfoArgs = {
  distinctOn?: InputMaybe<Array<RegionInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionInfoOrderBy>>;
  where?: InputMaybe<RegionInfoBoolExp>;
};


export type Subscription_RootRegionInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<RegionInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RegionInfoOrderBy>>;
  where?: InputMaybe<RegionInfoBoolExp>;
};


export type Subscription_RootRegionInfoByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRobolabsInvoiceArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceBoolExp>;
};


export type Subscription_RootRobolabsInvoiceAggregateArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceBoolExp>;
};


export type Subscription_RootRobolabsInvoiceByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRobolabsInvoiceStatusArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceStatusOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
};


export type Subscription_RootRobolabsInvoiceStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<RobolabsInvoiceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RobolabsInvoiceStatusOrderBy>>;
  where?: InputMaybe<RobolabsInvoiceStatusBoolExp>;
};


export type Subscription_RootRobolabsInvoiceStatusByPkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootSmsMessagesArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesOrderBy>>;
  where?: InputMaybe<SmsMessagesBoolExp>;
};


export type Subscription_RootSmsMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesOrderBy>>;
  where?: InputMaybe<SmsMessagesBoolExp>;
};


export type Subscription_RootSmsMessagesByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSmsMessagesTypesArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesTypesOrderBy>>;
  where?: InputMaybe<SmsMessagesTypesBoolExp>;
};


export type Subscription_RootSmsMessagesTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SmsMessagesTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SmsMessagesTypesOrderBy>>;
  where?: InputMaybe<SmsMessagesTypesBoolExp>;
};


export type Subscription_RootSmsMessagesTypesByPkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootTaskArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


export type Subscription_RootTaskAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderBy>>;
  where?: InputMaybe<TaskBoolExp>;
};


export type Subscription_RootTaskByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTaskEtaArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


export type Subscription_RootTaskEtaAggregateArgs = {
  distinctOn?: InputMaybe<Array<TasketaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TasketaOrderBy>>;
  where?: InputMaybe<TasketaBoolExp>;
};


export type Subscription_RootTaskSeqArgs = {
  distinctOn?: InputMaybe<Array<TaskSeqSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskSeqOrderBy>>;
  where?: InputMaybe<TaskSeqBoolExp>;
};


export type Subscription_RootTaskSeqAggregateArgs = {
  distinctOn?: InputMaybe<Array<TaskSeqSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskSeqOrderBy>>;
  where?: InputMaybe<TaskSeqBoolExp>;
};


export type Subscription_RootTaskSeqByPkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTripArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


export type Subscription_RootTripAggregateArgs = {
  distinctOn?: InputMaybe<Array<TripSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TripOrderBy>>;
  where?: InputMaybe<TripBoolExp>;
};


export type Subscription_RootUserArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Subscription_RootUserAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type Subscription_RootUserByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootVehicleArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};


export type Subscription_RootVehicleAggregateArgs = {
  distinctOn?: InputMaybe<Array<VehicleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VehicleOrderBy>>;
  where?: InputMaybe<VehicleBoolExp>;
};


export type Subscription_RootVehicleByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootWebhookArgs = {
  distinctOn?: InputMaybe<Array<WebhookSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookOrderBy>>;
  where?: InputMaybe<WebhookBoolExp>;
};


export type Subscription_RootWebhookAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookOrderBy>>;
  where?: InputMaybe<WebhookBoolExp>;
};


export type Subscription_RootWebhookByPkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootWebhookInvocationsArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


export type Subscription_RootWebhookInvocationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookInvocationsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookInvocationsOrderBy>>;
  where?: InputMaybe<WebhookInvocationsBoolExp>;
};


export type Subscription_RootWebhookInvocationsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWebhookTypesArgs = {
  distinctOn?: InputMaybe<Array<WebhookTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookTypesOrderBy>>;
  where?: InputMaybe<WebhookTypesBoolExp>;
};


export type Subscription_RootWebhookTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhookTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhookTypesOrderBy>>;
  where?: InputMaybe<WebhookTypesBoolExp>;
};


export type Subscription_RootWebhookTypesByPkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootWebhooksArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};


export type Subscription_RootWebhooksAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksOrderBy>>;
  where?: InputMaybe<WebhooksBoolExp>;
};


export type Subscription_RootWebhooksByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWebhooksTriggerConditionsArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};


export type Subscription_RootWebhooksTriggerConditionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<WebhooksTriggerConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WebhooksTriggerConditionsOrderBy>>;
  where?: InputMaybe<WebhooksTriggerConditionsBoolExp>;
};

export type Timestamp_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

export type Timestamptz_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

export type Timetz_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};

export type Uuid_Cast_Exp = {
  String?: InputMaybe<StringComparisonExp>;
};
