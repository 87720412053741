import {
  ErrorTranslationContextProvider,
  LocaleContextProvider,
} from '@barbora-delivery/merchant-ui'
import { Zoom } from 'general/components/elements/map'
import { ToasterContainer } from 'general/components/toaster'
import { ApolloContextProvider } from 'general/contexts/ApolloContextProvider'
import { IntlContextProvider } from 'general/contexts/IntlContextProvider'
import { OrganizationContextProvider } from 'general/contexts/OrganizationContextProvider'
import { CurrentUserProvider } from 'modules/auth/auth-providers/CurrentUserProvider'
import { FirebaseProvider } from 'modules/auth/auth-providers/FirebaseProvider'
import OrganizationGuard from 'modules/auth/guards/OrganizationGuard'
import { EntryRouter } from 'modules/entry-router'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthRoleProvider, HasuraSDKProvider } from 'sdk/common'
import formErrorsMessages from 'translations/modules/formErrors'

const client = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={client}>
      <LocaleContextProvider initialValue="en">
        <IntlContextProvider>
          <ErrorTranslationContextProvider initialValue={formErrorsMessages}>
            <FirebaseProvider>
              <OrganizationContextProvider initialValue={null}>
                <ApolloContextProvider>
                  <CurrentUserProvider>
                    <OrganizationGuard>
                      <HasuraSDKProvider>
                        <AuthRoleProvider>
                          <ToasterContainer />
                          <Zoom.ContextProvider initialValue={12}>
                            <EntryRouter />
                          </Zoom.ContextProvider>
                        </AuthRoleProvider>
                      </HasuraSDKProvider>
                    </OrganizationGuard>
                  </CurrentUserProvider>
                </ApolloContextProvider>
              </OrganizationContextProvider>
            </FirebaseProvider>
          </ErrorTranslationContextProvider>
        </IntlContextProvider>
      </LocaleContextProvider>
    </QueryClientProvider>
  )
}

export default App
