import { LoaderWindow } from 'general/components/elements/LoaderWindow'
import { fullRoute, ROUTES } from 'general/constants/routes'
import { SELF_SERVICE_ROUTES } from 'general/constants/selfServiceRoutes'
import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { COURIERS_ORG, ADMIN_ORG } from 'general/hooks/useCurrentOrganization'
import { FC } from 'react'
import { Navigate } from '@tanstack/react-location'

const RedirectAppGuard: FC = () => {
  const [currentOrganization] = useOrganizationContext()

  if (currentOrganization === COURIERS_ORG) {
    return <Navigate to={fullRoute(ROUTES.app.couriers.root())} />
  }

  if (currentOrganization) {
    if (currentOrganization !== ADMIN_ORG) {
      return (
        <Navigate
          to={fullRoute(SELF_SERVICE_ROUTES.selfService.organization.root(currentOrganization))}
        />
      )
    }

    return (
      <Navigate
        to={fullRoute(ROUTES.app.organization.root(currentOrganization))}
      />
    )
  }

  return <LoaderWindow />
}

export default RedirectAppGuard
