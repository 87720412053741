import { LoaderWindow } from 'general/components/elements/LoaderWindow'
import { fullRoute, ROUTES } from 'general/constants/routes'
import { SELF_SERVICE_ROUTES } from 'general/constants/selfServiceRoutes'
import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { FC } from 'react'
import { Navigate } from '@tanstack/react-location'
import { useCurrentUserContext } from '../auth-providers/CurrentUserProvider'
import { useLoggedInContext } from '../auth-providers/FirebaseProvider'
import { ADMIN_ORG } from '../../../general/hooks/useCurrentOrganization'

const RedirectRootGuard: FC = () => {
  const loggedIn = useLoggedInContext()
  const currentUser = useCurrentUserContext()
  const [organization] = useOrganizationContext()

  if (!loggedIn) {
    return <Navigate to={fullRoute(ROUTES.auth.root)} />
  }
  if (!currentUser) {
    return <Navigate to={fullRoute(ROUTES.setup.root)} />
  }
  if (!currentUser.roles.length) {
    return <Navigate to={fullRoute(ROUTES.setup.root)} />
  }

  if (!organization) {
    return <LoaderWindow />
  }

  if (organization !== ADMIN_ORG) {
    return <Navigate to={fullRoute(SELF_SERVICE_ROUTES.selfService.organization.root(organization))} />
  }

  return <Navigate to={fullRoute(ROUTES.app.root)} />
}

export default RedirectRootGuard
