import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { auth } from 'firebaseApp'
import { useLoggedInContext } from 'modules/auth/auth-providers/FirebaseProvider'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useOrganizationContext } from './OrganizationContextProvider'

const API_URL = process.env.REACT_APP_API_URL
const PROTOCOL_HTTP =
  process.env.REACT_APP_LOCALHOST === 'true' ? 'http' : 'https'
const PROTOCOL_WS = process.env.REACT_APP_LOCALHOST === 'true' ? 'ws' : 'wss'

function buildApolloClient(organization: string | null) {
  const httpLink = createHttpLink({
    uri: organization
      ? `${PROTOCOL_HTTP}://${API_URL}/${organization}`
      : `${PROTOCOL_HTTP}://${API_URL}`,
  })

  const wsLink = new WebSocketLink({
    uri: `${PROTOCOL_WS}://${API_URL}`,
  })

  const authLink = setContext((_, { headers }) =>
    auth.currentUser?.getIdToken().then((token) => {
      return {
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          authorization: token ? `Bearer ${token}` : '',
        },
      }
    })
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    authLink.concat(httpLink)
  )

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  })
  return client
}

export function ApolloContextProvider(props: React.PropsWithChildren<{}>) {
  const loggedIn = useLoggedInContext()
  const [organization] = useOrganizationContext()
  const client = useMemo(() => buildApolloClient(organization), [organization])
  const [resetting, setResetting] = useState(false)

  useEffect(() => {
    const fn = async () => {
      setResetting(true)
      await client.resetStore()
      setResetting(false)
    }

    fn()
  }, [client, loggedIn])

  if (resetting) {
    return null
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
