export const expressDeliveryStartingHour = 8

export const expressDeliveryCutOffHour = 19

export const expressDeliveryIntervalThreshold = 16

export const expressDeliveryInterval = 30

export const expressDeliveryHalfHourCutOff = true

export const defaultPricingRules = {
  dynamicPrice: 0.25,
  dynamicPriceDistanceM: 500,
  fix: 0,
  maxDistanceM: 0,
  maxPrice: 0,
  minDistanceM: 3000,
  minPrice: 3.99,
}

