import { easySetupRequester } from '@digimuza/graphql'
import { auth } from 'firebaseApp'
import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import * as P from 'ts-prime'
function generateSDK(args: {
  websocketUrl: string
  restUrl: string
  headers: () => Promise<Record<string, string>>
}) {
  const req = easySetupRequester({
    request: {
      url: args.restUrl,
    },
    ws: {
      url: args.websocketUrl,
    },
    headers: args.headers,
  })

  return req
}

export const HasuraSDK = createContext<ReturnType<typeof generateSDK> | null>(
  null
)

export function HasuraSDKProvider(props: PropsWithChildren<{}>) {
  const [organization] = useOrganizationContext()
  const sdk = useMemo(() => {
    return generateSDK({
      websocketUrl: process.env.REACT_APP_HASURA_WS_URL || '',
      restUrl: process.env.REACT_APP_HASURA_API_URL || '',
      headers: async () => {
        const token = await auth.currentUser?.getIdToken()
        return P.compact({
          'No-Auth': 'true',
          authorization: token ? `Bearer ${token}` : undefined,
          organization: organization || undefined,
        }) as Record<string, string>
      },
    })
  }, [organization])

  return <HasuraSDK.Provider value={sdk}>{props.children}</HasuraSDK.Provider>
}

export function useHasuraRequester() {
  const ctx = useContext(HasuraSDK)
  if (ctx == null) throw new Error('Hasura context is not provided')
  return ctx
}
