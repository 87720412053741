import { useOrganizationContext } from '../contexts/OrganizationContextProvider'
import { useLinkWithOrganization } from './useLinkWithOrganization'
import { fullRoute, relativeRoute, ROUTES } from '../constants/routes'
import { SELF_SERVICE_ROUTES } from '../constants/selfServiceRoutes'

export const useNavigationForSettings = () => {
  const [currentOrganization] = useOrganizationContext()
  const route = useLinkWithOrganization()

  let rootRoute,
    apiTokensRoute,
    apiTokensCreateRoute,
    webhooksRoute,
    webhooksCreateRoute,
    webhooksDetailsRoute: Function,
    apiPlaygroundRoute

  if (currentOrganization === 'main') {
    rootRoute = ROUTES.app.organization.orders.root()
    apiTokensRoute = ROUTES.app.organization.settings.apiTokens.root()
    apiTokensCreateRoute = ROUTES.app.organization.settings.apiTokens.create()
    webhooksRoute = ROUTES.app.organization.settings.webhooks.root()
    webhooksCreateRoute = ROUTES.app.organization.settings.webhooks.create()
    webhooksDetailsRoute = (id?: string): ReadonlyArray<string> => ROUTES.app.organization.settings.webhooks.details.root(id)
    apiPlaygroundRoute = ROUTES.app.organization.settings.apiPlayground.root()
  } else {
    rootRoute = SELF_SERVICE_ROUTES.selfService.organization.newOrder()
    apiTokensRoute = SELF_SERVICE_ROUTES.selfService.organization.settings.apiTokens.root()
    apiTokensCreateRoute = SELF_SERVICE_ROUTES.selfService.organization.settings.apiTokens.create()
    webhooksRoute = SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.root()
    webhooksCreateRoute = SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.create()
    webhooksDetailsRoute = (id?: string): ReadonlyArray<string> => SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.details.root(id)
    apiPlaygroundRoute = SELF_SERVICE_ROUTES.selfService.organization.settings.apiPlayground.root()
  }

  return {
    fullRoutes: {
      rootRoute: route(fullRoute(rootRoute)),
      apiTokensRoute: route(fullRoute(apiTokensRoute)),
      apiTokensCreateRoute: route(fullRoute(apiTokensCreateRoute)),
      webhooksRoute: route(fullRoute(webhooksRoute)),
      webhooksCreateRoute: route(fullRoute(webhooksCreateRoute)),
      webhooksDetailsRoute: (id: string) => route(fullRoute(webhooksDetailsRoute(id))),
      apiPlaygroundRoute: route(fullRoute(apiPlaygroundRoute)),
    },
    relativeRoutes: {
      rootRoute: relativeRoute(rootRoute),
      apiTokensRoute: relativeRoute(apiTokensRoute),
      apiTokensCreateRoute: relativeRoute(apiTokensCreateRoute),
      webhooksRoute: relativeRoute(webhooksRoute),
      webhooksCreateRoute: relativeRoute(webhooksCreateRoute),
      webhooksDetailsRoute: (id: string) => relativeRoute(webhooksDetailsRoute(id)),
      apiPlaygroundRoute: relativeRoute(apiPlaygroundRoute),
    }
  }
}