import { Breadcrumbs } from '@barbora-delivery/merchant-ui'
import { FC } from 'react'
import { useNavigationForPricing } from 'general/hooks/useNavigationForPricing'
import {
  Layout,
  LinkItem,
} from 'general/components/layout/LayoutWithBreadCrumbs'

export interface Props {
  topContent?: JSX.Element | string
  sideContent?: JSX.Element | string
  children?: JSX.Element | string
  withContainer?: boolean
}

export const PricingManagementLayout: FC<Props> = ({
  topContent,
  withContainer = true,
  children,
}) => {
  const { fullRoutes, relativeRoutes } = useNavigationForPricing()

  return (
    <Layout
      title="Pricing"
      children={children}
      withContainer={withContainer}
      topContent={topContent}
      innerMenu={
        <>
          <LinkItem to={relativeRoutes.merchantsPricingRoute}>
            Merchants
          </LinkItem>
          <LinkItem to={relativeRoutes.couriersPricingRoute}>Couriers</LinkItem>
        </>
      }
      breadCrumbs={
        <Breadcrumbs
          homeRoute={fullRoutes.rootRoute}
          pages={[
            {
              name: 'Pricing',
              to: fullRoutes.merchantsPricingRoute,
            },
          ]}
        />
      }
    />
  )
}
