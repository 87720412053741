import {
  DefaultGenerics,
  Navigate,
  Outlet,
  Route,
} from '@tanstack/react-location'
import { relativeRoute, ROUTES } from 'general/constants/routes'
import { ApiManagementLayout } from './layout'
import { webhookDetailsRouter } from './webhook-details/router'
import { SELF_SERVICE_ROUTES } from '../../general/constants/selfServiceRoutes'

export const apiManagementRouter: Route<DefaultGenerics>[] = [
  {
    path: 'api/webhooks/create',
    element: () => import('./webhook-create').then((el) => <el.default />),
  },
  {
    path: 'api/api-tokens/create',
    element: () => import('./api-token-create').then((el) => <el.default />),
  },
  webhookDetailsRouter,
  {
    path: relativeRoute(ROUTES.app.organization.settings.root()),
    element: (
      <ApiManagementLayout>
        <Outlet />
      </ApiManagementLayout>
    ),
    children: [
      {
        path: relativeRoute(ROUTES.app.organization.settings.apiTokens.root()),
        element: () =>
          import('./api-token-settings').then((el) => <el.default />),
      },
      {
        path: relativeRoute(ROUTES.app.organization.settings.webhooks.root()),
        element: () =>
          import('./webhook-settings').then((el) => <el.default />),
      },
      {
        path: relativeRoute(ROUTES.app.organization.settings.apiPlayground.root()),
        element: () =>
          import('./api-playground').then((el) => <el.default />)
      },
      {
        element: <Navigate to={relativeRoute(ROUTES.app.organization.settings.apiTokens.root())} />,
      },
    ],
  },
]

export const apiManagementRouterForSelfService: Route<DefaultGenerics>[] = [
  {
    path: 'api/api-tokens/create',
    element: () => import('./api-token-create').then((el) => <el.default />),
  },
  {
    path: 'api/webhooks/create',
    element: () => import('./webhook-create').then((el) => <el.default />),
  },
  webhookDetailsRouter,
  {
    path: relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.settings.root()),
    element: (
      <ApiManagementLayout>
        <Outlet />
      </ApiManagementLayout>
    ),
    children: [
      {
        path: relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.settings.apiTokens.root()),
        element: () =>
          import('./api-token-settings').then((el) => <el.default />),
      },
      {
        path: relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.settings.webhooks.root()),
        element: () =>
          import('./webhook-settings').then((el) => <el.default />),
      },
      {
        path: relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.settings.apiPlayground.root()),
        element: () =>
          import('./api-playground').then((el) => <el.default />)
      },
      {
        element: <Navigate to={relativeRoute(SELF_SERVICE_ROUTES.selfService.organization.settings.apiTokens.root())} />,
      },
    ],
  },
]
