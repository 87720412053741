import { auth } from 'firebaseApp'
import { ExpressRegion } from 'sdk/user/features'

export type UserPreference = 'COURIER' | 'MERCHANT'

export interface StorageAddressItems {
  PICKUP_ADDRESSES: string[]
  DROPOFF_ADDRESSES: string[]
}

interface StorageContactDetails {
  [key: string]: {
    name: string
    phone: string
    notes: string
  }
}

export interface StorageContactDetailsItems {
  PICKUP_DETAILS: StorageContactDetails
  DROPOFF_DETAILS: StorageContactDetails
}

export interface StorageItems
  extends StorageAddressItems,
    StorageContactDetailsItems {
  USER_PREFERENCE: UserPreference
  USER_ORGANIZATION: string
  REGION: ExpressRegion
}

type StorageItemsKeys = keyof StorageItems

type StorageMethods<T extends StorageItemsKeys> = [
  () => StorageItems[T] | null,
  (val: StorageItems[T] | null) => void
]

export const useLocalStorage = <T extends StorageItemsKeys>(
  localStorageKey: T,
  bindWithUser: boolean = false
): StorageMethods<T> => {
  const userId = auth.currentUser?.uid
  const storeKey =
    userId !== undefined && bindWithUser
      ? `${localStorageKey}${userId}`
      : localStorageKey

  const setValue = (value: StorageItems[T] | null) => {
    if (value == null) {
      localStorage.removeItem(storeKey)
      return
    }
    localStorage.setItem(storeKey, JSON.stringify(value))
  }

  const getValue = (): StorageItems[T] | null => {
    const record = localStorage.getItem(storeKey)
    if (record) {
      const parsedValue: StorageItems[T] = JSON.parse(record)
      return parsedValue
    } else {
      return null
    }
  }

  return [getValue, setValue]
}
