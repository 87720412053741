import {
  Button,
  DropdownMenu,
  LocaleKeys,
  useLocaleContext,
} from '@barbora-delivery/merchant-ui'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ReactComponent as LT } from 'assets/icons/flags/lt.svg'
import { ReactComponent as RU } from 'assets/icons/flags/ru.svg'
import { ReactComponent as EN } from 'assets/icons/flags/uk.svg'
import { FC } from 'react'
import * as P from 'ts-prime'

const Icons: Record<
  LocaleKeys,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  lt: LT,
  en: EN,
  ru: RU,
}
const Language: Record<LocaleKeys, string> = {
  lt: 'Lietuvių',
  en: 'English',
  ru: 'Русский',
}

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const RenderIcon: FC<Props> = ({ Icon }) => {
  return <Icon />
}

export const LanguageMenu: FC = () => {
  const [locale, setLocale] = useLocaleContext()

  const data = P.keys(Language).map((key) => ({
    onClick: () => setLocale(key),
    element: (
      <div className="w-36 flex gap-2">
        <RenderIcon Icon={Icons[key]} />
        <span>{Language[key]}</span>
      </div>
    ),
  }))

  return (
    <DropdownMenu items={data}>
      <Button color="WHITE">
        <div className="flex gap-2">
          <RenderIcon Icon={Icons[locale]} />
          <span>{Language[locale]}</span>
          <ChevronDownIcon className="text-gray-500 hover:text-gray-700 h-5 -mr-2" />
        </div>
      </Button>
    </DropdownMenu>
  )
}
