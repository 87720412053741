import * as Types from 'sdk/root';

import { Observable } from 'rxjs'
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export const GetOrganizationDocument = gql`
    query GetOrganization($id: String!) {
  organizationByPk(id: $id) {
    id
    displayName
    image
  }
}
    `;
export const CurrentRoleDocument = gql`
    query CurrentRole {
  auth {
    role
  }
}
    `;
export const GetUserDocument = gql`
    query GetUser {
  user {
    email
    id
  }
}
    `;
export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> & Observable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    GetOrganization(variables: GetOrganizationQueryVariables, options?: C): Promise<GetOrganizationQuery> {
      return requester<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, variables, options);
    },
    CurrentRole(variables?: CurrentRoleQueryVariables, options?: C): Promise<CurrentRoleQuery> {
      return requester<CurrentRoleQuery, CurrentRoleQueryVariables>(CurrentRoleDocument, variables, options);
    },
    GetUser(variables?: GetUserQueryVariables, options?: C): Promise<GetUserQuery> {
      return requester<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables, options);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export type GetOrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetOrganizationQuery = { organizationByPk?: { __typename?: 'Organization', id: string, displayName: string, image: string } | null };

export type CurrentRoleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentRoleQuery = { auth?: { __typename?: 'AuthOutput', role: Types.Role } | null };

export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = { user: Array<{ __typename?: 'User', email: string, id: string }> };
