import { makeContextValueComponent } from '@barbora-delivery/merchant-ui'
import { auth } from 'firebaseApp'
import { FC, useEffect, useState } from 'react'

const { LoggedInContextProvider, useLoggedInContext } =
  makeContextValueComponent<boolean>()('loggedIn')

export { useLoggedInContext }

export const FirebaseProvider: FC = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>()
  useEffect(() => {
    auth.onAuthStateChanged(async () => {
      const jwtToken = await auth.currentUser?.getIdToken()
      if (jwtToken) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }
    })
  }, [])

  if (loggedIn == null) {
    return null
  }

  return (
    <LoggedInContextProvider value={loggedIn}>
      {children}
    </LoggedInContextProvider>
  )
}
