export const ROUTES = {
  auth: {
    root: ['auth'] as const,
    signIn: () => [...ROUTES.auth.root, 'sign-in'] as const,
    signUpCourier: () => [...ROUTES.auth.root, 'sign-up/courier'] as const,
    signUpMerchant: () => [...ROUTES.auth.root, 'sign-up/merchant'] as const,
    passwordReset: () => [...ROUTES.auth.root, 'password-reset'] as const,
  },
  setup: {
    root: ['setup'] as const,
    profile: () => [...ROUTES.setup.root, 'profile'] as const,
    organization: () => [...ROUTES.setup.root, 'organization'] as const,
    courier: () => [...ROUTES.setup.root, 'courier'] as const,
  },
  app: {
    root: ['app'] as const,
    couriers: {
      root: () => [...ROUTES.app.root, 'couriers'] as const,
    },
    organization: {
      root: (organization?: string) =>
        [
          ...ROUTES.app.root,
          organization ? organization : ':organization',
        ] as const,
      dashboard: () =>
        [...ROUTES.app.organization.root(), 'dashboard'] as const,
      orders: {
        root: () => [...ROUTES.app.organization.root(), 'orders'] as const,
        list: () => [...ROUTES.app.organization.orders.root(), 'list'] as const,
        details: (id?: string) =>
          [
            ...ROUTES.app.organization.orders.root(),
            id ? `${id}/details` : ':id/details',
          ] as const,
        new: {
          root: () =>
            [...ROUTES.app.organization.orders.root(), 'new-order'] as const,
        },
      },
      couriers: {
        root: () => [...ROUTES.app.organization.root(), 'couriers'] as const,
        list: () =>
          [...ROUTES.app.organization.couriers.root(), 'list'] as const,
        details: {
          root: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.root(),
              id ? `${id}/details` : ':id/details',
            ] as const,
          account: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'account',
            ] as const,
          orders: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'orders',
            ] as const,
          payments: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'payments',
            ] as const,
          hardware: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'hardware',
            ] as const,
          blockings: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'blockings',
            ] as const,
          activityLog: (id?: string) =>
            [
              ...ROUTES.app.organization.couriers.details.root(id),
              'activity-log',
            ] as const,
        },
      },
      liveMap: {
        root: () => [...ROUTES.app.organization.root(), 'live-map'] as const,
      },
      pricing: {
        root: () => [...ROUTES.app.organization.root(), 'pricing'] as const,
        merchants: {
          root: () =>
            [...ROUTES.app.organization.pricing.root(), 'merchants'] as const,
        },
        couriers: {
          root: () =>
            [...ROUTES.app.organization.pricing.root(), 'couriers'] as const,
        },
      },
      settings: {
        root: () => [...ROUTES.app.organization.root(), 'api'] as const,
        apiTokens: {
          root: () =>
            [...ROUTES.app.organization.settings.root(), 'api-tokens'] as const,
          create: () =>
            [
              ...ROUTES.app.organization.settings.apiTokens.root(),
              'create',
            ] as const,
        },
        webhooks: {
          root: () =>
            [...ROUTES.app.organization.settings.root(), 'webhooks'] as const,
          create: () =>
            [
              ...ROUTES.app.organization.settings.webhooks.root(),
              'create',
            ] as const,
          details: {
            root: (id?: string) =>
              [
                ...ROUTES.app.organization.settings.webhooks.root(),
                id ? `${id}` : ':id',
              ] as const,
            update: (id?: string) =>
              [
                ...ROUTES.app.organization.settings.webhooks.details.root(id),
                'details',
              ] as const,
            invocations: {
              root: (id?: string) =>
                [
                  ...ROUTES.app.organization.settings.webhooks.details.root(id),
                  'invocations',
                ] as const,
              details: (id?: string, invocationId?: string) => [
                ...ROUTES.app.organization.settings.webhooks.details.invocations.root(
                  id
                ),
                invocationId ? `${invocationId}` : ':invocationId',
              ],
            },
          },
        },
        apiPlayground: {
          root: () =>
            [...ROUTES.app.organization.settings.root(), 'playground'] as const,
        },
      },
    },
  },
}

export const fullRoute = (routesArray: ReadonlyArray<string>) => {
  return routesArray.map((route: string) => '/' + route).join('')
}
export const relativeRoute = (routesArray: ReadonlyArray<string>) => {
  return routesArray[routesArray.length - 1]
}
