import { relativeRoute } from 'general/constants/routes'
import { FC, PropsWithChildren } from 'react'
import { Link, useLocation } from '@tanstack/react-location'
import classNames from 'classnames'
import { TitleLayout } from 'general/components/layout/TitleLayout'
import { XmasAndNYNotification } from '../../../general/components/elements/holidaysNotifications/XmasAndNYNotification'

export interface Props {
  topContent?: JSX.Element | string
  sideContent?: JSX.Element | string
  children?: JSX.Element | string
  withContainer?: boolean
  title: string
  innerMenu?: JSX.Element
  breadCrumbs?: JSX.Element
}

export function LinkItem(props: PropsWithChildren<{ to: string }>) {
  const location = useLocation()
  const isActive =
    props.to === relativeRoute(location.current.pathname.split('/'))

  return (
    <Link to={props.to}>
      <li
        className={classNames(
          'w-48  text-sm px-3 py-2 transition-all rounded-lg ',
          {
            'text-gray-600 hover:text-gray-900 hover:bg-indigo-200': !isActive,
            'bg-indigo-200 text-gray-900': isActive,
          }
        )}
      >
        {props.children}
      </li>
    </Link>
  )
}

export const Layout: FC<Props> = ({
  topContent,
  withContainer = true,
  children,
  title,
  innerMenu,
  breadCrumbs,
}) => {
  return (
    <div className="absolute top-6 bottom-6 md:left-8 md:right-8 sm:left-6 sm:right-6 left-4 right-4">
      <div className="h-full  max-h-full flex flex-col">
        <XmasAndNYNotification />
        <TitleLayout>{title}</TitleLayout>
        {breadCrumbs}
        <div
          className={classNames('pt-6 grid grid-cols-6 overflow-hidden ', {
            '-m-1 p-1': !withContainer,
          })}
        >
          <div className="w-full">
            <ul className="flex flex-col justify-center items-end mr-4 space-y-1">
              {innerMenu}
            </ul>
          </div>
          <div
            className={classNames(
              'col-span-4 flex flex-col overflow-hidden h-full',
              { '-m-1 p-1': !withContainer },
              {
                'bg-white md:px-8 sm:px-6 px-4 py-6 shadow rounded-lg h-screen':
                  withContainer,
              }
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
