import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { createContext, PropsWithChildren, useContext } from 'react'
import { useQuery } from 'react-query'
import { Role } from 'sdk/root'
import { useSDK } from 'sdk/user/api'

const AuthRoleContext = createContext<Role | null>(null)
function useAuthRoleQuery() {
  const [context] = useOrganizationContext()
  const role = useSDK()
  const query = useQuery(['auth_role', context], async () => {
    const result = await role.CurrentRole()
    return result.auth?.role
  })

  return query
}

export function useAuthRoleContext() {
  const ctx = useContext(AuthRoleContext)
  if (ctx == null) throw new Error('AuthRoleContext')
  return ctx
}

export function AuthRoleProvider(props: PropsWithChildren<{}>) {
  const role = useAuthRoleQuery()
  // TODO HANDLE ERRORS AND LOADING STATE
  // console.log(role.data)
  if (role.data == null) return null
  return (
    <AuthRoleContext.Provider value={role.data}>
      {props.children}
    </AuthRoleContext.Provider>
  )
}

export function CanSee(props: PropsWithChildren<{ roles: Role[] }>) {
  const role = useAuthRoleContext()
  if (props.roles.includes(role)) {
    return <>{props.children}</>
  }
  return null
}
