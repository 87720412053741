import { useSearch } from '@tanstack/react-location'
import { endOfDay, startOfDay } from 'date-fns'
import { GetOrdersQueryVariables } from 'sdk/organization_member/api'
import { OrderBy } from 'sdk/root'
import * as P from 'ts-prime'

export type CommonFilters = [
  {
    id: 'client'
    operator: 'search'
    value: string
  },
  {
    id: 'courier'
    operator: 'in'
    value: string[]
  },
  {
    id: 'createdAt'
    operator: 'range'
    valueFrom: string
    valueTo: string
  },
  {
    id: 'status'
    operator: 'in'
    value: ['in_progress', 'completed', 'failed'][number]
  },
  {
    id: 'orderIdSearch'
    operator: 'search'
    value: string
  },
  {
    id: 'pickup_address'
    operator: 'search'
    value: string
  },
  {
    id: 'organization'
    operator: 'in'
    value: string[]
  },
  {
    id: 'pickup_address'
    operator: 'search'
    value: string
  },
  {
    id: 'drop_off_address'
    operator: 'search'
    value: string
  },
  {
    id: 'city'
    operator: 'search'
    value: string
  },
  {
    id: 'asap'
    operator: 'search'
    value: boolean[]
  },
  {
    id: 'search'
    operator: 'search'
    value: string
  }
][number]

export type CommonSearchQuery = {
  Search: {
    filters: CommonFilters[]
    pageIndex: number
    itemCount: number
    sortBy?: { field: 'createdAt'; desc: boolean }[]
  }
}

type QueryArg = {
  range?: {
    from?: string
    to?: string
  }
  organization?: string[]
  courier?: string[]
  status?: ['in_progress', 'completed', 'failed'][number]
  orderId?: string
  client?: string
  pickup?: string
  dropOff?: string
  city?: string
  asap?: boolean[]
  search?: string
}

export function applyFilters(
  acc: GetOrdersQueryVariables,
  args?: QueryArg
): GetOrdersQueryVariables {
  if (args == null) return acc

  function filter<X>(
    data: X | undefined,
    fn: (v: X) => GetOrdersQueryVariables
  ) {
    if (data == null) return {}
    return fn(data)
  }

  // Deeply merge filter from left ro right
  const filters = P.deepMergeRight(
    acc,
    filter(args.courier, (c) => ({
      where: { courierId: { _in: c.map((q) => parseInt(q)) } },
    })),
    filter(args.status, (c) => {
      type OrderStatus =
        | 'CREATED'
        | 'COMPLETED'
        | 'PLANNED'
        | 'NEED_COURIER'
        | 'COURIER_MISSING'
        | 'ACTIVE'
        | 'FAILED'
        | 'STARTED'
      const statusMap: Record<typeof c, OrderStatus[]> = {
        in_progress: [
          'ACTIVE',
          'COURIER_MISSING',
          'NEED_COURIER',
          'PLANNED',
          'CREATED',
          'STARTED',
        ],
        failed: ['FAILED'],
        completed: ['COMPLETED'],
      }
      if (c.length > 1) {
        const statuses = []
        for (let index = 0; index < c.length; index++) {
          const element = c[index]
          statuses.push(...statusMap[element as typeof c])
        }

        return {
          where: { status: { _in: statuses as OrderStatus[] } },
        }
      }
      return {
        where: { status: { _in: statusMap[c] } },
      }
    }),
    filter(args.range, (c) => ({
      where: { createdAt: { _gte: c.from, _lte: c.to } },
    })),
    filter(args.organization, (c) => ({
      where: { organizationId: { _in: c } },
    })),
    filter(args.orderId, (c) => ({
      where: {
        _or: [
          {
            orderId: {
              _like: `%${c}%`,
            },
          },
          {
            shortOrderId: {
              _like: `%${c}%`,
            },
          },
        ],
      },
    })),
    filter(args.pickup, (c) => ({
      where: {
        tasks: {
          _and: [
            {
              taskType: {
                _eq: 'PICKUP' as const,
              },
              address: {
                address: {
                  _like: `%${c || ''}%`,
                },
              },
            },
          ],
        },
      },
    })),
    filter(args.city, (c) => ({
      where: {
        region: {
          _ilike: `%${c || ''}%`,
        },
      },
    })),
    filter(args.search, (c) => ({
      where: {
        _or: [
          { id: { _ilike: `%${c || ''}%` } },
          { orderId: { _ilike: `%${c || ''}%` } },
          { shortOrderId: { _ilike: `%${c || ''}%` } },
          { organization: { name: { _ilike: `%${c || ''}%` } } },
          { courier: { user: { email: { _ilike: `%${c || ''}%` } } } },
          {
            courier: {
              user: {
                _or: [
                  { firstName: { _ilike: `%${c || ''}%` } },
                  { lastName: { _ilike: `%${c || ''}%` } },
                ],
              },
            },
          },
          { tasks: { address: { address: { _ilike: `%${c || ''}%` } } } },
          { tasks: { address: { contactName: { _ilike: `%${c || ''}%` } } } },
          { tasks: { address: { contactPhone: { _ilike: `%${c || ''}%` } } } },
        ],
      },
    })),

    filter(args.dropOff, (c) => ({
      where: {
        tasks: {
          _and: [
            {
              taskType: {
                _eq: 'DROP_OFF' as const,
              },
              address: {
                address: {
                  _like: `%${c || ''}%`,
                },
              },
            },
          ],
        },
      },
    })),
    filter(args.asap, (c) => ({
      where: {
        asap: { _eq: c[0] },
      },
    })),
    filter(args.client, (c) => ({
      where: {
        tasks: {
          _and: [
            {
              taskType: {
                _eq: 'DROP_OFF' as const,
              },
              address: {
                _or: [
                  {
                    contactName: {
                      _like: `%${c}%`,
                    },
                  },
                  {
                    contactPhone: {
                      _like: `%${c}%`,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    }))
  )

  return filters
}

export function useFilters() {
  const search = useSearch<CommonSearchQuery>()
  const filterGen = search.filters?.reduce((acc, c) => {
    if (c.id === 'client') {
      return {
        ...acc,
        client: c.value,
      }
    }
    if (c.id === 'courier') {
      return {
        ...acc,
        courier: c.value,
      }
    }
    if (c.id === 'createdAt') {
      return {
        ...acc,
        range: {
          ...acc.range,
          from: startOfDay(new Date(c.valueFrom)).toISOString(),
          to: endOfDay(new Date(c.valueTo)).toISOString(),
        },
      }
    }

    if (c.id === 'pickup_address') {
      return {
        ...acc,
        pickup: c.value,
      }
    }
    if (c.id === 'drop_off_address') {
      return {
        ...acc,
        pickup: c.value,
      }
    }
    if (c.id === 'orderIdSearch') {
      return {
        ...acc,
        orderId: c.value,
      }
    }

    if (c.id === 'status') {
      return {
        ...acc,
        status: c.value,
      }
    }
    if (c.id === 'organization') {
      return {
        ...acc,
        organization: c.value,
      }
    }

    if (c.id === 'city') {
      return {
        ...acc,
        city: c.value,
      }
    }
    if (c.id === 'search') {
      return {
        ...acc,
        search: c.value,
      }
    }
    if (c.id === 'asap') {
      return {
        ...acc,
        asap: c.value,
      }
    }
    return acc
  }, {} as QueryArg)

  return applyFilters(
    {
      orderBy: search.sortBy?.map((q) => ({
        createdAt: q.desc ? OrderBy.Desc : OrderBy.Asc,
      })),
      offset: (search.pageIndex || 0) * (search?.itemCount ?? 20),
      limit: search?.itemCount ?? 20,
    },
    filterGen
  )
}
