import { NavigateToRoot } from 'general/components/router/NavigateToRoot'
import { fullRoute, ROUTES } from 'general/constants/routes'
import { useLocalStorage } from 'general/hooks/useLocalStorage'
import { FC } from 'react'
import { Navigate } from '@tanstack/react-location'
import { useCurrentUserContext } from '../auth-providers/CurrentUserProvider'

const RedirectSetupGuard: FC = () => {
  const currentUser = useCurrentUserContext()
  const [getValue] = useLocalStorage('USER_PREFERENCE')

  const preference = getValue()

  if (!currentUser) {
    return <Navigate to={fullRoute(ROUTES.setup.profile())} />
  }

  if (!currentUser.roles.length) {
    if (preference === 'MERCHANT') {
      return <Navigate to={fullRoute(ROUTES.setup.organization())} />
    } else {
      return <Navigate to={fullRoute(ROUTES.setup.courier())} />
    }
  }

  return <NavigateToRoot />
}

export default RedirectSetupGuard
