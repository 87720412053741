import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { useCurrentUserContext } from 'modules/auth/auth-providers/CurrentUserProvider'
import { useLocalStorage } from './useLocalStorage'
import * as P from 'ts-prime'

export const COURIERS_ORG = 'couriers'
export const ADMIN_ORG = 'main'

export const useSetOrganization = () => {
  const [getStoredOrganization, setStoredOrganization] =
    useLocalStorage('USER_ORGANIZATION')
  const currentUser = useCurrentUserContext()
  const [appOrganization, setAppOrganization] = useOrganizationContext()

  return (organizationToSet?: string) => {
    if (!currentUser?.roles.length) {
      setAppOrganization(null)
      setStoredOrganization(null)
      return
    }

    const userOrganizations = P.pipe(
      currentUser.roles,
      P.map((item) => item.organization.name),
      P.sortBy((item) => {
        if (item === 'main') return [0, item]
        if (item === 'couriers') return [1000, item]
        return [1, item]
      })
    )

    if (organizationToSet && userOrganizations.includes(organizationToSet)) {
      setAppOrganization(organizationToSet)
      setStoredOrganization(organizationToSet)
      return
    }

    if (appOrganization && userOrganizations.includes(appOrganization)) {
      return
    }

    const storedOrganization = getStoredOrganization()
    if (
      storedOrganization &&
      userOrganizations.includes(storedOrganization)
    ) {
      setAppOrganization(storedOrganization)
      setStoredOrganization(storedOrganization)
      return
    }

    setStoredOrganization(userOrganizations[0])
    setAppOrganization(userOrganizations[0])
  }
}
