import { FC } from 'react'

interface Props {}

export const TitleLayout: FC<Props> = ({ children }) => {
  return (
    <div className="text-2xl leading-8 font-bold text-gray-900 flex gap-4 items-center mb-4">
      {children}
    </div>
  )
}
