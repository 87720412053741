import { initializeApp } from 'firebase/app'
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth'

const app = initializeApp({
  apiKey: 'AIzaSyAKtaHvHW9U9UmgA-OEUPFe2q0ZF4-ePFs',
  authDomain: 'instant-delivery-68004.firebaseapp.com',
  projectId: 'instant-delivery-68004',
  appId: 'delivery',
})

const auth = getAuth(app)

export const signUp = async (email: string, password: string) =>
  createUserWithEmailAndPassword(auth, email, password)
export const signIn = async (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password)
export const logout = async () => {
  try {
    auth.signOut()
  } catch (error) {
    console.log(error)
  }
}

export { auth }
