import { defineMessages } from 'react-intl'

export const formErrorsMessages = defineMessages({
  REQUIRED: {
    id: `form-errors.REQUIRED`,
    defaultMessage: 'Required',
  },
  NOT_VALID_EMAIL: {
    id: `form-errors.NOT_VALID_EMAIL`,
    defaultMessage: 'Enter valid email address',
  },
  PASSWORD_MATCH: {
    id: `form-errors.PASSWORD_MATCH`,
    defaultMessage: 'Password does not match',
  },
  WRONG_EMAIL_OR_PASSWORD: {
    id: `form-errors.WRONG_EMAIL_OR_PASSWORD`,
    defaultMessage: 'Wrong email address or password.',
  },
  ENTER_EMAIL_AND_PASSWORD: {
    id: `form-errors.ENTER_EMAIL_AND_PASSWORD`,
    defaultMessage: 'Enter email address and password.',
  },
  EMAIL_IS_NOT_VALID: {
    id: `form-errors.EMAIL_IS_NOT_VALID`,
    defaultMessage: 'Email address is not valid.',
  },
  PHONE_IS_NOT_VALID: {
    id: `form-errors.PHONE_IS_NOT_VALID`,
    defaultMessage: 'Phone is not valid.',
  },
  PASSWORD_TOO_SHORT: {
    id: `form-errors.PASSWORD_TOO_SHORT`,
    defaultMessage: 'Password should be at least 6 characters.',
  },
  LOCATION_OUTSIDE_DELIVERY_ZONE: {
    id: `form-errors.LOCATION_OUTSIDE_DELIVERY_ZONE`,
    defaultMessage: 'This location is outside of the delivery zone.',
  },
  PICKUP_AND_DELIVERY_REGIONS_DO_NOT_MATCH: {
    id: `form-errors.PICKUP_AND_DELIVERY_REGIONS_DO_NOT_MATCH`,
    defaultMessage: 'Pickup and delivery locations are in different delivery regions.',
  },
  HOUSE_NUMBER_MISSING: {
    id: `form-errors.HOUSE_NUMBER_MISSING`,
    defaultMessage: 'House number is missing.',
  },
  INVALID_URL_FORMAT: {
    id: `form-errors.INVALID_URL_FORMAT`,
    defaultMessage: 'Invalid url format',
  },
})

export default formErrorsMessages
