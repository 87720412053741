import {
  DefaultGenerics,
  Navigate,
  Outlet,
  Route,
} from '@tanstack/react-location'
import { relativeRoute, ROUTES } from 'general/constants/routes'
import { PricingManagementLayout } from './layout'

export const pricingManagementRouter: Route<DefaultGenerics>[] = [
  {
    path: relativeRoute(ROUTES.app.organization.pricing.root()),
    element: (
      <PricingManagementLayout>
        <Outlet />
      </PricingManagementLayout>
    ),
    children: [
      {
        path: relativeRoute(ROUTES.app.organization.pricing.merchants.root()),
        element: <div>Merchants</div>,
      },
      {
        path: relativeRoute(ROUTES.app.organization.pricing.couriers.root()),
        element: () => import('./couriers').then((el) => <el.default />),
      },
      {
        element: (
          <Navigate
            to={relativeRoute(ROUTES.app.organization.pricing.couriers.root())}
          />
        ),
      },
    ],
  },
]
