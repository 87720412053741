import { FC } from 'react'
import { Toaster } from 'react-hot-toast'

export const ToasterContainer: FC = () => {
  return (
    <Toaster
      position="top-right"
      gutter={16}
      containerStyle={{
        top: 32,
        left: 32,
        bottom: 32,
        right: 32,
      }}
      toastOptions={{
        duration: 5000,
      }}
    />
  )
}
