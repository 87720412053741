import { FC } from 'react'
import { isSameDay } from 'date-fns'
import { isOrderCanBeCreated } from '../../../../modules/new-order/utils'

export const XmasAndNYNotification: FC = () => {
  const notificationText =
    isSameDay(new Date(), new Date(2023, 0, 1)) ||
    (isSameDay(new Date(), new Date(2022, 11, 31)) &&
      new Date().getHours() >= 17)
      ? 'Our services are currently unavailable due to New Year’s Eve Celebration. We will return on Monday, 2023.01.02.'
      : 'Our services are currently unavailable due to Christmas Holidays. We will return on Tuesday, 2022.12.27.'

  if (!isOrderCanBeCreated()) {
    return (
      <div className="bg-green-50 w-4/6 flex items-center  gap-4 py-2 px-4 rounded-r-md shadow-lg mb-3">
        <div className=" h-full w-1 bg-green-400" />
        <div className="flex flex-col">
          <span>{notificationText}</span>
          <span>
            In the meantime if you have any questions please contact our account
            managers or support.
          </span>
        </div>
      </div>
    )
  }

  return <></>
}
