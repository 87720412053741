import { makeContextComponent } from '@digimuza/react-utils'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { createContext, FC } from 'react'
import { Map, MapRef } from 'react-map-gl'
import { ArgsType } from 'ts-prime'
import './marker.css'

/* eslint import/no-webpack-loader-syntax: off */

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const VILNIUS_BBOX = [25.005, 54.493, 25.576, 54.851] as [
  number,
  number,
  number,
  number
]

type MapProps = ArgsType<typeof Map>[0]

interface Props extends MapProps {
  id?: string
  mapRef?: (mapRef: MapRef | null) => void
  initialBounds?: mapboxgl.LngLatBoundsLike | undefined
}

export const Zoom = makeContextComponent<number>('zoom-context')
export const BoundsContext = createContext(VILNIUS_BBOX)
export const BoundsProvider = BoundsContext.Provider

export const BaseMapGl: FC<Props> = (props) => {
  const { children, initialBounds } = props
  const setZoom = Zoom.useStream()
  return (
    <Map
      {...props}
      initialViewState={{
        bounds: initialBounds || VILNIUS_BBOX,
      }}
      maxZoom={16}
      minZoom={5}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onZoom={(newViewport) => {
        setZoom.next(newViewport.viewState.zoom)
      }}
    >
      <BoundsContext.Provider value={VILNIUS_BBOX}>
        {children}
      </BoundsContext.Provider>
    </Map>
  )
}
