import { Breadcrumbs } from '@barbora-delivery/merchant-ui'
import classNames from 'classnames'
import { FC } from 'react'
import { useNavigationForSettings } from '../../../general/hooks/useNavigationForSettings'
import {
  Layout,
  LinkItem,
} from 'general/components/layout/LayoutWithBreadCrumbs'

interface Props {
  topContent?: JSX.Element | string
  sideContent?: JSX.Element | string
  children?: JSX.Element | string
  withContainer?: boolean
}

export const ApiManagementLayout: FC<Props> = ({
  topContent,
  withContainer = true,
  children,
}) => {
  const { fullRoutes, relativeRoutes } = useNavigationForSettings()

  return (
    <Layout
      title="Settings"
      children={children}
      withContainer={withContainer}
      topContent={topContent}
      innerMenu={
        <>
          <LinkItem to={relativeRoutes.apiTokensRoute}>Api tokens</LinkItem>
          <LinkItem to={relativeRoutes.webhooksRoute}>Webhooks</LinkItem>
        </>
      }
      breadCrumbs={
        <Breadcrumbs
          homeRoute={fullRoutes.rootRoute}
          pages={[
            {
              name: 'Settings',
              to: fullRoutes.apiTokensRoute,
            },
          ]}
        />
      }
    />
  )
}

export const ApiManagementPageLayout: FC<Props> = ({
  topContent,
  withContainer = true,
  children,
}) => {
  return (
    <div className="absolute top-6 bottom-6 md:left-8 md:right-8 sm:left-6 sm:right-6 left-4 right-4">
      <div className="h-full  max-h-full flex flex-col">
        {topContent}

        <div
          className={classNames('pt-4 grid grid-cols-6 overflow-hidden ', {
            '-m-1 p-1': !withContainer,
          })}
        >
          <div className="col-span-1"></div>
          <div
            className={classNames(
              'col-span-4 flex flex-col overflow-hidden ',
              { '-m-1 p-1': !withContainer },
              {
                'bg-white md:px-8 sm:px-6 px-4 py-6 shadow rounded-lg':
                  withContainer,
              }
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ApiManagementPage2Layout: FC<Props> = ({
  topContent,
  withContainer = true,
  children,
}) => {
  return (
    <div className="absolute top-6 bottom-6 md:left-8 md:right-8 sm:left-6 sm:right-6 left-4 right-4">
      <div className="h-full  max-h-full flex flex-col">
        {topContent}

        <div
          className={classNames(
            ' gap-3 pt-4 grid grid-cols-6 overflow-hidden ',
            {
              '-m-1 p-1': !withContainer,
            }
          )}
        >
          <div className="col-span-1"></div>
          <div
            className={classNames(
              'col-span-4 flex flex-col overflow-hidden',
              { '-m-1 p-1': !withContainer },
              {
                'bg-white md:px-8 sm:px-6 px-4 py-6 shadow rounded-lg':
                  withContainer,
              }
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
