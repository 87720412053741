import {
  Navigate,
  Outlet,
  useMatches,
  useMatchRoute,
} from '@tanstack/react-location'
import { LoaderWindow } from 'general/components/elements/LoaderWindow'
import { NavigateToRoot } from 'general/components/router/NavigateToRoot'
import { fullRoute, ROUTES } from 'general/constants/routes'
import { useOrganizationContext } from 'general/contexts/OrganizationContextProvider'
import { COURIERS_ORG } from 'general/hooks/useCurrentOrganization'
import { FC } from 'react'
import { SELF_SERVICE_ROUTES } from '../../../general/constants/selfServiceRoutes'
import { useCurrentUserContext } from '../auth-providers/CurrentUserProvider'
import { useLoggedInContext } from '../auth-providers/FirebaseProvider'

const AppGuard: FC = () => {
  const [currentOrganization] = useOrganizationContext()
  const matches = useMatches()
  const matchRoute = useMatchRoute()

  const loggedIn = useLoggedInContext()
  const currentUser = useCurrentUserContext()

  if (!loggedIn) {
    return <NavigateToRoot />
  }
  if (!currentUser) {
    return <NavigateToRoot />
  }
  if (!currentUser.roles.length) {
    return <NavigateToRoot />
  }

  if (!currentOrganization) {
    return <LoaderWindow />
  }

  const couriersMatch = !!matchRoute({
    to: fullRoute(ROUTES.app.couriers.root()),
  })

  const currentOrganizationMatch = !!matches.filter(
    (match) => match.params.organization === currentOrganization
  ).length

  if (currentOrganization === COURIERS_ORG) {
    if (!couriersMatch) {
      return <Navigate to={fullRoute(ROUTES.app.couriers.root())} />
    }
  } else if (!currentOrganizationMatch) {
    const route =
      currentOrganization === 'main'
        ? ROUTES.app.organization.root(currentOrganization)
        : SELF_SERVICE_ROUTES.selfService.organization.root(currentOrganization)

    return <Navigate to={fullRoute(route)} />
  }

  return <Outlet />
}

export default AppGuard
