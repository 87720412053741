import { Navigate } from '@tanstack/react-location'

export const webhookDetailsRouter = {
  path: 'api/webhooks/:webhookId',
  element: () => import('./').then((el) => <el.default/>),
  children: [
    {
      path: 'details',
      element: () => import('./update-form').then((el) => <el.default/>),
    },
    {
      path: 'invocations/:invocationId',
      element: () =>
        import('./webhook-invocation-details').then((el) => <el.default/>),
    },
    {
      path: 'invocations',
      element: () =>
        import('./webhook-invocations').then((el) => <el.default />),
    },
    {
      element: <Navigate to={'details'} />,
    },
  ],
}
