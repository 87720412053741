import { useSetOrganization } from 'general/hooks/useCurrentOrganization'
import { FC, useEffect } from 'react'
import { useCurrentUserContext } from '../auth-providers/CurrentUserProvider'

const OrganizationGuard: FC = ({ children }) => {
  const currentUser = useCurrentUserContext()
  const setOrganization = useSetOrganization()

  useEffect(() => {
    if (!!currentUser) {
      setOrganization()
    }
  }, [currentUser, setOrganization])

  return <>{children}</>
}

export default OrganizationGuard
