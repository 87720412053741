import en from './en.json'
import lt from './lt.json'
import ru from './ru.json'

export const locales: any = {
  en,
  lt,
  ru,
}

export const getMessagesForLocale = (localeParam: string): any => {
  let locale = localeParam
  if (!locales[locale]) {
    console.warn(
      `Try to use unsupported locale: ${locale}. Take default 'en' locale`
    )
    locale = 'en'
  }

  return locales[locale]
}
