import { relativeRoute, ROUTES } from 'general/constants/routes'
import { DefaultGenerics, Navigate, Route } from '@tanstack/react-location'
import { newOrderRoutes } from 'modules/new-order/router'

export const ordersRoutes: Route<DefaultGenerics> = {
  path: relativeRoute(ROUTES.app.organization.orders.root()),
  children: [
    newOrderRoutes,
    {
      path: relativeRoute(ROUTES.app.organization.orders.list()),
      element: () => import('./orders-list').then((el) => <el.default />),
    },
    {
      path: relativeRoute(ROUTES.app.organization.orders.details()),
      element: () => import('./order-details').then((el) => <el.default />),
    },

    {
      element: (
        <Navigate to={relativeRoute(ROUTES.app.organization.orders.list())} />
      ),
    },
  ],
}
