import { add, differenceInMinutes, format, isEqual } from 'date-fns'
import HashID from 'hashids'
import * as P from 'ts-prime'
import { StorageAddressItems } from '../../general/hooks/useLocalStorage'
import { PricingRulesIncInput } from '../../sdk/root'
import {
  expressDeliveryCutOffHour,
  expressDeliveryHalfHourCutOff,
  expressDeliveryInterval,
  expressDeliveryIntervalThreshold,
  expressDeliveryStartingHour,
} from './constants'
import { FormStateMeta } from './controler'

export function isOrderCanBeCreated(date?: Date | string) {
  const dateTimeOrder = date ? new Date(date) : new Date()

  const dateOrder = new Date(
    dateTimeOrder.getFullYear(),
    dateTimeOrder.getMonth(),
    dateTimeOrder.getDate()
  )

  const notAvailableDates = [
    new Date(2022, 11, 25),
    new Date(2022, 11, 26),
    new Date(2023, 0, 1),

    // new Date(2022, 11, 19),
  ]

  if (
    notAvailableDates.some((elem) => {
      return isEqual(dateOrder, elem)
    })
  ) {
    return false
  }

  if (isEqual(dateOrder, new Date(2022, 11, 24))) {
    const hour = dateTimeOrder.getHours()

    return !(hour >= 16)
  }

  //for 31 december
  if (isEqual(dateOrder, new Date(2022, 11, 31))) {
    const hour = dateTimeOrder.getHours()

    return !(hour >= 17)
  }

  return true
}

// for testing purposes
export const testYear = 2022
export const testMonth = 7
export const testDay = 7
export const testHour = 15
export const testMinute = 31

export function shortId() {
  const date = new Date()
  const part1 = parseInt(`${date.getHours()}${new Date().getMinutes()}`)
    .toString(36)
    .toUpperCase()

  const part2 = parseInt(
    `${new Date().getSeconds()}${new Date().getMilliseconds()}`
  )
    .toString(36)
    .toUpperCase()
  return `${part1}-${part2}`
}

export function getHash(org: string | null) {
  return new HashID(
    org || P.uuidv4(),
    1,
    '-ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  )
}
export function orderId(gen: HashID) {
  return gen.encode(Date.now())
}

export const getTimeFromHHmmString = (time: string): Date => {
  const datetime = time.split(' ')

  // only time is present
  if (datetime.length === 1) {
    const base = new Date()
    const [HH, mm] = datetime[0].split(':')
    base.setHours(parseInt(HH))
    base.setMinutes(parseInt(mm))
    return base
  }

  const base = new Date(datetime[0])
  const [HH, mm] = datetime[1].split(':')
  base.setHours(parseInt(HH))
  base.setMinutes(parseInt(mm))
  return base
}

export function getInitialPickupTime(
  threshold: number = expressDeliveryIntervalThreshold,
  firstHour: number = expressDeliveryStartingHour
): Date {
  // const base = new Date(testYear, testMonth, testDay, testHour, testMinute)
  const base = new Date()

  const baseHour = base.getHours()

  if (baseHour < firstHour) {
    base.setHours(firstHour)
    base.setMinutes(0)

    return base
  }

  const nextHourDate = new Date(base)
  nextHourDate.setHours(baseHour + 1)
  nextHourDate.setMinutes(0)
  const difference = differenceInMinutes(nextHourDate, base)

  if (difference > 30 + threshold) {
    base.setMinutes(30)

    return base
  }

  if (difference > threshold && difference <= 30 + threshold) {
    base.setHours(baseHour + 1)
    base.setMinutes(0)

    return base
  }

  base.setHours(baseHour + 1)
  base.setMinutes(30)

  return base
}

export function getDeliveryTimeOptions(
  interval: number = expressDeliveryInterval,
  threshold: number = expressDeliveryIntervalThreshold,
  cutOffHour: number = expressDeliveryCutOffHour,
  halfHourCutOff: boolean = expressDeliveryHalfHourCutOff
): string[] {
  const base = getInitialPickupTime(threshold)

  if (!base) return []

  let startingHour = base.getHours()

  if (startingHour === cutOffHour) {
    return []
  }

  if (startingHour + 1 === cutOffHour) {
    base.setMinutes(30)

    if (halfHourCutOff) {
      return [
        format(new Date(base), 'HH:mm'),
        format(
          new Date(base.setTime(base.getTime() + interval * 60 * 1000)),
          'HH:mm'
        ),
      ]
    }

    return [format(new Date(base), 'HH:mm')]
  }

  const pickupIntervals: string[] = []

  while (startingHour < cutOffHour) {
    pickupIntervals.push(format(new Date(base), 'HH:mm'))

    base.setTime(base.getTime() + interval * 60 * 1000)
    startingHour = base.getHours()
  }

  if (expressDeliveryCutOffHour) {
    pickupIntervals.push(format(new Date(base), 'HH:mm'))
  }

  return pickupIntervals
}

export function getDeliveryIntervalString(time: string): string {
  const intervalStart = getTimeFromHHmmString(time)
  const intervalEnd = add(intervalStart, { minutes: 30 })

  return `${format(intervalStart, 'HH:mm')} - ${format(intervalEnd, 'HH:mm')}`
}

export const mapAddressLabelToLocalStorageItem: {
  [key: string]: keyof StorageAddressItems
} = {
  'Pickup address': 'PICKUP_ADDRESSES',
  'Drop off address': 'DROPOFF_ADDRESSES',
}

export const getFormStateMeta = (
  now: Date,
  startingHour: number = expressDeliveryStartingHour,
  cutOffHour: number = expressDeliveryCutOffHour,
  halfHourCutOff: boolean = expressDeliveryHalfHourCutOff
): FormStateMeta => {
  const currentHour = now.getHours()
  const currentMinutes = now.getMinutes()

  const tooEarlyForAsapDelivery = currentHour < startingHour
  const passedLastInterval = halfHourCutOff
    ? currentHour === cutOffHour ||
      (currentHour + 1 === cutOffHour && currentMinutes > 45)
    : currentHour + 1 === cutOffHour && currentMinutes > 15
  const afterHours = halfHourCutOff
    ? (currentHour === cutOffHour && currentMinutes >= 30) ||
      currentHour > cutOffHour
    : currentHour >= cutOffHour

  return {
    tooEarlyForAsapDelivery,
    passedLastInterval,
    afterHours,
  }
}

type PriceAddon = {
  id: string
  addon: number
  minDistance?: number
  maxDistance?: number
}
export interface PricingRulesInputUpdated extends PricingRulesIncInput {
  priceAddon?: PriceAddon
}

export const calculatePriceEstimateUpdated = (
  distance: number,
  pricingRules?: PricingRulesInputUpdated
) => {
  if (!pricingRules) return 0

  const {
    fix,
    minPrice,
    minDistanceM,
    dynamicPrice,
    dynamicPriceDistanceM,
    maxPrice,
    priceAddon,
  } = pricingRules

  let calculatedPrice = 0

  // fix scenario
  if (fix) {
    // console.log('fixed pricing scenario')
    return fix
  }

  // only dynamic price scenario
  if (!minPrice && !maxPrice && !!dynamicPrice && !!dynamicPriceDistanceM) {
    // console.log('only dynamic price scenario')
    const distanceRounded =
      Math.round((distance / 1000) * (1000 / dynamicPriceDistanceM)) /
      (1000 / dynamicPriceDistanceM)

    calculatedPrice =
      distanceRounded * (1000 / dynamicPriceDistanceM) * dynamicPrice
  }

  // only min + max scenario
  if (
    (!dynamicPrice || !dynamicPriceDistanceM) &&
    !!maxPrice &&
    !!minPrice &&
    !!minDistanceM
  ) {
    // console.log('min + max scenario')
    if (distance <= minDistanceM) {
      calculatedPrice = minPrice
    } else {
      calculatedPrice = maxPrice
    }
  }

  // dynamic + min scenario
  if (
    !maxPrice &&
    !!minPrice &&
    !!minDistanceM &&
    !!dynamicPrice &&
    !!dynamicPriceDistanceM
  ) {
    // console.log('dynamic price + min scenario')
    if (distance <= minDistanceM) return minPrice

    const distanceRounded =
      Math.round((distance / 1000) * (1000 / dynamicPriceDistanceM)) /
      (1000 / dynamicPriceDistanceM)

    calculatedPrice =
      (distanceRounded - minDistanceM / 1000) *
        (1000 / dynamicPriceDistanceM) *
        dynamicPrice +
      minPrice
  }

  // dynamic + max scenario
  if (!minPrice && !!maxPrice && !!dynamicPrice && !!dynamicPriceDistanceM) {
    // console.log('dynamic price + max scenario')
    const distanceRounded =
      Math.round((distance / 1000) * (1000 / dynamicPriceDistanceM)) /
      (1000 / dynamicPriceDistanceM)

    calculatedPrice = Math.min(
      distanceRounded * (1000 / dynamicPriceDistanceM) * dynamicPrice,
      maxPrice
    )
  }

  // dynamic + min and max scenario !!!
  if (
    !!minPrice &&
    !!minDistanceM &&
    !!maxPrice &&
    !!dynamicPrice &&
    !!dynamicPriceDistanceM
  ) {
    // console.log('dynamic price + min + max scenario')
    if (distance <= minDistanceM) return minPrice

    const distanceRounded =
      Math.round((distance / 1000) * (1000 / dynamicPriceDistanceM)) /
      (1000 / dynamicPriceDistanceM)

    calculatedPrice = Math.min(
      (distanceRounded - minDistanceM / 1000) *
        (1000 / dynamicPriceDistanceM) *
        dynamicPrice +
        minPrice,
      maxPrice
    )
  }

  if (!priceAddon) return calculatedPrice

  if (!priceAddon.minDistance && priceAddon.maxDistance) {
    if (priceAddon.maxDistance >= distance)
      return calculatedPrice + priceAddon.addon

    return calculatedPrice
  }

  if (!priceAddon.maxDistance && priceAddon.minDistance) {
    if (priceAddon.minDistance <= distance)
      return calculatedPrice + priceAddon.addon

    return calculatedPrice
  }

  if (priceAddon.maxDistance && priceAddon.minDistance) {
    if (
      distance >= priceAddon.minDistance &&
      distance <= priceAddon.maxDistance
    )
      return calculatedPrice + priceAddon.addon
  }

  return calculatedPrice
}
